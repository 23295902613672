import type { Favorite, FavoriteCreateInput, FavoriteList, FavoriteListCreateInput } from '~/models/Favorites'
import { computed } from 'vue'
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query'
import { useFavoritesApi } from '~/api/favoritesApi'
import useAuthStore from '~/stores/auth'

const QUERY_KEY: Readonly<string> = 'favorite-lists'

export const useFavorites = () => {
  const {
    getFavoriteLists: apiGetFavoriteLists,
    updateFavoriteList: apiUpdateFavoriteList,
    deleteFavoriteList: apiDeleteFavoriteList,
    createFavoriteList: apiCreateFavoriteList,
    createFavorite: apiCreateFavorite,
    removeFavoriteFromList: apiRemoveFavoriteFromList,
  } = useFavoritesApi()

  const queryClient = useQueryClient()
  const auth = useAuthStore()

  const addItemToList = useMutation({
    mutationFn: ({ item, list }: { item: FavoriteCreateInput; list: FavoriteList }) => apiCreateFavorite(item, list),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEY] })
  })

  const deleteFavoriteList = useMutation({
    mutationFn: (list: FavoriteList) => apiDeleteFavoriteList(list),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEY] }),
  })

  const updateFavoriteList = useMutation({
    mutationFn: (updatedList: FavoriteList) => apiUpdateFavoriteList(updatedList),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEY] }),
  })

  const createFavoriteList = useMutation({
    mutationFn: (newList: FavoriteListCreateInput) => apiCreateFavoriteList(newList),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEY] }),
  })

  const removeFavoriteFromList = useMutation({
    mutationFn: ({ item, list }: { item: Favorite; list: FavoriteList }) => {
      return apiRemoveFavoriteFromList({ item, list })
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEY] }),
  })

  const favoriteLists = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => apiGetFavoriteLists(),
    staleTime: Infinity,
    enabled: computed(() => auth.isAuthenticated)
  })

  const locationIds = computed(() =>
    favoriteLists
      .data
      .value
      ?.flatMap(({ favorites }) => favorites?.map(({ aunivers }) => aunivers.locationId) ?? [])
      .map((id) => Number(id))
    ?? []
  )

  return {
    createFavoriteList,
    deleteFavoriteList,
    removeFavoriteFromList,
    updateFavoriteList,
    favoriteLists,
    addItemToList,
    locationIds,
  }
}
