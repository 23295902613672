import { computed } from 'vue'
import { colorMap, getColorAndShade } from '~/utils/colors'
import { useAppColor } from '~/composables/useAppColor'

export default function useSearchColors() {
  const { themeColor } = useAppColor()

  const colorVariables = computed(() => {
    const color = themeColor.value?.name || ''
    return {
      '--ks-primary': colorMap.get(getColorAndShade(color, '40'))?.rgb,
      '--ks-input': colorMap.get(getColorAndShade(color, '5'))?.rgb,
      '--ks-inputhover': colorMap.get(getColorAndShade(color, '10'))?.rgb,
      '--ks-secondary': colorMap.get(getColorAndShade(color, '50'))?.rgb,
      '--ks-border': colorMap.get(getColorAndShade(color, '10'))?.rgb,
      '--ks-borderhoverfill': colorMap.get(getColorAndShade(color, '10'))?.rgb,
      '--ks-borderhovertext': colorMap.get(getColorAndShade(color, '50'))?.rgb,
    }
  })

  return {
    colorVariables,
  }
}
