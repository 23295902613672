import type { ContentBlogPost } from '~/models/Content/ContentBlogPost'
import { storeToRefs } from 'pinia'
import { useQuery } from '@tanstack/vue-query'
import arrayUtils from '~/utils/arrayUtils'
import useAuthStore from '~/stores/auth'
import { Subtree } from '~/models/Content/Subtree'
import { RemoteId } from '~/models/Content/RemoteId'
import { ContentType } from '~/models/Content/ContentType'
import useContentApi from '~/api/contentApi'

export default () => {
  const { findContents } = useContentApi()
  const { userGrades } = storeToRefs(useAuthStore())
  const { intersect } = arrayUtils()

  const loadPosts = (limit: number) => useQuery({
    staleTime: Infinity,
    queryKey: ['blog-posts', limit],
    queryFn: () => findContents<ContentBlogPost>({
      parentLocationIdCriterion: [RemoteId.BlogPostsFolder],
      contentTypeCriterion: [ContentType.BlogPost],
      subtreeCriterion: [Subtree.App],
      sortField: 'published',
      sortOrder: 'desc',
    }, limit)
  })

  const findPost = (locationId: number) =>  useQuery({
    staleTime: Infinity,
    queryKey: ['blog-post', locationId],
    queryFn: async () => (await findContents<ContentBlogPost>({
      locationIdCriterion: [locationId],
      contentTypeCriterion: [ContentType.BlogPost],
      subtreeCriterion: [Subtree.App],
    }, 1))[0]
  })

  const matchesGrades = (post: ContentBlogPost) => !post.grades.length
    || !userGrades.value.length
    || intersect(userGrades.value, post.grades).length > 0

  return {
    loadPosts,
    findPost,
    matchesGrades,
  }
}
