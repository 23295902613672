<script setup lang="ts">
import type { ContentImageCollection } from '~/models/Content/ContentImageCollection'
import { computed } from 'vue'
import { createReusableTemplate } from '@vueuse/core'
import { ImageCollectionMode } from '~/models/Content/ContentImageCollection'
import { useMedia } from '~/composables/useMedia'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import FullscreenContent from '~/components/utils/FullscreenContent.vue'
import Image from '~/components/media/Image.vue'
import Carousel from '~/components/media/Carousel.vue'

const props = defineProps<{
  content: ContentImageCollection
}>()

const { data: media, isLoading } = useMedia(computed(() => props.content.images?.destinationContentIds ?? []))
const [DefineImageText, ReuseImageText] = createReusableTemplate()
const sorted = computed(() =>
  media.value.toSorted((a, b) => {
    const contentIds = props.content.images?.destinationContentIds ?? []
    return contentIds.indexOf(a.contentId) - contentIds.indexOf(b.contentId)
  })
)
</script>

<template>
  <DefineImageText v-slot="{ image }">
    <RichTextRenderer :text="image.caption" />
    <small v-if="image.copyright">{{ $t('copyright.image', { copyright: image.copyright }) }}</small>
  </DefineImageText>

  <div
    v-if="content.mode === ImageCollectionMode.Carousel"
    class="grid"
  >
    <Carousel
      :style="{
        '--carousel-spacing': '2rem',
      }"
      nav="floating"
    >
      <template #default="{ index: activeIndex }">
        <div
          v-for="(image, index) in sorted"
          :key="image.contentId"
          class="grid max-h-[80vh] w-[calc(60ch+var(--col-width)*2+var(--col-gap)*3)] max-w-[80vw] grid-rows-[minmax(0,1fr),max-content]"
        >
          <FullscreenContent>
            <template #default="{ isFullscreen }">
              <Image
                :content="image"
                class="pointer-events-none col-start-1 row-start-1 !h-full !w-full"
                :class="isFullscreen ? 'object-scale-down p-4 place-self-center' : 'object-cover'"
                :width="isFullscreen ? '100vw' : '400px'"
              />
              <div
                v-if="'caption' in image && image.caption.trim()"
                class="flex flex-col bg-white p-4 transition-opacity"
                :class="{ 'opacity-0': activeIndex !== index }"
              >
                <ReuseImageText :image />
              </div>
            </template>
          </FullscreenContent>
        </div>
      </template>
    </Carousel>
  </div>

  <!-- Falls back to ImageCollectionMode.DancingImages -->
  <div
    v-else
    class="relative z-0 grid auto-cols-fr auto-rows-fr grid-cols-2 gap-2 text-base md:pb-16"
    :class="sorted.length > 6 ? 'md:grid-cols-3 lg:grid-cols-6' : 'md:grid-flow-col'"
  >
    <div
      v-for="(image, index) in sorted"
      :key="image.contentId"
      class="grid bg-white p-4 md:-mx-2"
      :class="{
        '-rotate-6': (index + 1) % 4 === 1,
        'rotate-6 lg:translate-y-16': (index + 1) % 4 === 2 && sorted.length > 6,
        'rotate-6 md:translate-y-16': (index + 1) % 4 === 2 && sorted.length <= 6,
        '-rotate-2': (index + 1) % 4 === 3,
        'rotate-2 lg:translate-y-16': (index + 1) % 4 === 0 && sorted.length > 6,
        'rotate-2 md:translate-y-16': (index + 1) % 4 === 0 && sorted.length <= 6,
      }"
      :style="{
        zIndex: index * -1,
      }"
    >
      <FullscreenContent>
        <template #default="{ isFullscreen }">
          <Image
            :content="image"
            class="col-start-1 row-start-1 !h-full max-h-[90lvh] !w-full"
            :class="isFullscreen ? 'object-contain p-4 place-self-center' : 'object-cover'"
            :width="isFullscreen ? '100vw' : '400px'"
          />
          <div
            class="row-start-2"
            :class="isFullscreen ? 'bg-white p-4' : 'mt-2'"
          >
            <ReuseImageText :image />
          </div>
        </template>
      </FullscreenContent>
    </div>
  </div>
</template>
