<script setup lang="ts">
import type { SubjectCode } from '~/models/Subject'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { KsButton } from '@aschehoug/kloss'

defineProps<{ subjectCode: SubjectCode }>()

const router = useRouter()
const { t } = useI18n()
</script>
<template>
  <div
    class="relative"
    @click.prevent="() => router.push({ name: 'subject_upcoming', params: { subject: subjectCode.toLowerCase() } })"
  >
    <aside
      class="font-small absolute -left-3 top-1 -rotate-45 rounded bg-yellow-40 px-2 py-0 text-xs font-bold uppercase tracking-wider text-green-40 hover:cursor-pointer"
      v-text="t('beta')"
    />
    <KsButton
      variant="secondary"
      shape="rounded"
    >
      {{ t('new') }}
    </KsButton>
  </div>
</template>
