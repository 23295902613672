import type { MaybeRefOrGetter } from 'vue'
import { computed, toValue } from 'vue'
import { colorMap } from '~/utils/colors'
import { ColorName, ColorTheme } from '~/models/Content/Color'

const [defaultColor] = colorMap.values()

function color(name: ColorName) {
  return colorMap.get(name) ?? defaultColor
}

const GreenSeagreen = {
  60: color(ColorName.Green60),
  50: color(ColorName.Green50),
  pop: color(ColorName.SeagreenPop),
  20: color(ColorName.Green20),
  10: color(ColorName.Green10),
  5: color(ColorName.Green5),
}

const Coral = {
  60: color(ColorName.Coral60),
  50: color(ColorName.Coral50),
  pop: color(ColorName.CoralPop),
  20: color(ColorName.Coral20),
  10: color(ColorName.Coral10),
  5: color(ColorName.Coral5),
}

const VioletYellow = {
  60: color(ColorName.Violet60),
  50: color(ColorName.Violet50),
  pop: color(ColorName.YellowPop),
  20: color(ColorName.Violet20),
  10: color(ColorName.Violet10),
  5: color(ColorName.Yellow5),
}

const BlueOrange = {
  60: color(ColorName.Blue60),
  50: color(ColorName.Blue50),
  pop: color(ColorName.OrangePop),
  20: color(ColorName.Orange20),
  10: color(ColorName.Orange10),
  5: color(ColorName.Orange5),
}

export function useColorTheme(theme: MaybeRefOrGetter<ColorTheme>) {
  const computedTheme = computed(() => {
    switch(toValue(theme)) {
      case ColorTheme.GreenSeagreen:
        return GreenSeagreen

      case ColorTheme.Coral:
        return Coral

      case ColorTheme.VioletYellow:
        return VioletYellow

      case ColorTheme.BlueOrange:
      default:
        return BlueOrange
    }
  })

  return {
    theme: computedTheme,
  }
}

export function useMonoColorTheme(theme: MaybeRefOrGetter<ColorTheme>) {
  const computedTheme = computed(() => {
    const value = toValue(theme)

    return {
      60: color(`${value}-60` as ColorName),
      50: color(`${value}-50` as ColorName),
      pop: color(`${value}-pop` as ColorName),
      20: color(`${value}-20` as ColorName),
      10: color(`${value}-10` as ColorName),
      5: color(`${value}-5` as ColorName),
    }
  })

  return {
    theme: computedTheme,
  }
}
