<script setup lang="ts">
import type { ContentBlogPost } from '~/models/Content/ContentBlogPost'
import { computed, onMounted } from 'vue'
import { KsIcon } from '@aschehoug/kloss'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useTime from '~/composables/useTime'
import useSlugify from '~/composables/useSlugify'
import { useLottie } from '~/composables/useLottie'

const props = defineProps<{ blogPost: ContentBlogPost }>()

const { slugify } = useSlugify()
const { dateTimeToAlternativeOutputFormat, isoStringToDateTime } = useTime()
const { buildImageUrlByField } = useUrlBuilder()
const { isLoading, lottieJSON, getLottie } = useLottie()

const date = computed(() => dateTimeToAlternativeOutputFormat(isoStringToDateTime(props.blogPost.publishedDate)))

const imageUrl = computed(() => {
  const imageId = Number(props.blogPost.image?.destinationContentId)
  return imageId ? buildImageUrlByField(imageId, 'image', 'banner_large') : ''
})

const lottieId = computed( () => Number(props.blogPost.lottie?.destinationContentId))
const tag = computed(() => props.blogPost.tags.length ? props.blogPost.tags[0] : '')
const title = computed(() => props.blogPost.title)
const intro = computed(() => props.blogPost.intro)

onMounted(() => lottieId.value && getLottie(lottieId.value))
</script>

<template>
  <article
    v-if="tag"
    class="group relative mx-auto flex w-full max-w-5xl animate-fade flex-col gap-4 rounded-xl md:grid md:grid-cols-2 md:gap-8"
  >
    <div class="order-1 flex flex-col gap-4 md:gap-6">
      <h3 class="text-4xl font-black !leading-tight text-gray-50 underline-offset-4 transition-all group-hover:underline md:text-5xl">
        <router-link
          :to="{ name: 'blog_post', params: { tag: slugify(tag), locationId: blogPost.locationId, blogPost: slugify(title) } }"
          class="after:absolute after:inset-0 after:z-10 after:rounded-xl after:ring-offset-2 after:focus-visible:ring"
        >
          {{ title }}
        </router-link>
      </h3>
      <div class="order-first inline-flex items-center gap-2">
        <p
          v-if="tag"
          class="text-sm font-medium uppercase tracking-widest text-seagreen-40"
          v-text="tag"
        />
        <span
          v-if="tag"
          aria-hidden="true"
        >&bull;</span>
        <p
          class="text-sm capitalize text-gray-40"
          v-text="date"
        />
      </div>
      <p
        v-if="intro"
        class="text-gray-40"
        v-html="intro"
      />
    </div>
    <div
      v-if="!isLoading"
      class="aspect-video w-full rounded-3xl bg-orange-10/30"
    >
      <LottieAnimation
        v-if="lottieJSON"
        :animation-data="lottieJSON"
      />
      <img
        v-else-if="imageUrl"
        :src="imageUrl"
        class="block size-full rounded-3xl object-cover"
        alt=""
      >
      <div
        v-else
        class="flex size-full items-center justify-center rounded-3xl bg-blue-40 text-white"
      >
        <KsIcon
          icon="newspaper"
          :scale="10"
        />
      </div>
    </div>
  </article>
</template>
