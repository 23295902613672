<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'
import { KsIcon } from '@aschehoug/kloss'

const h2Elements = ref<HTMLElement[]>([])
const visibleH2Elements = ref<HTMLElement[]>([])
const isVisible = ref(true)
const marginAboveFooter = 48

const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      visibleH2Elements.value.push(entry.target as HTMLElement)
    } else {
      const index = visibleH2Elements.value.indexOf(entry.target as HTMLElement)
      if (index > -1) {
        visibleH2Elements.value.splice(index, 1)
      }
    }
  })
}, { threshold: 0.1 })

const scrollToNextH2 = () => {
  const viewportCenter = window.innerHeight / 2
  const viewportTop = 0
  let nearestElement = null
  let minDistance = Infinity

  h2Elements.value.forEach((el) => {
    const rect = el.getBoundingClientRect()
    const distance = rect.top >= 0 ? Math.abs(rect.top - viewportCenter) : Math.abs(rect.bottom - viewportCenter)
    if (distance < minDistance) {
      minDistance = distance
      nearestElement = el
    }
  })

  if (nearestElement) {
    const rect = nearestElement.getBoundingClientRect()
    if (rect.top <= viewportTop + 40) {
      const nextElement = h2Elements.value.find(el => el.getBoundingClientRect().top > viewportTop + 40)
      if (nextElement) {
        nextElement.scrollIntoView({ behavior: 'smooth' })
        return
      }
    }
    nearestElement.scrollIntoView({ behavior: 'smooth' })
  } else {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}

const handleScroll = () => {
  const scrollPosition = window.innerHeight + window.scrollY
  const documentHeight = document.documentElement.scrollHeight
  const footerHeight = document.getElementsByTagName('footer')[0].offsetHeight
  isVisible.value = scrollPosition < documentHeight - (footerHeight + marginAboveFooter)
}

onMounted(() => {
  handleScroll()
  h2Elements.value = Array.from(document.querySelectorAll('section h2'))
  h2Elements.value.forEach((el) => observer.observe(el))
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<template>
  <button
    v-if="isVisible"
    class="fixed bottom-0 left-1/2 z-50 flex -translate-x-1/2 items-center justify-center rounded-t-full border-x-2 border-t-2 border-seagreen-50 bg-white px-6 py-3 text-2xl text-seagreen-50 transition hover:bg-seagreen-5 focus-visible:ring"
    @click="scrollToNextH2"
  >
    <KsIcon icon="chevron-down" />
  </button>
</template>
