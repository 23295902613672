<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { faUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons'
import { KsButton, KsCallout } from '@aschehoug/kloss'
import { useMessagesStore } from '~/stores/messages'
import usePaywallOverlay from '~/composables/usePaywallOverlay'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import AuLogo from '~/components/utils/AuLogo.vue'
import LoginButtons from '~/components/login/LoginButtons.vue'

const router = useRouter()
const { t } = useI18n()
const { hasLoginMessage, messages } = useMessagesStore()
const { washPage } = usePaywallOverlay()

const webshopUrl = <string>import.meta.env.VITE_WEBSHOP_TRIALURL
const currentPath = computed(() => router.currentRoute.value.fullPath)
</script>

<template>
  <Transition
    tag="div"
    class="paywall-overlay-ignore z-20"
    enter-active-class="duration-500 delay-500"
    enter-from-class="translate-y-full opacity-0"
    enter-to-class="translate-y-0 opacity-100"
    appear
    paywall-overlay-ignore
    @after-enter="washPage"
  >
    <div class="absolute h-screen w-screen bg-gradient-to-b from-transparent via-white to-white">
      <div class="container mx-auto h-screen">
        <div class="absolute inset-x-0 bottom-10 z-20 m-auto h-fit max-w-prose rounded-lg border-gray-10 bg-white p-10 text-center sm:bottom-32 sm:border-2">
          <div class="mb-3 inline-flex rounded-full p-2 text-seagreen-50">
            <AuLogo class="mx-auto h-24 w-auto pt-8 text-black" />
          </div>
          <p
            class="mb-5 mt-3"
            v-text="t('login.intro')"
          />
          <KsCallout
            v-if="hasLoginMessage"
            :title="messages?.loginMessageTitle"
            variant="warning"
            class="mb-8"
          >
            <RichTextRenderer :text="messages?.loginMessage" />
          </KsCallout>
          <div class="grid-cols-l place-items-centergap-4 sm:gap grid w-full gap-4 px-10 sm:grid-cols-2 md:grid-cols-2">
            <LoginButtons :redirect-to="currentPath" />
          </div>
          <div class="grid-cols-l grid w-full place-items-center gap-4 px-10 py-4 sm:py-6">
            <KsButton
              variant="tertiary"
              size="medium"
              :icon-left="faUpRightFromSquare"
              :href="webshopUrl"
              target="_blank"
            >
              {{ t('login.more') }}
            </KsButton>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>
