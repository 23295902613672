<script setup lang="ts">
import type { TaskUser } from '~/models/AssignTask'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { KsButton, KsIcon, KsInput } from '@aschehoug/kloss'
import { debounce } from '~/utils/functionUtils'
import { PendoFeature } from '~/models/Pendo'
import { TaskStatus } from '~/models/AssignTask'
import ScrollBox from '~/components/utils/ScrollBox.vue'
import SearchStudentsDialog from '~/components/products/exams/assignment/SearchStudentsDialog.vue'

const { taskId } = defineProps<{ taskId: string|null }>()
const students = defineModel<TaskUser[]>({ default: [] })

const { t } = useI18n()

const searchQuery = ref('')
const isScrolled = ref(false)
const isLoadingList = ref(false)

const filteredStudents = computed(() => students.value
  .filter(student => (student.fullName ?? '').toLowerCase().includes(searchQuery.value.toLowerCase()))
  .sort((a, b) => (a.fullName ?? '').localeCompare(b.fullName ?? '')))

const handleScroll = (e: Event) => {
  const target = e.target as HTMLElement
  isScrolled.value = target.scrollTop > 0
}

const onInput = debounce((event: Event) => {
  searchQuery.value = (event.target as HTMLInputElement).value
  isLoadingList.value = false
}, 500)

const handleInput = (event: Event) => {
  isLoadingList.value = true
  onInput(event)
}

const toggleStudentSelection = (student: TaskUser) => {
  students.value = students.value.map(s =>
    s.userId === student.userId
      ? { ...s, status: s.status === TaskStatus.Open ? TaskStatus.Closed : TaskStatus.Open }
      : s
  )
}

const toggleSelectAll = () => {
  const allSelected = students.value.every(student => student.status === TaskStatus.Open)
  students.value = allSelected
    ? students.value.map(student => ({ ...student, status: TaskStatus.Closed }))
    : students.value.map(student => ({ ...student, status: TaskStatus.Open }))
}

const addStudents = (studentsToAdd: TaskUser[]) => {
  students.value = [...studentsToAdd, ...students.value]
}
</script>

<template>
  <div>
    <span
      class="font-medium"
      v-text="t('assign.chooseStudents')"
    />
    <form
      v-if="students.length"
      class="mt-2 rounded-md bg-secondary pt-4"
    >
      <div
        class="flex w-full items-center justify-between px-4 pb-4 transition-all"
        :class="{ 'shadow-md': isScrolled }"
      >
        <div class="relative w-2/3">
          <div class="absolute left-5 top-1/2 z-10 flex -translate-y-1/2 justify-center text-base text-gray-40">
            <KsIcon
              icon="magnifying-glass"
              :fade="isLoadingList"
              animation-duration="500ms"
              loop-animation
            />
          </div>
          <KsInput
            :value="searchQuery"
            type="text"
            name="search"
            style="--ks-input: white; --ks-inputhover: white"
            :placeholder="t('assign.searchPlaceholder')"
            class="!rounded !pl-12 !text-base placeholder:text-gray-40"
            @input="handleInput"
          />
          <KsButton
            v-if="searchQuery.length"
            icon-left="xmark"
            shape="square"
            variant="tertiary"
            size="small"
            class="absolute right-3 top-1/2 z-10 -translate-y-1/2 text-xl"
            @click="searchQuery = ''"
          />
        </div>
        <div>
          <label
            for="select-all"
            class="flex cursor-pointer items-center gap-2 rounded px-1 py-2 text-sm font-medium transition hover:bg-secondary-hover"
          >
            <span v-text="t('assign.chooseAll')" />
            <KsInput
              id="select-all"
              type="checkbox"
              :checked="students.every(student => student.status === TaskStatus.Open)"
              :aria-checked="students.every(student => student.status === TaskStatus.Open)"
              :data-pendo="PendoFeature.TermExam.SelectStudent"
              @change="toggleSelectAll"
            />
          </label>
        </div>
      </div>
      <ScrollBox
        class="max-h-96 !overflow-x-hidden px-4 pb-4"
        @scroll="handleScroll"
      >
        <transition-group
          v-if="filteredStudents.length"
          name="list"
          tag="ul"
          class="relative"
        >
          <li
            v-for="student in filteredStudents"
            :key="`student-${student.userId}`"
            class="size-full border-b border-green-10 last:border-b-0"
          >
            <label
              :for="`id-${taskId}-${student.userId}`"
              class="flex size-full cursor-pointer items-center justify-between px-1 py-3 font-medium transition-all hover:rounded hover:bg-secondary-hover hover:pl-2"
            >
              <span v-text="student.fullName" />
              <KsInput
                :id="`id-${taskId}-${student.userId}`"
                type="checkbox"
                :checked="student.status === TaskStatus.Open"
                :aria-checked="student.status === TaskStatus.Open"
                :data-pendo="PendoFeature.TermExam.SelectStudent"
                @change="toggleStudentSelection(student)"
              />
            </label>
          </li>
        </transition-group>
        <p
          v-else
          class="text-sm"
          v-text="t('assign.noStudents')"
        />
      </ScrollBox>
    </form>
    <p
      v-else
      class="mt-1 text-gray-40"
      v-text="t('assign.noStudentsInGroup')"
    />
    <SearchStudentsDialog
      :current-students="students"
      @add-students="addStudents"
    />
  </div>
</template>

<style scoped>
.list-move,
.list-enter-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.list-leave-active {
  transition: all 0.2s cubic-bezier(0.55, 0, 0.1, 1);
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}

.list-leave-active {
  position: absolute;
}
</style>
