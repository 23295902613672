import type { Translations } from '~/translations/Model'

const nb: Translations = {
  aschehoug: 'Aschehoug',
  reset: 'Start på nytt',
  next: 'Neste',
  previous: 'Forrige',
  loading: 'Laster...',
  logout: 'Logg ut',
  back: 'Tilbake',
  backTo: 'Tilbake til {name}',
  save: 'Lagre',
  cancel: 'Avbryt',
  error: 'Noe gikk galt!',
  jumpToContent: 'Hopp til hovedinnhold',
  help: 'Hjelp',
  resource: 'Ressurs',
  resourceCenter: 'Åpne hjelpesenter',
  close: 'Lukk',
  open: 'Åpne',
  download: 'Last ned',
  downloadIn: 'Last ned {in}',
  recommendedContent: 'Foreslått innhold',
  remove: 'Fjern',
  unknownError: 'Noe gikk galt dessverre. Prøv igjen senere.',
  relatedContent: 'Relatert innhold',
  new: 'Nytt',
  beta: 'Beta',
  newShort: 'Ny',
  preview: 'Forhåndsvis',
  breadcrumbs: 'Brødsmulesti',
  copyrightBtn: 'Opphavsrett',
  login: {
    brand: 'Aschehoug univers',
    slogan: 'Med læreren. For eleven.',
    title: 'Logg inn',
    welcome: 'Velkommen til Aunivers',
    intro: 'For å se læremidlene må du logge inn',
    withServiceProvider: 'Med Feide',
    withLocalProvider: 'Uten Feide',
    logout: 'Logg ut',
    news: 'Aktuelt',
    more: 'Mer om aunivers.no',
    error: {
      title: 'Feil ved innlogging',
      unknown: 'Noe gikk galt i våre systemer. Vennligst prøv igjen.',
    }
  },
  time: {
    week: 'Uke',
    weekWithNumber: 'Uke {number}',
  },
  http: {
    404: {
      code: '404',
      message: 'Ikke funnet',
    },
  },
  notFound: {
    title: 'Finnes ikke',
    heading: 'Ooops!',
    message: 'Innholdet kan være fjernet, eller du kan mangle tilgang.',
    buttonText: 'Tilbake til forsiden',
    statusCode: 'Statuskode: 404',
  },
  calendar: {
    title: 'Kalender',
    messages: {
      noResults: 'Ingen resultater',
      noResultsInWeekSwitcher: {
        message: 'Ingen oppgaver planlagt i uke {number} for {grade}',
        solution: 'Prøv å bytte gruppe, trinn eller uke. Legg til ved å trykke på \'Se flere oppgaver\'',
      },
      noResultsDescription: 'Fant ingen uker med innhold i valgt periode',
      noWeekResources: 'Fant ingen ressurser denne uken',
      noResourcesInPeriod: 'Fant ingen ressurser i valgt periode.',
      periodUpdated: 'Periode oppdatert',
      periodUpdateError: 'Kunne ikke oppdatere periode',
      periodCreated: 'Periode opprettet',
      periodCreateError: 'Kunne ikke opprette periode',
    },
    updatePlan: {
      titlePlaceholder: 'Tittel',
      bodyPlaceholder: 'Beskrivelse',
      dialogTitle: 'Rediger periode',
      save: 'Lagre',
      change: 'Rediger',
      cancel: 'Avbryt',
      delete: 'Slett',
    },
    createPeriod: {
      titlePlaceholder: 'Tittel',
      bodyPlaceholder: 'Beskrivelse',
      new: 'Ny periode',
      dialogTitle: 'Opprett periode',
      startWeek: 'Start-uke',
      endWeek: 'Slutt-uke',
      create: 'Opprett',
      abort: 'Avbryt',
      delete: 'Slett',
    },
    note: {
      teacher: 'Skriv notat',
      studentShow: 'Se notat fra lærer',
      studentHide: 'Skjul notat fra lærer',
    },
    yearPlan: {
      sharedYearPlans: 'Kollegers planer',
    },
    errors: {
      loadYearPlans: 'Noe gikk galt ved lasting av årsplaner',
      loadSharedYearPlans: 'Noe gikk galt ved lasting av foreslåtte årsplaner',
      loadPlans: 'Noe gikk galt ved lasting av planer',
    },
  },
  groups: {
    removeSelectedGroup: 'Fjern gruppevalg',
    active: 'Aktiv',
    activeTitle: 'Aktiv gruppe',
    title: 'Ingen klasse valgt | {count} klasser valgt',
    dialogBody: 'Velg hvilket fag og trinn du planlegger for.',
    missingSubject: 'Gruppen mangler et tilkoblet fag',
    confirmData: 'Bekreft fag og trinn',
    readOnlyBody: 'Gruppen er koblet til {grade}. For å endre, ta kontakt med {admins}',
    noGrades: 'Ingen trinn funnet',
    noSubjects: 'Ingen fag funnet',
    saveGroup: 'Lagre gruppe',
    editGroup: 'Rediger gruppe',
    deleteGroup: 'Slett gruppe',
    newGroup: 'Ny gruppe',
    noGroup: 'Mangler aktiv gruppe',
    chooseGroup: 'Velg en aktiv gruppe i toppmenyen for å se planlagte ressurser.',
    cancel: 'Avbryt',
    noSave: 'Gå til fagside',
    saveError: 'Feil ved lagring av gruppe',
    saveSuccess: 'Gruppen ble lagret',
    nonWriteable: 'Grupper fra Feide kan ikke redigeres i Aunivers',
    nonAdminGroup: 'For å endre på gruppen må du ta kontakt med en administrator',
    nonAdminContact: 'For å endre på gruppen må du ta kontakt med {admins}',
    membersCount: '{count} medlemmer',
    members: 'Medlemmer',
    addMember: 'Legg til medlem i gruppen',
    addMemberShort: 'Legg til',
    removeMember: 'Fjern medlem fra gruppen',
    grade: 'Trinn',
    groupName: 'Gruppenavn',
    types: {
      basis: 'Klasser (Hentet fra Feide)',
      teaching: 'Undervisningsgrupper',
      other: 'Grupper (Laget i Aunivers)',
    },
    userSearch: {
      search: 'Søk etter brukere',
      searching: 'Søker...',
      noResults: 'Ingen resultater',
    },
    groupDeleted: 'Gruppen ble slettet',
    groupDeleteError: 'Feil ved sletting av gruppe',
    noGradesConnected: 'Ingen tilkoblede trinn eller fag',
    admin: 'Admin',
    groupDeleteConfirm: 'Er du sikker på at du vil slette denne gruppen?',
    groupNamePlaceholder: 'Skriv gruppenavn...',
    noLocalGroups: 'Du har ingen Aunivers grupper enda. <br> Trykk på "Ny gruppe" for å lage en.',
    noGroups: 'Du har ingen klasser eller grupper tilkoblet til bruker. Begynn å lage grupper enten i Feide eller ved å trykke "Opprett ny gruppe" nedenfor.',
    noGroupsTitle: 'Mangler grupper',
    copyGroup: 'Kopier gruppe',
    copyOf: '{groupName} (kopi)',
  },
  header: {
    chooseGroup: 'Velg gruppe',
    showMenu: 'Vis sidemeny',
    notFound: 'Ingen ressurser funnet for {grade}',
    teacherGuide: {
      teacherGuide: 'Lærerveiledning',
      teacherGuideFor: 'Lærerveiledning: {title}',
      tip: 'Tips',
    },
  },
  settings: {
    title: 'Innstillinger',
    roleTeacher: 'Lærer hos',
    roleStudent: 'Elev ved',
    roleAtOrganization: '{role} {organization}',
    updated: 'Innstillinger lagret!',
    activeGroup: 'Du har en aktiv gruppe ({groupName})',
    missingGrade: 'Gruppen din mangler et tilkoblet trinn',
    missingSubject: 'Gruppen din mangler et tilkoblet fag',
    changeSubjectInfo: 'Du kan fortsatt redigere hvilke fag du vil ha synlig på forsiden!',
    groupInfo: 'Grupper gjør det enklere å navigere riktig innhold! Derfor krysser vi automatisk av på innstillingene nedenfor basert på trinn og fag koblet til din gruppe',
    chooseGrade: 'Velg skoleslag for å se relaterte fag',
    chooseGradeToClose: 'Du må velge et skoleslag',
    chooseGradeType: 'Velg et skoleslag',
    gradeTypeWarning: 'Noe er ikke helt riktig. Trykk på "Innstillinger" for å velge ditt skoleslag.',
    readOnlyGradesSpansAcrossgradeTypes: {
      title: 'Feide-trinn på tvers av skoleslag',
      info: 'Dine Feide-trinn dekker flere skoleslag. Alle valgte trinn vises når du velger skoleslag, og bytter du skoleslag, blir trinnene for det skoleslaget også tilgjengelige i Aunviers, sammen med trinnene fra Feide.',
    },
    sections: {
      display: 'Visningsvalg',
      gradeType: 'Skoleslag',
      language: 'Målform',
      instruction: 'Undervisning',
      tts: 'Talesyntese',
    },
    drawers: {
      customization: 'Fag og skoleslag',
      language: 'Språk',
      groups: 'Klasser og grupper',
      plans: 'Foreslåtte planer',
      licenses: 'Lisenser',
      shortcuts: 'Snarveier',
      subscription: 'Abonnement',
    },
    shortcuts: {
      digitalBooks: 'Unibok - Digitale bøker',
      oldContent: 'Innhold fra Lokus (LK-06)',
    },
    upperSecondaryWeirdness: {
      cantRemoveLastSelected: 'Du kan ikke fjerne det siste valgte trinnet. Velg heller et annet skoleslag.',
      useArrowToTheRight: 'Bruk pila til høyre for å velge Videregående-trinn',
    },
  },
  wizard: {
    warningTitle: 'Vent litt!',
    warningText: 'Velg både skoleslag og fag før du lukker vinduet.',
    nextStep: 'Neste',
    prevStep: 'Forrige',
    step: 'Steg {activeStep} av {steps}',
    finish: 'Fullfør',
    grades: 'Velg hvilket skoleslag du underviser på',
    subjects: 'Hvilke fag ønsker du å se?',
    language: 'Velg foretrukket målform',
  },
  licenses: {
    title: 'Lisenser',
  },
  home: {
    title: 'Hjem',
    welcome: 'Velkommen til Aschehoug univers!',
    yourSubjects: 'Dine fag',
    yourProducts: 'Dine produkter',
    yourAddons: 'Dine tilleggsprodukter',
    showMoreSubjects: 'Vis flere fag',
    showLessSubjects: 'Vis færre fag',
    showMoreProducts: 'Vis flere produkter',
    showLessProducts: 'Vis færre produkter',
    upperSecondaryProducts: 'VGS-produkter',
    greetings: {
      morning: 'God morgen',
      afternoon: 'God ettermiddag',
      evening: 'God kveld',
      night: 'God natt',
    },
  },
  notifications: {
    title: 'Varsler',
    notification: 'Varsel',
    today: 'I dag',
    thisWeek: 'Siste syv dager',
    markRead: 'Marker som lest',
    markAllRead: 'Marker alle som lest',
    allNotifications: 'Alle varsler',
    allNotificationsMarkedAsRead: 'Alle varsler ble markert som lest',
    sentNotifications: 'Sendte varsler',
    receivedNotifications: 'Mottatte varsler',
    noNotifications: 'Hurra! Du har ingen nye varsler',
    noReceivedNotifications: 'Du har ingen nye mottatte varsler',
    noSentNotifications: 'Du har ikke sendt varsler nylig',
    chooseNotificationType: 'Velg type varsel',
    viewAll: 'Se alle',
    goToResource: 'Gå til ressurs',
    received: 'Mottatt',
    sent: 'Sendt',
  },
  metadata: {
    weekdays: {
      monday: 'Mandag',
      tuesday: 'Tirsdag',
      wednesday: 'Onsdag',
      thursday: 'Torsdag',
      friday: 'Fredag',
      saturday: 'Lørdag',
      sunday: 'Søndag',
    },
    subscriptionGrades: {
      '1-7': '1–7',
      '8-10': '8–10',
      'vgs': 'VGS',
    },
    gradeTypes: {
      lowerPrimary: 'Barneskole 1-4',
      upperPrimary: 'Barneskole 5-7',
      lowerSecondary: 'Ungdomsskole',
      upperSecondary: 'Videregående',
      adultEducation: 'Voksen',
      prepEducation: 'Forkurs',
      short: {
        lowerPrimary: '1–4',
        upperPrimary: '5–7',
        lowerSecondary: '8–10',
        upperSecondary: 'Videregående',
        adultEducation: 'Voksen',
        prepEducation: 'Forkurs',
      }
    },
    grades: {
      aarstrinn1: 'Trinn 1',
      aarstrinn2: 'Trinn 2',
      aarstrinn3: 'Trinn 3',
      aarstrinn4: 'Trinn 4',
      aarstrinn5: 'Trinn 5',
      aarstrinn6: 'Trinn 6',
      aarstrinn7: 'Trinn 7',
      aarstrinn8: 'Trinn 8',
      aarstrinn9: 'Trinn 9',
      aarstrinn10: 'Trinn 10',
      vg1: 'Vg1',
      vg2: 'Vg2',
      vg3: 'Vg3',
      vok: 'Voksen',
      prep: 'Forkurs',
      forkurs: 'Forkurs',
    },
    gradesPunctuated: {
      aarstrinn1: '1. trinn',
      aarstrinn2: '2. trinn',
      aarstrinn3: '3. trinn',
      aarstrinn4: '4. trinn',
      aarstrinn5: '5. trinn',
      aarstrinn6: '6. trinn',
      aarstrinn7: '7. trinn',
      aarstrinn8: '8. trinn',
      aarstrinn9: '9. trinn',
      aarstrinn10: '10. trinn',
      vg1: 'Vg1',
      vg2: 'Vg2',
      vg3: 'Vg3',
      vok: 'Voksenopplæring',
      forkurs: 'Forkurs',
      prep: 'Forkurs',
    },
    gradesShort: {
      aarstrinn1: '1.',
      aarstrinn2: '2.',
      aarstrinn3: '3.',
      aarstrinn4: '4.',
      aarstrinn5: '5.',
      aarstrinn6: '6.',
      aarstrinn7: '7.',
      aarstrinn8: '8.',
      aarstrinn9: '9.',
      aarstrinn10: '10.',
      vg1: 'vg1',
      vg2: 'vg2',
      vg3: 'vg3',
      vok: 'vok',
      forkurs: 'Forkurs',
      prep: 'forkurs',
    },
    subjects: {
      ENG: 'Engelsk',
      FSP: 'Fremmedspråk',
      KRO: 'Kroppsøving',
      MAT: 'Matematikk',
      NAT: 'Naturfag',
      FLE: 'Flerfaglig',
      NOR: 'Norsk',
      SAF: 'Samfunnsfag',
      FYS: 'Fysikk',
      GEO: 'Geografi',
      HEL: 'Helse',
      HIS: 'Historie',
      KJE: 'Kjemi',
      MED: 'Mediefag',
      POL: 'Politikk',
      PSY: 'Psykologi',
      REL: 'Religion og etikk',
      KRL: 'KRLE',
      TVE: 'Året rundt',
      INF: 'Informasjonsteknologi',
      SPA: 'Spansk',
      TYS: 'Tysk',
      FRA: 'Fransk',
      GFG: 'Geofag',
      KHV: 'Kunst og håndverk',
      MUS: 'Musikk',
      MHE: 'Mat og helse',
      SOK: 'Sosialkunnskap',
      SOS: 'Sosiologi og sosialantropologi',
      POM: 'Politikk og menneskerettigheter',
      SAK: 'Samfunnskunnskap',
      SAG: 'Samfunnsgeografi ',
      LANG: 'Språkfag',
    },
    language: {
      title: 'Målform',
      nb: 'Bokmål',
      nn: 'Nynorsk',
    },
    taskTypes: {
      article: 'Artikkel',
      video: 'Video',
      audio: 'Lyd',
      file: 'Fil',
      document: 'Dokument',
      task: 'Oppgave',
      resource: 'Ressurs',
      digitalbook: 'Digitalbok',
      game: 'Spill',
    },
  },
  organization: {
    title: 'Velg en aktiv skole for din økt',
    choose: 'Velg skole',
  },
  filters: {
    forTeacher: 'Til lærer',
    content: 'Innhold',
    emptyFilter: 'Tøm filter',
    chooseTheme: 'Velg tema',
    chooseTaskType: 'Velg innholdstype',
    chooseSubject: 'Velg fag',
    chooseGrade: 'Velg trinn',
    chooseGradeType: 'Velg skoleslag',
    chooseGroup: 'Velg gruppe',
    chooseLanguage: 'Velg målform',
    chooseFilters: 'Vis filtre',
    hideFilters: 'Skjul filtre',
    chooseSorting: 'Velg sortering',
    chooseProduct: 'Velg bok',
    chooseWeekNumber: 'Velg uke',
    chooseHeader: 'Velg tema',
    chooseSchoolYear: 'Velg skoleår',
    noResourcesFound: 'Ingen ressurser funnet',
    noProducts: 'Ingen bøker funnet',
    noSubjects: 'Ingen fag funnet',
    toLegacySubject: 'Gammelt oppsett',
    filteredAway: {
      title: 'Fant ingen ressurser',
      messageWithGrades: 'Du kan se innhold ved å bytte til følgende trinn:',
      messageWithGradeTypes: 'Du kan se innhold ved å bytte til følgende skoleslag:',
      messageWithoutGrades: 'Prøv å endre på filtreringen, eller ta kontakt med kundeservice',
    },
    subject: 'Fag',
    grade: 'Trinn',
    genre: 'Sjanger',
    product: 'Bok',
    header: 'Overskrift',
    taskType: 'Innholdstype',
    filterSettings: 'Visningsvalg',
    sortSettings: 'Sorteringsvalg',
    noHits: 'Oi! Ser ut som filteret ditt ikke har noen treff.',
    trySomethingElse: 'Prøv andre kombinasjoner for å få flere resultater.',
    frontpage: 'Forside {subjectName}',
    settings: {
      suggested: 'Foreslått rekkefølge',
      alphabetical: 'Alfabetisk',
      studentActivity: 'Siste elevaktivitet',
      random: 'Tilfeldig sortering',
    },
    values: {
      allSubjects: 'Alle fag',
      thisWeek: 'Denne uken',
      wholeClass: 'Hele klassen',
      week: {
        previous: 'Forrige uke',
        current: 'Denne uken',
        next: 'Neste uke',
        previousFourWeeks: 'Siste fire uker',
        previousThreeMonths: 'Siste tre måneder',
        fromSchoolYearStart: 'Siden skolestart',
        yearplan: 'Se årsplan',
      },
    },
  },
  favorite: {
    favoriteListsPageTitle: 'Favoritter',
    favoriteListsViewTitle: 'Dine favoritter',
    save: 'Lagre favoritt',
    counted: '{number} favoritter',
    addToList: 'Lagre som favoritt',
    addToListSuccess: '{name} ble lagt til i {listName}',
    createListDialogTitle: 'Ny favorittliste',
    createListButtonName: 'Opprett ny favorittliste',
    favoriteListsBackButton: 'Dine favorittlister',
    favoriteListDropdownLabel: 'Velg favorittliste',
    createListNameLabel: 'Gi listen et navn',
    createSuccess: 'Opprettet liste',
    deleteSuccess: 'Slettet liste',
    deleteList: 'Slett listen',
    removeFromList: 'Fjern fra listen',
    removedItemFromListSuccess: '{name} fjernet fra {listName}',
    deleteConfirmationText: 'Vil du virkelig slette {name}?',
    editListDialogTitle: 'Endre navn',
    editListNameLabel: 'Gi listen et nytt navn',
    editListNoteLabel: 'Skriv et notat for listen',
    editListInfo: 'Endre navn',
    noneCounted: 'Ingen favoritter',
  },
  plans: {
    plan: 'Plan',
    plannedThisWeek: 'Planlagt denne uka',
    allPlans: 'Alle planer',
    allYears: 'Alle år',
  },
  plan: {
    title: 'Planlagt',
    content: 'Planlagt innhold',
    add: 'Legg i plan',
    sharePlan: 'Knytt til gruppe',
    copySelection: 'Kopieringsvalg',
    copyYearPlan: 'Kopier plan',
    deleteYearPlan: 'Slett plan',
    copyYearPlanToGroup: 'Kopier ressurser',
    copyYearPlanToGroupExplanation: 'Dette vil kopiere ressursene som er delt med {groupName} i {weekNumber} til en annen gruppe.',
    chooseGroupToCopyTo: 'Velg gruppe å kopiere ressurser til',
    noPlans: 'Fant ingen planlagte ressurser i {subject} i {weekNumber}',
    goToResources: 'Gå til fagsiden for {subject}',
    resourcesCopied: 'Ressursene ble kopiert til {groupName}',
  },
  yearPlan: {
    plural: 'Planer',
  },
  learningPath: {
    learningPathOverview: 'Åpne oversikt over læringsløp',
    learningPathParts: '{partsCount} deler',
    noResourcesFound: 'Fant ingen ressurser i læringsløpet',
  },
  links: {
    seeAll: 'Se alle',
    viewTasks: 'Se ukens planlagte ({number})',
    viewMoreTasks: 'Tilbake til fagside',
    loadingTasks: 'Laster ukens planlagte',
  },
  contentTypes: {
    default: 'Ressurs',
    resource: 'Ressurs',
    folder: 'Mappe',
    product: 'Produkt',
    product_container: 'Oversikt',
    product_article: 'Artikkel',
    product_package: 'Innholdspakke',
    product_header: 'Tema',
    product_tailor: 'Ressurs',
    product_part: 'Del',
    part: 'Del av læringsløp',
    product_colophon: 'Kolofon',
    article: 'Artikkel',
    learning_path: 'Læringsløp',
    document: 'Dokument',
    landing_page: 'Forside',
    cloubi_content: 'Ressurs',
    video: 'Video',
    image: 'Bilde',
    upload_video: 'Video',
    activetextbook: 'Interaktiv bok',
    drawing_tool: 'Tavleressurs',
    file: 'Fil',
    audio: 'Lyd',
    svg: 'Bilde',
    game_board: 'Spillbrett',
    game_part: 'Øve',
    form_writer: 'Skriveramme',
    epub: 'E-bok',
    link: 'Link',
    literary_text: 'Litterær tekst',
    peer_gynt: 'Spill',
    upload_file: 'Opplasting',
    book_reader: 'Bokleser',
    presentation: 'Presentasjon',
    flyt_task: 'Oppgave',
    task: 'Oppgave',
    pdf_book: 'Digitalbok',
    digitalbook: 'Digitalbok',
    game: 'Spill',
    cloubi: 'Ressurs',
    blog_post: 'Blogginnlegg',
    guide: 'Lærerveiledning',
    model3d: '3D-Modell',
    image_360: '360-Bilde',
    image_collection: 'Bildesamling',
    reading_aid: 'Lesehjelper',
    colophon: 'Kolofon',
    app_article: 'Tekst',
    app_article_teacher: 'Lærerveiledning',
    flashcard_deck: 'Flashcards',
    chatbot: 'Chatbot',
  },
  sections: {
    none: 'Mangler innhold til dine trinn og fag. Prøv å endre på innstillingene.',
    teacherResources: 'Lærerressurser for {subject}, {grade}',
  },
  activity: {
    groupHasNoStudents: 'Gruppa du har valgt har ingen elever i seg. Kanskje du må legge de til først?',
    visited: 'Besøkt',
    youHaveCompletedAll: 'Du har fullført hele',
    errorLoadingActivities: 'Noe gikk galt ved lasting av elevaktivitet. Prøv å oppfriske siden og prøve på nytt!',
    openActivity: 'Åpne',
    youHaveCompletedNOfTotal: 'Du har fullført {n} av {total}',
    lastInteraction: 'Sist besøkt',
    planActivity: 'Planlegg',
    planActivityInMoreWeeks: 'Planlegg i flere uker',
    planUnplanned: 'Planlegg i en uke',
    notPlannedInfo: 'Ressurser som ikke er planlagt, men som elevene har besøkt likevel',
    removePlannedActivity: 'Fjern',
    plannedInWeek: 'Ukene hvor denne ressursene er planlagt',
    planned: 'Planlagte ressurser med elevaktivitet',
    pageTitle: 'Elevaktivitet',
    notPlannedActivities: 'Ressurser med elevaktivitet',
    completed: 'Fullført',
    actionOnLine: 'Vis valg for {title}',
    nOfTotal: 'av {total} har besøkt',
    noStudentActivities: {
      title: 'Det er ingen aktivitet å vise',
      body: 'Her er det ingen registrert aktivitet enda. Prøv å velge en annen uke!',
    },
    noActivities: {
      title: 'Det er ingen aktivitet å vise',
      body: 'Her er det ingen aktivitet å vise enda. Prøv å velge en annen gruppe, et annet fag, eller å se på en annen uke.',
    },
    changeGroup: {
      button: 'Endre gruppe',
      title: 'Velg gruppe',
      body: 'For å se elevaktivitet må du velge en gruppe med elever å se elevaktivitet for.',
      cancel: 'Avbryt',
      confirm: 'Velg gruppe',
    },
    selectGroup: 'Velg en gruppe',
    title: {
      forStudent: 'Din aktivitet på Aunivers',
      forTeacher: 'Elevaktivitet',
    },
    callout: {
      noData: {
        title: 'Fant ikke aktivitet',
        message: 'Her klarte vi ikke finne aktivitet. Om du tror dette er feil kan du forsøke å laste siden på nytt.'
      },
    },
    messages: {
      studentActivity: 'Elevaktivitet',
      activityForStudent: 'Aktivitet for {student}',
      noAnswer: 'Finner ikke besvarelse',
      progressText: '{filledFields} av {totalFields}',
    },
    status: {
      visited: 'Besøkt',
      notStarted: 'Ikke påbegynt',
    },
    button: {
      toAssignment: 'Vis mer',
      openActivity: 'Vis aktivitet for ressurs {title}'
    },
  },
  planner: {
    changeGroup: {
      button: 'Endre gruppe',
      title: 'Velg gruppe',
      body: 'For å se planlagte oppgaver må du velge en gruppe med elever å se oppgaver for.',
      cancel: 'Avbryt',
      confirm: 'Velg gruppe',
    },
    addToWeek: {
      title: 'Legg til i uke',
      planTitle: 'Tema for {week} {year}',
      notCreated: 'Ressursen ble ikke lagt til',
      noGroup: 'Velg en aktiv gruppe, øverst på siden, for å planlegge',
      noGroupShort: 'Velg en gruppe',
      noGroups: 'Du har ingen grupper, og kan derfor ikke planlegge',
      noYearPlan: 'Ingen plan funnet for denne ressursen',
      noWeek: 'Uka ble ikke funnet i planen',
      noRemove: 'Mangler tilgang',
      removed: 'Ressursen ble fjernet',
      failed: 'Noe gikk galt. Prøv igjen senere',
      pastWeek: 'Kan ikke planlegge bakover i tid',
    },
    selectYearPlanDialog: {
      title: 'Velg hvilken årsplan vi skal bruke',
      description: 'Du har flere årsplaner for denne gruppa. På grunn av det får du nå velge hvilken vi skal bruke, eller om vi skal opprette en ny. Noen grupper har allerede fag, og da vil vi automatisk velge gruppa sitt fag for deg, men du kan velge flere fag hvis du trenger det.',
    },
    selectGradeDialog: {
      title: 'Velg trinn',
      description: 'Velg et trinn for denne gruppa: «{group}»',
      explanation: 'For å kunne lagre og hente ut informasjon riktig må vi ha et trinn for denne gruppa. Siden det ikke går an å hente det ut automatisk må du oppgi det her.',
    },
    selectWeekNumberDialog: {
      selectWeek: 'Velg uke',
      title: 'Planlegg ressurs',
    },
    addResource: {
      error: 'Fikk ikke lagt ressursen til i uka',
      success: 'Ressursen ble lagt til i uke {weekNumber}',
      resourceInWeeks: 'Ressursen «{resourceTitle}» finnes allerede i følgende uker:',
      addToWeek: 'Legg til ressurs i uke {weekNumber}',
      removeFromWeek: 'Fjern ressurs fra uke {weekNumber}',
      addedToWeek: 'Lagt til i uke',
      alreadyPlanned: 'Allerede planlagt',
      moreWeeks: 'Vis alle uker',
    },
    createYearPlan: {
      title: 'Planlegger',
      body: 'Velg hvor du vil planlegge denne ressursen',
      missingOrganizationOrGroups: 'Uten grupper kan du ikke planlegge',
      missingActiveGroup: 'Velg en aktiv gruppe først',
      missingSubject: 'Velg et fag å planlegge i',
      missingGrade: 'Velg et trinn å planlegge i',
      missingWeekNumber: 'Mangler uke å planlegge i',
      save: 'Lagre plan',
      saveDisabledTooltip: 'Det er noe informasjon som mangler, og da kan vi ikke lagre',
    },
    createResource: {
      title: 'Del egen ressurs',
      upload: 'Del en fil',
      url: 'URL',
      shareUrl: 'Del en URL',
      save: 'Lagre ressurs',
      isUploading: 'Laster...',
      editFileName: 'Rediger navn på ressursen',
      missingFileName: 'Mangler filnavn',
      fileNamePlaceholder: 'Skriv et navn...',
      maxFileSize: 'Maks filstørrelse: {mb}MB',
      dragAndDrop: 'Dra og slipp en fil fra skrivebordet eller <strong>trykk her</strong>',
      fileIsTooLargeTitle: 'For stor fil',
      fileIsTooLargeText: 'Denne filen er for stor! Vi har en maks filstørrelse på {mb}MB. Kan du velge en mindre fil?',
      missingResource: 'Ingen ressurs er opprettet',
      downloadError: 'Filen ble ikke funnet',
      unknownError: 'Noe gikk galt dessverre. Prøv igjen senere.',
      linkError: 'Lenken er ikke gyldig. Prøv igjen.',
      linkTest: 'Test at lenka fungerer',
      linkPlaceholder: 'Lim inn URLen her',
      created: 'Ressursen ble opprettet og delt i uke {weekNumber}',
      unable: 'Velg en aktiv gruppe i toppmenyen',
      blockedHost: 'Deling av lenker til {host} er ikke tillatt',
      invalidUrl: 'Deling av denne lenken er ikke tillatt',
    },
    suggestedYearPlans: {
      title: 'Foreslåtte planer',
      description: 'Her er Aschehougs foreslåtte planer, klare for kopiering ut i uker.',
      filteredByActiveGroup: 'Viser planer for {grade}, som er trinnet som er satt for den aktive gruppen «{group}». Fagene er de samme som i innstillingene dine.',
      filteredBySettings: 'Planene er filtrert på fag og trinn fra innstillingene dine.',
      showAll: 'Vis alle foreslåtte planer',
      noYearPlansForYou: 'Vi har ingen planer for de fag og trinn du har valgt. Derfor viser vi deg alle planene vi har.',
    },
    copyYearPlan: {
      title: 'Kopierer plan: { title }',
      body: 'Velg hvor du vil kopiere denne planen',
      activatePreSharing: 'Del ukeplaner til elevene dine uken før de er planlagt',
      displayToColleagues: 'Gjør planen synlig for dine kolleger',
      displayToColleaguesNote: 'Dine kolleger vil kunne se planens navn og temaer. De kan ikke åpne planen, men be deg om tilgang til den.',
      every: 'Hver',
      showMore: 'Vis {howMany} flere årsplaner',
      confirmSameTitle: 'Planen har samme navn',
      titleNotChanged: 'Du har ikke endret tittelen på årsplanen. Vil du likevel kopiere den?',
      copy: 'Kopier!',
      cancel: 'Vent litt …',
      goToPreview: 'Forhåndsvis plan',
      placeholders: {
        chooseGroup: 'Velg gruppe',
        chooseSchoolYear: 'Velg skoleår',
        chooseGrade: 'Velg trinn',
        chooseSubject: 'Velg fag',
      },
      errors: {
        noTitle: 'Du må velge en tittel',
        noSchoolYear: 'Du må velge et skoleår',
        noGrade: 'Du må velge et trinn',
        noGroup: 'Du må velge en gruppe',
        noSubject: 'Du må velge et fag',
        groupSubjectAndGradeDoesNotMatch: 'Gruppens fag og trinn ({subject}, {grade}) er ulikt det som er valgt for denne planen. Har du valgt riktig gruppe?',
        yearPlanSubjectAndGradeDoesNotMatch: 'Planens foreslåtte fag og trinn er ulikt det som er valgt. Har du valgt riktig plan?',
      },
      preview: {
        yearPlanDialog: 'Forhåndsviser planen «{planTitle}» før kopiering',
        explanation: 'Dette er en forhåndsvisning av hvordan denne planen ser ut. Ressursene har blitt fordelt ut over uker gjennom skoleåret.',
        moveToWeek: 'Flytt',
        moveToWeekNumber: 'Uke {weekNumber}',
        plannedInWeek: 'Uke {weekNumber}',
        copy: 'Kopier',
        cancel: 'Avbryt',
        missingResources: 'Ingen foreslåtte ressurser denne uka.',
        duration: 'Uke {fromWeek} – Uke {toWeek}',
      },
      copyYearPlanDialog: {
        labels: {
          title: 'Tittel',
          group: 'Gruppe',
          grade: 'Trinn',
          subject: 'Fag',
        },
        planCopied: 'Planen «{title}» er kopiert! Den er merket med {grade} og {subject}.',
        planShared: 'Planen har blitt delt med gruppa {groupName}, og ressursene blir vist til elevene.',
        resourcesAppear: 'Du kan nå gå til fagsiden for {subject} og se at ressursene dukker opp i riktige uker.',
        openSubjectPage: 'Se oppgaver for {subject}',
      },
    },
  },
  dialog: {
    confirm: 'OK',
    cancel: 'Avbryt',
    choose: 'Velg',
    create: 'Opprett ny',
    add: 'Legg til',
    on: 'På',
    off: 'Av',
  },
  copy: {
    copyUrl: 'Kopier lenke',
    copyTemplate: {
      title: 'Uke {weekNumber}',
    },
    messages: {
      success: 'Kopiert',
      error: 'Kunne ikke kopiere',
    },
  },
  footer: {
    customerService: 'Kundeservice',
    whitelist: 'Hvitlisting',
    privacy: 'Personvern',
    accessibility: 'Universell utforming',
    webshop: 'Nettbutikk',
    copyright: 'Rettigheter',
    cookies: 'Cookies',
  },
  trial: {
    title: 'Velkommen!',
    message: 'Her finner du innhold til alle fag og alle trinn. <a href="{href}" target="_blank">Bestill en gratis prøvelisens</a> til vårt utforskende læringsunivers. God lærelyst!',
    noHits: 'Husk at du kan <a href="{href}" target="_blank">bestille en gratis prøvelisens</a> til vårt utforskende læringsunivers.',
  },
  license: {
    message: 'Lisensdetaljer for {name}',
    ean: 'ISBN',
    licenseModel: 'Type',
    endDate: 'Gyldig til',
    orderId: 'Ordrenr',
    usageRights: 'Antall',
    noLicenses: {
      title: 'Fant ingen lisenser',
      message: 'Om du tror dette er en feil kan du forsøke å laste inn siden på nytt eller kontakte kundestøtte.'
    }
  },
  licenseModel: {
    OPEN: 'Åpen lisens',
    SCHOOL: 'Skolelisens',
    INDIVIDUAL: 'Enkeltlisens',
    PRIVATE: 'Privatlisens',
    TRIAL_SCHOOL: 'Prøvelisens, skole'
  },
  colophon: {
    title: 'Om {subject}',
    plural: 'Kolofoner',
    back: 'Tilbake til fagside',
    notFoundSubject: 'Ingen kolofoner funnet for {subject} i {grade}',
    notFoundLocation: 'Ingen kolofon funnet',
  },
  search: {
    title: 'Søk',
    placeholder: 'Søk her...',
    lookingFor: 'Søk innenfor {filters}...',
    globalSearch: 'Søk i hele Aunivers',
    resultsCount: '{count} treff',
    currentPage: 'Side {page} av {totalPages}',
    noResults: 'Ingen resultater funnet',
    noResultsDescription: 'Fant ingen søkeresultater som samsvarer med søkeordet eller filtrene dine.',
    spellingSuggestion: 'Mente du',
    searchingIn: 'Søker i {in}',
    loadingProducts: 'Stiller inn søket',
    failed: 'Noe gikk galt',
    reset: 'Nullstill',
    resetSearch: 'Start på nytt',
    resetFilters: 'Fjern alle filtre',
    contentTypes: 'Innholdstyper',
    animationLabel: 'Animert illustrasjon som illustrerer et søk',
    popularSearches: 'Populære søkeord',
    subject: 'Søk i {subject}',
    subjectAll: 'Søk i alle {subject} ressurser',
    settings: {
      mySettings: 'Om søket',
      grade: 'Trinninnstillinger',
      results: 'Du får resultater basert på disse bøkene',
      editMessage: 'Du kan redigere trinnene dine i <b>Innstillinger > Trinn og fag</b>',
    },
    lockedContent: {
      noAccess: 'Du har ikke tilgang til dette innholdet',
      goToWebshop: 'Gå til nettbutikken',
      relatedProduct: 'Dette innholdet hører til produktet <b>{product}</b>.',
      unknownProduct: 'Dette innholdet hører til et produkt som er lisensbelagt.',
      findTeacher: 'Du finner produktet i vår nettbutikk.',
      findStudent: 'Ta kontakt med læreren din hvis du forventet å ha tilgang.',
    },
    paginator: {
      page: 'Side {page}',
      nextPage: 'Neste side',
      prevPage: 'Forrige side',
      navigation: 'Sidenavigering',
    },
    filters: {
      showGradeFilter: 'Vis trinnfiltre',
      hideGradeFilter: 'Skjul trinnfiltre',
      showSubjectFilter: 'Vis fagfiltre',
      hideSubjectFilter: 'Skjul fagfiltre',
      activeFilters: 'Aktive filtre',
      removeFilter: 'Fjern {filter} som filter',
      removeAllFilters: 'Fjern alle filtre',
      filterBySubject: 'Filtrer på fag',
      allGrades: 'Alle trinn',
    },
    suggested: {
      one: 'Rettigheter',
      two: 'Bærekraftig utvikling',
      three: 'Demokrati',
      four: 'Samfunn',
      five: 'Identitet',
    },
    sortField: {
      sorting: 'Sortering',
      score: 'Mest relevant',
      modified: 'Nylig oppdatert',
      published: 'Nylig publisert',
    },
    checkboxes: {
      has_audio: 'Med lyd',
      has_more: 'Med opplegg',
    },
    filterBoxes: {
      'target_group': 'Passer for',
      'theme': 'Tema',
      'genre': 'Sjanger',
      'series': 'Serie',
      'language': 'Språk',
      '1-4': 'Barneskole 1-4',
      '5-7': 'Barneskole 5-7',
      '8-10': 'Ungdomsskole',
      'vgs': 'Videregående',
      'voksen': 'Voksenopplæring',
      'forkurs': 'Forkurs',
      'support': 'Bok- og lydstøtte',
    },
    product: {
      back: 'Tilbake til {product}',
      placeholder: {
        Biblioteket: 'Søk i biblioteket etter tittel eller forfatter...',
        default: 'Søk her...',
      },
    }
  },
  register: {
    form: {
      title: 'Registrer ny bruker',
      intro: 'For å bruke digitale læremidler på Aunivers.no/unibok.no må du registrere deg.',
      submit: 'Registrer',
    },
    input: {
      firstName: 'Fornavn',
      lastName: 'Etternavn',
      email: 'E-postadresse',
      password: 'Ditt passord',
      terms: 'Jeg har lest, forstått og akseptert <a href="{url}" class="underline" target="_blank">brukervilkårene</a>'
    },
    invalid: {
      firstName: 'Fornavn er ikke gyldig',
      lastName: 'Etternavn er ikke gyldig',
      email: 'E-postadressen er ikke gyldig',
      password: 'Passordet er ikke gyldig',
    },
    error: {
      internal: 'Noe gikk galt ved registrering av brukeren. Vennligst prøv på nytt om en liten stund.',
      loggedIn: 'Du kan ikke registrere en bruker når du allerede er logget inn',
      invalidInput: 'Feil ved validering av dataene dine. Se over og prøv på nytt',
      alreadyExists: 'En bruker med samme e-postadresse eksisterer allerede',
      missingParams: 'Mangler nødvendig aktiveringskode',
      activate: 'Noe gikk galt ved aktivering av kontoen din',
    },
    success: {
      title: 'Vellykket registrering!',
      info: 'Brukeren din er registrert, men må bekreftes først. Dette må du gjøre nå:',
      confirm1: 'Åpne e-posten du har fått',
      confirm2: 'Klikk på lenken <b>innen to dager</b> for å aktivere brukeren din',
    },
    activate: {
      title: 'Aktivering',
      info: 'Lag et passord til brukeren din. Oppgi minst åtte tegn, inkludert minst ett tall.',
      inputText: 'Vis i klartekst',
      inputPassword: 'Skjul tekst',
      success: 'Passordet er lagret. Du kan nå logge inn.',
    },
  },
  access: {
    title: 'Tilgang',
    public: 'Åpen for alle',
    teacher: 'Kun for lærer',
  },
  blog: {
    title: 'Siste nytt i Aunivers',
    shortTitle: 'Nyheter',
    noPosts: 'Her var det tomt!',
    seeAllPosts: 'Se alle nyhetssaker',
    subpages: {
      all: 'Alle',
      news: 'Nyheter',
      inspiration: 'Inspirasjon',
    }
  },
  mimeType: {
    pdf: 'PDF',
    word: 'Word',
    excel: 'Regneark',
    video: 'Video',
    audio: 'Lydfil',
    image: 'Bilde',
    powerpoint: 'Presentasjon',
    archive: 'Arkiv',
    file: 'Fil',
  },
  bookreader: {
    error: 'Kunne ikke laste inn bokleser',
    loading: 'Laster inn bokleser',
    readBook: 'Les bok',
  },
  flashcards: {
    error: 'Kunne ikke laste inn flashcards',
    tip: 'Trykk på kortene for å snu dem rundt.',
    start: 'Start',
    retry: 'Prøv på nytt',
    complete: 'Da var du igjennom!',
    stage: '{n} av {total}',
  },
  content: {
    published: 'Utgitt',
    language: 'Språk',
    pageCount: 'Antall sider',
    audio: 'Lyd',
    recordedAudio: 'Innlest lyd',
    inSeries: 'Inngår i serie',
  },
  presentation: {
    page: 'Side',
    pageNumber: 'Side {pageNumber}',
    pageNumberOfTotal: 'Side {pageNumber} / {totalPages}',
    teacherGuide: 'Lærerveiledning',
    menu: {
      menu: 'Meny',
      content: 'Innhold',
    },
  },
  article: {
    word: 'Ord',
    text: 'Tekst',
    toTeacher: 'Til lærer',
    toStudent: 'Veiledning',
    teacherGuide: 'Lærerveiledning',
    studentGuide: 'Veiledning',
    error: 'Noe gikk galt med å hente innholdet. Vennligst prøv igjen.',
    noContent: 'Mangler innhold ...',
  },
  media: {
    playAudio: 'Spill av lyd',
    pauseAudio: 'Pause lyd',
    closeAudio: 'Lukk lyd',
    showCopyright: 'Vis rettigheter',
    hideCopyright: 'skjul rettigheter',
  },
  lottie: {
    play: 'Spill av animasjon',
    pause: 'Pause animasjon',
  },
  licenseControl: {
    message: {
      title: 'Du har ikke tilgang til dette innholdet',
      body: {
        teacher: 'Vi klarte ikke å finne en gyldig lisens på dette innholdet. Om du mener dette er en feil, kontakt kundestøtte for hjelp',
        student: 'Spør læreren din om hjelp',
      },
      product: {
        library: '<b>Biblioteket</b> er fullt av digitale bøker, skapt for å gi lesekompetanse og leseglede gjennom hele grunnskolen. Du finner bøker med innlest lyd, bøker på engelsk og populære serier.',
        paperPlus: '<b>Papir+</b> gir deg en mengde elevark med selvforklarende oppgaver i en rekke fag på 1.-7. trinn. Skriv ut og ta med rett inn i timen.',
        exam: '<b>Terminprøvene</b> kommer hver høst og vår i norsk, matematikk og engelsk for ungdomstrinnet. Her får du komplette løsningsforslag og prøver, i tillegg til støttemateriell. Du får mulighet til å tilpasse, endre og differensiere oppgavene slik at dine elever får en unik prøve.',
      },
      upgrade: {
        noSubscription: 'Du kan bestille prøvelisens.',
        trial: 'Du har prøvelisens. Lisens kan kjøpes i nettbutikken.',
        why: 'Abonnementet må oppgraderes for å få tilgang til Biblioteket/Papir+/Terminprøver',
        who: 'Snakk med din skoleleder om å oppgradere abonnementet skolen din har på Aunivers.',
      },
    },
    buttons: {
      back: 'Tilbake',
      webshop: 'Til nettbutikk',
      trial: 'Prøvelisens',
    },
  },
  fullscreen: {
    close: 'Lukk fullskjerm',
    open: 'Åpne fullskjerm',
  },
  pdfViewer: {
    downloadDisabled: 'Beklagar, men du kan ikkje lagre denne PDFen.',
  },
  labels: {
    product_article: 'Produktartikkel',
    learning_path: 'Læringsløp',
    file: 'Fil',
    product_part: 'Del av løp',
    video: 'Video',
    article: 'Artikkel',
    task: 'Oppgave',
    resource: 'Ressurs',
    digitalbook: 'Digitalbok',
    audio: 'Lyd',
    audio_transcription: 'Tekst',
    part: 'Del av løp',
    game: 'Spill',
    document: 'Dokument',
    book_reader: 'Bokleser',
    presentation: 'Presentasjon',
    other: 'Annet',
    app_teacher: 'Lærerveiledning',
    podcast: 'Podkast',
    essay: 'Fagtekst',
    activity: 'Aktivitet',
    map: 'Kart',
    print: 'Utskrift',
    fiction: 'Skjønnlitterær tekst',
    poem: 'Dikt',
    short: 'Novelle',
    experiment: 'Forsøk',
    pause: 'Avbrekk',
    text: 'Tekst',
    wimmelimage: 'Myldrebilde',
    teacher: 'Lærerveiledning',
    theme: 'Tema',
    package: 'Innholdspakke',
    recommended: 'Anbefalt',
    product: 'Produkt',
    chatbot: 'Chatbot',
    flashcards: 'Flashcards',
    subject_matter: 'Fagstoff',
    model_text: 'Modelltekst',
    summary: 'Sammendrag',
    grammar: 'Grammatikk',
    dialog: 'Dialog',
  },
  activities: {
    'messages': {
      title: 'Aktiviteter',
    },
    'bruke begrepene': 'Bruke begrepene',
    'diskutere': 'Diskutere',
    'leke': 'Leke',
    'lese': 'Lese',
    'lytte': 'Lytte',
    'reflektere': 'Reflektere',
    'skrive': 'Skrive',
    'snakke': 'Snakke',
    'synge': 'Synge',
    'utforske': 'Utforske',
    'øve': 'Øve',
    'lyd': 'Lyd',
    'video': 'Video',
    'lage': 'Lage',
  },
  genre: {
    'romanutdrag': 'Romanutdrag',
    'fortelling': 'Fortelling',
    'novelle': 'Novelle',
    'eventyr': 'Eventyr',
    'fantasy': 'Fantasy',
    'dikt': 'Dikt',
    'sangtekst': 'Sangtekst',
    'fagartikkel': 'Fagartikkel',
    'saktekst': 'Saktekst',
    'debattinnlegg': 'Debattinnlegg',
    'nyhetsartikkel': 'Nyhetsartikkel',
    'reklame': 'Reklame',
    'some-innlegg': 'SoMe-innlegg',
    'tale': 'Tale',
    'appell': 'Appell',
  },
  copyright: {
    open: 'Åpne bildebeskrivelse',
    close: 'Lukk bildebeskrivelse',
    image: 'Bilde: {copyright}',
    animation: 'Animasjon: {copyright}',
  },
  embeds: {
    notSupported: 'Innebygd media er ikke støttet'
  },
  feedback: {
    question: 'Fant du det du lette etter?',
    positive: 'Tusen takk!',
    negative: 'Vi setter stor pris på tilbakemelding! Send gjerne en e-post til <a href="mailto:{email}" class="font-bold underline">{email}</a>. Husk å inkludere en kopi av adresselinjen.',
    copyUrl: 'Kopier adresselinjen',
    send: 'Send inn',
    event: {
      rated: 'Takk!',
      submit: 'Takk for tilbakemeldingen!',
      copied: 'Adresselinjen ble kopiert',
      copyFailed: 'Kunne ikke kopiere adresselinjen',
    }
  },
  vidstack: {
    'Caption Styles': 'Tekststiler',
    'Captions look like this': 'Teksting ser slik ut',
    'Closed-Captions Off': 'Teksting av',
    'Closed-Captions On': 'Teksting på',
    'Display Background': 'Vis bakgrunn',
    'Enter Fullscreen': 'Gå til fullskjerm',
    'Enter PiP': 'Gå til bilde i bilde',
    'Exit Fullscreen': 'Avslutt fullskjerm',
    'Exit PiP': 'Avslutt bilde i bilde',
    'Google Cast': 'Google Cast',
    'Keyboard Animations': 'Tastaturanimasjoner',
    'Seek Backward': 'Spol tilbake',
    'Seek Forward': 'Spol fremover',
    'Skip To Live': 'Hopp til direktesending',
    'Text Background': 'Tekstbakgrunn',
    'Accessibility': 'Tilgjengelighet',
    'AirPlay': 'AirPlay',
    'Announcements': 'Kunngjøringer',
    'Audio': 'Lyd',
    'Auto': 'Auto',
    'Boost': 'Forsterk',
    'Captions': 'Teksting',
    'Chapters': 'Kapitler',
    'Color': 'Farge',
    'Connected': 'Tilkoblet',
    'Connecting': 'Kobler til',
    'Continue': 'Fortsett',
    'Default': 'Standard',
    'Disabled': 'Deaktivert',
    'Disconnected': 'Frakoblet',
    'Download': 'Last ned',
    'Family': 'Familie',
    'Font': 'Skrift',
    'Fullscreen': 'Fullskjerm',
    'LIVE': 'DIREKTE',
    'Loop': 'Gjenta',
    'Mute': 'Demp',
    'Normal': 'Normal',
    'Off': 'Av',
    'Opacity': 'Gjennomsiktighet',
    'Pause': 'Pause',
    'PiP': 'Bilde i bilde',
    'Play': 'Spill av',
    'Playback': 'Avspilling',
    'Quality': 'Kvalitet',
    'Replay': 'Spill igjen',
    'Reset': 'Tilbakestill',
    'Seek': 'Søk',
    'Settings': 'Innstillinger',
    'Shadow': 'Skygge',
    'Size': 'Størrelse',
    'Speed': 'Hastighet',
    'Text': 'Tekst',
    'Track': 'Spor',
    'Unmute': 'Slå på lyd',
    'Volume': 'Volum',
  },
  subscription: {
    messages: {
      endDate: 'Lisensen på {name} utløper {date}',
      missing: 'Ingen abonnement',
      current: 'Du har følgende aktive abonnement:',
      upgrade: {
        fromBasic: 'Ved å oppgradere til PLUSS får hele skolen tilgang til Papir+ og Biblioteket.',
        fromPlus: '',
      },
      webshop: {
        internal: '<a href="https://skole.aschehoug.no" class="underline focus-visible:ring" target="_blank">skole.aschehoug.no</a>',
        external: '<a href="https://www.pendel.no/" class="underline focus-visible:ring" target="_blank">Pendel</a> eller <a href="https://skolon.no" class="underline focus-visible:ring" target="_blank">Skolon</a>',
      },
      readMore: 'Les mer om, og endre abonnementet her:',
    },
    application: 'Aunivers',
    name: {
      PrimaryTrial: 'Aunivers 1–7 Prøvelisens',
      PrimaryBasic: 'Aunivers 1–7 Basis',
      PrimaryIndividual: 'Aunivers 1–7 Individuell',
      PrimaryPlus: 'Aunivers 1–7 Pluss',
      PrimaryPremium: 'Aunivers 1–7 Premium',
      SecondaryTrial: 'Aunivers 8–10 Prøvelisens',
      SecondaryBasic: 'Aunivers 8–10 Basis',
      SecondaryIndividual: 'Aunivers 8–10 Individuell',
      SecondaryPlus: 'Aunivers 8–10 Pluss',
      SecondaryPremium: 'Aunivers 8–10 Premium',
      VgsTrial: 'Aunivers VGS Prøvelisens',
      VgsIndividual: 'Aunivers VGS Individuell',
    },
    type: {
      none: 'Ingen',
      trial: 'Prøve',
      individual: 'Individuell',
      basic: 'Basis',
      plus: 'Pluss',
      premium: 'Premium',
    }
  },
  validation: {
    general: {
      required: {
        error: 'Feltet må fylles ut',
      },
    },
    favorite: {
      name: {
        error: 'Navnefeltet kan bare inneholde tegn: a til å, 0 til 9 og utvalgte spesialtegn: {\'.\'}{\',\'}{\'@\'}{\'!\'}{\'?\'}{\'|\'}{\'§\'}{\'#\'}{\'<\'}{\'>\'}{\'[\'}{\']\'}{\'-\'}{\'+\'}\'{\'*\'}{\'"\'}{\'^\'}{\'`\'}{\':\'}{\';\'}',
      },
      note: {
        error: 'Notatfeltet kan bare inneholde tegn: a til å, 0 til 9 og utvalgte spesialtegn: {\'.\'}{\',\'}{\'@\'}{\'!\'}{\'?\'}{\'|\'}{\'§\'}{\'#\'}{\'<\'}{\'>\'}{\'[\'}{\']\'}{\'-\'}{\'+\'}\'{\'*\'}{\'"\'}{\'^\'}{\'`\'}{\':\'}{\';\'}',
      },
    },
  },
  flyt: {
    missingTranslationMessage: 'Vi arbeider med å få vist oppgavene våre på nynorsk!',
  },
  chatbot: {
    remember: 'Botten husker bare meldingene under',
    placeholder: 'Skriv her ...',
    answering: 'Chatbotten svarer',
    answered: 'Chatbotten har svart',
    answer: 'Svar fra chatbotten',
    message: 'Din melding',
    error: 'Noe gikk galt, vennligst prøv igjen'
  },
  assign: {
    share: 'Del',
    close: 'Steng',
    delete: 'Slett',
    shareWithStudents: 'Del med elever',
    goToExam: 'Gå til prøven',
    openTheExam: 'Åpne prøven',
    openingExam: 'Åpner prøven ...',
    closeTheExam: 'Steng prøven',
    closeForAll: 'Steng prøven for alle',
    closingExam: 'Stenger prøven ...',
    examClosed: 'Prøven er stengt',
    deleteExam: 'Slett prøven',
    deletingExam: 'Sletter prøven ...',
    deleteConfirm: 'Er du helt sikker på at du vil slette terminprøven: {name}?',
    locked: 'Låst',
    shared: 'Åpen',
    opened: 'Åpnet',
    closed: 'Stengt',
    editAccess: 'Rediger elevtilgang',
    noStudents: 'Fant ingen elever. Sørg for at du har skrevet riktig navn.',
    noStudentsInGroup: 'Fant ingen elever i gruppen',
    chooseAll: 'Velg alle',
    chooseGroup: 'Velg gruppe',
    chooseStudents: 'Velg elever',
    chooseStudentsAccess: 'Velg hvilke elever som skal få tilgang til terminprøven.',
    searchPlaceholder: 'Søk blant elevene ...',
    startExam: 'Start prøven nå',
    startingExam: 'Starter prøven ...',
    open: 'Åpne',
    closeExam: 'Steng prøve',
    createExam: 'Opprett prøve',
    creatingExam: 'Oppretter prøve ...',
    saveStudents: 'Lagre elevvalg',
    savingStudents: 'Lagrer elevvalg ...',
    createError: 'Noe gikk galt med å opprette prøven, vennligst prøv igjen eller kontakt kundeservice.',
    createInfo1: 'Du må opprette prøven før du kan dele den med de elevene du ønsker i valgt gruppe.',
    createInfo2: 'Frykt ikke, du kan trygt opprette prøven uten at den blir delt med elevene dine.',
    taskIsOpen: 'Terminprøven er åpen',
    taskIsClosed: 'Terminprøven er låst',
    taskStatusInfo: 'For at eleven skal få tilgang til terminprøven må prøven og elevens status være satt til åpen.',
    error: 'Noe gikk galt.',
    planned: 'Planlagte prøver',
    noTasks: 'Du har ikke opprettet noen terminprøver',
    noTasksInfo1: 'Når du har opprettet en prøve, kan du dele den med elever.',
    noTasksInfo2: 'Sørg for at læreren som skal dele prøven med elevene på prøvedagen, er medlem i gruppa.',
    confirm: 'Bekreft valg',
    confirmInfo: 'Er du sikker på at du vil {status} prøven for {student}?',
    confirmClose: 'Stenge',
    searchForStudents: 'Søk etter elever',
    searchForStudentsTip: 'Søkeordet må ha minst 3 tegn',
    missingStudents: 'Mangler det noen elever?',
    searchSchoolPlaceholder: 'Søk blant elevene på skolen ...',
    studentAdded: 'Lagt til',
    addStudents: 'Legg til elever',
    closedFor: 'Prøven er <b>stengt</b> for:',
    openFor: 'Prøven er <b>åpen</b> for:',
    seeExam: 'Se prøven',
    toasts: {
      createSuccess: 'Prøven ble opprettet',
      createError: 'Noe gikk galt med å opprette prøven',
      deleteSuccess: 'Prøven ble slettet',
      deleteError: 'Noe gikk galt med å slette prøven',
      openSuccess: 'Prøven ble åpnet',
      openError: 'Noe gikk galt med å åpne prøven',
      closeSuccess: 'Prøven ble stengt',
      closeError: 'Noe gikk galt med å stenge prøven',
      saveSuccess: 'Prøven ble lagret',
      saveError: 'Noe gikk galt med å lagre prøven',
      addUsersAndOpenSuccess: 'Terminprøven ble åpnet og delt med elevene',
      addUsersAndOpenError: 'Noe gikk galt med å åpne terminprøven og dele med elevene',
      updateUserSuccess: 'Prøven ble {status} for {user}',
      updateUserError: 'Noe gikk galt med å endre status for {user}',
    },
    student: {
      infoTitle: 'Akkurat nå har du ingen tildelte terminprøver',
      infoMessage: 'Når læreren din deler en terminprøve med deg, vil den dukke opp her!'
    }
  },
  termExam: {
    title: 'Terminprøve',
    byline: 'Terminprøve { title }',
    group: 'Gruppe: <b>{ groupName }</b>',
    earlier: 'Tidligere prøver',
  },
  report: {
    title: 'Rapporter',
    button: 'Rapport',
    banner: {
      title: 'Rapport',
      description: 'Terminprøve vår 2025',
      responseRate: 'Svarprosent: ',
      total: 'Totalresultat',
      group: 'Gruppe: ',
    },
    toolbar: {
      groupDropdown: 'Gruppe',
      subjectDropdown: 'Fag',
      gradeDropdown: 'Trinn',
    },
    status: {
      correct: 'riktig',
      wrong: 'feil',
      unanswered: 'ubesvart',
    },
    studentList: {
      name: 'Navn',
      result: 'Resultat',
    },
    messages: {
      error: {
        title: 'Noe gikk galt',
        message: 'Her gikk noe galt. Prøv igjen senere.',
      },
      missingFilters: {
        title: 'Velg gruppe og trinn',
        message: 'Både gruppe og trinn må være valgt',
      },
      noResults: {
        title: 'Ingen resultater',
        message: 'Har du valgt riktig gruppe og trinn?',
      }
    },
  },
}

export default nb
