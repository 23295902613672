<script setup lang="ts">
import type { Organization } from '~/models/Organization'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton, KsInput } from '@aschehoug/kloss'
import useLicenseControlStore from '~/stores/licenseControl'
import { useAuthStore } from '~/stores/auth'

const { t } = useI18n()
const authStore = useAuthStore()
const { updateUserOrganization } = authStore
const { userOrganization, userOrganizations } = storeToRefs(authStore)

const licenseStore = useLicenseControlStore()
const { checkItemLicense } = licenseStore
const { content } = storeToRefs(licenseStore)

const update = async (organization: Organization) => {
  await updateUserOrganization(organization)
  if (!content.value) return
  await checkItemLicense(content.value)
}
</script>
<template>
  <p
    class="text-center text-gray-40"
    v-text="t('organization.title')"
  />
  <form class="mt-6">
    <fieldset class="flex flex-wrap justify-center gap-2">
      <KsButton
        v-for="organization in userOrganizations"
        :key="organization.number"
        type="label"
        variant="secondary"
        shape="rounded"
      >
        <KsInput
          type="radio"
          name="organization"
          :checked="organization.number === userOrganization?.number"
          :value="organization.number"
          @click="update(organization)"
        />
        {{ organization.name }}
      </KsButton>
    </fieldset>
  </form>
</template>
