<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { KsDialog } from '@aschehoug/kloss'
import { TeleportationTarget } from '~/models/TeleportationTarget'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'

const { t } = useI18n()

const emit = defineEmits(['closeGuide'])

defineProps<{
  isOpen: boolean
  content: string
}>()
</script>
<template>
  <Teleport :to="TeleportationTarget.AppTop">
    <KsDialog
      :title="t('article.studentGuide')"
      :open="isOpen"
      size="medium"
      @close="emit('closeGuide')"
    >
      <template #body>
        <article class="prose prose-au mx-auto prose-img:rounded-xl">
          <div class="au-teacher-article">
            <RichTextRenderer :text="content" />
          </div>
        </article>
      </template>
    </KsDialog>
  </Teleport>
</template>
