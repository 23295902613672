import type { LoginName } from '~/models/Login'
import { ref } from 'vue'
import { waitFor } from '~/utils/asyncUtils'
import { PendoEventName, PendoTrackName } from '~/models/Pendo'
import usePendo from '~/composables/usePendo'

export default () => {
  const { pendoEnabled, initAnonPendo, pendoTrack, pendoTeardown } = usePendo()

  const activeButton = ref<LoginName>()
  const hasTrackedLogin = ref(false)

  /**
   * Catch the first click on a login button, initialize Pendo, track, teardown and _then_ redirect
   * Maximum allowed delay is 2 seconds, after which the user is redirected without tracking
   */
  const trackLoginButton = async (type: LoginName, event: PointerEvent) => {
    if (!pendoEnabled) {
      return
    }

    if (event.isTrusted) {
      activeButton.value = type
      window.addEventListener(PendoEventName.Ready, pendoTrackHandler)
      initAnonPendo()
    }

    await waitFor(() => hasTrackedLogin.value, 2000)

    activeButton.value = ''
    window.removeEventListener(PendoEventName.Ready, pendoTrackHandler)
  }

  const pendoTrackHandler = () => {
    pendoTrack(PendoTrackName.LoginButton, {
      type: activeButton.value,
      app: 'AU2',
    })
    pendoTeardown()
    hasTrackedLogin.value = true
  }

  return {
    trackLoginButton,
  }
}
