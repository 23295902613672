import type { SubjectCode } from '~/models/Subject'

export default () => {
  const homePath = '/1/2/249932/249933/'

  const featuredPath = '/1/2/249932/318778/'

  const subjectPath: Partial<Record<SubjectCode, string>> = {
    TYS: '/1/2/249932/259679/262211/',
    TVE: '/1/2/249932/259679/268270/',
    SPA: '/1/2/249932/259679/262212/',
    SAF: '/1/2/249932/259679/260690/',
    NOR: '/1/2/249932/259679/260696/',
    NAT: '/1/2/249932/259679/260702/',
    MUS: '/1/2/249932/259679/262219/',
    MAT: '/1/2/249932/259679/260697/',
    MHE: '/1/2/249932/259679/262217/',
    KHV: '/1/2/249932/259679/262216/',
    KRO: '/1/2/249932/259679/262215/',
    KRL: '/1/2/249932/259679/260695/',
    FRA: '/1/2/249932/259679/262213/',
    ENG: '/1/2/249932/259679/260694/',
  }

  return {
    homePath,
    featuredPath,
    subjectPath,
  }
}
