import type { SubjectCode } from '~/models/Subject'
import type { Product, SupportProductName } from '~/models/Product'
import type { GradeCode } from '~/models/Grade'
import {
  adultEducationGrades,
  lowerPrimaryGrades,
  lowerSecondaryGrades,
  prepEducationGrades,
  upperPrimaryGrades,
  upperSecondaryGrades
} from '~/utils/gradeSorter'
import arrayUtils from '~/utils/arrayUtils'
import { ProductVariation } from '~/models/Product'
import { useStaticProduct } from '~/composables/useStaticProduct'

export default () => {
  const { intersect } = arrayUtils()
  const { Terminprover, Biblioteket, PapirPluss } = useStaticProduct()

  const supportProductNames: SupportProductName[] = ['bokstøtte', 'lydstøtte']

  const addonProductColorMap: Record<string, string> = {
    [Biblioteket.ean]: 'purple',
    [Terminprover.ean]: 'orange',
    [PapirPluss.ean]: 'blue',
  }

  const hasRelatedLocations = (product: Product) => {
    const metadata = product.aunivers
    return !!(metadata?.relatedLocations
      && (metadata.relatedLocations.flexibleContentLocationId
        || metadata.relatedLocations.learningPathsLocationId
        || metadata.relatedLocations.interdisciplinaryContentLocationId
        || metadata.relatedLocations.teacherContentLocationId))
  }

  const productHasSubjectAndGrade = (product: Product, grade: GradeCode, subject: SubjectCode) =>
    product.subjects.includes(subject) && product.grades.includes(grade)

  const isProductVariation = (product: Product, variation: ProductVariation) =>
    product.aunivers?.productVariation === variation

  const isRelevantProduct = (product: Product, grades: GradeCode[]) => !product.grades.length || intersect(product.grades, grades).length > 0

  const isAddonProduct = (product: Product) => isProductVariation(product, ProductVariation.Addon)

  const isSupportName = (product: Product, name: SupportProductName) => product.aunivers.name.toLowerCase().includes(name)

  const isSupportProduct = (product: Product) => supportProductNames.some((identifier) => isSupportName(product, identifier))

  const isSearchableProduct = (product: Required<{ ean: string }>): boolean => isLibrary(product) || isExams(product)

  const isLibrary = (product: Required<{ ean: string }>) => product.ean === Biblioteket.ean
  const isPaperPlus = (product: Required<{ ean: string }>) => product.ean === PapirPluss.ean
  const isExams = (product: Required<{ ean: string }>) => product.ean === Terminprover.ean
  const isExtraProduct = (product: Required<{ ean: string }>) => isLibrary(product) || isPaperPlus(product) || isExams(product)

  const isLowerPrimaryProduct = (product: Product) =>
    intersect(product.grades, lowerPrimaryGrades).length > 0

  const isUpperPrimaryProduct = (product: Product) =>
    intersect(product.grades, upperPrimaryGrades).length > 0

  const isUpperSecondaryProduct = (product: Product) =>
    intersect(product.grades, upperSecondaryGrades).length > 0

  const isLowerSecondaryProduct = (product: Product) =>
    intersect(product.grades, lowerSecondaryGrades).length > 0

  const isAdultProduct = (product: Product) =>
    intersect(product.grades, adultEducationGrades).length > 0
    && ![
      91090, // Uttale i praksis
      294385, //God i norsk fellesressurser
    ].includes(product.aunivers.contentId)

  const isPrepProduct = (product: Product) =>
    intersect(product.grades, prepEducationGrades).length > 0

  return {
    hasRelatedLocations,
    productHasSubjectAndGrade,
    isProductVariation,
    supportProductNames,
    addonProductColorMap,
    isRelevantProduct,
    isAddonProduct,
    isSupportName,
    isSupportProduct,
    isSearchableProduct,
    isLibrary,
    isPaperPlus,
    isExams,
    isExtraProduct,
    isLowerPrimaryProduct,
    isUpperPrimaryProduct,
    isLowerSecondaryProduct,
    isUpperSecondaryProduct,
    isAdultProduct,
    isPrepProduct,
  }
}
