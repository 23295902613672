<script setup lang="ts">
import type { GroupMember } from '~/models/Group'
import type { Task, TaskUser } from '~/models/AssignTask'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons'
import { KsButton, KsDialog, KsSpinner, KsCallout } from '@aschehoug/kloss'
import useGroupsStore from '~/stores/groups'
import useAssignTaskDialog from '~/stores/assignTaskDialog'
import { UserRole } from '~/models/User/UserRole'
import TeleportationTarget from '~/models/TeleportationTarget'
import { PendoFeature } from '~/models/Pendo'
import { TaskStatus } from '~/models/AssignTask'
import { useAssignTask } from '~/composables/useAssignTask'
import StudentSelectionList from '~/components/products/exams/assignment/StudentSelectionList.vue'
import DeleteTaskDialog from '~/components/products/exams/assignment/DeleteTaskDialog.vue'

const { task } = defineProps<{ task: Task }>()

const { t } = useI18n()
const { loadGroupMembersById, findGroupMembersById } = useGroupsStore()
const { isLoading } = storeToRefs(useGroupsStore())
const { taskHasBeenClosed } = storeToRefs(useAssignTaskDialog())
const { addTaskUsers: add, addTaskUsersAndOpen } = useAssignTask()
const { setDialogOpen, setDialogClose, isDialogOpen } = useAssignTaskDialog()

const students = ref<TaskUser[]>(task.users ?? [])
const showClosedMessage = ref(task.taskId ? taskHasBeenClosed.value[task.taskId] : false)

const isOpen = computed(() => task.taskId && isDialogOpen(task.taskId, 'assign'))
const disabled = computed(() => !students.value.length || add.isPending.value || addTaskUsersAndOpen.isPending.value)
const taskUserRequest = computed(() => ({
  users: students.value.map(({ userId, status }) => ({ userId, status })),
}))

const openDialog = () => task.taskId && setDialogOpen(task.taskId, 'assign')
const closeDialog = () => task.taskId && setDialogClose(task.taskId, 'assign')

const addUsersAndOpenTask = async () => {
  if (!task.taskId || !students.value.length) return
  await addTaskUsersAndOpen.mutateAsync({ task, users: taskUserRequest.value })
  closeDialog()
  setDialogOpen(task.taskId, 'edit')
}

const addTaskUsers = async () => {
  if (!task.taskId || !students.value.length) return
  await add.mutateAsync({ task, users: taskUserRequest.value })
  closeDialog()
}

const filterStudents = (groupMembers: GroupMember[]): GroupMember[] =>
  groupMembers.filter(student => student.role === UserRole.Student)

watch(isOpen, async (open) => {
  if (open) {
    if (task.users?.length) {
      students.value = task.users
    } else if (task.groups) {
      await loadGroupMembersById(task.groups[0])
      const allStudents = findGroupMembersById(task.groups[0])
      students.value = filterStudents(allStudents)
        .map(({ userId, firstName, lastName, fullName, role }) => ({
          userId,
          status: TaskStatus.Open,
          firstName,
          lastName,
          fullName,
          primaryRole: role,
        }))
    }
  }
})

watch(isOpen, () => {
  if (!task.taskId) return
  if (taskHasBeenClosed.value[task.taskId]) {
    showClosedMessage.value = true
  }
})
</script>

<template>
  <KsButton
    variant="secondary"
    :icon-left="faPenToSquare"
    size="small"
    @click="openDialog"
  >
    {{ t('assign.editAccess') }}
  </KsButton>
  <Teleport :to="TeleportationTarget.AppTop">
    <KsDialog
      :title="task.name"
      :open="isOpen"
      @close="closeDialog"
    >
      <template #body>
        <div
          class="flex flex-col gap-4"
          style="--ks-roundness: .6"
        >
          <KsCallout
            v-if="showClosedMessage"
            variant="info"
            :title="t('assign.toasts.closeSuccess')"
          />
          <p
            v-else
            v-text="t('assign.chooseStudentsAccess')"
          />
          <StudentSelectionList
            v-if="!isLoading"
            v-model="students"
            :task-id="task.taskId"
            @close-modal="closeDialog"
          />
          <KsSpinner v-else />
        </div>
      </template>
      <template #footer>
        <div class="flex justify-end gap-4">
          <DeleteTaskDialog
            :task
            :disable-btn="add.isPending.value || addTaskUsersAndOpen.isPending.value"
          />
          <KsButton
            variant="secondary"
            :disabled
            shape="rounded"
            :data-pendo="PendoFeature.TermExam.SaveTask"
            @click="addTaskUsers"
          >
            <div class="flex gap-1">
              {{ add.isPending.value ? t('assign.savingStudents') : t('assign.saveStudents') }}
              <KsSpinner
                v-if="add.isPending.value"
                size="small"
                style="--ks-primary: rgb(var(--au-seagreen-30))"
              />
            </div>
          </KsButton>
          <KsButton
            variant="primary"
            :disabled
            shape="rounded"
            :data-pendo="PendoFeature.TermExam.StartTask"
            @click="addUsersAndOpenTask"
          >
            <div class="flex gap-1">
              {{ addTaskUsersAndOpen.isPending.value ? t('assign.startingExam') : t('assign.startExam') }}
              <KsSpinner
                v-if="addTaskUsersAndOpen.isPending.value"
                size="small"
                style="--ks-primary: rgb(var(--au-seagreen-30))"
              />
            </div>
          </KsButton>
        </div>
      </template>
    </KsDialog>
  </Teleport>
</template>
