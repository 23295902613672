<script lang="ts" setup>
import type { FavoriteListCreateInput } from '~/models/Favorites'
import type { ValidateFormResults, IsValid } from '~/composables/useValidation'
import { reactive, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { KsButton, KsInput } from '@aschehoug/kloss'
import { colors } from '~/utils/colors'
import useAuth from '~/stores/auth'
import { PendoFeature } from '~/models/Pendo'
import { useValidation } from '~/composables/useValidation'

type FormData = {
  name: string
  note: string
}

const { t } = useI18n()
const { userId } = useAuth()

const emit = defineEmits<{
  close: []
  create: [item: FavoriteListCreateInput]
}>()

const { rules, validateForm } = useValidation()
const form = reactive<FormData>({ name: '', note: '' })
const validationResults = reactive<ValidateFormResults<FormData> & IsValid>({
  name: [],
  note: [],
  isValid: false,
})

const newFavoriteList: FavoriteListCreateInput = {
  ownerUserId: userId ?? '',
  name: '',
  colorId: Math.floor(Math.random() * colors.length) + 1,
  description: '',
}

const create = () => {
  if (!validationResults.isValid) return
  emit('create', { ...newFavoriteList, name: form.name, description: form.note })
}

watch(form, () => validateForm(
  form,
  {
    name: [
      { rule: rules.string(), message: t('validation.favorite.name.error') },
      { rule: rules.required(), message: t('validation.general.required.error') },
    ],
    note: [
      { rule: rules.string(), message: t('validation.favorite.note.error') },
    ],
  },
  validationResults,
))
</script>

<template>
  <div class="-m-6 mt-0 grid gap-5 rounded-b-2xl bg-seagreen-5 p-6 pb-10">
    <label class="pt-4">
      <span
        class="text-black"
        v-text="t('favorite.createListNameLabel')"
      />
      <!-- 250ms delay is how long the dialog wait to focus, so this needs to wait longer -->
      <KsInput
        v-model="form.name"
        v-focus="{ delay: 251 }"
        class="!mt-2 !rounded-md !border-2 !border-solid !border-gray-20 !bg-white"
        type="text"
        shape="normal"
        @keydown.enter="create"
      />
    </label>
    <div
      v-if="validationResults.name.some(r => r.isError)"
      class="text-error"
    >
      <div
        v-for="error in validationResults.name"
        :key="error.message"
        v-text="error.message"
      />
    </div>
    <div
      v-if="validationResults.note.some(r => r.isError)"
      class="text-error"
    >
      <div
        v-for="error in validationResults.note"
        :key="error.message"
        v-text="error.message"
      />
    </div>
    <KsButton
      class="justify-self-end"
      variant="primary"
      shape="rounded"
      :disabled="!validationResults.isValid"
      :data-pendo="PendoFeature.Favorites.CreateList"
      @click="create"
    >
      {{ t('save') }}
    </KsButton>
  </div>
</template>
