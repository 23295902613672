<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { KsButton, KsCallout, KsSpinner } from '@aschehoug/kloss'
import useOidc from '~/composables/useOidc'
import useError from '~/composables/useError'

const { t } = useI18n()
const router = useRouter()
const { manager, getRedirectUri, createCookie, removeRedirectUri } = useOidc()
const { getErrorMessage } = useError()

const isLoading = ref(false)
const errorMessage = ref('')

const computedError = computed(() => router.currentRoute.value.query?.error_description ?? errorMessage.value)

onMounted(async () => {
  isLoading.value = true
  try {
    await manager.signinCallback().finally(async () => {
      const user = await manager.getUser()
      if (user) createCookie(user)
      await router.replace(getRedirectUri())
        .then(() => removeRedirectUri())
    })
  } catch (error) {
    errorMessage.value = getErrorMessage(error)
    throw error
  } finally {
    isLoading.value = false
  }
})

</script>
<template>
  <div
    v-if="isLoading"
    class="absolute inset-0 flex items-center justify-center"
  >
    <KsSpinner />
  </div>
  <div
    v-else-if="computedError"
    class="mx-1 mb-12 mt-20 rounded-xl p-6 font-inter max-md:px-4 sm:mx-4 lg:mx-auto lg:max-w-[var(--flyt-ideal-content-width-wide)]"
  >
    <KsCallout
      variant="error"
      :title="t('login.error.title')"
    >
      {{ computedError }}
    </KsCallout>
    <KsButton
      variant="secondary"
      size="medium"
      href="/"
      class="mt-6"
      :icon-left="faArrowLeft"
    >
      {{ t('back') }}
    </KsButton>
  </div>
</template>
