import type { xAPIParams, xAPIStatement } from '~/models/xAPIStatement'
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/stores/auth'
import useEnvironment from '~/composables/useEnvironment'
import useActivityApi from '~/api/activityApi'

const statements = ref<Record<number, xAPIStatement>>({})

export default function useActivity() {
  const { isAuthenticated, isTeacher, hasOrganization } = storeToRefs(useAuthStore())
  const { isDev } = useEnvironment()
  // This is a teacher- and devloper-specific noop-function, types added for parity with student version
  if (isTeacher.value || !hasOrganization.value || !isAuthenticated.value) {
    return { reportProgress: (locationId: number, newParams?: xAPIParams) => {} }
  }
  if (isDev()) {
    return {
      reportProgress: (locationId: number, newParams?: xAPIParams) => {
        console.warn('No activity reported in dev mode for locationId:', locationId, 'with params:', newParams)
      }
    }
  }

  const { loadStatement, postStatement } = useActivityApi()

  const getStatement = async (locationId: number, params?: xAPIParams): Promise<xAPIStatement | undefined> => {
    if (params?.count && params.count <= 1) {
      delete params.count
      delete params.position
    }
    if (!locationId) throw new Error(`The specified locationId "${locationId}" is not a valid locationId`)
    const statement = statements.value[locationId]
    if (!statement) {
      statements.value = {
        ...statements.value,
        [locationId]: await loadStatement(locationId, params),
      }
      return getStatement(locationId, params)
    }
    return statement
  }


  const reportProgress = async (locationId: number, newParams?: xAPIParams) => {
    const statement = await getStatement(locationId, newParams)
    if (!statement) {
      throw new Error('Could not get, create or update statement!')
    }

    postStatement(statement)
  }

  return {
    reportProgress,
  }
}
