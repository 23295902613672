import type { SessionUser, SessionAttributes } from '~/models/User/User'
import type { SessionUserInfo } from '~/models/User/SessionUserInfo'
import type { Organization } from '~/models/Organization'
import { externalClient } from '~/api/client/externalClient'
import { contentClient } from '~/api/client/contentClient'

export default function useUserApi() {
  const getUser = () => externalClient.get('/frontend/session/user')
    .then(({ data }: { data: SessionUser }) => data)

  const getSessionAttributes = () => externalClient.get('/frontend/session/attributes')
    .then(({ data }: { data: SessionAttributes }) => data)

  const postUser = (sessionInfo: SessionUserInfo) => externalClient
    .put('/frontend/session/user', sessionInfo)
    .then(({ data }: { data: SessionUser }) => data)

  const postUserOrganization = (organization: Organization) => externalClient
    .patch('/frontend/session/attributes', {
      activeOrganization: organization.number,
      activeOrganizationName: organization.name
    })
    .then(() => organization)

  const invalidateLegacyUser = () => contentClient
    .delete('/user/invalidate')

  return {
    getUser,
    postUser,
    postUserOrganization,
    getSessionAttributes,
    invalidateLegacyUser,
  }
}
