<script lang="ts" setup>
import type { FavoriteList } from '~/models/Favorites'
import { useI18n } from 'vue-i18n'
import { KsButton } from '@aschehoug/kloss'
import { PendoFeature } from '~/models/Pendo'

const { t } = useI18n()

const props = defineProps<{
  item: FavoriteList
}>()

const emit = defineEmits<{
  close: []
  delete: [item: FavoriteList]
}>()
</script>

<template>
  <div class="-m-6 mt-0 grid grid-cols-[auto,max-content] gap-4 rounded-b-2xl bg-seagreen-5 p-6 pb-10">
    <p
      class="col-span-2"
      v-text="t('favorite.deleteConfirmationText', { name: props.item.name })"
    />
    <KsButton
      v-focus
      :data-pendo="PendoFeature.Favorites.DeleteList"
      class="justify-self-start"
      variant="primary"
      shape="rounded"
      @click="emit('delete', props.item)"
    >
      {{ t('favorite.deleteList') }}
    </KsButton>
    <KsButton
      class="justify-self-end"
      variant="tertiary"
      shape="rounded"
      @click="emit('close')"
    >
      {{ t('cancel') }}
    </KsButton>
  </div>
</template>

