<script setup lang="ts">
import { computed } from 'vue'
import { setTitle } from '~/utils/dom'
import { useI18n } from '~/composables/useI18n'
import useBlogPosts from '~/composables/useBlogPosts'
import BlogSkeleton from '~/components/skeletons/BlogSkeleton.vue'
import NewsCard from '~/components/cards/NewsCard.vue'
import HighlightedBlogPost from '~/components/blog/HighlightedBlogPost.vue'

const { t } = useI18n()
const { loadPosts, matchesGrades } = useBlogPosts()
const { data: posts, isLoading  } = loadPosts(100)

const postsByGrades = computed(() => (posts?.value ?? []).filter(matchesGrades))
const firstPost = computed(() => postsByGrades.value[0])
const otherPosts = computed(() => postsByGrades.value.slice(1))

setTitle(t('blog.shortTitle'))
</script>

<template>
  <h1
    class="mt-4 px-5 text-center text-3xl font-bold sm:px-8 sm:text-4xl"
    v-text="t('blog.title')"
  />
  <div
    v-if="isLoading"
    class="relative mx-auto my-14 max-w-5xl gap-20 px-5 sm:px-8"
  >
    <BlogSkeleton />
  </div>
  <div
    v-if="!isLoading && posts?.length"
    class="relative mx-auto my-14 flex max-w-screen-au flex-col justify-center gap-12 px-5 sm:px-8 md:gap-20"
  >
    <HighlightedBlogPost
      v-if="firstPost"
      :blog-post="firstPost"
    />
    <section class="mx-auto motion-safe:animate-fade">
      <ul class="grid max-w-5xl grid-cols-1 gap-x-8 gap-y-12 sm:grid-cols-2 lg:grid-cols-3">
        <NewsCard
          v-for="(blogPost, i) in otherPosts"
          :key="i"
          :blog-post="blogPost"
        />
      </ul>
    </section>
  </div>
  <div
    v-if="!isLoading && !posts?.length"
    class="mx-auto mt-20 flex max-w-xl flex-col items-center gap-5 px-5 md:px-8"
  >
    <h2
      class="mb-4 text-xl font-medium"
      v-text="t('blog.noPosts')"
    />
  </div>
</template>
