<script setup lang="ts">
import type { MediaLoadingStrategy } from 'vidstack'
import type { AudioPlayerVariant } from '~/models/Media'
import { inject } from 'vue'
import AudioPlayer from '~/components/media/AudioPlayer.vue'

defineProps({
  src: {
    type: String,
    required: true,
  },
  mediaId: String,
})

const variant = inject<AudioPlayerVariant>('embedAudioVariant', 'button-with-text')
const load = inject<MediaLoadingStrategy>('embedAudioLoad', 'play')
const onEmbedTranscription = inject<((event: any) => void) | null>('onEmbedTranscription', null)
const onEmbedShowTranscription = inject<((event: any) => void) | null>('onEmbedShowTranscription', null)
</script>

<template>
  <AudioPlayer
    :src
    :media-id
    :variant
    :load
    @update:transcription="onEmbedTranscription?.($event)"
    @update:show-transcription="onEmbedShowTranscription?.($event)"
  />
</template>
