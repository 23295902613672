<script setup lang="ts">
import type { ContentBookReader } from '~/models/Content/ContentBookReader'
import type { BaseItem } from '~/models/Content/BaseItem'
import type { ImageRelationField } from '~/models/Content/BaseField'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { KsSkeleton } from '@aschehoug/kloss'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useImage from '~/composables/useImage'
import useDescriptor from '~/composables/useDescriptor'
import { useContentHelper } from '~/composables/useContentHelper'
import LinkOrAnchor from '~/components/utils/LinkOrAnchor.vue'
import CardMenu from '~/components/cards/buttons/CardMenu.vue'

const props = defineProps<{ item: BaseItem|ContentBookReader }>()

const { t } = useI18n()
const { buildResourceUrl } = useUrlBuilder()
const { getImageAltText } = useImage()
const { isBookReader } = useContentHelper()
const { getDescriptor } = useDescriptor()

const imageLoaded = ref(false)

const link = computed(() => buildResourceUrl(props.item))
const label = computed(() => isBookReader(props.item) ? t('filters.product') : getDescriptor(props.item))
const imageAltText = computed(() => isBookReader(props.item) &&
  (Number(props.item.image?.destinationContentId) > 0
    ? getImageAltText(props.item.image as ImageRelationField)
    : props.item.thumbnailAlt))
</script>

<template>
  <li class="relative row-span-2 grid grid-rows-subgrid border-t border-purple-10 p-4 transition hover:bg-purple-5">
    <aside
      v-if="item.new"
      class="pointer-events-none absolute right-0 top-0 rounded-bl-2xl bg-green-40 p-1.5 pl-2 text-sm font-medium uppercase tracking-wider text-white"
      v-text="t('newShort')"
    />
    <div class="order-1 flex size-full animate-fade flex-col gap-1 space-y-2 self-end">
      <span class="font-semibold text-purple-50">
        <LinkOrAnchor
          class="line-clamp-3 outline-0 before:absolute before:inset-0 before:transition before:focus-visible:ring"
          :link="link"
        >
          {{ item.title }}
        </LinkOrAnchor>
      </span>
      <div class="order-first">
        <span
          class="text-sm font-medium uppercase text-coral-40"
          v-text="label"
        />
      </div>
      <CardMenu
        :resource="item"
        button-variant="tertiary"
        style="--ks-input: rgb(var(--au-purple-10))"
        class="!mt-auto"
      />
    </div>
    <div class="flex w-full place-self-end overflow-hidden">
      <img
        v-if="item.thumbnail"
        v-show="imageLoaded"
        :src="item.thumbnail"
        :alt="imageAltText"
        class="mt-auto size-full animate-fade object-contain"
        @load="imageLoaded = true"
        @error="imageLoaded = true"
      >
      <KsSkeleton
        v-if="item.thumbnail && !imageLoaded"
        height="260px"
        background="rgb(var(--au-purple-10))"
        border-radius="0px"
      />
    </div>
  </li>
</template>
