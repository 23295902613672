import type { FiltersQueryParams } from '~/models/Filter'
import { computed, nextTick, watch } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { findGradesByGradeType, gradesSorted } from '~/utils/gradeSorter'
import useArrayUtils from '~/utils/arrayUtils'
import useFilterStore from '~/stores/filter'
import { useAuthStore } from '~/stores/auth'
import { QueryParamKey } from '~/models/QueryParamKeys'
import useQueryParams from '~/composables/queryParams'

export default() => {
  const { truthy } = useArrayUtils()
  const filterStore = useFilterStore()
  const {
    selectedHeaders,
    selectedGrade,
  } = storeToRefs(filterStore)
  const { initFilters } = filterStore
  const { set, get, clear } = useQueryParams<FiltersQueryParams>(QueryParamKey.Filters)
  const { userRelevantGrades, userPreferredGrade, selectedGradeType } = storeToRefs(useAuthStore())

  const userRelevantGradeTypeGrades = computed(() => {
    const grades = selectedGradeType.value ? findGradesByGradeType(selectedGradeType.value) : []
    return userRelevantGrades.value.filter((grade) => grades.includes(grade))
  })

  const defaultGrade = computed(() => {
    return userPreferredGrade.value || userRelevantGradeTypeGrades.value[0] || gradesSorted[0]
  })

  const router = useRouter()
  const queryFilter = computed(() => router?.currentRoute.value.query[QueryParamKey.Filters])

  watch(queryFilter, ((newFilter, oldFilter) => {
    if (newFilter === oldFilter) return
    initFilters({
      ...get(),
      [QueryParamKey.Grade]: get()[QueryParamKey.Grade] || defaultGrade.value,
    })
  }))

  nextTick(() => {
    initFilters({
      ...get(),
      [QueryParamKey.Grade]: get()[QueryParamKey.Grade] || defaultGrade.value,
    })
  })
  const eitherOr = (a: any, b: any) => (a && !b) || (!a && b)

  watch([ selectedGrade, selectedHeaders ],
    ([newGrade, newHeaders], [oldGrade, oldHeaders]) => {
      if (eitherOr(newGrade, oldGrade) || eitherOr(newHeaders, oldHeaders)) {
        /**
         *  Skip initialization and resetting of values, in order to only update query params if a
         * filter has changed from one value to another. Initialization is handled by both the onMounted
         * hook and the watching of the route, and the initialization is handled better by initFilters.
         */
        return
      }
      const params: Record<string, any> = {
        [QueryParamKey.Grade]: undefined,
        [QueryParamKey.Headers]: undefined,
        ...get(),
        ...(newGrade !== oldGrade ? { [QueryParamKey.Grade]: selectedGrade.value } : {} ),
      }

      if (selectedHeaders.value.length > 0) {
        params[QueryParamKey.Headers] = [...(new Set(selectedHeaders.value.map((header) => header.locationId).filter(truthy)))]
      } else {
        params[QueryParamKey.Headers] = undefined
      }

      if (Object.values(params).every(v => v === undefined)) {
        return clear()
      }

      if (Object.keys(params).length) {
        return set(params)
      }
  })
}
