<script setup lang="ts">
import type { Task, TaskUser } from '~/models/AssignTask'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { faEye, faLock } from '@fortawesome/pro-regular-svg-icons'
import { KsButton, KsDialog, KsIcon } from '@aschehoug/kloss'
import TeleportationTarget from '~/models/TeleportationTarget'
import { PendoFeature } from '~/models/Pendo'
import { TaskStatus } from '~/models/AssignTask'
import { useAssignTask } from '~/composables/useAssignTask'

const { student, task } = defineProps<{ student: TaskUser; task: Task }>()

const { t } = useI18n()
const { updateTaskUser } = useAssignTask()

const confirmDialogOpen = ref(false)

const statusIsOpen = computed(() => student.status === TaskStatus.Open)
const buttonColor = computed(() => statusIsOpen.value && {
  '--ks-primary': 'rgb(var(--au-coral-50))',
  '--ks-secondary': 'rgb(var(--au-coral-40))'
})
const buttonText = computed(() => {
  if (updateTaskUser.isPending.value) {
    return !statusIsOpen.value ? t('assign.closingExam') : t('assign.openingExam')
  }
  return statusIsOpen.value ? t('assign.closeTheExam') : t('assign.openTheExam')
})

const updateStudent = async () => {
  await updateTaskUser.mutateAsync({
    task: task,
    user: student,
    status: statusIsOpen.value ? TaskStatus.Closed : TaskStatus.Open
  })
  confirmDialogOpen.value = false
}

const tracking = computed(() => ({
  'data-pendo': statusIsOpen.value
    ? PendoFeature.TermExam.CloseStudent
    : PendoFeature.TermExam.OpenStudent
}))
</script>

<template>
  <li class="relative flex size-full items-center justify-between border-b border-green-10 px-1 py-2 font-medium last:border-b-0">
    <div class="flex items-center gap-4">
      <div
        class="flex items-center justify-center rounded-lg p-1 text-base transition-all"
        :class="statusIsOpen ? 'bg-green-10 text-green-50' : 'bg-coral-20 text-coral-50'"
      >
        <KsIcon
          :icon="statusIsOpen ? faEye : faLock"
          :class="statusIsOpen ? 'text-green-50' : 'text-coral-50'"
        />
      </div>
      <span v-text="student.fullName" />
    </div>
    <KsButton
      size="small"
      variant="secondary"
      @click="confirmDialogOpen = true"
    >
      {{ statusIsOpen ? t('assign.close') : t('assign.open') }}
    </KsButton>
    <Teleport :to="TeleportationTarget.AppTop">
      <KsDialog
        :open="confirmDialogOpen"
        :title="t('assign.confirm')"
        @close="confirmDialogOpen = false"
      >
        <template #body>
          <p
            class="font-medium"
            v-text="t('assign.confirmInfo', {
              status: (statusIsOpen ? t('assign.confirmClose') : t('assign.open')).toLowerCase(),
              student: student.fullName
            })"
          />
        </template>
        <template #footer>
          <div class="flex items-center justify-center gap-4">
            <KsButton
              variant="secondary"
              shape="rounded"
              @click="confirmDialogOpen = false"
            >
              {{ t('cancel') }}
            </KsButton>
            <KsButton
              variant="primary"
              :style="buttonColor"
              shape="rounded"
              :disabled="updateTaskUser.isPending.value"
              v-bind="tracking"
              @click="updateStudent"
            >
              {{ buttonText }}
            </KsButton>
          </div>
        </template>
      </KsDialog>
    </Teleport>
  </li>
</template>
