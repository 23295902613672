<script setup lang="ts">
import type { SubjectCode } from '~/models/Subject'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { KsButton } from '@aschehoug/kloss'

defineProps<{ subjectCode: SubjectCode }>()

const router = useRouter()
const { t } = useI18n()
</script>

<template>
  <KsButton
    variant="border"
    size="medium"
    shape="rounded"
    @click.prevent="() => router.push({ name: 'subject', params: { subject: subjectCode.toLowerCase() } })"
  >
    {{ t('filters.toLegacySubject') }}
  </KsButton>
</template>
