<script setup lang="ts">
import { computed, watch } from 'vue'
import { KsSpinner } from '@aschehoug/kloss'
import useLicenseControlStore from '~/stores/licenseControl'
import { useProductQuery } from '~/composables/useProductQuery'
import useProductHelper from '~/composables/useProductHelper'
import NotFound from '~/components/ResourceEngine/Components/NotFound.vue'
import ProductSearchView from '~/components/products/ProductSearchView.vue'
import ProductGenericView from '~/components/products/ProductGenericView.vue'

const props = defineProps<{ locationId: number }>()

const { isSearchableProduct } = useProductHelper()
const { checkItemLicense } = useLicenseControlStore()
const { data: product, isLoading, error } = useProductQuery(props.locationId)

const componentMap = computed(() =>
  product.value && isSearchableProduct(product.value) ? ProductSearchView : ProductGenericView)

watch(product, () => product.value && checkItemLicense(product.value), { immediate: true })
</script>

<template>
  <NotFound v-if="error" />
  <template v-else>
    <div
      v-if="isLoading"
      class="absolute inset-0 flex items-center justify-center"
    >
      <KsSpinner />
    </div>
    <component
      :is="componentMap"
      v-else
      :product="product"
    />
  </template>
</template>
