<script setup lang="ts">
import type { AuniversMetadata } from '~/models/Product'
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsIcon } from '@aschehoug/kloss'
import { colors, forceColorShade, getColorForId } from '~/utils/colors'
import useProductStore from '~/stores/product'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import { useStaticProduct } from '~/composables/useStaticProduct'
import useProductHelper from '~/composables/useProductHelper'
import useGradeString from '~/composables/useGradeString'
import useDescriptor from '~/composables/useDescriptor'
import LinkOrAnchor from '~/components/utils/LinkOrAnchor.vue'
import LockedContentDialog from '~/components/search/LockedContentDialog.vue'
import CardMenu from '~/components/cards/buttons/CardMenu.vue'

const { item, packageStyle = false, showPath = true, showThumbnail = true } = defineProps<{
  item: BaseItem
  packageStyle?: boolean
  showPath?: boolean
  showThumbnail?: boolean
}>()

const { t } = useI18n()
const { getDescriptor } = useDescriptor()
const { buildResourceUrl } = useUrlBuilder()
const { createGradeString } = useGradeString()
const { getProductFromResource } = useProductStore()
const { products } = storeToRefs(useProductStore())
const { isAddonProduct } = useProductHelper()
const { ALWAYS_SEARCHABLE_PRODUCTS } = useStaticProduct()

const dialogOpen = ref(false)

const matchesProduct = (metadata: AuniversMetadata) => metadata.pathString && item.pathString.includes(metadata.pathString)

const belongsToAlways = computed(() => ALWAYS_SEARCHABLE_PRODUCTS.find(({ aunivers }) => matchesProduct(aunivers)))
const belongsToProduct = computed(() => products.value.find(({ aunivers }) => matchesProduct(aunivers)))

const hasAccess = computed(() => !(belongsToAlways.value && !belongsToProduct.value))
const product = computed(() => getProductFromResource(item))
const link = computed(() => buildResourceUrl(item))
const label = computed(() => getDescriptor(item))
const borderColor = computed(() => forceColorShade(getColorForId(item.locationId, colors), '5'))
const subjectOrProductName = computed(() => {
  if (!hasAccess.value) return (belongsToProduct.value || belongsToAlways.value)?.name
  if (!product.value) return ''
  if (isAddonProduct(product.value)) return product.value.aunivers?.name
  return product.value.subjects?.length ? t(`metadata.subjects.${product.value.subjects[0]}`) : ''
})
const subtitle = computed(() => {
  const subject = subjectOrProductName.value
  const grades = createGradeString(item.grades)
  const parent = item.parentLocationName

  return [subject, grades, parent].filter(Boolean).join(' / ')
})
</script>

<template>
  <li
    class="group relative size-full rounded-md bg-white transition"
    :class="packageStyle ? 'grid grid-cols-1' : 'grid grid-cols-3 gap-1 border border-gray-10'"
  >
    <div
      class="col-span-2 flex flex-col p-3"
      :class="item.thumbnail ? 'col-span-2' : 'col-span-3'"
    >
      <span
        class="mb-1 font-extrabold text-gray-50"
        :class="packageStyle ? 'text-xl' : 'text-base'"
      >
        <LinkOrAnchor
          v-if="hasAccess"
          class="line-clamp-3 outline-0 before:absolute before:inset-0 before:rounded-md before:transition before:hover:shadow-lg before:focus-visible:ring"
          :link="link"
        >
          {{ item.title }}
        </LinkOrAnchor>
        <button
          v-else
          class="line-clamp-3 text-left outline-0 before:absolute before:inset-0 before:rounded-md before:transition before:hover:shadow-lg before:focus-visible:ring"
          @click="dialogOpen = true"
        >
          {{ item.title }}
        </button>
      </span>
      <div
        v-if="!hasAccess"
        class="absolute -right-1 -top-1 z-10 flex size-9 cursor-pointer items-center justify-center rounded-full border border-white bg-blue-50 text-white shadow-md"
      >
        <KsIcon icon="lock" />
        <LockedContentDialog
          :open="dialogOpen"
          :product="belongsToProduct || belongsToAlways"
          @close-dialog="dialogOpen = false"
        />
      </div>
      <p
        v-if="!!subtitle && showPath"
        class="mb-2 text-xs text-gray-50"
        v-text="subtitle"
      />
      <span
        v-if="false"
        class="order-first mb-2 self-start rounded-md bg-seagreen-60 px-2 py-1 text-xs font-semibold text-seagreen-5"
        v-text="label"
      />
      <CardMenu
        v-if="!packageStyle"
        class="mt-auto"
        :resource="item"
        button-variant="tertiary"
      >
        <template #favorite>
          <slot name="favorite-menu" />
        </template>
      </CardMenu>
      <KsIcon
        v-else
        class="mt-auto transition-all group-hover:ml-2"
        icon="arrow-right"
      />
    </div>
    <div
      v-if="item.thumbnail && showThumbnail"
      class="col-span-1 size-full overflow-hidden p-2 transition-all group-hover:p-1.5"
      :class="packageStyle ? 'aspect-video order-first' : 'aspect-square rounded-r-md'"
    >
      <img
        :src="item.thumbnail"
        :alt="item.thumbnailAlt"
        class="size-full rounded-md object-cover"
      >
    </div>
  </li>
</template>
