<script setup lang="ts">
import type { Presentation } from '~/models/Presentation/Presentation'
import { computed, watch } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { KsSpinner } from '@aschehoug/kloss'
import { setTitle } from '~/utils/dom'
import useLicenseControlStore from '~/stores/licenseControl'
import router from '~/router'
import { Subtree } from '~/models/Content/Subtree'
import { ContentType } from '~/models/Content/ContentType'
import useSlugify from '~/composables/useSlugify'
import useContentApi from '~/api/contentApi'
import PresentationPageRenderer from '~/components/ResourceEngine/PresentationPageRenderer.vue'
import NotFound from '~/components/ResourceEngine/Components/NotFound.vue'

const { findContents } = useContentApi()
const { checkItemLicense } = useLicenseControlStore()
const { slugify } = useSlugify()

const props = withDefaults(defineProps<{
  locationId: number
  pageNumber?: number
}>(), {
  pageNumber: 1,
})

const {
  data: presentation,
  isError,
  isLoading,
} = useQuery({
  staleTime: Infinity,
  queryKey: computed(() => ['presentation-view', props.locationId]),
  queryFn: () =>
    findContents<Presentation>({
      locationIdCriterion: [props.locationId],
      contentTypeCriterion: [ContentType.Presentation],
      subtreeCriterion: [Subtree.Content, Subtree.Media],
    }, 1)
      .then(([content]) => content ?? null)
})

watch(presentation, () => {
  if (!presentation.value) return
  setTitle(presentation.value?.title)
  checkItemLicense(presentation.value)
}, { immediate: true })

const goToPage = (pageNumber: number, replace = false) => {
  const method = replace ? router.replace : router.push
  method({
    name: 'presentation-page',
    params: {
      locationId: presentation.value?.locationId,
      slug: slugify(presentation.value?.title || ''),
      pageNumber: pageNumber,
    },
  })
}
</script>
<template>
  <NotFound v-if="isError || (!isLoading && !presentation)" />
  <PresentationPageRenderer
    v-else-if="!isLoading && presentation"
    v-show="!isLoading"
    :page-content-ids="presentation?.pages.destinationContentIds || []"
    :page-number="props.pageNumber"
    :presentation="presentation"
    @update:page-number="goToPage"
    @replace:page-number="goToPage($event, true)"
  />
  <div
    v-else
    class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
  >
    <KsSpinner />
  </div>
</template>
