<script setup lang="ts">
import type { BasicUser } from '~/models/User/BasicUser'
import type { FlytTestAssignment, TestAnswer, TestData, TestResult } from '~/models/Assignment'
import { computed } from 'vue'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import ResultGraph from '~/components/reports/ResultGraph.vue'
import {
  KsAccordionContent,
  KsAccordionHeader,
  KsAccordionItem,
  KsAccordionRoot,
  KsAccordionTrigger,
  KsIcon,
} from '@aschehoug/kloss-2'
import { PendoFeature } from '~/models/Pendo'

const props = defineProps<{
  reportData: FlytTestAssignment[]
  user: BasicUser
}>()

const testData = computed((): TestResult[] => {
  const formatedData = props.reportData
    .filter(assignment => assignment.responseSummary.length > 0)
    .map(assignment => {
    const data = parseJson(assignment.responseSummary.find(summary => summary.student.userId === props.user.userId)?.latestAnswer?.data) as TestData

    if (!data) {
      const summary = assignment.responseSummary.at(0)
      if (!summary) {
        return {
          user: props.user,
          assignmentKey: assignment.assignment.assignmentKey,
          name: assignment.assignment.name,
          correct: 0,
          wrong: 0,
          unanswered: 1,
        }
      }

      const exampleAnswer = parseJson(summary.latestAnswer?.data) as TestData
      const totalQuestionsSum = exampleAnswer.items.reduce((sum, item) => {
        return sum + item.score.totalQuestions;
      }, 0);

      return {
        user: props.user,
        assignmentKey: assignment.assignment.assignmentKey,
        name: assignment.assignment.name,
        correct: 0,
        wrong: 0,
        unanswered: totalQuestionsSum,
      }
    }

    let correctAnswers = 0
    let wrongAnswers = 0
    let unansweredAnswers = 0

    data.items?.forEach(item => {
      correctAnswers += item.score.correctAnswers
      wrongAnswers += item.score.totalQuestions - item.score.correctAnswers
    })

    return {
      user: props.user,
      assignmentKey: assignment.assignment.assignmentKey,
      name: assignment.assignment.name,
      correct: correctAnswers,
      wrong: wrongAnswers,
      unanswered: unansweredAnswers,
    }
  })

  return formatedData.sort((a, b) => {
    const numA = parseInt(a.name.split(' ')[0], 10)
    const numB = parseInt(b.name.split(' ')[0], 10)
    return numA - numB
  })
})

const studentData = computed((): TestResult => {
  const userSummaries = props.reportData
    .flatMap(assignment => assignment.responseSummary)
    .filter(summary => summary.student.userId === props.user.userId)

  const dataFields = userSummaries.flatMap(summary => {
    return summary.latestAnswer?.data
  })

  const groupedItems = getGroupedItems()

  const items = dataFields
    .filter(answer => answer !== undefined)
    .map(answer => JSON.parse(answer))
    .flatMap(data => data.items)

  let correctAnswers = 0
  let wrongAnswers = 0
  let unansweredAnswers = 0

  Object.keys(groupedItems).forEach(key => {
    const item = items.find(item => item.itemId === key)
    if (!item) {
      unansweredAnswers += groupedItems[key]?.at(0)?.score?.totalQuestions ?? 0
    }

    if (item) {
      correctAnswers += item.score.correctAnswers
      wrongAnswers += item.score.totalQuestions - item.score.correctAnswers
    }
  })

  return {
    user: props.user,
    correct: correctAnswers,
    wrong: wrongAnswers,
    unanswered: unansweredAnswers,
  }
})

const getGroupedItems = () => {
  const dataFields = props.reportData.flatMap(assignment => {
    return assignment.responseSummary.flatMap(summary => {
      return summary.latestAnswer?.data
    })
  })

  const items: TestAnswer[] = dataFields
    .filter(answer => answer !== undefined)
    .map(answer => JSON.parse(answer))
    .flatMap(data => {
      return data.items
    })

  const groupedItems = items.reduce((acc, item) => {
    if (!acc[item.itemId]) {
      acc[item.itemId] = []
    }
    acc[item.itemId].push(item)
    return acc
  }, {} as { [key: string]: TestAnswer[] })

  return groupedItems
}

const studentPercentageScore = computed(() => {
  const correct = studentData.value.correct ?? 0
  const wrong = studentData.value.wrong ?? 0
  const unanswered = studentData.value.unanswered ?? 0
  const total = correct + wrong + unanswered

  if (total === 0) {
    return 0
  }

  return Math.round((correct / total) * 100)
})

const parseJson = (data?: string): Nullable<object> => {
  if (!data) return null
  try {
    const json = JSON.parse(data)
    return (json instanceof Object) ? <object>json : null
  } catch {
    return null
  }
}
</script>

<template>
  <KsAccordionRoot
    class="w-full"
    type="single"
    :collapsible="true"
    :data-pendo="PendoFeature.Report.ToggleAccordion"
  >
      <KsAccordionItem
        :value="props.user.userId"
      >
        <KsAccordionHeader>
          <KsAccordionTrigger class="w-full group focus-visible:ring hover:bg-green-10/20">
            <div class="mx-auto max-w-screen-au">
              <div class="h-14 flex flex-row">
                <div class="content-center basis-5/12">
                  <p class="px-4 text-xl font-medium text-left">
                    {{ props.user.fullName }}
                  </p>

                </div>
                <div
                  ref="wrapper"
                  class="flex place-content-center basis-6/12"
                >
                  <p
                    ref="part"
                    class="content-center pr-4 text-3xl font-medium"
                    v-text="`${studentPercentageScore}%`"
                  />
                  <ResultGraph
                    class="w-full"
                    :test-result="studentData"
                  />
                </div>
                <KsIcon class="basis-1/12 transition-transform duration-300 group-data-[state=open]:rotate-180 h-1/2 self-center text-seagreen-50" :icon="faChevronDown"></KsIcon>
              </div>
            </div>
          </KsAccordionTrigger>
        </KsAccordionHeader>
        <KsAccordionContent class="data-[state=open]:animate-accordion-open data-[state=closed]:animate-accordion-close overflow-hidden p-2">
          <ul>
            <li
              v-for="testResult in testData"
              :key="testResult.assignmentKey"
            >
              <div class="mx-auto max-w-screen-au">
                <div class="grid h-14 xs:grid-cols-2">
                  <div class="content-center p-4 flex justify-between">
                    <p v-text="testResult.name" />
                    <p v-text="`${testResult.correct}/${testResult.correct + testResult.wrong + testResult.unanswered}`"></p>
                  </div>
                  <ResultGraph :test-result="testResult" />
                </div>
              </div>
            </li>
          </ul>
        </KsAccordionContent>
      </KsAccordionItem>
  </KsAccordionRoot>
</template>
