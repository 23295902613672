<script setup lang="ts">
import type { GradeCode } from '~/models/Grade'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsDropdown } from '@aschehoug/kloss'
import { sortByGradeIndex } from '~/utils/gradeSorter'
import useSubjectsStore from '~/stores/subjects'
import useProductStore from '~/stores/product'
import useFilterStore from '~/stores/filter'
import { useAuthStore } from '~/stores/auth'

const props = withDefaults(defineProps<{ gradeCodes?: GradeCode[] }>(), {
  gradeCodes: () => ([]),
})

const { t } = useI18n()
const authStore = useAuthStore()
const { setPreferredGrade } = authStore
const { selectedGradeTypeGrades, userReadOnlyGrades } = storeToRefs(authStore)
const { selectedGrade } = storeToRefs(useFilterStore())
const { currentSubject } = storeToRefs(useSubjectsStore())
const { products } = storeToRefs(useProductStore())

const availableGradesBasedOnChosenSubject = computed<GradeCode[]>(() => {
  if (props.gradeCodes.length > 0) return props.gradeCodes
  const gradeSet = new Set<GradeCode>(selectedGradeTypeGrades.value.concat(userReadOnlyGrades.value))
  if (currentSubject.value) {
    const subject = currentSubject.value
    products.value
      .filter((product) => product.subjects.length === 0 || product.subjects.includes(subject))
      .forEach((product) => product.grades.forEach((grade) => {
        if (selectedGradeTypeGrades.value.includes(grade) || (grade === selectedGrade.value)) gradeSet.add(grade)
      }))
  }

  return [...gradeSet].sort(sortByGradeIndex)
})
</script>

<template>
  <KsDropdown
    v-model="selectedGrade"
    :options="availableGradesBasedOnChosenSubject"
    :option-id="(grade: GradeCode) => grade"
    :option-label="(grade: GradeCode) => t(`metadata.grades.${grade}`)"
    :placeholder="$t('filters.chooseGrade')"
    shape="rounded"
    autolabel
    :multiple="false"
    :deselectable="() => false"
    flexible
    @select="setPreferredGrade(selectedGrade)"
  />
</template>
