<script setup lang="ts">
import type { GradeCode } from '~/models/Grade'
import { storeToRefs } from 'pinia'
import useAuthStore from '~/stores/auth'
import TeacherAssignedTasks from '~/components/products/exams/assignment/TeacherAssignedTasks.vue'
import StudentAssignedTasks from '~/components/products/exams/assignment/StudentAssignedTasks.vue'

const { selectedGrades } = defineProps<{ selectedGrades: GradeCode[] }>()

const { isTeacher, isStudent } = storeToRefs(useAuthStore())
</script>

<template>
  <TeacherAssignedTasks
    v-if="isTeacher"
    :selected-grades="selectedGrades"
  />
  <StudentAssignedTasks
    v-else-if="isStudent"
    :selected-grades="selectedGrades"
  />
</template>
