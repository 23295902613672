<script setup lang="ts">
import type { ContentProductPackage } from '~/models/Content/ContentProductPackage'
import type { ContentProductHeader } from '~/models/Content/ContentProductHeader'
import type { ContentPackage } from '~/models/Content/ContentPackage'
import { computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import useFilterStore from '~/stores/filter'
import { PendoFeature } from '~/models/Pendo'
import { useStaticProduct } from '~/composables/useStaticProduct'
import useContentApi from '~/api/contentApi'
import HeaderPreviewAccordion from '~/components/subject/HeaderPreviewAccordion.vue'
import ColorCard from '~/components/cards/ColorCard.vue'

const { header, section, style, index, hash } = defineProps<{
  header: ContentProductHeader|ContentProductPackage
  section: ContentPackage
  isCollapsable: boolean
  style: string
  index: number
  hash: string
}>()

const { gradeFilterFunc } = useFilterStore()
const { findContents } = useContentApi()
const { Terminprover } = useStaticProduct()

const children = computed(() => (data?.value ?? []).filter(gradeFilterFunc))

const { data, isLoading } = useQuery({
  enabled: computed(() => !!section.mainLocationId),
  queryKey: computed(() => ['section-children', section.mainLocationId]),
  queryFn: () => findContents({
    parentLocationIdCriterion: [section.mainLocationId],
    sortField: section.sortField,
    sortOrder: section.sortOrder,
  }, 100),
  staleTime: Infinity,
})

const tracking = computed(() => section.pathString.startsWith(Terminprover.aunivers.pathString!)
  ? { 'data-pendo': PendoFeature.TermExam.NavigateTask }
  : {}
)
</script>
<template>
  <section
    v-if="isLoading || children.length > 0"
    :style="style"
  >
    <KsSkeletonWrapper
      v-if="isLoading"
      class="mx-auto max-w-screen-au space-y-4 px-4 py-8 sm:px-8"
    >
      <KsSkeleton
        height="36px"
        width="120px"
      />
      <ul class="grid grid-cols-1 gap-8 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <KsSkeleton
          v-for="i in 4"
          :key="i"
          height="280px"
        />
      </ul>
    </KsSkeletonWrapper>
    <HeaderPreviewAccordion
      v-else-if="isCollapsable"
      :id="`${index+1}`"
      :header="header"
      :section="section"
      :open="`#${index+1}` === hash"
    >
      <ul class="grid grid-cols-1 gap-8 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <li
          v-for="(childContent, childIndex) in children"
          :key="childContent.locationId"
          v-bind="tracking"
        >
          <ColorCard
            :resource="childContent"
            :loading="childIndex === 0 ? 'eager' : 'lazy'"
          />
        </li>
      </ul>
    </HeaderPreviewAccordion>
    <div
      v-else
      :id="`${index+1}`"
      class="mx-auto max-w-screen-au space-y-8 px-4 py-12 sm:px-8"
    >
      <h2
        class="font-calistoga fluid-text-2xl"
        v-text="section.title"
      />
      <ul class="grid grid-cols-1 gap-8 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <li
          v-for="(childContent, childIndex) in children"
          :key="childContent.locationId"
          v-bind="tracking"
        >
          <ColorCard
            :resource="childContent"
            :loading="childIndex === 0 ? 'eager' : 'lazy'"
          />
        </li>
      </ul>
    </div>
  </section>
</template>
