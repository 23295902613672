<script setup lang="ts">
import type { VideoPage } from '~/models/Presentation/Pages/VideoPage'
import type { BasePageProps } from '~/models/Presentation/BasePage'
import type { ContentVideo } from '~/models/Content/ContentVideo'
import { computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { Subtree } from '~/models/Content/Subtree'
import { ContentType } from '~/models/Content/ContentType'
import useHiddenHeading from '~/composables/useHiddenHeading'
import useContentApi from '~/api/contentApi'
import ThirdPartyEmbed from '~/components/utils/ThirdPartyEmbed.vue'
import PresentationRichText from '~/components/PresentationPages/Components/PresentationRichText.vue'
import VideoPlayer from '~/components/media/VideoPlayer.vue'

const props = defineProps<BasePageProps & { page: VideoPage }>()

const { findContents } = useContentApi()
const { hasHeading } = useHiddenHeading()

const relationContentId = computed(() => props.page.metadata ? props.page.contentId : props.page.video?.destinationContentId)

const { data } = useQuery({
  enabled: Boolean(relationContentId.value),
  queryKey: computed(() => ['video-page', props.page.contentId, relationContentId.value, props.currentPageNumber]),
  queryFn: () => findContents<ContentVideo|VideoPage>({
    contentIdCriterion: [Number(relationContentId.value)],
    contentTypeCriterion: [ContentType.Video, ContentType.VideoPage],
    subtreeCriterion: [Subtree.Content, Subtree.Media],
    mainLocationCriterion: true,
  })
  .then(([data]) => data)
})
</script>

<template>
  <div
    class="flex h-full items-center justify-center gap-16 px-16 py-[--presentation-nav-height]"
    :class="page.fontSize === 'small'
      ? 'xl:px-36 3xl:px-48 xl:gap-36 3xl:gap-48'
      : '2xl:px-36 2xl:gap-36'"
    :style="{ background: page.colorPair.background.rgb }"
  >
    <h1
      v-if="!hasHeading"
      class="sr-only"
      v-text="page.title"
    />
    <div
      v-if="page.header?.trim() || page.body?.trim()"
      class="max-w-[min(40ch,40vw)] lg:flex-none"
      :class="[page.textSizes.paragraph]"
    >
      <h1
        v-if="page.header"
        class="text-pretty"
        :class="[page.textSizes.heading, page.fontWeight, page.fontFamily]"
        :style="{ color: page.colorPair.text.rgb }"
      >
        {{ page.header }}
      </h1>
      <PresentationRichText
        v-if="page.body"
        :page="page"
      />
    </div>

    <ThirdPartyEmbed
      v-if="data && 'embedCode' in data && data.embedCode"
      class="flex aspect-square h-full max-h-full max-w-full flex-col justify-center md:aspect-video"
      :code="data.embedCode"
    />

    <VideoPlayer
      v-else-if="data?.metadata"
      class="aspect-square max-h-full !w-auto max-w-full md:aspect-video"
      :src="data.metadata.elementURI"
      :media-id="data.metadata.contentItemId"
    />
  </div>
</template>
