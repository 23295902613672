<script setup lang="ts">
import type { ContentSectionStandalone } from '~/models/Content/ContentSectionStandalone'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useQuery } from '@tanstack/vue-query'
import { KsIcon } from '@aschehoug/kloss'
import { gradeTypeNames } from '~/utils/gradeSorter'
import useAuthStore from '~/stores/auth'
import { PendoTrackName } from '~/models/Pendo'
import { ContentType } from '~/models/Content/ContentType'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useSearchHelper from '~/composables/useSearchHelper'
import usePendo from '~/composables/usePendo'
import useContentApi from '~/api/contentApi'

const { findContents } = useContentApi()
const { t } = useI18n()
const { buildResourceUrl } = useUrlBuilder()
const { defaultContentTypes } = useSearchHelper()
const { pendoTrack, pendoFlushNow } = usePendo()
const { selectedGradeType } = storeToRefs(useAuthStore())

const props = defineProps<{ section: ContentSectionStandalone }>()

const { data: content } = useQuery({
  staleTime: Infinity,
  enabled: computed(() => Number(props.section.resource?.destinationContentId) > 0),
  queryKey: computed(() => ['content-id', props.section.contentId]),
  queryFn: async () => (await findContents({
    contentIdCriterion: [Number(props.section.resource?.destinationContentId)],
    mainLocationCriterion: true,
    contentTypeCriterion: [...defaultContentTypes, ContentType.Product],
  }, 1))[0]
})

const url = computed(() => {
  if (!content.value) return ''
  return buildResourceUrl(content.value)
})

const track = () => {
  if (!content?.value || !selectedGradeType.value) return
  pendoTrack(PendoTrackName.BannerArticle, {
    title: content.value.title,
    gradeType: gradeTypeNames[selectedGradeType.value],
    url: url.value,
  })
  pendoFlushNow()
}

const subtitle = computed(() => {
  const subject = props.section.subjects[0] ? t(`metadata.subjects.${props.section.subjects[0]}`) : null
  const theme = content.value?.parentLocationName || null

  return [subject, theme].filter(Boolean).join(' | ')
})

const color = computed(() => props.section.colorPair.text.rgb)
const backgroundColor = computed(() => props.section.colorPair.background.rgb)
</script>

<template>
  <RouterLink
    :to="url"
    class="group grid h-full grid-cols-3 items-center overflow-hidden rounded-lg text-left ring-offset-2 ring-offset-seagreen-50 transition hover:ring hover:ring-seagreen-10 focus-visible:ring md:min-h-64"
    :style="{ backgroundColor }"
    @click="track"
  >
    <div class="col-span-full aspect-video size-full sm:col-span-1">
      <img
        class="size-full object-cover"
        :src="section.thumbnail"
        :alt="section.thumbnailAlt"
      >
    </div>
    <div class="col-span-full px-6 py-8 sm:col-span-2">
      <p
        v-if="subtitle"
        class="mb-4 max-w-prose text-xs font-medium uppercase tracking-wider"
        :style="{ color }"
        v-text="subtitle"
      />
      <span
        class="max-w-prose text-xl font-bold"
        :style="{ color }"
        v-text="section.title"
      />
      <p
        v-if="section.description"
        class="mt-2 max-w-prose text-base"
        :style="{ color }"
        v-html="section.description"
      />
      <KsIcon
        icon="arrow-right-long"
        :scale="1.3"
        class="mt-4 transition-all group-hover:ml-2"
        :style="{ color }"
      />
    </div>
  </RouterLink>
</template>

<style scoped>
:deep(a) {
  color: v-bind(color);
  font-weight: 600;
}
:deep(a):hover {
  background: v-bind(color);
  color: v-bind(backgroundColor);
  transition: .1s;
  border-radius: 1px;
}
:deep(a):focus-visible {
  @apply ring
}
</style>
