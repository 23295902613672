<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { DateTime } from 'luxon'

const { t } = useI18n()

defineProps<{name: string}>()

const greetings = computed(() => {
  const hour = Number(DateTime.local().toFormat('H'))
  if (hour < 4) return t('home.greetings.night')
  if (hour < 12) return t('home.greetings.morning')
  if (hour < 18) return t('home.greetings.afternoon')
  return t('home.greetings.evening')
})
</script>

<template>
  <p class="fluid-text-base">
    {{ greetings }},<br>
    <span
      class="font-semibold fluid-text-lg"
      v-text="name"
    />
  </p>
</template>
