<script setup lang="ts">
import type { ContentBlogPost } from '~/models/Content/ContentBlogPost'
import { computed } from 'vue'
import { KsIcon } from '@aschehoug/kloss'
import useTime from '~/composables/useTime'
import useSlugify from '~/composables/useSlugify'

const props = defineProps<{ blogPost: ContentBlogPost }>()

const { slugify } = useSlugify()
const { dateTimeToAlternativeOutputFormat, isoStringToDateTime } = useTime()

const date = computed(() => dateTimeToAlternativeOutputFormat(isoStringToDateTime(props.blogPost.publishedDate)))
const tag = computed(() => props.blogPost.tags.length ? props.blogPost.tags[0] : '')
const title = computed(() => props.blogPost.title)
const intro = computed(() => props.blogPost.intro)
</script>

<template>
  <li
    v-if="tag"
    class="group relative flex w-full flex-col gap-2 rounded-lg border border-seagreen-40/50 p-4"
  >
    <div class="flex w-full flex-col">
      <h3 class="my-1 text-lg font-bold text-gray-50 underline-offset-2 group-hover:underline">
        <router-link
          :to="{ name: 'blog_post', params: { tag: slugify(tag), locationId: blogPost.locationId, blogPost: slugify(title) } }"
          class="after:absolute after:inset-0 after:z-10 after:rounded-lg after:transition-all after:hover:ring after:hover:ring-seagreen-40 after:focus-visible:ring"
        >
          {{ title }}
        </router-link>
      </h3>
      <div class="order-first inline-flex items-center gap-2">
        <p
          v-if="tag"
          class="text-sm text-gray-40"
          v-text="tag"
        />
        <span
          v-if="tag"
          aria-hidden="true"
          class="text-gray-40"
        >&bull;</span>
        <p
          class="text-sm capitalize text-gray-40"
          v-text="date"
        />
      </div>
      <p
        v-if="intro"
        class="line-clamp-2 text-base text-gray-40"
        v-html="intro"
      />
      <KsIcon
        class="mt-2 transition-all group-hover:ml-2"
        icon="arrow-right"
      />
    </div>
  </li>
</template>
