<script setup lang="ts">
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { KsButton } from '@aschehoug/kloss'
import useLoginButton from '~/composables/useLoginButton'

const props = defineProps<{ redirectTo: string }>()

const { t } = useI18n()
const { clickFeide, clickLogin, isLoading, buildFeideUrl, buildLocalUrl, redirectUri } = useLoginButton()

onMounted(() => redirectUri.value = props.redirectTo)
</script>
<template>
  <KsButton
    :href="buildFeideUrl"
    variant="primary"
    shape="rounded"
    :disabled="isLoading"
    center
    @click.once.prevent="clickFeide($event)"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      class="size-5"
    >
      <path
        fill="currentColor"
        d="M16.61 10.346h-1.739v4.175h1.74v-4.175ZM10.87 11.563H9.13v4.697H5.129v-5.914h-1.74v6.262h.009V18H16.61v-1.74h-5.74v-4.697ZM10 9.824a1.044 1.044 0 1 0 0-2.088 1.044 1.044 0 0 0 0 2.088Z"
      />
      <path
        fill="currentColor"
        d="M10 3.74a4.872 4.872 0 0 1 4.866 4.866h1.74A6.613 6.613 0 0 0 10 2a6.613 6.613 0 0 0-6.606 6.606h1.741A4.87 4.87 0 0 1 10 3.74Z"
      />
    </svg>
    {{ t('login.withServiceProvider') }}
  </KsButton>
  <KsButton
    :href="buildLocalUrl"
    variant="secondary"
    shape="rounded"
    :disabled="isLoading"
    icon-left="user"
    center
    @click.once.prevent="clickLogin($event)"
  >
    {{ t('login.withLocalProvider') }}
  </KsButton>
</template>
