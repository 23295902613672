<script setup lang="ts">
import type { GroupMember } from '~/models/Group'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton, KsDropdown } from '@aschehoug/kloss'
import { debounce } from '~/utils/functionUtils'
import useAuthStore from '~/stores/auth'
import useGroupsApi from '~/api/groupsApi'

const emit = defineEmits(['selectMember'])

const { t } = useI18n()
const { searchUsers } = useGroupsApi()
const { userId } = storeToRefs(useAuthStore())

const members = ref<GroupMember[]>([])
const selectedMembers = ref<GroupMember[]>([])
const loading = ref(false)

const getMemberName = (member: GroupMember) => member.fullName

const searchMembers = debounce(async (query: string) => {
  try {
    members.value = await searchUsers(query)
  } catch (e) {
    members.value = []
    throw e
  } finally {
    loading.value = false
  }
}, 350)

const onSearchMembers = (query: string) => {
  if (query.length >= 3) {
    loading.value = true
    searchMembers(query)
  }
}

const addSelectedMembers = () => {
  selectedMembers.value = selectedMembers.value.filter((member) => {
    emit('selectMember', member)
  })
}

</script>
<template>
  <div class="my-3 flex w-full items-center gap-2">
    <KsDropdown
      v-model="selectedMembers"
      :multiple="true"
      shape="rounded"
      class="w-full"
      searchable
      :options="members"
      :placeholder="t('groups.userSearch.search')"
      :no-options="loading ? t('groups.userSearch.searching') : t('groups.userSearch.noResults')"
      :close-on-select="false"
      :option-id="(member: GroupMember) => member.userId"
      :option-label="getMemberName"
      :selectable="opt => opt.userId !== userId"
      @search="onSearchMembers"
    />
    <KsButton
      :disabled="!selectedMembers.length"
      variant="primary"
      shape="rounded"
      @click="addSelectedMembers"
    >
      {{ t('groups.addMemberShort') }}
    </KsButton>
  </div>
</template>
