<script setup lang="ts">
import type { HeaderGradeTypeProps } from '~/models/Subject'
import type { ContentProductHeader } from '~/models/Content/ContentProductHeader'
import { computed, onMounted } from 'vue'
import { RouterLink } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton } from '@aschehoug/kloss'
import { firstOf } from '~/utils/queryUtils'
import useSubjectsStore from '~/stores/subjects'
import useProductStore from '~/stores/product'
import { useAuthStore } from '~/stores/auth'
import { PendoFeature } from '~/models/Pendo'
import { ColorTheme } from '~/models/Content/Color'
import { useMedia } from '~/composables/useMedia'
import { useContentHelper } from '~/composables/useContentHelper'
import { useColorTheme } from '~/composables/useColorTheme'
import useCardStyles from '~/composables/useCardStyles'
import { useAppColor } from '~/composables/useAppColor'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import HeaderViewBannerGrid from '~/components/utils/HeaderViewBannerGrid.vue'
import CopyrightButton from '~/components/utils/CopyrightButton.vue'
import HeaderTeacherButton from '~/components/subject/HeaderTeacherButton.vue'
import LowerSecondaryHeaderSection from '~/components/subject/gradetypes/LowerSecondaryHeaderSection.vue'
import GradeFilter from '~/components/ResourceFinder/GradeFilter.vue'
import Image from '~/components/media/Image.vue'
import SimpleCard from '~/components/cards/SimpleCard.vue'

const props = withDefaults(defineProps<HeaderGradeTypeProps>(), {
  subjectRoute: 'subject',
})

const { t } = useI18n()
const { isTeacher } = storeToRefs(useAuthStore())
const { hasLoaded: hasLoadedProducts } = storeToRefs(useProductStore())
const { currentSubject } = storeToRefs(useSubjectsStore())
const { set: setAppColor } = useAppColor()
const { isContentHeader } = useContentHelper()
const { theme } = useColorTheme(computed(() => props.header?.colorTheme ?? ColorTheme.BlueOrange))
const { cardStyle, themeStyle } = useCardStyles(theme)

const mediaContentId = computed(() => props.header?.backgroundMedia
  && Number(props.header.backgroundMedia.destinationContentId))
const { data: bgMedia, isLoading: bgMediaIsLoading } = firstOf(useMedia(computed(() => mediaContentId.value ? [mediaContentId.value] : [])))

const showTeacherButton = computed(() => isTeacher.value && hasLoadedProducts.value && props.header)

const hideGradeFilter = computed(() => props.subjectCode === 'NOR')

onMounted(() => setAppColor({
  themeColor: theme.value[60].name,
  bgColor: theme.value[10].name,
}))
</script>

<template>
  <div
    class="grid gap-20 text-[--theme-60]"
    :style="themeStyle"
  >
    <HeaderViewBannerGrid
      v-if="props.header"
      class="bg-[--theme-60] text-[--theme-10]"
    >
      <div
        id="top-dropdowns"
        class="flex items-center gap-2 pt-24 grid-in-filters"
        style="--ks-input: white;"
      >
        <KsButton
          :is="RouterLink"
          v-if="currentSubject"
          :to="{ name: props.subjectRoute, params: { subject: currentSubject.toLowerCase() } }"
          variant="secondary"
          icon-left="arrow-left"
        >
          {{ t(`metadata.subjects.${currentSubject}`) }}
        </KsButton>
        <GradeFilter
          v-if="header && !hideGradeFilter"
          :grade-codes="header.grades"
        />
      </div>
      <div class="flex flex-col items-start gap-4 self-center pb-24 pr-9 grid-in-header-info">
        <h1
          class="text-4xl font-semibold"
          v-text="props.header.title"
        />
        <RichTextRenderer
          v-if="props.header.intro"
          class="prose text-xl text-[currentColor]"
          :text="props.header.intro"
        />
        <HeaderTeacherButton
          v-if="showTeacherButton"
          :selected-header="props.header"
          :data-pendo="PendoFeature.TeacherContent.Header"
        />
      </div>
      <Image
        v-if="mediaContentId"
        :content="mediaContentId"
        class="aspect-video !h-auto min-h-full w-full max-w-full place-self-end object-cover object-left grid-in-image md:h-0 md:grid-in-[image-top_/_image-top_/_image]"
        :class="!bgMediaIsLoading && 'animate-fade'"
        width="700px"
      />
      <CopyrightButton
        v-if="bgMedia && 'copyright' in bgMedia"
        :copyright="bgMedia.copyright"
        class="row-start-2 m-4 place-self-end col-end-image"
      />
    </HeaderViewBannerGrid>
    <section
      v-for="(content, index) in packages"
      :key="content.contentId"
      class="grid grid-cols-1 place-items-center last:pb-20"
      :class="{ 'bg-[--theme-20] py-20 [.py-20+&]:-mt-20 [.py-20+&]:pt-0': isContentHeader(content) && content.viewMode === 'task' }"
      :style="isContentHeader(content) && cardStyle(content, index)"
    >
      <div class="flex w-full max-w-screen-au flex-col gap-24 px-4 sm:px-8">
        <LowerSecondaryHeaderSection
          :header="content"
          :index="index"
        />
      </div>
    </section>

    <section
      v-if="contents.length > 0"
      class="mx-auto flex w-full max-w-screen-au flex-col gap-4 px-4 pb-20 sm:px-8"
    >
      <h2
        class="font-tiempos text-4xl"
        v-text="t('labels.other')"
      />
      <ul class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <li
          v-for="content in contents"
          :key="content.locationId"
        >
          <SimpleCard :resource="content" />
        </li>
      </ul>
    </section>
  </div>
</template>
