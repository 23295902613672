export default () => {
  const decodeStore = () => {
    try {
      const data = localStorage.getItem('accordion')
      if (!data) return {}
      return JSON.parse(data)
    } catch (error) {
      return {}
    }
  }

  const saveStore = (stored: Record<number, Array<number>>) => {
    localStorage.setItem('accordion', JSON.stringify(stored))
  }

  const getAccordion = (locationId: number): Array<number> => decodeStore()[locationId] || []

  const addAccordion = (locationId: number, childId: number) => {
    const stored = decodeStore()
    const accordion = stored[locationId] || []
    if (accordion.includes(childId)) return
    accordion.push(childId)
    stored[locationId] = accordion
    saveStore(stored)
  }

  const toggleAccordion = (locationId: number, childId: number) => {
    const stored = decodeStore()
    const accordion = stored[locationId] || []
    const index = accordion.indexOf(childId)
    if (index === -1) {
      accordion.unshift(childId)
    } else {
      accordion.splice(index, 1)
    }
    stored[locationId] = accordion
    saveStore(stored)
    return accordion.includes(childId)
  }

  return {
    getAccordion,
    toggleAccordion,
    addAccordion,
  }
}
