<script setup lang="ts">
import { PendoRating, PendoTrackData } from '~/models/Pendo'
import { computed, inject, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton } from '@aschehoug/kloss'
import { useAuthStore } from '~/stores/auth'
import { PendoTrackName } from '~/models/Pendo'
import usePendo from '~/composables/usePendo'

const toast = <Toast>inject('ksToast')
const { t } = useI18n()
const { pendoTrack, pendoFlushNow } = usePendo()
const { isTeacher } = storeToRefs(useAuthStore())

const props = defineProps<{
  context: string
  data: string
}>()

const feedbackRating = ref<PendoRating>('neutral')
const submitted = ref<boolean>(false)

const messageText = computed(() => {
  if (feedbackRating.value === 'positive') return t('feedback.positive')
  if (feedbackRating.value === 'negative') return t('feedback.negative', { email: import.meta.env.VITE_SUPPORT_EMAIL })
  return ''
})

const hasRated = computed(() => feedbackRating.value === 'positive' || feedbackRating.value === 'negative')
const showFeedback = computed(() => isTeacher.value && !submitted.value)

watch(() => props.data, (newData, oldData) => {
  if (newData !== oldData) reset()
})

const reset = () => {
  feedbackRating.value = 'neutral'
  submitted.value = false
}

const handleFeedbackButtons = (rating: PendoRating) => {
  if(feedbackRating.value !== 'neutral') return
  feedbackRating.value = rating
  const ratingData: PendoTrackData  = {
    context: props.context,
    rating: rating,
    data: props.data,
    url: window.location.href,
  }
  pendoTrack(PendoTrackName.Rating, ratingData)
  pendoFlushNow()
  toast.success(t('feedback.event.rated'))
}

const copyUrl = () => {
  try {
    navigator.clipboard.writeText(window.location.href)
    toast.success(t('feedback.event.copied'))
  } catch (error) {
    toast.error(t('feedback.event.copyFailed'))
  }
}
</script>
<template>
  <Transition
    enter-from-class="transform opacity-0"
    enter-active-class="duration-1000 ease delay-500"
    enter-to-class="opacity-100"
    leave-from-class="opacity-100"
    leave-active-class="duration-1000 ease-out"
    leave-to-class="opacity-0"
    appear
  >
    <section
      v-if="showFeedback"
      id="feedback-section"
      class="p-10 transition-all"
      :style="{ '--ks-input': 'white', '--ks-inputhover': 'white' }"
    >
      <div class="mx-auto max-w-screen-md">
        <div class="mb-10 flex-wrap items-center gap-3 sm:flex">
          <h3
            class="w-full text-xl font-bold text-gray-50"
            v-text="t('feedback.question')"
          />
          <KsButton
            :class="{'pointer-events-none': feedbackRating !== 'neutral'}"
            :variant="feedbackRating === 'positive' ? 'primary' : 'secondary'"
            shape="round"
            size="large"
            icon-left="thumbs-up"
            @click="handleFeedbackButtons('positive')"
          />
          <KsButton
            :class="{'pointer-events-none': feedbackRating !== 'neutral'}"
            :variant="feedbackRating === 'negative' ? 'primary' : 'secondary'"
            shape="round"
            size="large"
            icon-left="thumbs-down"
            @click="handleFeedbackButtons('negative')"
          />
        </div>
        <div
          v-if="hasRated"
          class="flex-wrap items-center gap-3 sm:flex"
        >
          <p
            class="text-base"
            v-html="messageText"
          />
          <KsButton
            v-if="feedbackRating === 'negative'"
            variant="primary"
            size="medium"
            @click="copyUrl"
          >
            {{ t('feedback.copyUrl') }}
          </KsButton>
        </div>
      </div>
    </section>
  </Transition>
</template>
