<script setup lang="ts">
import type { Toast } from '~/models/Kloss'
import { computed, inject, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useQueryClient } from '@tanstack/vue-query'
import { KsButton, KsCallout, KsDrawer, KsInput } from '@aschehoug/kloss'
import { findRelevantGradeType } from '~/utils/gradeSorter'
import useArrayUtils from '~/utils/arrayUtils'
import useSettingsStore from '~/stores/settings'
import { useAuthStore } from '~/stores/auth'
import router from '~/router'
import { PreferredLanguage } from '~/models/User/PreferredLanguage'
import { TeleportationTarget } from '~/models/TeleportationTarget'
import { GradeType } from '~/models/GradeType'
import useSettings from '~/composables/useSettings'
import GradeTypeButton from '~/components/settings/GradeTypeButton.vue'
import { SettingsBasic, SettingsGroups, SettingsSubscription, SettingsYearPlans } from '.'
import { PendoFeature } from '~/models/Pendo'

const {
  toggleSubscriptions,
  toggleGroups,
  togglePlans
} = useSettings()

const {
  groupsOpen,
  subscriptionsOpen,
  plansOpen,
} = storeToRefs(useSettingsStore())


const props = defineProps<{ open: boolean }>()
const emit = defineEmits(['close'])

const { t } = useI18n()
const queryClient = useQueryClient()
const { unique, truthy } = useArrayUtils()
const { isPreferredLanguage, setPreferredLanguage, setGradeType, updateUser } = useAuthStore()
const { isTeacher, hasOrganization, userGrades, userPreferredLanguage, userReadOnlyGrades, sessionUserInfo, isLoading } = storeToRefs(useAuthStore())

const ksToast = inject('ksToast') as Toast
let savedPreferredLanguage = ''

const digitalBooksUrl = computed(() => <string>import.meta.env.VITE_UNIBOK_SITEURL)
const oldContentUrl = computed(() => `${import.meta.env.VITE_AUNIVERS_SITEURL}/fagpakker/nedlastinger/innhold-fra-lokus-lk-06`)

const readOnlyGradesSpansAcrossgradeTypes = computed(() => {
  return userReadOnlyGrades.value.length > 0
    ? userReadOnlyGrades.value.map(findRelevantGradeType).filter(truthy<GradeType>).filter(unique<GradeType>).length > 1
    : false
})

const saveLanguage = () => {
  if (userPreferredLanguage.value as string === savedPreferredLanguage) return

  updateUser(sessionUserInfo.value)
    .then(() => window.location.reload())
    .catch(() => ksToast.error(t('error')))
}

const saveThenClose = () => {
  if (!userGrades.value.length) {
    ksToast.info(t('settings.chooseGradeToClose'))
    return
  }
  saveLanguage()
  emit('close')
}

watch(props, () => {
  if (props.open) {
    savedPreferredLanguage = userPreferredLanguage.value
  }
})

watch(userGrades, () => {
  if (!isLoading.value && props.open) return queryClient.invalidateQueries()
})
</script>

<template>
  <Teleport :to="TeleportationTarget.AppTop">
    <KsDrawer
      :title="t('settings.title')"
      :open="props.open"
      size="600px"
      @close="saveThenClose"
    >
      <template #body>
        <div class="flex flex-col gap-6 pb-16">
          <SettingsBasic />
          <KsCallout
            v-if="readOnlyGradesSpansAcrossgradeTypes"
            :title="t('settings.readOnlyGradesSpansAcrossgradeTypes.title')"
            variant="info"
          >
            <p v-text="t('settings.readOnlyGradesSpansAcrossgradeTypes.info')" />
          </KsCallout>
          <fieldset>
            <legend
              class="mb-2 text-base font-medium uppercase tracking-wide text-gray-40"
              v-text="t('settings.sections.gradeType')"
            />
            <div class="flex flex-wrap gap-4">
              <GradeTypeButton
                v-for="gradeType in GradeType"
                :key="gradeType"
                :grade-type="gradeType"
                :disabled="isLoading"
                :data-pendo="PendoFeature.Settings.ChangeGradeType"
                @save-grade-type="setGradeType(gradeType)"
              />
            </div>
          </fieldset>
          <hr
            class="border-t border-gray-10"
            aria-hidden="true"
          >
          <fieldset class="flex gap-3">
            <legend
              class="mb-2 text-base font-medium uppercase tracking-wide text-gray-40"
              v-text="t('settings.sections.language')"
            />
            <KsButton
              variant="secondary"
              shape="rounded"
              type="label"
              :data-pendo="PendoFeature.Settings.ChangeLanguage"
            >
              <KsInput
                :value="PreferredLanguage.Bokmal"
                type="radio"
                :checked="isPreferredLanguage(PreferredLanguage.Bokmal)"
                :disabled="isLoading"
                @click="setPreferredLanguage(PreferredLanguage.Bokmal)"
              />
              {{ t(`metadata.language.${PreferredLanguage.Bokmal}`) }}
            </KsButton>
            <KsButton
              variant="secondary"
              shape="rounded"
              type="label"
              :data-pendo="PendoFeature.Settings.ChangeLanguage"
            >
              <KsInput
                :value="PreferredLanguage.Nynorsk"
                type="radio"
                :checked="isPreferredLanguage(PreferredLanguage.Nynorsk)"
                :disabled="isLoading"
                @click="setPreferredLanguage(PreferredLanguage.Nynorsk)"
              />
              {{ t(`metadata.language.${PreferredLanguage.Nynorsk}`) }}
            </KsButton>
          </fieldset>
          <hr
            class="border-t border-gray-10"
            aria-hidden="true"
          >
          <div v-if="isTeacher">
            <p
              class="mb-2 text-base font-medium uppercase tracking-wide text-gray-40"
              v-text="t('settings.sections.instruction')"
            />
            <div class="flex flex-col gap-3">
              <KsButton
                class="justify-between"
                variant="secondary"
                icon-right="long-arrow-right"
                :disabled="!hasOrganization"
                :data-pendo="PendoFeature.Settings.Groups"
                @click="toggleGroups"
              >
                {{ t('settings.drawers.groups') }}
              </KsButton>
              <KsButton
                class="justify-between"
                variant="secondary"
                icon-right="long-arrow-right"
                :data-pendo="PendoFeature.Settings.SuggestedPlans"
                @click="togglePlans"
              >
                {{ t('settings.drawers.plans') }}
              </KsButton>
              <KsButton
                class="justify-between"
                variant="secondary"
                icon-right="long-arrow-right"
                :data-pendo="PendoFeature.Settings.Subscriptions"
                @click="toggleSubscriptions"
              >
                {{ t('settings.drawers.subscription') }}
              </KsButton>
            </div>
          </div>
          <hr
            v-if="isTeacher"
            class="border-t border-gray-10"
            aria-hidden="true"
          >
          <div>
            <p
              class="mb-2 text-base font-medium uppercase tracking-wide text-gray-40"
              v-text="t('settings.drawers.shortcuts')"
            />
            <div class="flex flex-col gap-3">
              <KsButton
                class="justify-between"
                variant="secondary"
                icon-right="long-arrow-right"
                :data-pendo="PendoFeature.Settings.ShortcutUnibok"
                :href="digitalBooksUrl"
              >
                {{ t('settings.shortcuts.digitalBooks') }}
              </KsButton>
              <KsButton
                class="justify-between"
                variant="secondary"
                icon-right="long-arrow-right"
                :data-pendo="PendoFeature.Settings.ShortcutBlog"
                @click="router.push('/nyheter').then(() => emit('close'))"
              >
                {{ t('blog.title') }}
              </KsButton>
              <KsButton
                class="justify-between"
                variant="secondary"
                icon-right="long-arrow-right"
                :data-pendo="PendoFeature.Settings.ShortcutLokus"
                :href="oldContentUrl"
              >
                {{ t('settings.shortcuts.oldContent') }}
              </KsButton>
            </div>
          </div>
        </div>
      </template>
    </KsDrawer>
  </Teleport>
  <SettingsGroups
    :open="groupsOpen"
    @close="emit('close')"
    @back="toggleGroups()"
  />
  <SettingsSubscription
    :open="subscriptionsOpen"
    @close="emit('close')"
    @back="toggleSubscriptions()"
  />
  <SettingsYearPlans
    :open="plansOpen"
    @close="emit('close')"
    @back="togglePlans()"
  />
</template>
