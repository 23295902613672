import type { ContentProduct } from '~/models/Content/ContentProduct'
import { storeToRefs } from 'pinia'
import { useQuery } from '@tanstack/vue-query'
import { waitFor } from '~/utils/asyncUtils'
import useProductStore from '~/stores/product'
import { ContentType } from '~/models/Content/ContentType'
import useProductMapper from '~/composables/useProductMapper'
import useContentApi from '~/api/contentApi'

export const useProductQuery = (locationId: number) => {
  const { findContents } = useContentApi()
  const { mapProduct } = useProductMapper()
  const { hasLoaded: hasLoadedProducts } = storeToRefs(useProductStore())
  const { addProduct, hasProduct } = useProductStore()

  const fetchProduct = async (locationId: number) => {
    const contentValue = (await findContents<ContentProduct>({
      locationIdCriterion: [locationId],
      contentTypeCriterion: [ContentType.Product],
    }, 1))[0]
    if (!contentValue) throw Error(`Product ${locationId} not found`)
    await waitFor(() => hasLoadedProducts.value, 1000)
    if (!hasProduct(contentValue.ean)) {
      addProduct(await mapProduct(contentValue))
    }
    return contentValue
  }

  return useQuery({
    queryKey: ['product', locationId],
    queryFn: () => fetchProduct(locationId),
    staleTime: Infinity,
    retry: 1,
  })
}
