<script setup lang="ts">
import type { GroupMember } from '~/models/Group'
import type { TaskUser } from '~/models/AssignTask'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useQuery } from '@tanstack/vue-query'
import { KsButton, KsDialog, KsIcon, KsInput, KsSpinner } from '@aschehoug/kloss'
import { debounce } from '~/utils/functionUtils'
import { UserRole } from '~/models/User/UserRole'
import TeleportationTarget from '~/models/TeleportationTarget'
import { TaskStatus } from '~/models/AssignTask'
import useGroupsApi from '~/api/groupsApi'
import ScrollBox from '~/components/utils/ScrollBox.vue'

const { currentStudents } = defineProps<{ currentStudents: TaskUser[] }>()
const emit = defineEmits(['addStudents'])

const { t } = useI18n()
const { searchUsers } = useGroupsApi()

const open = ref(false)
const searchQuery = ref('')
const students = ref<TaskUser[]>([])
const showTip = ref(false)

const { data, isLoading, error } = useQuery({
  queryKey: computed(() => ['searchUsers', searchQuery.value]),
  queryFn: () => searchUsers(searchQuery.value),
  select: (data) => data
    .filter((student: GroupMember) => student.role === UserRole.Student)
    .map((student: GroupMember) => ({
      userId: student.userId,
      status: TaskStatus.Open,
      firstName: student.firstName,
      lastName: student.lastName,
      fullName: student.fullName,
      primaryRole: student.role
    })),
  enabled: computed(() => searchQuery.value.length >= 3),
  staleTime: Infinity,
})

const studentIsSelected = (student: TaskUser) => currentStudents.some(({ userId }) => userId === student.userId)

const handleInput = debounce((event: Event) => {
  searchQuery.value = (event.target as HTMLInputElement).value
  showTip.value = [1, 2].includes(searchQuery.value.length)
}, 300)

const toggleStudent = (student: TaskUser) => {
  const index = students.value.findIndex(({ userId }) => userId === student.userId)
  return index === -1 ? students.value.push(student) : students.value.splice(index, 1)
}

const handleAddStudents = () => {
  emit('addStudents', students.value)
  searchQuery.value = ''
  students.value = []
  open.value = false
}
</script>

<template>
  <button
    class="mt-6 text-seagreen-40 underline hover:text-seagreen-50 focus-visible:ring"
    @click="open = true"
    v-text="t('assign.missingStudents')"
  />
  <Teleport :to="TeleportationTarget.AppTop">
    <KsDialog
      :title="t('assign.searchForStudents')"
      :open
      @close="open = false"
    >
      <template #body>
        <div class="flex flex-col gap-4">
          <div class="relative">
            <div class="absolute left-5 top-1/2 z-10 flex -translate-y-1/2 justify-center text-base text-gray-40">
              <KsIcon
                icon="magnifying-glass"
                :fade="isLoading"
                animation-duration="500ms"
                loop-animation
              />
            </div>
            <KsInput
              v-focus="{ delay: 251 }"
              :value="searchQuery"
              type="text"
              name="search"
              :placeholder="t('assign.searchSchoolPlaceholder')"
              class="!rounded !pl-12 !text-base placeholder:text-gray-40"
              @input="handleInput"
            />
            <KsButton
              v-if="searchQuery.length"
              icon-left="xmark"
              shape="square"
              variant="tertiary"
              size="small"
              class="absolute right-3 top-1/2 z-10 -translate-y-1/2 text-xl"
              @click="() => {
                searchQuery = ''
                showTip = false
              }"
            />
          </div>
          <KsSpinner
            v-if="isLoading"
            class="mt-4"
          />
          <div
            v-else-if="error"
            class="text-sm text-coral-40"
            v-text="t('assign.error')"
          />
          <div
            v-else-if="searchQuery.length >= 3 && !data?.length"
            class="text-sm"
            v-text="t('assign.noStudents')"
          />
          <div
            v-else-if="showTip"
            class="text-sm text-coral-40"
            v-text="t('assign.searchForStudentsTip')"
          />
          <ScrollBox
            v-else-if="data?.length"
            class="max-h-72 !overflow-x-hidden"
          >
            <transition-group
              name="list"
              tag="ul"
              class="relative"
            >
              <li
                v-for="student in data"
                :key="`student-${student.userId}`"
                :class="{ 'pointer-events-none text-green-20': studentIsSelected(student) }"
                class="size-full border-b border-green-10 last:border-b-0"
              >
                <label
                  :for="`id-${student.userId}`"
                  class="flex size-full cursor-pointer items-center justify-between px-1 py-3 font-medium transition-all hover:rounded hover:bg-secondary-hover hover:pl-2"
                >
                  <span v-text="student.fullName" />
                  <KsInput
                    v-if="!studentIsSelected(student)"
                    :id="`id-${student.userId}`"
                    type="checkbox"
                    :checked="students.some(({ userId }) => userId === student.userId)"
                    :aria-checked="students.some(({ userId }) => userId === student.userId)"
                    @change="toggleStudent(student)"
                  />
                  <span
                    v-else
                    class="flex items-center gap-2"
                  >
                    <span
                      class="text-xs"
                      v-text="t('assign.studentAdded')"
                    />
                    <KsIcon
                      icon="check"
                      :scale=".8"
                      class="pr-2"
                    />
                  </span>
                </label>
              </li>
            </transition-group>
          </ScrollBox>
          <div
            v-if="students.length"
            class="flex flex-wrap items-center gap-x-2"
          >
            <ul class="flex flex-wrap gap-x-1">
              <li
                v-for="student in students"
                :key="`key-${student.userId}`"
                class="flex items-center text-sm font-medium"
              >
                <span>{{ student.fullName }}</span>
                <KsButton
                  icon-left="xmark"
                  shape="square"
                  size="small"
                  @click="toggleStudent(student)"
                />
              </li>
            </ul>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex justify-end gap-4">
          <KsButton
            variant="secondary"
            shape="rounded"
            @click="open = false"
          >
            {{ t('cancel') }}
          </KsButton>
          <KsButton
            :disabled="!students.length"
            variant="primary"
            shape="rounded"
            @click="handleAddStudents"
          >
            {{ t('assign.addStudents') }}
          </KsButton>
        </div>
      </template>
    </KsDialog>
  </Teleport>
</template>

<style scoped>
.list-move,
.list-enter-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.list-leave-active {
  transition: all 0.2s cubic-bezier(0.55, 0, 0.1, 1);
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}

.list-leave-active {
  position: absolute;
}

:deep(.ks-dialog) {
  max-width: 470px!important;
}
</style>
