import type { BaseItem } from '~/models/Content/BaseItem'
import { ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { defineStore, storeToRefs } from 'pinia'
import useAuthStore from '~/stores/auth'
import useLicenseControlApi from '~/api/licenseControlApi'

const useLicenseControlStore = defineStore('licenseControl', () => {
  const isLicenseControlEnabled = (import.meta.env.VITE_LICENSE_CONTROL_ENABLED.toLowerCase() === 'true')
  const { isAuthenticated, needsOrganization, userOrganization } = storeToRefs(useAuthStore())
  const { checkLicenseByPathString } = useLicenseControlApi()
  const route = useRoute()

  const accessRestricted = ref<boolean>(false)
  const routeLocation = ref<number>()
  const content = ref<BaseItem>()

  const hasAccessRestricted = computed(() => accessRestricted.value)

  const canCheckLicense = computed(() => isLicenseControlEnabled && isAuthenticated.value && !needsOrganization.value)

  /**
   * Disable overlay on route change, e.g. if browser-back is pressed
   */
  watch(route, (newRoute) => {
    const locationId = newRoute.params.locationId ? Number(newRoute.params.locationId) : undefined
    if (routeLocation.value !== locationId) resetLicenseControlStore()
  })

  /**
   * Triggers the license control check.
   * Activates the overlay and washes the page if the user is not allowed to access the content.
   * @param item The item to check the license for. Returns early if noe item is provided to allow
   * for less checking on the providing side.
   */
  const checkItemLicense = async (item: BaseItem): Promise<void> => {
    content.value = item
    if (!content.value || !canCheckLicense.value) return
    resetLicenseControlStore()
    routeLocation.value = content.value.locationId

    const isAllowed = await checkLicenseByPathString(content.value.pathString, userOrganization.value)
    if (!isAllowed) accessRestricted.value = true
  }

  const resetLicenseControlStore = () => {
    accessRestricted.value = false
    routeLocation.value = undefined
  }

  const locationPath = computed(() => content.value?.pathString)

  return {
    checkItemLicense,
    hasAccessRestricted,
    resetLicenseControlStore,
    locationPath,
    content,
  }
})

export default useLicenseControlStore
