<script setup lang="ts">
import type { Task } from '~/models/AssignTask'
import { computed } from 'vue'
import { RouterLink } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { faEye, faLock } from '@fortawesome/pro-solid-svg-icons'
import { KsButton, KsIcon } from '@aschehoug/kloss'
import useGroupsStore from '~/stores/groups'
import useAuthStore from '~/stores/auth'
import { PendoFeature } from '~/models/Pendo'
import { TaskStatus } from '~/models/AssignTask'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useGradeString from '~/composables/useGradeString'
import EditAssignTaskDialog from '~/components/products/exams/assignment/EditAssignTaskDialog.vue'
import AssignStudentsDialog from '~/components/products/exams/assignment/AssignStudentsDialog.vue'

const { task } = defineProps<{ task: Task }>()

const { t } = useI18n()
const { findGroupById } = useGroupsStore()
const { createGradeString } = useGradeString()
const { isTeacher, isStudent } = storeToRefs(useAuthStore())
const { buildAssignedTaskUrl } = useUrlBuilder()

const groupName = computed(() => {
  if (!task.groups?.length) return
  return findGroupById(task.groups[0])?.name
})

const subtitle = computed(() => {
  const grades = task.grades ? createGradeString(task.grades) : ''

  return [grades, t('termExam.title')].filter(Boolean).join(' | ')
})

const showReport = computed(() => {
  return (task.users?.length ?? 0) > 0 && task.subjects?.includes('ENG')
})

const reportRoute = computed(() => {
  return { name: 'report', query: { groupId: task.groups?.[0] ?? '', grade: task.grades?.[0] ?? '' } }
})

const taskIsOpen = computed(() => task.status === TaskStatus.Open)
</script>

<template>
  <article class="group relative flex size-full flex-col flex-wrap items-start justify-start gap-2 rounded-md bg-white p-3 transition sm:flex-row sm:items-center">
    <div
      class="absolute right-0 top-0 flex size-8 shrink-0 flex-col items-center justify-center gap-1 rounded-md text-xs sm:relative sm:size-14 sm:text-base"
      :class="taskIsOpen ? 'bg-green-5' : 'bg-coral-5'"
    >
      <KsIcon
        :class="taskIsOpen ? 'text-green-40' : 'text-coral-40'"
        :icon="taskIsOpen ? faEye : faLock"
        :beat="taskIsOpen"
        loop-animation
        animation-duration="2500ms"
      />
      <span
        class="hidden text-xs font-semibold sm:block"
        :class="taskIsOpen ? 'text-green-50' : 'text-coral-50'"
      >
        {{ taskIsOpen ? t('assign.shared') : t('assign.closed') }}
      </span>
    </div>
    <div class="flex">
      <div
        class="flex shrink-0 flex-col gap-1"
        :class="groupName && 'border-r border-blue-10 pr-4'"
      >
        <p
          class="text-xs font-medium uppercase tracking-wider text-gray-40"
          v-text="subtitle"
        />
        <h3
          class="font-bold text-gray-50 md:text-lg"
          v-text="task.name"
        />
      </div>
      <div
        v-if="groupName"
        class="flex flex-col gap-1.5 pl-4"
      >
        <span
          class="text-xs font-medium uppercase tracking-wider text-gray-40"
          v-text="t('groups.groupName')"
        />
        <p
          class="line-clamp-2 text-sm font-bold text-gray-50"
          v-text="groupName"
        />
      </div>
    </div>
    <div
      v-if="isTeacher"
      class="flex items-center gap-2 md:ml-auto"
    >
      <KsButton
        :is="RouterLink"
        v-if="showReport"
        :to="reportRoute"
        variant="secondary"
        size="small"
        style="--ks-input: rgb(var(--au-orange-20)); --ks-inputhover: rgb(var(--au-orange-10)); --ks-secondary: rgb(var(--au-coral-60));"
      >
        {{ t('report.button') }}
      </KsButton>
      <EditAssignTaskDialog
        v-if="taskIsOpen"
        :task
      />
      <AssignStudentsDialog
        v-else
        :task
      />
    </div>
    <KsButton
      :is="RouterLink"
      v-if="isStudent && task.status === TaskStatus.Open"
      :to="buildAssignedTaskUrl(task)"
      variant="primary"
      icon-right="arrow-right"
      :data-pendo="PendoFeature.TermExam.GoToTask"
    >
      {{ t('assign.goToExam') }}
    </KsButton>
  </article>
</template>
