<script lang="ts" setup>
import type { SubjectViewProps } from '~/models/Subject'
import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { setTitle } from '~/utils/dom'
import useProductStore from '~/stores/product'
import useFilterStore from '~/stores/filter'
import useSubjectView from '~/composables/useSubjectView'
import { useAppColor } from '~/composables/useAppColor'
import SubjectComponentView from '~/components/subject/SubjectComponentView.vue'
import LegacySubjectView from '~/components/subject/LegacySubjectView.vue'

const props = defineProps<SubjectViewProps>()

const { t } = useI18n()
const filterStore = useFilterStore()
const { selectedGrade } = storeToRefs(filterStore)
const { hasLoaded } = storeToRefs(useProductStore())
const { isComponentView } = useSubjectView()
const { clear: clearAppColor } = useAppColor()

setTitle(t(`metadata.subjects.${props.subjectCode}`))

const renderComponent = computed(() => selectedGrade.value && isComponentView(props.subjectCode, selectedGrade.value)
  ? SubjectComponentView
  : LegacySubjectView
)

const componentProps = computed((): SubjectViewProps => ({
  subjectCode: props.subjectCode,
  isUpcoming: false
}))

watch(renderComponent, () => clearAppColor())
</script>

<template>
  <component
    :is="renderComponent"
    v-if="hasLoaded"
    v-bind="componentProps"
  />
</template>
