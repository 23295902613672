<script setup lang="ts">
import { nextTick, ref, useTemplateRef, watch } from 'vue'
import { faPlus, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { KsIcon, KsButton } from '@aschehoug/kloss'

const open = ref(false)
const triggerEl = useTemplateRef('trigger')
const dialogEl = useTemplateRef('dialog')

watch(open, (value) => {
  nextTick(() => {
    if (value) {
      dialogEl.value?.showModal()
    }

    else {
      dialogEl.value?.close()
      triggerEl.value?.focus()
    }
  })
})
</script>

<template>
  <div
    class="relative grid auto-rows-max grid-rows-1"
    v-bind="$attrs"
  >
    <slot :is-fullscreen="open" />

    <button
      ref="trigger"
      class="group col-start-1 row-start-1 grid size-full place-items-center"
      :aria-label="$t('open')"
      @click="open = true"
    >
      <div class="flex aspect-square rounded-full bg-white p-6 text-black opacity-0 group-hover:opacity-75 group-focus-visible:opacity-75 group-focus-visible:ring">
        <KsIcon :icon="faPlus" />
      </div>
    </button>
  </div>

  <dialog
    ref="dialog"
    class="fullscreen-content-dialog"
    @close="open = false"
  >
    <div class="!fixed inset-0 z-10 grid size-full auto-rows-max grid-rows-1 overscroll-none bg-black">
      <slot :is-fullscreen="open" />

      <KsButton
        v-focus
        :icon-left="faXmark"
        shape="round"
        variant="secondary"
        class="absolute right-8 top-4"
        size="large"
        :aria-label="$t('close')"
        @click="open = false"
      />
    </div>
  </dialog>
</template>

<style>
  html:has(.fullscreen-content-dialog[open]) {
    overflow: hidden;
  }
</style>
