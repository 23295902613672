<script setup lang="ts">
import type { HeaderGradeTypeProps, HeaderViewProps } from '~/models/Subject'
import type { ContentProductPackage } from '~/models/Content/ContentProductPackage'
import type { ContentProductHeader } from '~/models/Content/ContentProductHeader'
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useQuery } from '@tanstack/vue-query'
import { KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { setTitle } from '~/utils/dom'
import { waitFor } from '~/utils/asyncUtils'
import arrayUtils from '~/utils/arrayUtils'
import useSubjectsStore from '~/stores/subjects'
import useProductStore from '~/stores/product'
import useFilterStore from '~/stores/filter'
import useAuthStore from '~/stores/auth'
import { GradeType } from '~/models/GradeType'
import { ContentType } from '~/models/Content/ContentType'
import { MaxTimeoutMs } from '~/constants/api'
import { useContentHelper } from '~/composables/useContentHelper'
import { useAppColor } from '~/composables/useAppColor'
import useContentApi from '~/api/contentApi'
import HeaderViewBannerGrid from '~/components/utils/HeaderViewBannerGrid.vue'
import HeaderNotFound from '~/components/subject/HeaderNotFound.vue'
import LowerSecondaryHeader from '~/components/subject/gradetypes/LowerSecondaryHeader.vue'
import LowerPrimaryHeader from '~/components/subject/gradetypes/LowerPrimaryHeader.vue'
import ContentFilteredAwayCallout from '~/components/ResourceFinder/ContentFilteredAwayCallout.vue'

const props = defineProps<HeaderViewProps>()

const router = useRouter()
const filterStore = useFilterStore()
const { gradeFilterFunc, removeTeacherArticlesFunc } = filterStore
const { selectedGrade } = storeToRefs(filterStore)

const { selectedGradeType } = storeToRefs(useAuthStore())
const { isPackage } = useContentHelper()
const { findContents } = useContentApi()
const { truthy } = arrayUtils()
const { setCurrentSubject } = useSubjectsStore()

const productStore = useProductStore()
const { getRelatedLocationsByGradeAndSubject } = productStore
const { hasLoaded: hasLoadedProducts } = storeToRefs(productStore)
const { clear: clearAppColor } = useAppColor()

const isUpcoming = ref(false)

const children = computed(() => data?.value?.children ?? [])

const filteredPackages = computed(() => children.value.filter((child) => isPackage(child) && gradeFilterFunc(child)))
const filteredContents = computed(() => children.value.filter((child) => !isPackage(child) && gradeFilterFunc(child) && removeTeacherArticlesFunc(child)))
const filteredCombined = computed(() => [...filteredPackages.value, ...filteredContents.value])

const headerProps = computed((): HeaderGradeTypeProps => ({
  subjectCode: props.subjectCode,
  contents: filteredContents.value,
  packages: filteredPackages.value,
  siblings: siblings?.value ?? [],
  header: data?.value?.header,
  subjectRoute: isUpcoming.value ? 'subject_upcoming' : 'subject',
  isLoading: isLoading.value,
}))

const { data, isLoading } = useQuery({
  enabled: computed(() => !!selectedGrade.value),
  queryKey: computed(() => ['header-content', props.headerLocationId, selectedGrade.value]),
  queryFn: async () => {
    const header = (await findContents<ContentProductHeader|ContentProductPackage>({
      locationIdCriterion: [props.headerLocationId],
      contentTypeCriterion: [ContentType.ProductHeader, ContentType.ProductPackage],
    }))[0]

    if (!header) return { header, children: [] }

    return {
      header,
      children: await findContents({
        parentLocationIdCriterion: [header.mainLocationId],
        gradeFieldCriterion: [selectedGrade.value!],
        sortField: header.sortField,
        sortOrder: header.sortOrder,
      })
    }
  },
  staleTime: Infinity,
})

const { data: siblings } = useQuery({
  enabled: computed(() => !!(selectedGrade.value && data?.value?.header?.parentLocationId)),
  queryKey: computed(() => ['header-siblings', props.headerLocationId, selectedGrade.value]),
  queryFn: () => findContents<ContentProductHeader>({
    parentLocationIdCriterion: [data.value!.header.parentLocationId],
    contentTypeCriterion: [ContentType.ProductHeader],
    gradeFieldCriterion: [selectedGrade.value!],
    sortField: 'priority',
    sortOrder: 'asc',
  }),
  staleTime: Infinity,
})

watch(data, async () => {
  if (!data.value?.header) return
  setTitle(data.value.header.title)
  await waitFor(() => hasLoadedProducts.value, MaxTimeoutMs)
  if (!selectedGrade.value) return
  const { upcomingLocationIds } = await getRelatedLocationsByGradeAndSubject(selectedGrade.value, props.subjectCode)
  isUpcoming.value = upcomingLocationIds.filter(truthy).some((u) => data.value.header.pathString.includes(`/${u}/`))
}, { immediate: true })

onBeforeUnmount(() => {
  clearAppColor()
  setCurrentSubject(undefined)
})

watch(selectedGrade, () => {
  router.replace({
    name: 'header',
    params: {
      subject: props.subjectCode.toLowerCase(),
      locationId: props.headerLocationId,
    },
    query: {
      grade: selectedGrade.value
    }
  })
})

watch(props, () => {
  if (!props.subjectCode) return
  setCurrentSubject(props.subjectCode)
}, { immediate: true })
</script>

<template>
  <div
    v-if="!filteredCombined.length && children.length > 0 && !isLoading"
    class="mx-auto flex max-w-screen-au flex-col gap-4 px-4 pb-16 pt-36 sm:px-8"
  >
    <ContentFilteredAwayCallout :contents="children" />
  </div>

  <div v-else-if="isLoading">
    <KsSkeletonWrapper>
      <HeaderViewBannerGrid class="py-24">
        <div class="flex gap-2 grid-in-filters">
          <KsSkeleton
            height="2rem"
            width="8rem"
          />
          <KsSkeleton
            height="2rem"
            width="8rem"
          />
        </div>
        <div class="grid-in-header-info">
          <KsSkeleton height="4rem" />
          <KsSkeleton
            height="1.5rem"
            width="80%"
          />
          <KsSkeleton
            height="1.5rem"
            width="70%"
          />
          <KsSkeleton
            height="1.5rem"
            width="80%"
          />
          <KsSkeleton
            height="1.5rem"
            width="65%"
          />
          <KsSkeleton
            height="1.5rem"
            width="70%"
          />
        </div>
      </HeaderViewBannerGrid>
    </KsSkeletonWrapper>

    <!-- these sections are a copy of the skeleton in LowerPrimaryHeader -->
    <section
      v-for="index in 2"
      :key="index"
    >
      <KsSkeletonWrapper class="mx-auto max-w-screen-au space-y-4 px-4 py-8 sm:px-8">
        <KsSkeleton
          height="36px"
          width="120px"
        />
        <ul class="grid grid-cols-1 gap-8 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <KsSkeleton
            v-for="i in 4"
            :key="i"
            height="280px"
          />
        </ul>
      </KsSkeletonWrapper>
    </section>
  </div>

  <template v-else>
    <LowerPrimaryHeader
      v-if="selectedGradeType === GradeType.LowerPrimary && filteredCombined.length > 0"
      v-bind="headerProps"
    />

    <LowerPrimaryHeader
      v-else-if="selectedGradeType === GradeType.UpperPrimary && filteredCombined.length > 0"
      v-bind="headerProps"
    />

    <LowerSecondaryHeader
      v-else-if="selectedGradeType === GradeType.LowerSecondary && filteredCombined.length > 0"
      v-bind="headerProps"
    />

    <HeaderNotFound
      v-else
      :subject-code="headerProps.subjectCode"
      :subject-route="headerProps.subjectRoute"
      :grade-codes="headerProps.header?.grades ?? []"
    />
  </template>
</template>
