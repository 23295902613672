import type { SubjectCode } from '~/models/Subject'
import type { Product, RelatedLocations, SupportProductName } from '~/models/Product'
import type { GradeCode } from '~/models/Grade'
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed, ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { isExcludedSubject, sortBySubjectIndex } from '~/utils/subjectSorter'
import { sortByNameGradeSubject } from '~/utils/productSorter'
import { fromApiCache } from '~/utils/cache'
import { waitFor } from '~/utils/asyncUtils'
import arrayUtils from '~/utils/arrayUtils'
import useFilterStore from '~/stores/filter'
import { useAuthStore } from '~/stores/auth'
import { LicenseModel, ProductVariation } from '~/models/Product'
import { useStaticProduct } from '~/composables/useStaticProduct'
import useProductHelper from '~/composables/useProductHelper'
import useProductsApi from '~/api/productsApi'
import { externalClient } from '~/api/client/externalClient'
import { contentClient } from '~/api/client/contentClient'

const useProductStore = defineStore('product', () => {
  const { getOpenProducts, getLicensedProducts } = useProductsApi()
  const {
    hasRelatedLocations,
    productHasSubjectAndGrade,
    isProductVariation,
    isRelevantProduct,
    isAddonProduct,
    isSupportName,
    isSupportProduct,
    supportProductNames,
  } = useProductHelper()
  const { uniqueBy, truthy, unique } = arrayUtils()
  const { ALWAYS_SEARCHABLE_PRODUCTS, ALWAYS_SHOWN_PRODUCTS } = useStaticProduct()
  const { userPreferredLanguage, userRelevantGrades } = storeToRefs(useAuthStore())

  const products = ref<Product[]>([])
  const isLoading = ref(false)
  const hasLoaded = ref(false)
  const hasProducts = computed(() => products.value.length > 0 && !isLoading.value)
  const productsWithRelatedLocations = computed(() => products.value.filter(hasRelatedLocations))

  function mapProductResponse(products: any[]): Product[] {
    return products
      .filter(uniqueBy('ean'))
      .filter((product) => Boolean(product.aunivers?.pathString))
      .map((product) => ({
        ...product,
        name: product.aunivers?.name ? product.aunivers.name : product.name,
        subjects: product?.subjects || [],
        grades: product?.grades || [],
      }))
  }

  Promise
    .all([
      fromApiCache(contentClient.getUri({ url: '/account/products/open' })),
      fromApiCache(externalClient.getUri({ url: '/frontend/licenses/products' }), { ignoreSearch: true }),
    ])
    .then(([open = [], licenced = []]): void => {
      if (products.value.length !== 0) return
      products.value.push(...mapProductResponse([...open, ...licenced]))
    })

  const hasOnlyTrialProducts = computed((): boolean | undefined => {
    if (!hasProducts.value) return undefined

    const trialProducts = products.value.filter((product) => {
      return product.licenseModel === LicenseModel.Trial || product.licenseModel === LicenseModel.Open
    })

    return trialProducts.length === products.value.length
  })

  const getProducts = async () => {
    isLoading.value = true
    products.value = mapProductResponse([
      ...await Promise.all([
        getOpenProducts(),
        getLicensedProducts(userPreferredLanguage.value),
      ])
        .then(([open, licensed]) => [
          ...open,
          ...licensed,
        ])
        .finally(() => {
          hasLoaded.value = true
          isLoading.value = false
        }),
    ])
  }

  const hasProduct = (ean: string) =>
    products.value.some((product) => ean === product.ean)

  const addProduct = (product: Product) => {
    const index = products.value.findIndex(({ ean }) => ean && ean === product.ean)
    index > -1
      ? products.value.splice(index, 1)
      : products.value.unshift(product)
  }

  const getRelatedLocationsByGradeAndSubject = async (grade: GradeCode, subject: SubjectCode) => {
    if (products.value.length === 0) {
      console.log('Products was loaded slowly. Waiting for up to 5 seconds for products to load.')
      await waitFor(() => products.value.length > 0, 5000)
    }
    return products.value
      .filter((product) =>
        productHasSubjectAndGrade(product, grade, subject)
        && !isProductVariation(product, ProductVariation.Addon))
      .map((product) => product.aunivers?.relatedLocations)
      .filter((relatedLocations) => !!relatedLocations)
      .reduce((relatedLocations, location) => {
        if (!location) return relatedLocations
        relatedLocations.flexibleContentLocationIds.push(location.flexibleContentLocationId || 0)
        relatedLocations.learningPathsLocationIds.push(location.learningPathsLocationId || 0)
        relatedLocations.interdisciplinaryContentLocationIds.push(location.interdisciplinaryContentLocationId || 0)
        relatedLocations.forTeacherLocationIds.push(location.teacherContentLocationId || 0)
        relatedLocations.colophonLocationIds.push(location?.colophonLocationId || 0)
        relatedLocations.upcomingLocationIds.push(location?.upcomingContentLocationId || 0)
        return relatedLocations
      }, {
        flexibleContentLocationIds: <number[]>[],
        learningPathsLocationIds: <number[]>[],
        interdisciplinaryContentLocationIds: <number[]>[],
        forTeacherLocationIds: <number[]>[],
        colophonLocationIds: <number[]>[],
        upcomingLocationIds: <number[]>[],
      })
  }

  const getRelatedLocationsByLocationId = async (locationId: number): Promise<{
    flexibleContentLocationIds: number[]
    learningPathsLocationIds: number[]
    interdisciplinaryContentLocationIds: number[]
    forTeacherLocationIds: number[]
    colophonLocationIds: number[]
  }> => {
    if (products.value.length === 0) {
      console.log('Products was loaded slowly. Waiting for up to 5 seconds for products to load.')
      await waitFor(() => products.value.length > 0, 5000)
    }
    const product: Product | undefined = getProductFromLocationId(locationId)
    if (!product) {
      return {
        flexibleContentLocationIds: [],
        learningPathsLocationIds: [],
        interdisciplinaryContentLocationIds: [],
        forTeacherLocationIds: [],
        colophonLocationIds: [],
      }
    }

    const relatedLocations: RelatedLocations | undefined = product.aunivers?.relatedLocations
    if (!relatedLocations) {
      return {
        flexibleContentLocationIds: [],
        learningPathsLocationIds: [],
        interdisciplinaryContentLocationIds: [],
        forTeacherLocationIds: [],
        colophonLocationIds: [],
      }
    }

    return {
      flexibleContentLocationIds: <number[]>[relatedLocations.flexibleContentLocationId].filter(truthy),
      learningPathsLocationIds: <number[]>[relatedLocations.learningPathsLocationId].filter(truthy),
      interdisciplinaryContentLocationIds: <number[]>[relatedLocations.interdisciplinaryContentLocationId].filter(truthy),
      forTeacherLocationIds: <number[]>[relatedLocations.teacherContentLocationId].filter(truthy),
      colophonLocationIds: <number[]>[relatedLocations?.colophonLocationId].filter(truthy),
    }
  }

  const getRelatedLocationsByResource = (resource: BaseItem): RelatedLocations | undefined => {
    const product: Product | undefined = getProductFromResource(resource)

    if (product) {
      return product.aunivers?.relatedLocations
    }

    return undefined
  }

  const getProductFromResource = (item: BaseItem, checkProductHeaders = true): Product | undefined => {
    const { productHeaders } = storeToRefs(useFilterStore())
    const foundProduct = products.value.find((product) => {
      const metadata = product?.aunivers
      return (item?.pathString || '').includes(`/${metadata?.locationId}/`)
    })
    if (foundProduct) return foundProduct

    const { flexibleContent, learningPath, upcomingContent } = productHeaders.value
    const possibleItems = [...flexibleContent, ...learningPath, ...upcomingContent]
    const foundItem = possibleItems.find(({ locationId }) => locationId === item.locationId)
    if (foundItem && checkProductHeaders) return getProductFromResource(foundItem, false)
    return undefined
  }

  const licensedProducts = computed(() => products.value
    .filter(({ licenseModel }) => licenseModel !== LicenseModel.Open))

  const noLicensedProducts = computed(() => hasLoaded.value && !licensedProducts.value.length)

  const getProductFromLocationId = (locationId: number): Product | undefined => products.value
    .find((product) => Number(product.aunivers?.locationId) === locationId)

  const getProductFromPathString = (pathString: string): Product | undefined => products.value
    .find((product) => product?.aunivers?.locationId && pathString.includes(`/${product.aunivers.locationId}/`))

  const addonProducts = computed((): Product[] => products.value
    .filter((product) => isAddonProduct(product))
  )

  const matchingProducts = computed(() => products.value
    .concat(ALWAYS_SEARCHABLE_PRODUCTS)
    .filter(uniqueBy('ean'))
    .filter((product) => (product.aunivers.pathString || '').endsWith('/')))

  const filteredAddonProducts = computed(() => addonProducts.value
    .filter((product) => isRelevantProduct(product, userRelevantGrades.value) && isAddonProduct(product) && !isSupportProduct(product))
    .filter((product) => ALWAYS_SHOWN_PRODUCTS.filter((alwaysShownProduct) => alwaysShownProduct.ean === product.ean).length === 0)
    .sort(sortByNameGradeSubject)
  )

  const filteredSubjectCodes = computed(() => products.value
    .filter((product) => isRelevantProduct(product, userRelevantGrades.value) && !isAddonProduct(product) && !isSupportProduct(product))
    .flatMap(({ subjects }) => subjects)
    .filter((subject) => !isExcludedSubject(subject))
    .filter(unique<SubjectCode>)
    .sort(sortBySubjectIndex)
  )

  const selectedSupportProductName = ref<SupportProductName>()

  const filteredSupportProductNames = computed(() => supportProductNames
    .filter((identifier) => products.value.some((product) => isRelevantProduct(product, userRelevantGrades.value) && isSupportName(product, identifier)))
  )

  const supportProducts = computed(() => products.value.filter((product) => isSupportProduct(product)))

  const filteredSupportProducts = computed(() => products.value
    .filter((product) => isRelevantProduct(product, userRelevantGrades.value) && isSupportProduct(product))
  )

  return {
    products,
    productsWithRelatedLocations,
    addonProducts,
    matchingProducts,
    licensedProducts,
    getProducts,
    getRelatedLocationsByGradeAndSubject,
    getRelatedLocationsByResource,
    getRelatedLocationsByLocationId,
    hasProducts,
    isLoading,
    getProductFromResource,
    noLicensedProducts,
    hasLoaded,
    hasOnlyTrialProducts,
    addProduct,
    hasProduct,
    getProductFromPathString,
    filteredSubjectCodes,
    filteredAddonProducts,
    selectedSupportProductName,
    filteredSupportProductNames,
    filteredSupportProducts,
    supportProducts,
  }
})

export default useProductStore
