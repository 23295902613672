import type { BaseItem } from '~/models/Content/BaseItem'
import type { RelationsField } from '~/models/Content/BaseField'

export enum ImageCollectionMode {
  Timeline = 'timeline_horizontal',
  Carousel = 'image_carousel',
  Dialog = 'dialog_dialog',
  Chat = 'dialog_chat',
  DancingImages = 'dancing_images',
}

export interface ContentImageCollection extends BaseItem {
  images?: RelationsField
  mode: ImageCollectionMode
}
