import type { SubjectCode } from '~/models/Subject'
import type { GradeCode } from '~/models/Grade'
import type { EAN } from '~/models/Ean'
import type { ContentVideo } from '~/models/Content/ContentVideo'
import type { MetadataField } from '~/models/Content/BaseField'

export enum ProductVariation {
  Standard = 'standard',
  Addon = 'addon',
}

export enum LicenseModel {
  Open = 'OPEN',
  School = 'SCHOOL',
  Individual = 'INDIVIDUAL',
  Private = 'PRIVATE',
  Trial = 'TRIAL_SCHOOL'
}

export interface RelatedLocations {
  flexibleContentLocationId?: number
  learningPathsLocationId?: number
  teacherContentLocationId?: number
  interdisciplinaryContentLocationId?: number
  colophonLocationId?: number
  upcomingContentLocationId?: number
}

export interface AuniversMetadata {
  design?: string
  disabled?: boolean
  externalUrl?: Nullable<string>
  inDevelopment?: boolean
  locationId: number
  contentId: number
  name: string
  promoted?: boolean
  redirectLocation?: Nullable<string>
  remoteId: string
  thumbnailAlt?: Nullable<string>
  thumbnailColor?: Nullable<string>
  thumbnailUrl?: Nullable<string>
  relatedLocations: RelatedLocations
  productVariation?: ProductVariation
  urlAlias?: Nullable<string>
  pathString: Nullable<string>
  webshopUrl?: Nullable<string>
}

export interface Product {
  name: string
  ean: EAN
  grades: GradeCode[]
  subjects: SubjectCode[]
  site: Site
  productType: ProductType
  licenseModel: LicenseModel
  aunivers: AuniversMetadata
  campaignVideo?: MetadataField
}

export type Site =
  'AUNIVERS' |
  'LOKUS' |
  'UNIBOK' |
  'GAN' |
  'BRETTBOKA'|
  'UNSPECIFIED' |
  'KUNNE'

export type ProductType =
  'TEACHER' |
  'TEACHER_GUIDE' |
  'STUDENT' |
  'COMMON'

export type Program =
  'BA' |
  'DH' |
  'EL' |
  'HS' |
  'ID' |
  'MK' |
  'MD' |
  'NA' |
  'RM' |
  'SS' |
  'ST' |
  'TP' |
  'SU' |
  'YU' |
  'PB'

export type SupportProductName = 'bokstøtte' | 'lydstøtte'

