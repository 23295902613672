<script setup lang="ts">
import type { Media } from '~/models/Content/Media'
import { computed, ref } from 'vue'
import { KsIcon, KsSkeleton } from '@aschehoug/kloss'
import { ContentType } from '~/models/Content/ContentType'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import { useMedia } from '~/composables/useMedia'

const props = withDefaults(defineProps<{
  content: number | Media
  alt?: string
  autoplay?: boolean
  /** Must be a CSS length unit (That excludes unites like %). This is important to load the correct sized image */
  width?: string
  /** Any valid CSS height */
  height?: string
}>(), {
  alt: '',
  autoplay: !matchMedia('(prefers-reduced-motion)').matches,
  width: '100vw',
  height: 'auto',
})

const { buildImageUrlByField, buildSvgUrlByField } = useUrlBuilder()
const query = typeof props.content === 'number'
  ? useMedia(computed(() => [props.content as number]))
  : null

const media = computed(() => typeof props.content === 'number' ? query?.data.value[0] : props.content)

const srcset = computed(() => {
  if (media.value?.contentTypeIdentifier !== 'image') return ''

  const { contentId } = media.value

  return [
    `${buildImageUrlByField(contentId, 'image', 'small')} 300w`,
    `${buildImageUrlByField(contentId, 'image', 'article_780')} 780w`,
    `${buildImageUrlByField(contentId, 'image', 'article_1024')} 1024w`,
    `${buildImageUrlByField(contentId, 'image', 'banner_large')} 2000w`,
  ].join(', ')
})

const lottie = ref()
const lottieLink = computed(() => media.value && `${import.meta.env.VITE_AUNIVERS_SITEURL}/lottie/${media.value.contentId}/file.json`)
const lottiePlaying = ref(props.autoplay)
const imageIsLoaded = ref(false)

function onClick({ target }: PointerEvent | MouseEvent) {
  if (target instanceof HTMLVideoElement) {
    target.paused ? target.play() : target.pause()
  }

  if (lottie.value) {
    lottiePlaying.value ? lottie.value.pause() : lottie.value.play()
    lottiePlaying.value = !lottiePlaying.value
  }
}
</script>

<template>
  <img
    v-if="media?.contentTypeIdentifier === ContentType.Image && ('image' in media)"
    v-show="imageIsLoaded"
    :src="buildImageUrlByField(media.contentId, 'image', 'small')"
    :srcset
    :sizes="`(min-width: 768px) ${width}, 100vw`"
    :alt="alt || media.image.alternativeText || ''"
    class="image-size"
    @load="imageIsLoaded = true"
  >

  <img
    v-else-if="media?.contentTypeIdentifier === ContentType.Svg && ('svg' in media)"
    :src="buildSvgUrlByField(media.contentId)"
    class="image-size"
    :alt="alt || media.alternativeText || ''"
  >

  <template v-else-if="media?.contentTypeIdentifier === ContentType.Lottie">
    <LottieAnimation
      ref="lottie"
      :aria-labelledby="`lottie-${media.contentId}`"
      :animation-link="lottieLink"
      :auto-play="autoplay"
      v-bind="$attrs"
      role="img"
      @click="onClick"
    />

    <p
      :id="`lottie-${media.contentId}`"
      class="hidden"
      v-text="lottie?.alternativeText"
    />
  </template>

  <video
    v-else-if="media && 'metadata' in media && media.metadata"
    :src="media.metadata.elementURI"
    :autoplay
    muted
    loop
    class="image-size"
    @click="onClick"
  />

  <KsSkeleton
    v-else-if="query?.isFetching"
    :class="{ 'aspect-video': height === 'auto' }"
    :width
    :height
  />

  <KsIcon
    v-else
    icon="xmark"
    :aria-label="$t('error')"
  />
</template>

<style scoped>
  .image-size {
    width: v-bind('props.width');
    height: v-bind('props.height');
  }
</style>
