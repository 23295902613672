<script setup lang="ts">
import { watch } from 'vue'
import { KsIcon, KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { ContentType } from '~/models/Content/ContentType'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import { useFlashcardQuery } from '~/composables/useFlashcardsQuery'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import Image from '~/components/media/Image.vue'
import AudioPlayer from '~/components/media/AudioPlayer.vue'
import FlashCard from '~/components/flashcards/FlashCard.vue'

const { locationId } = defineProps<{
  locationId: number
}>()
const { buildAudioUrl } = useUrlBuilder()
const { location, media, audio, isLoading } = useFlashcardQuery(locationId)
</script>

<template>
  <KsSkeletonWrapper v-if="isLoading">
    <KsSkeleton
      width="100%"
      height="100px"
      style="height: auto !important;"
      class="aspect-[3/2]"
    />
  </KsSkeletonWrapper>
  <FlashCard
    v-else-if="location.data.value"
    class="not-prose relative grid aspect-[3/2] place-items-center overflow-hidden rounded-md bg-[--theme-20,white] text-4xl text-[--theme-60,black]"
  >
    <template #front>
      <Image
        v-if="media?.data.value"
        :content="media.data.value"
        class="max-h-full min-h-0 !w-full min-w-0 place-self-stretch"
        :class="media.data.value.contentTypeIdentifier === ContentType.Svg ? 'object-contain' : 'object-cover'"
      />
      <div
        class="max-w-[40ch] p-24"
        :class="{ 'sr-only': media?.data.value }"
      >
        <RichTextRenderer :text="location.data.value.bodyFront ?? 'no text'" />
      </div>
    </template>

    <template
      v-if="location.data.value.bodyBack"
      #back
    >
      <RichTextRenderer :text="location.data.value.bodyBack" />
    </template>

    <template
      v-if="audio.data.value"
      #overlay
    >
      <AudioPlayer
        :media-id="audio.data.value.metadata?.contentItemId"
        :src="{
          src: buildAudioUrl(audio.data.value),
          type: audio.data.value.metadata?.mimeType ?? audio.data.value.file?.mimeType
        }"
        class="-translate-x-1/2 !rounded-full"
      >
        <template #controls>
          <media-play-button
            tabindex="-1"
            class="group grid size-32 place-content-center rounded-full bg-[--theme-5] text-3xl text-[--theme-60] shadow-lg"
          >
            <KsIcon
              icon="play"
              variant="solid"
              class="hidden translate-x-[10%] group-data-[paused]:block"
            />
            <KsIcon
              icon="pause"
              variant="solid"
              class="group-data-[paused]:hidden"
            />
          </media-play-button>
        </template>
      </audioplayer>
    </template>
  </FlashCard>
</template>
