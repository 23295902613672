<script setup lang="ts">
import type { ContentProductPackage } from '~/models/Content/ContentProductPackage'
import { RouterLink } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { KsButton } from '@aschehoug/kloss'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useGradeString from '~/composables/useGradeString'
import AssignTaskDialog from '~/components/products/exams/assignment/AssignTaskDialog.vue'

const { resource } = defineProps<{ resource: ContentProductPackage }>()

const { buildResourceUrl } = useUrlBuilder()
const { createGradeString } = useGradeString()
const { t } = useI18n()

const path = computed(() => buildResourceUrl(resource))

const subtitle = computed(() => {
  const grades = createGradeString(resource.grades)

  return [grades, t('termExam.title')].filter(Boolean).join(' | ')
})
</script>

<template>
  <article class="group relative size-full rounded-md border-2 border-blue-5 bg-white p-3 transition">
    <div class="mb-2 space-y-1">
      <p
        class="text-xs font-medium uppercase tracking-wider text-gray-40"
        v-text="subtitle"
      />
      <h3
        class="text-lg font-bold text-gray-50"
        v-text="resource.title"
      />
    </div>
    <div class="pointer-events-none aspect-[5/2] overflow-hidden rounded bg-blue-10">
      <img
        v-if="resource.thumbnail"
        class="size-full rounded-md object-cover"
        :src="resource.thumbnail"
        :alt="resource.thumbnailAlt || ''"
      >
    </div>
    <div class="mt-3 space-y-2">
      <AssignTaskDialog :resource />
      <KsButton
        :is="RouterLink"
        :to="path"
        variant="secondary"
        stretch
        center
        icon-left="eye"
        class="!text-sm"
      >
        {{ t('assign.seeExam') }}
      </KsButton>
    </div>
  </article>
</template>
