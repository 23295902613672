<script lang="ts" setup>
import type { SubjectCode, SubjectViewProps } from '~/models/Subject'
import { computed, onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { setTitle } from '~/utils/dom'
import useProductStore from '~/stores/product'
import { useAppColor } from '~/composables/useAppColor'
import SubjectComponentView from '~/components/subject/SubjectComponentView.vue'

const props = defineProps<{ subjectCode: SubjectCode }>()

const { t } = useI18n()
const { hasLoaded } = storeToRefs(useProductStore())
const { clear: clearAppColor } = useAppColor()

setTitle(t(`metadata.subjects.${props.subjectCode}`))

onBeforeUnmount(() => {
  clearAppColor()
})

const componentProps = computed((): SubjectViewProps => ({
  subjectCode: props.subjectCode,
  isUpcoming: true,
}))
</script>

<template>
  <SubjectComponentView
    v-if="hasLoaded"
    v-bind="componentProps"
  />
</template>
