<script setup lang="ts">
import type { ContentProduct } from '~/models/Content/ContentProduct'
import { computed } from 'vue'
import useProductHelper from '~/composables/useProductHelper'
import NotFound from '~/components/ResourceEngine/Components/NotFound.vue'
import LibrarySearchView from '~/components/products/library/LibrarySearchView.vue'
import ExamsSearchView from '~/components/products/exams/ExamsSearchView.vue'

const { product } = defineProps<{ product: ContentProduct }>()

const { isLibrary, isExams } = useProductHelper()

const componentMap = computed(() => {
  if (isLibrary(product)) return LibrarySearchView
  if (isExams(product)) return ExamsSearchView
  return null
})
</script>

<template>
  <component
    :is="componentMap"
    v-if="componentMap"
    :product="product"
  />
  <NotFound v-else />
</template>
