import type { User } from 'oidc-client-ts'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import Cookies from 'js-cookie'
import { amCookieConfig, amCookieNames, cookieConfig, cookieName, manager } from '~/api/client/oidcClient'

export default () => {
  const router = useRouter()
  const isLoading = ref(false)
  const hasError = ref(false)
  const redirectUri = ref('/')
  const redirectStorageKey = 'redirect-to-path'

  const buildLocalUrl = computed(() => router.resolve({ name: 'oidc', query: { redirectUri: redirectUri.value } }).href)

  const buildFeideUrl = computed(() => `${import.meta.env.VITE_AM_URL}/am/XUI/?realm=/&goto=${import.meta.env.VITE_OIDC_URL}&gotoOnFail=${import.meta.env.VITE_AM_LOGIN_URL}/feil&service=skoleFeide${decodeURIComponent('#')}login`)

  const buildVippsUrl = computed(() => `${import.meta.env.VITE_AM_URL}/am/XUI/?realm=/&goto=${import.meta.env.VITE_OIDC_URL}&gotoOnFail=${import.meta.env.VITE_AM_LOGIN_URL}/feil&service=vipps_kunne_oidc_tree${decodeURIComponent('#')}login`)

  const buildLogoutUrl = computed(() => `${import.meta.env.VITE_AUNIVERS_SITEURL}/oauth/logout?redirect_uri=https://${import.meta.env.VITE_HOST}`)

  const saveRedirectUri = (redirectTo: string) => localStorage.setItem(redirectStorageKey, redirectTo)

  const getRedirectUri = () => {
    let path = localStorage.getItem(redirectStorageKey) || '/'
    path = path.startsWith('/') ? path : '/'
    return ['/oidc', '/logg-inn', '/callback', '/vipps'].some((r) => path.startsWith(r)) ? '/' : path
  }

  const removeRedirectUri = () => localStorage.removeItem(redirectStorageKey)

  const loginWithCookieCleaning = (returnUrl: string) =>{
    const queryString = Object.entries(amCookieNames)
      .map(([cookieName, domain]) => `cookies[${encodeURIComponent(cookieName)}]=${encodeURIComponent(domain)}`)
      .join('&')

    return `${amCookieConfig.url}?${queryString}&returnUrl=${encodeURIComponent(returnUrl)}`
  }

  const loginRedirect = async () => {
    isLoading.value = true
    try {
      await manager.signinRedirect()
    } catch (error) {
      hasError.value = true
      throw error
    } finally {
      isLoading.value = false
    }
  }

  const feideRedirect = () => window.location.href = loginWithCookieCleaning(buildFeideUrl.value)

  const vippsRedirect = () => window.location.href = loginWithCookieCleaning(buildVippsUrl.value)

  const logoutRedirect = () => {
    Cookies.remove(cookieName, cookieConfig)
    window.location.href = buildLogoutUrl.value
  }

  const createCookie = (user: User) => {
    const expires = new Date(new Date().getTime() + Number(user.expires_in) * 1000)
    Cookies.set(cookieName, user.access_token, { ...{ expires }, ...cookieConfig })
  }

  return {
    redirectUri,
    buildFeideUrl,
    buildLocalUrl,
    buildVippsUrl,
    buildLogoutUrl,
    isLoading,
    hasError,
    manager,
    saveRedirectUri,
    getRedirectUri,
    removeRedirectUri,
    loginRedirect,
    feideRedirect,
    logoutRedirect,
    vippsRedirect,
    createCookie,
  }
}
