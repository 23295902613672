<script setup lang="ts">
import type { GradeCode } from '~/models/Grade'
import { computed, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton, KsInput, KsPopover } from '@aschehoug/kloss'
import { findGradesByGradeType } from '~/utils/gradeSorter'
import { useAuthStore } from '~/stores/auth'
import { GradeType } from '~/models/GradeType'

const ksToast = inject<Toast>('ksToast')

const { t } = useI18n()
const authStore = useAuthStore()
const { isReadOnlyGrade, setGradeType } = authStore
const { gradeCodes } = storeToRefs(authStore)

const grades = computed(() => findGradesByGradeType(GradeType.UpperSecondary))

const safeToggleGrade = (event: MouseEvent, grade: GradeCode) => {

  let gradesCopy = [...gradeCodes.value]

  const index = gradesCopy.findIndex((code) => code === grade)

  if (index > -1) {
    gradesCopy.splice(index, 1)
  } else {
    gradesCopy.unshift(grade)
  }

  if (gradesCopy.length === 0) {
    event.preventDefault()
    event.stopPropagation()
    ksToast?.info(t('settings.upperSecondaryWeirdness.cantRemoveLastSelected'))
    return
  }

  setGradeType(GradeType.UpperSecondary, gradesCopy)
}

</script>
<template>
  <KsPopover :title="t('filters.chooseGrade')">
    <template #toggle>
      <KsButton
        icon-left="chevron-down"
        shape="square"
      />
    </template>

    <template #content>
      <form class="flex flex-col">
        <KsButton
          v-for="grade in grades"
          :key="grade"
          type="label"
        >
          <KsInput
            type="checkbox"
            :disabled="isReadOnlyGrade(grade)"
            :checked="gradeCodes.includes(grade)"
            @click="safeToggleGrade($event, grade)"
          />
          {{ t(`metadata.grades.${grade}`) }}
        </KsButton>
      </form>
    </template>
  </KsPopover>
</template>
