<script setup lang="ts">
import type { ContentArticleTabs } from '~/models/Content/ContentArticleTabs'
import { ref, useId, useTemplateRef } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { ArticleContentTypeCriterion, type ArticleContentTypes } from '~/models/Content/ContentArticle'
import useContentApi from '~/api/contentApi'

const { item } = defineProps<{
  item: ContentArticleTabs
}>()


const id = useId()
const tabs = useTemplateRef('tabs')
const { findContents } = useContentApi()
const { data } = useQuery({
  staleTime: Infinity,
  queryKey: ['app-article-content', item.locationId],
  async queryFn() {
    const contentIds = item.items.flatMap(({ content }) => content.destinationContentIds)
    if (!contentIds.length) return []
    const articleContent = await findContents<ArticleContentTypes>({
      contentIdCriterion: contentIds,
      contentTypeCriterion: ArticleContentTypeCriterion,
      mainLocationCriterion: true,
    })
    return articleContent.sort((a, b) => contentIds.indexOf(a.contentId) - contentIds.indexOf(b.contentId))
  },
})

const activeIndex = ref(0)

function onKeydown(event: KeyboardEvent) {
  const index = activeIndex.value
  const items = item.items

  event.preventDefault()

  switch (event.key) {
    case 'ArrowRight':
      activeIndex.value = index < items.length - 1 ? index + 1 : 0
      break

    case 'ArrowLeft':
      activeIndex.value = index < 1 ? items.length - 1 : index - 1
      break

    case 'Home':
      activeIndex.value = 0
      break

    case 'End':
      activeIndex.value = items.length - 1
      break
  }

  tabs.value?.at(activeIndex.value)?.focus()
}
</script>

<template>
  <div class="col-[fill] grid grid-cols-subgrid gap-[inherit]">
    <nav class="col-[extext] grid grid-cols-subgrid border-b">
      <div
        class="col-[text] flex"
        role="tablist"
      >
        <button
          v-for="(tab, index) in item.items"
          :id="`tab-${id}-${index}`"
          :key="tab.name"
          ref="tabs"
          class="-mb-px border border-transparent border-b-[--color-darker] bg-[--color-lighter] px-6 py-3 hover:bg-[--color-light] focus-visible:z-10 focus-visible:ring"
          :class="{
            '!border-[--color-darker] !border-b-transparent': activeIndex === index
          }"
          role="tab"
          :aria-selected="activeIndex === index"
          :aria-controls="`panel-${id}-${index}`"
          :tabindex="activeIndex === index ? 0 : -1"
          @click="activeIndex = index"
          @keydown.right.left.home.end="onKeydown"
        >
          {{ tab.name }}
        </button>
      </div>
    </nav>

    <div
      v-for="(tab, index) in item.items"
      v-show="index === activeIndex"
      :id="`panel-${id}-${index}`"
      :key="tab.name"
      role="tabpanel"
      class="contents"
      :aria-labelledby="`tab-${id}-${index}`"
    >
      <slot :items="data?.filter(({ contentId }) => tab.content.destinationContentIds.includes(contentId)) ?? []" />
    </div>
  </div>
</template>
