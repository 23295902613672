type CacheResponse = Response | { data: any; status: number; statusText: string; headers: HeadersInit | {} }

export function toApiCache(url: string | URL, response: CacheResponse) {
  return caches
    .open('api-cache')
    .then((cache) => {
      if (response.status !== 200) {
        return
      }

      return cache.put(
        url,
        response instanceof Response
          ? response
          : new Response(JSON.stringify(response.data), { ...response })
      )
    })
}

export function fromApiCache(url: string | URL, options?: CacheQueryOptions) {
  return caches
    .open('api-cache')
    .then((cache) => cache.match(url, options))
    .then((res) => res?.json())
}

export function clearApiCache() {
  return caches.delete('api-cache')
}
