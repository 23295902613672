import type { AxiosInstance } from 'axios'
import axios from 'axios'
import { toApiCache } from '~/utils/cache'
import { getAccessToken } from '~/api/client/oidcClient'

export const externalClient: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_CORE_EXTERNAL_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  transformRequest: [(data, headers) => {
    headers['Authorization'] = `Bearer ${getAccessToken()}`
    return JSON.stringify(data)
  }],
})

externalClient.interceptors.response.use((response) => {
  if (response.config.method === 'get') {
    toApiCache(`${response.config.baseURL}${response.config.url}`, response)
  }
  return response
})
