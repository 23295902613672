<script setup lang="ts">
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed, ref } from 'vue'
import { KsIcon } from '@aschehoug/kloss'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useColors from '~/composables/colors'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import LinkOrAnchor from '~/components/utils/LinkOrAnchor.vue'

const props = withDefaults(defineProps<{
  article: BaseItem
  loading?: 'eager' | 'lazy'
}>(), {
  loading: 'lazy',
})

const { buildResourceUrl } = useUrlBuilder()

const elementRef = ref()

const url = computed(() => buildResourceUrl(props.article))

const { backgroundStyle } = useColors({
  identifier: props.article.locationId,
  elementRef,
})
</script>
<template>
  <article class="relative h-full min-h-[190px] min-w-[215px] rounded-2xl bg-white text-base shadow-md motion-safe:animate-fade-in-down">
    <div class="flex flex-col">
      <div
        ref="elementRef"
        class="relative aspect-[3/2] h-unset max-w-full overflow-hidden rounded-t-2xl"
      >
        <img
          v-if="article.thumbnail"
          :src="article.thumbnail"
          class="block size-full overflow-hidden bg-gray-10 object-cover object-center"
          :loading
          :alt="article.thumbnailAlt"
          aria-hidden="true"
        >
        <div
          v-else
          class="block size-full overflow-hidden object-cover object-center"
          :style="backgroundStyle"
        />
      </div>
      <div class="p-4">
        <span class="group mb-2 block overflow-hidden text-lg font-semibold leading-tight text-blue-50">
          <LinkOrAnchor
            :link="url"
            new-tab
            is-anchor
            class="outline-0 before:absolute before:inset-0 before:z-10 before:rounded-2xl before:transition before:hover:shadow-lg before:focus-visible:ring"
          >
            {{ article.title }}
          </LinkOrAnchor>
          <KsIcon
            icon="arrow-right"
            :scale=".8"
            class="ml-1 inline-block transition-all group-focus-within:ml-2 group-hover:ml-2"
          />
        </span>
        <RichTextRenderer
          v-if="article.intro"
          class="text-base text-gray-40"
          :text="article.intro"
        />
      </div>
    </div>
  </article>
</template>
