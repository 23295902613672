<script setup lang="ts">
import type { ArticleSize } from '~/models/Content/ContentArticle'
import type { BaseItem } from '~/models/Content/BaseItem'
import { ImageCollectionMode } from '~/models/Content/ContentImageCollection'
import { useContentHelper } from '~/composables/useContentHelper'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import FullscreenContent from '~/components/utils/FullscreenContent.vue'
import CopyrightButton from '~/components/utils/CopyrightButton.vue'
import Image from '~/components/media/Image.vue'
import FlytTask from '~/components/flyt/FlytTask.vue'
import FlashCardDeck from '~/components/flashcards/FlashCardDeck.vue'
import ModelText from '~/components/article/ModelText.vue'
import ImageCollection from '~/components/article/ImageCollection.vue'
import ArticleTabRenderer from '~/components/article/ArticleTabRenderer.vue'
import ArticleContent from '~/components/article/ArticleContent.vue'

const {
  locationId,
  sections,
  size
} = defineProps<{
  locationId: number
  sections: BaseItem[]
  size: ArticleSize
}>()

const { isArticleTabs, isModelText, isArticleContent, isImageCollection, isFlytTask, isFlashcards, isMedia } = useContentHelper()
</script>

<template>
  <template
    v-for="item in sections"
    :key="item.locationId"
  >
    <ArticleContent
      v-if="isArticleContent(item)"
      :article-content="item"
    />

    <ArticleTabRenderer
      v-else-if="isArticleTabs(item)"
      :item="item"
    >
      <template #default="{ items }">
        <ArticleContentRenderer
          :location-id
          :sections="items"
          :size
        />
      </template>
    </ArticleTabRenderer>

    <ModelText
      v-else-if="isModelText(item)"
      class="col-[media]"
      :content="item"
      :size="size"
    />

    <section
      v-else-if="isImageCollection(item)"
      :class="{
        '!col-[1/-1]': item.mode === ImageCollectionMode.Carousel,
        'col-[media]': Number(item.images?.destinationContentIds?.length) > 2,
        'col-[extext]': Number(item.images?.destinationContentIds?.length) <= 2,
      }"
    >
      <ImageCollection :content="item" />
    </section>

    <section
      v-else-if="isFlytTask(item)"
      class="col-[extext] mx-[calc(var(--col-gap)*-1)] rounded-xl bg-[#ffead1ff] p-[--col-gap] font-inter"
    >
      <FlytTask
        :task-id="item.taskId"
        :progress-id="locationId"
        :mode="item.mode"
        :assignment="item.assignment"
      />
    </section>

    <FlashCardDeck
      v-else-if="isFlashcards(item)"
      class="col-[1/-1] grid bg-[--color-dark] p-[--col-width] text-[--color-pop]"
      :location-id="item.locationId"
    />

    <FullscreenContent
      v-else-if="isMedia(item)"
      class="col-[text]"
    >
      <template #default="{ isFullscreen }">
        <Image
          :content="item"
          class="col-start-1 row-start-1"
          :class="isFullscreen ? 'p-4 place-self-center object-scale-down' : 'object-cover'"
        />

        <div
          v-if="item && 'caption' in item && item.caption"
          class="bg-white p-4"
        >
          <RichTextRenderer :text="item.caption" />
        </div>

        <CopyrightButton
          v-if="item && 'copyright' in item && item.copyright"
          class="col-start-1 row-start-1 place-self-end"
          :copyright="item.copyright"
          style="--ks-roundness: 0"
          color-theme
        />
      </template>
    </FullscreenContent>
  </template>
</template>
