<script setup lang="ts">
import type { ContentFlytTask } from '~/models/Content/ContentFlytTask'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useQuery } from '@tanstack/vue-query'
import { setTitle } from '~/utils/dom'
import { colorMap } from '~/utils/colors'
import useLicenseControlStore from '~/stores/licenseControl'
import useAuthStore from '~/stores/auth'
import { ContentType } from '~/models/Content/ContentType'
import { ColorName } from '~/models/Content/Color'
import { useAppColor } from '~/composables/useAppColor'
import useContentApi from '~/api/contentApi'
import TopBar from '~/components/utils/TopBar.vue'
import NotFound from '~/components/ResourceEngine/Components/NotFound.vue'
import FlytTask from '~/components/flyt/FlytTask.vue'
import ArticleTeacherGuide from '~/components/article/ArticleTeacherGuide.vue'
import ArticleStudentGuide from '~/components/article/ArticleStudentGuide.vue'
import ArticleNavigation from '~/components/article/ArticleNavigation.vue'

const props = defineProps<{ locationId: number }>()

const { t } = useI18n()
const { checkItemLicense } = useLicenseControlStore()
const { findContents } = useContentApi()
const { isAuthenticated, isTeacher } = storeToRefs(useAuthStore())

const isTeacherGuideOpen = ref(false)
const isStudentGuideOpen = ref(false)

const hasTeacherGuide = computed(() => isTeacher.value && Number(content?.value?.teacherGuide?.destinationContentId) > 0)
const hasStudentGuide = computed(() => Number(content?.value?.studentGuide?.length) > 0)
const hasGuides = computed(() => hasTeacherGuide.value || hasStudentGuide.value)

const {
  data: content,
  isError,
} = useQuery({
  staleTime: Infinity,
  queryKey: computed(() => ['flyt-task-view', props.locationId]),
  queryFn: () =>
    findContents<ContentFlytTask>({
      locationIdCriterion: [props.locationId],
      contentTypeCriterion: [ContentType.FlytTask],
    }, 1)
      .then(([content]) => content ?? null)
})

useAppColor(ColorName.Orange10)

watch(content, () => {
  if(!content.value) return
  setTitle(content.value?.title)
  checkItemLicense(content.value)
}, { immediate: true })
</script>

<template>
  <NotFound v-if="isError" />
  <template v-else>
    <header v-if="content">
      <h1
        class="sr-only"
        v-text="content.shortTitle || content.title"
      />
      <TopBar :item="content" />
    </header>
    <div
      v-if="hasGuides"
      class="mx-0 mb-2 mt-20 flex flex-row-reverse gap-2 font-inter max-md:px-4 sm:mx-4 lg:mx-auto lg:max-w-[var(--flyt-ideal-content-width-wide)]"
    >
      <button
        v-if="hasTeacherGuide"
        class="rounded-3xl border border-coral-60 bg-orange-20 px-6 py-2 font-medium text-coral-60 transition hover:bg-orange-10 focus-visible:ring"
        @click="isTeacherGuideOpen = true"
        v-text="t('article.toTeacher')"
      />
      <button
        v-if="hasStudentGuide"
        class="rounded-3xl border border-coral-60 bg-orange-20 px-6 py-2 font-medium text-coral-60 transition hover:bg-orange-10 focus-visible:ring"
        @click="isStudentGuideOpen = true"
        v-text="t('article.toStudent')"
      />
    </div>
    <FlytTask
      v-if="content && isAuthenticated"
      :task-id="content.taskId"
      :progress-id="locationId"
      :mode="content.mode"
      :assignment="content.assignment"
      class="mx-1 mb-12 rounded-xl bg-[#ffead1ff] p-6 font-inter max-md:px-4 sm:mx-4 lg:mx-auto lg:max-w-[var(--flyt-ideal-content-width-wide)]"
      :class="{ 'mt-20': !hasGuides }"
    />
    <ArticleTeacherGuide
      v-if="hasTeacherGuide"
      :is-open="isTeacherGuideOpen"
      :content-id="content!.teacherGuide!.destinationContentId"
      @close-guide="isTeacherGuideOpen = false"
    />
    <ArticleStudentGuide
      v-if="hasStudentGuide"
      :is-open="isStudentGuideOpen"
      :content="content!.studentGuide"
      @close-guide="isStudentGuideOpen = false"
    />
    <ArticleNavigation
      v-if="content"
      :item="content"
      :colors="{
        text: colorMap.get(ColorName.Black),
        background: colorMap.get(ColorName.Orange10),
        border: colorMap.get(ColorName.Black),
      }"
    />
  </template>
</template>
