<script setup lang="ts">
import type { Component } from 'vue'
import type { ArticleContent } from '~/models/Content/ArticleContent'
import { computed } from 'vue'
import { ArticleContentVariation } from '~/models/Content/ArticleContentVariation'
import ArticleTextBoxSolid from '~/components/article/TextBoxSolid.vue'
import ArticleTextBoxDashed from '~/components/article/TextBoxDashed.vue'
import ArticleText from '~/components/article/Text.vue'
import ReflectionBox from '~/components/article/ReflectionBox.vue'
import BigTextAndRoundImage from '~/components/article/BigTextAndRoundImage.vue'

const { articleContent } = defineProps<{ articleContent: ArticleContent }>()

const componentMap: Record<string, Component> = {
  [ArticleContentVariation.Text]: ArticleText,
  [ArticleContentVariation.TextAndRoundImage]: BigTextAndRoundImage,
  [ArticleContentVariation.TextOnImage]: ArticleText,
  [ArticleContentVariation.TextBoxDashed]: ArticleTextBoxDashed,
  [ArticleContentVariation.TextBoxSolid]: ArticleTextBoxSolid,
  [ArticleContentVariation.ReflectionBox]: ReflectionBox,
}

const renderedComponent = computed(() => articleContent.variation.identifier
  ? componentMap[articleContent.variation.identifier]
  : ArticleText
)
</script>

<template>
  <component
    :is="renderedComponent"
    :article-content
  />
</template>
