<script setup lang="ts">
import type { SubjectCode } from '~/models/Subject'
import type { GradeCode } from '~/models/Grade'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton, KsCallout } from '@aschehoug/kloss'
import useFilterStore from '~/stores/filter'
import GradeFilter from '~/components/ResourceFinder/GradeFilter.vue'

const { subjectRoute = 'subject', subjectCode, gradeCodes } = defineProps<{
  subjectRoute?: string
  subjectCode: SubjectCode
  gradeCodes: GradeCode[]
}>()

const { t } = useI18n()
const router = useRouter()
const { selectedGrade } = storeToRefs(useFilterStore())
</script>
<template>
  <div
    v-if="selectedGrade"
    class="mx-auto mt-24 flex max-w-screen-au flex-col gap-6 px-4 sm:px-8"
  >
    <KsCallout
      variant="info"
      :title="t('header.notFound', { grade: t(`metadata.grades.${selectedGrade}`).toLowerCase() })"
    >
      <GradeFilter
        v-if="gradeCodes.length > 0"
        :grade-codes="gradeCodes"
        class="mr-4"
      />
      <KsButton
        variant="secondary"
        class="m-4 ml-0"
        icon-left="arrow-left"
        @click.prevent="() => router.push({ name: subjectRoute, params: { subject: subjectCode.toLowerCase() }})"
      >
        {{ t('backTo', { name: t(`metadata.subjects.${subjectCode}`).toLowerCase() }) }}
      </KsButton>
    </KsCallout>
  </div>
</template>
