<script setup lang="ts">
import { RouterLink } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton, KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import useProductStore from '~/stores/product'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useText from '~/composables/useText'
import useSubjectButton from '~/composables/useSubjectButton'
import { useStaticProduct } from '~/composables/useStaticProduct'
import useProductHelper from '~/composables/useProductHelper'
import SupportProductModal from '~/components/home/SupportProductModal.vue'

const { t } = useI18n()
const { resolveBaseUrl } = useUrlBuilder()
const { capitalize } = useText()
const { getStyles } = useSubjectButton()
const { ALWAYS_SHOWN_PRODUCTS } = useStaticProduct()
const { isUpperPrimaryProduct } = useProductHelper()

const productStore = useProductStore()
const { hasProduct } = productStore
const {
  filteredSubjectCodes,
  selectedSupportProductName,
  filteredAddonProducts,
  filteredSupportProductNames,
  filteredSupportProducts,
  isLoading,
} = storeToRefs(productStore)

</script>

<template>
  <ul
    v-if="filteredSubjectCodes.length || filteredAddonProducts.length || filteredSupportProductNames.length "
    class="flex flex-col gap-2 sm:flex-row sm:flex-wrap"
  >
    <li
      v-for="(subject, index) of filteredSubjectCodes"
      :key="subject"
    >
      <KsButton
        :is="RouterLink"
        :to="{ name: 'subject', params: { subject: subject.toLowerCase() } }"
        variant="primary"
        stretch
        center
        class="subject-button !text-xl !font-bold"
        :style="getStyles(index)"
      >
        {{ t(`metadata.subjects.${subject}`) }}
      </KsButton>
    </li>
    <li
      v-for="(addon, index) in filteredAddonProducts"
      :key="addon.ean"
    >
      <KsButton
        :is="RouterLink"
        :to="{ path: resolveBaseUrl(addon) }"
        variant="primary"
        stretch
        center
        class="subject-button !text-xl !font-bold"
        :style="getStyles(filteredSubjectCodes.length + index)"
      >
        {{ addon.aunivers.name }}
      </KsButton>
    </li>
    <li
      v-for="(product, index) in ALWAYS_SHOWN_PRODUCTS.filter(isUpperPrimaryProduct)"
      :key="product.ean"
    >
      <KsButton
        :is="RouterLink"
        :to="{ path: resolveBaseUrl(product) }"
        variant="primary"
        stretch
        center
        class="subject-button !text-xl !font-bold"
        :icon-left="!hasProduct(product.ean) ? 'lock' : ''"
        :style="getStyles(filteredSubjectCodes.length + filteredAddonProducts.length + index)"
      >
        {{ product.aunivers.name }}
      </KsButton>
    </li>
    <li
      v-for="(name, index) of filteredSupportProductNames"
      :key="name"
    >
      <KsButton
        variant="primary"
        stretch
        center
        class="subject-button relative !text-xl !font-bold after:absolute after:bottom-0 after:right-0 after:box-content after:size-[--flip-size] after:rounded-tl-[inherit] after:border-l-2 after:border-t-2 after:bg-[--ks-secondary]"
        :style="{
          ...getStyles(filteredSubjectCodes.length + filteredAddonProducts.length + filteredSupportProductNames.length + index),
          '--flip-size': '1.1rem',
          'clip-path': 'polygon(0 0, 100% 0, 100% calc(100% - var(--flip-size)), calc(100% - var(--flip-size)) 100%, 0 100%)',
        }"
        @click="selectedSupportProductName = name"
      >
        {{ capitalize(name) }}
      </KsButton>

      <SupportProductModal
        :name="name"
        :products="filteredSupportProducts"
        :subjects="filteredSubjectCodes"
        @close="selectedSupportProductName = undefined"
      />
    </li>
  </ul>

  <KsSkeletonWrapper
    v-else-if="isLoading"
    class="flex flex-col gap-2 sm:flex-row sm:flex-wrap"
  >
    <KsSkeleton
      v-for="n in 9"
      :key="n"
      :width="`${n % 4 * 10 + 10}ch`"
      height="3rem"
    />
  </KsSkeletonWrapper>
</template>

<style scoped>
.subject-button {
  --ks-primary: rgb(var(--au-seagreen-60) / .8);
  padding: 1.3rem 1.1rem !important;
}
.subject-button:hover {
  --ks-primarytext: #002422!important;
}
</style>
