import type { MaybeRefOrGetter } from 'vue'
import type { Media } from '~/models/Content/Media'
import type { ContentFlashcard, ContentFlashcardDeck } from '~/models/Content/ContentFlashcards'
import type { ContentAudio } from '~/models/Content/ContentAudio'
import { computed, ref, toValue } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { firstOf } from '~/utils/queryUtils'
import arrayUtils from '~/utils/arrayUtils'
import { Subtree } from '~/models/Content/Subtree'
import { ContentType } from '~/models/Content/ContentType'
import { useMedia } from '~/composables/useMedia'
import useContentApi from '~/api/contentApi'

export function useFlashcardQuery(locationId: MaybeRefOrGetter<number>) {
  const { findContents } = useContentApi()

  const location = useQuery({
    queryKey: computed(() => ['flashcard', toValue(locationId)]),
    async queryFn() {
      const [location] = await findContents<ContentFlashcard>({
        contentTypeCriterion: [ContentType.Flashcard],
        subtreeCriterion: [Subtree.Content],
        locationIdCriterion: [toValue(locationId)],
      }, 1)

      return location
    },
  })

  const toContentId = (maybeId?: number | string) => [maybeId].filter((x) => x != null).map((n) => Number(n))
  const mediaContentId = computed(() => toContentId(location.data.value?.imageFront?.destinationContentId))
  const audioContentId = computed(() => toContentId(location.data.value?.audioFront?.destinationContentId))

  const media = firstOf(useMedia(mediaContentId))

  const audio = useQuery({
    queryKey: computed(() => ['audio', ...audioContentId.value]),
    enabled: computed(() => audioContentId.value.length > 0),
    async queryFn() {
      if (!audioContentId.value) return null

      const [audio] = await findContents<ContentAudio>({
        contentTypeCriterion: [ContentType.Audio],
        subtreeCriterion: [Subtree.Content, Subtree.Media],
        contentIdCriterion: audioContentId.value,
      }, 1)

      return audio ?? null
    },
  })

  return {
    location,
    media,
    audio,
    isLoading: computed(() => location.isLoading.value || media.isLoading.value || audio.isLoading.value)
  }
}

export function useFlashcardsQuery(locationId: number) {
  const { findContents } = useContentApi()
  const { unique } = arrayUtils()

  const fetchLocation = async () => {
    const [location] = await findContents<ContentFlashcardDeck>({
      contentTypeCriterion: [ContentType.Flashcards],
      subtreeCriterion: [Subtree.Content],
      locationIdCriterion: [locationId],
    }, 1)
    return location
  }

  const fetchCards = async (location: ContentFlashcardDeck) => await findContents<ContentFlashcard>({
    contentTypeCriterion: [ContentType.Flashcard],
    subtreeCriterion: [Subtree.Content],
    contentIdCriterion: location.cards.destinationContentIds,
    mainLocationCriterion: true,
  })

  const fetchMedia = async (cards: ContentFlashcard[]) => {
    const images = new Map<number, Media>()
    const audioFiles = new Map<number, ContentAudio>()

    const destinationContentIds = cards.reduce((acc, card) => {
      acc.push(Number(card.imageFront?.destinationContentId))
      acc.push(Number(card.audioFront?.destinationContentId))
      return acc.filter(Boolean)
    }, [] as number[]).filter(unique)

    if (destinationContentIds.length === 0) {
      return { images, audioFiles }
    }

    const medias = await findContents<Media>({
      subtreeCriterion: [Subtree.Content, Subtree.Media],
      contentIdCriterion: destinationContentIds,
      mainLocationCriterion: true,
    }, destinationContentIds.length)

    for (const card of cards) {
      const image = medias.find((media) => media.contentId === card.imageFront?.destinationContentId)
      const audio = medias.find((media) => media.contentId === card.audioFront?.destinationContentId)
      if (image) images.set(image.contentId, image)
      if (audio) audioFiles.set(audio.contentId, audio)
    }

    return { images, audioFiles }
  }

  const { data, isLoading } = useQuery({
    queryKey: ['flashcard-deck', locationId],
    enabled: !!locationId,
    staleTime: Infinity,
    queryFn: async () => {
      const location = await fetchLocation()
      if (location.cards.destinationContentIds.length === 0) {
        return { location, cards: [] }
      }
      const cards = await fetchCards(location)
      const { images, audioFiles } = await fetchMedia(cards)
      return { location, cards, images, audioFiles }
    },
  })

  return {
    data,
    isLoading,
  }
}
