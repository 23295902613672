<script setup lang="ts">
import type { Task, TaskUser } from '~/models/AssignTask'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { faEdit, faEye } from '@fortawesome/pro-regular-svg-icons'
import { KsButton, KsDialog, KsIcon, KsSpinner } from '@aschehoug/kloss'
import useAssignTaskDialog from '~/stores/assignTaskDialog'
import TeleportationTarget from '~/models/TeleportationTarget'
import { PendoFeature } from '~/models/Pendo'
import { TaskStatus } from '~/models/AssignTask'
import { useAssignTask } from '~/composables/useAssignTask'
import StudentEditList from '~/components/products/exams/assignment/StudentEditList.vue'
import DeleteTaskDialog from '~/components/products/exams/assignment/DeleteTaskDialog.vue'

const { task } = defineProps<{ task: Task }>()

const { t } = useI18n()
const { setDialogOpen, setDialogClose, isDialogOpen } = useAssignTaskDialog()
const { taskHasBeenClosed } = storeToRefs(useAssignTaskDialog())
const { closeTask: close } = useAssignTask()

const searchQuery = ref('')

const allUsersStatus = computed(() => {
  const allOpen = task.users?.every(user => user.status === TaskStatus.Open)
  const allClosed = task.users?.every(user => user.status === TaskStatus.Closed)
  return allOpen || allClosed
})

const isOpen = computed(() => task.taskId && isDialogOpen(task.taskId, 'edit'))

const openDialog = () => task.taskId && setDialogOpen(task.taskId, 'edit')
const closeDialog = () => task.taskId && setDialogClose(task.taskId, 'edit')

const closeTask = async () => {
  if (!task.taskId) return
  await close.mutateAsync(task)
  setDialogOpen(task.taskId, 'assign')
  taskHasBeenClosed.value[task.taskId] = true
}
</script>

<template>
  <KsButton
    variant="secondary"
    :icon-left="faEdit"
    size="small"
    @click="openDialog"
  >
    {{ t('assign.editAccess') }}
  </KsButton>
  <Teleport :to="TeleportationTarget.AppTop">
    <KsDialog
      :open="isOpen"
      :title="task.name"
      :size="allUsersStatus || searchQuery.length ? 'small' : 'large'"
      @close="closeDialog"
    >
      <template #body>
        <div class="flex flex-col gap-4">
          <div class="flex size-full gap-2 space-y-1 rounded-md border-2 border-green-20 bg-green-5 px-3 py-2 text-green-50">
            <KsIcon
              :icon="faEye"
              class="mt-1.5"
            />
            <div>
              <p
                class="font-semibold"
                v-text="t('assign.taskIsOpen')"
              />
              <p
                class="text-sm font-medium"
                v-text="t('assign.taskStatusInfo')"
              />
            </div>
          </div>
          <StudentEditList
            v-model="searchQuery"
            :task
          />
        </div>
      </template>
      <template #footer>
        <div class="flex items-center justify-end gap-4">
          <DeleteTaskDialog
            :task
            :disable-btn="close.isPending.value"
          />
          <KsSpinner
            v-if="close.isPending.value"
            size="small"
          />
          <KsButton
            v-if="task.status === TaskStatus.Open"
            variant="primary"
            shape="rounded"
            :disabled="close.isPending.value"
            :data-pendo="PendoFeature.TermExam.CloseTask"
            :style="{
              '--ks-primary': 'rgb(var(--au-coral-50))',
              '--ks-secondary': 'rgb(var(--au-coral-40))'
            }"
            @click="closeTask"
          >
            {{ t('assign.closeForAll') }}
          </KsButton>
        </div>
      </template>
    </KsDialog>
  </Teleport>
</template>
