<script setup lang="ts">
import type { GeoGebraAppletParams } from '~/models/Geogebra'
import { useGeoGebraApplet } from '~/composables/useGeoGebraApplet'

const { params, contentId } = defineProps<{
  contentId: string
  params: string | GeoGebraAppletParams
}>()

const applet = useGeoGebraApplet(params as GeoGebraAppletParams, parseInt(contentId))
</script>

<template>
  <div
    :id="applet.appletId"
    class="geogebra-applet"
  />
</template>

<style scoped>
/* stylelint-disable */
:deep(.GeoGebraFrame .toolbarPanel) {
  z-index: 50;
}
/* stylelint-enable */

.geogebra-applet {
  transform: translateX(-50%);
  left: 50%;

  /* using !important because the GeoGebra applet sets its own position when toggling fullscreen */
  position: relative !important;
}
</style>
