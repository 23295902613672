import { GradeType } from '~/models/GradeType'

// Premium is not yet used
export type SubscriptionType = 'none' | 'trial' | 'individual' | 'basic' | 'plus' | 'premium'

export type SubscriptionDetails = {
  name: string
  ean: string
  grades: string
  type: SubscriptionType
  source?: string
  endDate?: string
  gradeTypes: GradeType[]
}

/*
 * Hardcoded list of subscriptions. Premium is not in use yet.
 * This list should cover all possible subscriptions.
 */
export const StaticSubscriptions: SubscriptionDetails[] = [
  { name: 'PrimaryTrial', ean: '9788203413285', grades: '1-7', type: 'trial', gradeTypes: [GradeType.LowerPrimary, GradeType.UpperPrimary] },
  { name: 'PrimaryBasic', ean: '9788203410130', grades: '1-7', type: 'basic', gradeTypes: [GradeType.LowerPrimary, GradeType.UpperPrimary] },
  { name: 'PrimaryIndividual', ean: '9788203339622', grades: '1-7', type: 'individual', gradeTypes: [GradeType.LowerPrimary, GradeType.UpperPrimary] },
  { name: 'PrimaryPlus', ean: '9788203412752', grades: '1-7', type: 'plus', gradeTypes: [GradeType.LowerPrimary, GradeType.UpperPrimary] },
  { name: 'PrimaryPremium', ean: '', grades: '1-7', type: 'premium', gradeTypes: [GradeType.LowerPrimary, GradeType.UpperPrimary] }, // Not is use yet
  { name: 'SecondaryTrial', ean: '9788203413278', grades: '8-10', type: 'trial', gradeTypes: [GradeType.LowerSecondary] },
  { name: 'SecondaryBasic', ean: '9788203339769', grades: '8-10', type: 'basic', gradeTypes: [GradeType.LowerSecondary]  },
  { name: 'SecondaryIndividual', ean: '9788203410147', grades: '8-10', type: 'individual', gradeTypes: [GradeType.LowerSecondary] },
  { name: 'SecondaryPlus', ean: '9788203412776', grades: '8-10', type: 'plus', gradeTypes: [GradeType.LowerSecondary] },
  { name: 'SecondaryPremium', ean: '', grades: '8-10', type: 'premium', gradeTypes: [GradeType.LowerSecondary] }, // Not is use yet
  { name: 'VgsTrial', ean: '9788203413353', grades: 'vgs', type: 'trial', gradeTypes: [GradeType.UpperSecondary] },
  { name: 'VgsIndividual', ean: '9788203410321', grades: 'vgs', type: 'individual', gradeTypes: [GradeType.UpperSecondary] },
]
