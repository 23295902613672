<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton, KsDrawer, KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import useSubscriptionsStore from '~/stores/subscriptions'
import useLicenseStore from '~/stores/license'
import { TeleportationTarget } from '~/models/TeleportationTarget'

const { t } = useI18n()
const {
  hasSubscription,
  trialEndDateMessages,
  showUpgradeFromPlus,
  showUpgradeFromBasic,
  subscriptionsDetails,
  webshopMessages,
} = storeToRefs(useSubscriptionsStore())

const {
  isLoadingLicenses,
  hasLoadedLicenses,
  hasLicenses,
} = storeToRefs(useLicenseStore())

const props = defineProps<{
  open: boolean
}>()

const emit = defineEmits(['back', 'close'])

</script>

<template>
  <Teleport :to="TeleportationTarget.AppTop">
    <KsDrawer
      :title="t('settings.drawers.subscription')"
      :open="props.open"
      :backdrop="false"
      size="600px"
      @close="emit('close')"
    >
      <template #header>
        <KsButton
          shape="square"
          icon-left="arrow-left"
          :aria-label="t('back')"
          :title="t('back')"
          @click="emit('back')"
        />
      </template>
      <template #body>
        <div class="flex flex-col gap-4 border-t border-seagreen-10 py-6">
          <KsSkeletonWrapper v-if="isLoadingLicenses">
            <KsSkeleton height="10rem" />
          </KsSkeletonWrapper>
          <section v-if="hasLoadedLicenses">
            <div v-if="hasLicenses && hasSubscription">
              <p
                class="mb-4 text-xl font-bold"
                v-text="t('subscription.messages.current')"
              />
              <div class="grid size-full grid-cols-2 gap-6">
                <div
                  v-for="subscription in subscriptionsDetails"
                  :key="subscription.ean"
                  class="flex flex-col gap-4 rounded-lg bg-seagreen-40 py-6 text-center text-white"
                >
                  <p
                    class="text-2xl font-black"
                    v-text="`${t('subscription.application')} ${t('metadata.subscriptionGrades.'+subscription.grades)}`"
                  />
                  <span
                    class="-mx-1 rounded rounded-bl-none rounded-tr-none border border-seagreen-40 px-6 py-1 text-lg font-black uppercase shadow"
                    :class="subscription.type === 'plus' ? 'bg-yellow-pop text-green-30' : 'bg-green-50'"
                    v-text="t(`subscription.type.${subscription.type}`)"
                  />
                </div>
              </div>
              <div class="mt-6">
                <p
                  v-for="message in trialEndDateMessages"
                  :key="message"
                >
                  {{ message }}
                </p>
                <p
                  v-if="showUpgradeFromBasic"
                  v-text="t('subscription.messages.upgrade.fromBasic')"
                />
                <p
                  v-if="!showUpgradeFromPlus"
                  v-text="t('subscription.messages.upgrade.fromPlus')"
                />
                <p
                  v-if="webshopMessages"
                  class="my-4"
                  v-text="t('subscription.messages.readMore')"
                />
                <p
                  v-for="message in webshopMessages"
                  :key="message"
                  class="my-4"
                  v-html="message"
                />
              </div>
            </div>
            <div v-if="!hasLicenses || !hasSubscription">
              <p v-text="t('subscription.messages.missing')" />
            </div>
          </section>
        </div>
      </template>
    </KsDrawer>
  </Teleport>
</template>
