import type { ColorPair } from '~/models/Presentation/BasePage'
import { colorMap } from '~/utils/colors'
import { ColorName } from '~/models/Content/Color'

function createColorPair(text: ColorName, bg: ColorName, isDark: boolean): ColorPair {
  const [bgFallback, textFallback] = colorMap.values()
  return {
    text: colorMap.get(text) ?? textFallback,
    background: colorMap.get(bg) ?? bgFallback,
    isDark,
  }
}

const colorPairs: Record<string, ColorPair> = {
  'Gerd': createColorPair(ColorName.GreenPop, ColorName.Green50, true),
  'Gerd 2': createColorPair(ColorName.Green50, ColorName.GreenPop, false),
  'Gina': createColorPair(ColorName.Coral20, ColorName.Green50, true),
  'Gina 2': createColorPair(ColorName.Green50, ColorName.Coral20, false),
  'Gitte': createColorPair(ColorName.YellowPop, ColorName.Green30, true),
  'Gitte 2': createColorPair(ColorName.Green30, ColorName.YellowPop, false),
  'Gøril': createColorPair(ColorName.Seagreen10, ColorName.Seagreen50, true),
  'Gøril 2': createColorPair(ColorName.Seagreen50, ColorName.Seagreen10, false),
  'Ronald': createColorPair(ColorName.CoralPop, ColorName.Coral60, true),
  'Ronald 2': createColorPair(ColorName.Coral60, ColorName.CoralPop, false),
  'Ruben': createColorPair(ColorName.Coral20, ColorName.Blue50, true),
  'Ruben 2': createColorPair(ColorName.Blue50, ColorName.Coral20, false),
  'Rasmus': createColorPair(ColorName.Yellow30, ColorName.Gray50, true),
  'Rasmus 2': createColorPair(ColorName.Gray50, ColorName.Yellow30, false),
}

export default () => {
  const getBackgroundColor = (colorName: string) => colorName ? colorName : 'blue-10'

  const getColorPair = (colorPairName: string) => colorPairName ? colorPairs[colorPairName] : colorPairs['Gerd']

  return {
    getColorPair,
    getBackgroundColor
  }
}
