import { LoginName } from '~/models/Login'
import usePendoLogin from '~/composables/usePendoLogin'
import useOidc from '~/composables/useOidc'

export default () => {
  const { trackLoginButton } = usePendoLogin()
  const {
    loginRedirect,
    feideRedirect,
    saveRedirectUri,
    isLoading,
    buildFeideUrl,
    buildLocalUrl,
    redirectUri,
  } = useOidc()

  const clickFeide = async (event: PointerEvent) => {
    isLoading.value = true
    await trackLoginButton(LoginName.Feide, event)
    saveRedirectUri(redirectUri.value)
    setTimeout(() => feideRedirect(), 300)
  }

  const clickLogin = async (event: PointerEvent) => {
    isLoading.value = true
    await trackLoginButton(LoginName.Local, event)
    saveRedirectUri(redirectUri.value)
    setTimeout(() => loginRedirect(), 300)
  }

  return {
    clickFeide,
    clickLogin,
    isLoading,
    redirectUri,
    buildFeideUrl,
    buildLocalUrl,
  }
}
