import type { Task } from '~/models/AssignTask'
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useQueryClient } from '@tanstack/vue-query'
import { useTaskStreamsStore } from '~/stores/taskStreams'
import { useAuthStore } from '~/stores/auth'
import { useSseApi } from '~/api/sseApi'

export function useTaskEventListener() {
  const { subscribeStream } = useSseApi()
  const { userId } = storeToRefs(useAuthStore())
  const { addTaskId, hasTaskId } = useTaskStreamsStore()
  const queryClient = useQueryClient()

  const eventSourceInstance = ref<EventSource | null>(null)
  const currentStreamId = ref<string | null>(null)
  const processedMessages = ref<Set<string>>(new Set())

  const subscribeToStream = (streamId: string) => {
    if (eventSourceInstance.value && currentStreamId.value === streamId) {
      return // Already subscribed to this streamId
    }

    eventSourceInstance.value = subscribeStream(streamId)
    currentStreamId.value = streamId

    eventSourceInstance.value.onmessage = async (event: MessageEvent) => {
      if (event.data === 'keep-alive') return
      const message = JSON.parse(event.data)

      // Prevent processing duplicate messages based on the unique ID
      if (processedMessages.value.has(message.id)) return
      processedMessages.value.add(message.id)

      // Proceed only if message is relevant for the user
      if (message.all || message.userId === userId.value) {
        await queryClient.invalidateQueries()
      }
    }

    eventSourceInstance.value.onerror = (err: Event) => {
      console.error('Error with SSE stream:', err)
    }
  }

  const subscribeToTasks = (tasks: Task[]) => {
    const newTaskIds = new Set(tasks.map(task => String(task.taskId)))
    const tasksToSubscribe = Array.from(newTaskIds).filter(taskId => !hasTaskId(taskId))

    tasksToSubscribe.forEach(taskId => {
      subscribeToStream(taskId)
      addTaskId(taskId)
    })
  }

  return {
    subscribeToStream,
    subscribeToTasks,
  }
}
