<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import FavoritesLists from '~/components/favorites/FavoritesLists.vue'

const { t } = useI18n()
</script>

<template>
  <div class="bg-seagreen-50">
    <div class="mx-auto flex max-w-screen-au flex-col gap-16 px-4 py-40 text-white sm:px-8">
      <h1
        class="inline-block font-semibold drop-shadow-md fluid-text-3xl"
        v-text="t('favorite.favoriteListsViewTitle')"
      />
      <FavoritesLists />
    </div>
  </div>
</template>
