<script setup lang="ts">
import type { OnlyTextPage } from '~/models/Presentation/Pages/OnlyTextPage'
import type { BasePageProps } from '~/models/Presentation/BasePage'
import useHiddenHeading from '~/composables/useHiddenHeading'
import ScrollBox from '~/components/utils/ScrollBox.vue'
import PresentationRichText from '~/components/PresentationPages/Components/PresentationRichText.vue'

defineProps<BasePageProps & { page: OnlyTextPage }>()

const lineHeights: Record<string,string> = {
  'font-tiempos': '!leading-[1.4]',
  'font-inter': '!leading-[1.2]',
  'font-calistoga': '!leading-tight',
  'font-playpen': '!leading-normal',
}

const { hasHeading } = useHiddenHeading()
</script>
<template>
  <div
    class="flex h-full flex-col justify-center"
    :style="{ background: page.colorPair.background.rgb, color: page.colorPair.text.rgb }"
  >
    <h1
      v-if="!hasHeading"
      class="sr-only"
      v-text="page.title"
    />
    <ScrollBox class="max-h-[calc(100%-9rem)] px-8 sm:px-12">
      <div
        class="m-auto"
        :class="page.textMaxWidth"
      >
        <h1
          v-if="page.header"
          class="text-pretty"
          :class="[page.textSizes.heading, page.fontWeight, page.fontFamily, lineHeights[page.fontFamily]]"
          v-text="page.header"
        />
        <PresentationRichText
          v-if="page.body"
          :page="page"
        />
      </div>
    </ScrollBox>
  </div>
</template>
