import { EventSource } from 'eventsource'
import { getAccessToken } from '~/api/client/oidcClient'
import { externalClient } from '~/api/client/externalClient'

export const useSseApi = () => {
  const closeStream = async (streamId: string): Promise<void> => {
    await externalClient.delete(`/frontend/sse/closeStream/${streamId}`)
  }

  const createStream = async (streamId: string): Promise<void> => {
    await externalClient.post(`/frontend/sse/createStream/${streamId}`)
  }

  const notifyStream = async (streamId: string, message: { id: string; status: string; all: boolean; userId?: string }): Promise<void> => {
    await externalClient.post(`/frontend/sse/notify/${streamId}?message=${encodeURIComponent(JSON.stringify(message))}`)
  }

  const subscribeStream = (streamId: string) => {
    return new EventSource(`${import.meta.env.VITE_CORE_EXTERNAL_API_URL}/frontend/sse/subscribe/${streamId}`, {
      fetch: (input, init = {}) =>
        fetch(input, {
          ...init,
          headers: {
            ...init.headers,
            Authorization: `Bearer ${getAccessToken()}`,
          },
        })
    })
  }

  return {
    closeStream,
    createStream,
    notifyStream,
    subscribeStream,
  }
}
