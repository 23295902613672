<script setup lang="ts">
import useChatBot from '~/composables/useChatbot'
import Chatbot from '~/components/chat/Chatbot.vue'

const props = defineProps<{ locationId: number; prompt: string }>()

const { bot, isLoading, isError, isStreaming, chatHistory, maxRecall, prompt, isInteracting } = useChatBot({ locationId: props.locationId })
</script>

<template>
  <Chatbot
    class="my-8 w-full rounded-xl bg-[--theme-10,#ffead1ff] p-6 font-inter text-base"
    v-bind="{ bot, isLoading, isError, isStreaming, chatHistory, maxRecall, prompt, autoFocus: false, isInteracting }"
  />
</template>
