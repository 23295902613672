<script setup lang="ts">
import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { KsButton, KsIcon } from '@aschehoug/kloss'
import { setTitle } from '~/utils/dom'
import arrayUtils from '~/utils/arrayUtils'
import router from '~/router'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useTime from '~/composables/useTime'
import { useLottie } from '~/composables/useLottie'
import useBlogPosts from '~/composables/useBlogPosts'
import BlogSkeleton from '~/components/skeletons/BlogSkeleton.vue'

const props = defineProps<{ locationId: number }>()

const { t } = useI18n()
const { buildImageUrlByField } = useUrlBuilder()
const { dateTimeToAlternativeOutputFormat, isoStringToDateTime } = useTime()
const { isLoading: isLoadingLottie, lottieJSON, getLottie } = useLottie()
const { unique } = arrayUtils()

const { findPost } = useBlogPosts()
const { data: post, isLoading } = findPost(Number(props.locationId))

const postTags = computed(() => (post?.value?.tags || [])
  .map((tag) => tag.toLowerCase())
  .filter(unique))

const date = computed(() => post?.value?.publishedDate
  ? dateTimeToAlternativeOutputFormat(isoStringToDateTime(post.value.publishedDate))
  : '')

const imageUrl = computed(() => {
  const imageId = Number(post?.value?.image?.destinationContentId)
  return imageId ? buildImageUrlByField(imageId, 'image', 'banner_large') : ''
})

const lottieId = computed( () => Number(post?.value?.lottie?.destinationContentId))
const tag = computed(() => post?.value?.tags ? post.value.tags[0] : '')
const title = computed(() => post?.value?.title ?? '')
const body = computed(() => post?.value?.body ?? '')

watch(lottieId, () => lottieId.value > 0 && getLottie(lottieId.value), { immediate: true })
watch(post, () => post.value && setTitle(post.value.title), { immediate: true })
</script>

<template>
  <h1
    class="mt-4 px-5 text-center text-3xl font-bold sm:px-8 sm:text-4xl"
    v-text="t('blog.title')"
  />
  <div
    v-if="isLoading"
    class="relative mx-auto my-14 max-w-5xl gap-20 px-5 sm:px-8"
  >
    <BlogSkeleton />
  </div>
  <div
    v-if="!isLoading && post"
    class="relative mx-auto my-14 flex max-w-screen-au flex-col justify-center gap-12 px-5 sm:px-8 md:gap-20"
  >
    <article class="mb-12 animate-fade">
      <div
        class="relative mx-auto flex max-w-4xl flex-col justify-between"
        :class="{'!flex-row justify-between md:grid md:grid-cols-3': postTags.length <= 1}"
      >
        <KsButton
          v-if="postTags.length <= 1"
          icon-left="arrow-left"
          class="mr-auto !text-sm"
          @click.prevent="router.push({ name: 'blog' })"
        >
          {{ t('back') }}
        </KsButton>
        <p class="flex items-center justify-center gap-2 text-xs sm:text-sm lg:text-base">
          <span
            v-if="tag"
            class="font-medium uppercase tracking-widest text-seagreen-40"
            v-text="tag"
          />
          <span
            v-if="tag"
            aria-hidden="true"
          >&bull;</span>
          <span
            class="text-gray-40"
            v-text="date"
          />
        </p>
      </div>
      <h2
        class="mx-auto my-5 max-w-3xl text-center text-3xl font-bold !leading-tight sm:my-8 sm:text-4xl md:text-6xl"
        v-text="title"
      />
      <div
        v-if="!isLoadingLottie"
        class="mx-auto my-12 aspect-video max-w-4xl overflow-hidden rounded-3xl"
      >
        <LottieAnimation
          v-if="lottieJSON"
          :animation-data="lottieJSON"
          width="100%"
        />
        <img
          v-else-if="imageUrl"
          :src="imageUrl"
          alt=""
          class="size-full object-cover"
        >
        <div
          v-else
          class="flex size-full items-center justify-center bg-blue-40 text-white"
        >
          <KsIcon
            icon="newspaper"
            :scale="14"
          />
        </div>
      </div>
      <article
        class="au-cms mx-auto prose-p:text-black prose-img:rounded-2xl sm:prose-p:text-lg"
        v-html="body"
      />
    </article>
  </div>
</template>
