<script setup lang="ts">
import type { Component } from 'vue'
import { computed, defineProps, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useTaskStreamsStore } from '~/stores/taskStreams'
import { useTaskEventListener } from '~/composables/useTaskEventListener'
import { useAssignTask } from '~/composables/useAssignTask'
import StudentTaskHeaderView from '~/views/StudentTaskHeaderView.vue'
import FlytTaskView from '~/views/FlytTaskView.vue'
import ArticleView from '~/views/ArticleView.vue'

const {
  contentType,
  locationId,
} = defineProps<{
  contentType: string
  locationId: number
}>()

const { subscribeToTasks } = useTaskEventListener()
const { subscribedTaskIds } = storeToRefs(useTaskStreamsStore())
const { tasks } = useAssignTask()

const componentMap: Record<string, Component> = {
  pakke: StudentTaskHeaderView,
  tekst: ArticleView,
  oppgave: FlytTaskView,
}

const componentToRender = computed(() => componentMap[contentType])

watch(tasks, () => {
  if (!tasks.value?.length) return
  const tasksToSubscribe = tasks.value?.filter(task => !subscribedTaskIds.value.has(String(task.taskId)))
  if (tasksToSubscribe.length > 0) {
    subscribeToTasks(tasksToSubscribe)
  }
}, { immediate: true })
</script>

<template>
  <component
    :is="componentToRender"
    :location-id="locationId"
  />
</template>
