import type { GradeCode } from '~/models/Grade'
import stringUtils from '~/utils/stringUtils'
import { GradeType } from '~/models/GradeType'

const { lastInPath } = stringUtils()

export const GRADE1 = 'aarstrinn1'
export const GRADE2 = 'aarstrinn2'
export const GRADE3 = 'aarstrinn3'
export const GRADE4 = 'aarstrinn4'
export const GRADE5 = 'aarstrinn5'
export const GRADE6 = 'aarstrinn6'
export const GRADE7 = 'aarstrinn7'
export const GRADE8 = 'aarstrinn8'
export const GRADE9 = 'aarstrinn9'
export const GRADE10 = 'aarstrinn10'
export const GRADE11 = 'vg1'
export const GRADE12 = 'vg2'
export const GRADE13 = 'vg3'
export const GRADE_ADULT = 'vok'
export const GRADE_PREP = 'forkurs'

export const gradesSorted: GradeCode[] = [
  GRADE1,
  GRADE2,
  GRADE3,
  GRADE4,
  GRADE5,
  GRADE6,
  GRADE7,
  GRADE8,
  GRADE9,
  GRADE10,
  GRADE11,
  GRADE12,
  GRADE13,
  GRADE_ADULT,
  GRADE_PREP,
]

export const lowerPrimaryGrades: GradeCode[] = [GRADE1, GRADE2, GRADE3, GRADE4]
export const upperPrimaryGrades: GradeCode[] = [GRADE5, GRADE6, GRADE7]
export const lowerSecondaryGrades: GradeCode[] = [GRADE8, GRADE9, GRADE10]
export const upperSecondaryGrades: GradeCode[] = [GRADE11, GRADE12, GRADE13]
export const adultEducationGrades: GradeCode[] = [GRADE_ADULT]
export const prepEducationGrades: GradeCode[] = [GRADE_PREP]

export const primaryGrades: GradeCode[] = lowerPrimaryGrades.concat(upperPrimaryGrades)
export const combinedGrades: GradeCode[] = primaryGrades.concat(lowerSecondaryGrades).concat(upperSecondaryGrades)
export const lowerGrades: GradeCode[] = primaryGrades.concat(lowerSecondaryGrades)
export const higherGrades: GradeCode[] = lowerSecondaryGrades.concat(upperSecondaryGrades)

export const findGradesByGradeType = (gradeType: GradeType) => {
  switch (gradeType) {
    case GradeType.LowerPrimary: return lowerPrimaryGrades
    case GradeType.UpperPrimary: return upperPrimaryGrades
    case GradeType.LowerSecondary: return lowerSecondaryGrades
    case GradeType.UpperSecondary: return upperSecondaryGrades
    case GradeType.AdultEducation: return adultEducationGrades
    case GradeType.PrepEducation: return prepEducationGrades
    default: return []
  }
}

export const findRelevantGradeType = (grade: GradeCode) => {
  if (lowerPrimaryGrades.includes(grade)) return GradeType.LowerPrimary
  if (upperPrimaryGrades.includes(grade)) return GradeType.UpperPrimary
  if (lowerSecondaryGrades.includes(grade)) return GradeType.LowerSecondary
  if (upperSecondaryGrades.includes(grade)) return GradeType.UpperSecondary
  if (adultEducationGrades.includes(grade)) return GradeType.AdultEducation
  if (prepEducationGrades.includes(grade)) return GradeType.PrepEducation
}

export const sortByGradeIndex = (gradeCodeA: GradeCode | string, gradeCodeB: GradeCode | string): number => {
  return gradesSorted.indexOf(lastInPath<GradeCode>(gradeCodeA)) - gradesSorted.indexOf(lastInPath<GradeCode>(gradeCodeB))
}

export const sortByLowestGrade = (a: any, b: any): number => {
  if (!a.grades || !b.grades) return 0
  if (a.grade && b.grade) return sortByGradeIndex(a.grade, b.grade)
  const gradesA: GradeCode[] | string[] = a.grades
  const gradesB: GradeCode[] | string[] = b.grades

  const lowestGradeA = gradesA.sort(sortByGradeIndex)[0]
  const lowestGradeB = gradesB.sort(sortByGradeIndex)[0]

  return sortByGradeIndex(lowestGradeA, lowestGradeB)
}

export const ageGroupMapping: Record<string, GradeCode[]> = {
  '6-9 år': lowerPrimaryGrades,
  '9-12 år': upperPrimaryGrades,
  '12-15 år': lowerSecondaryGrades,
}

export const gradeTypeMapping: Record<string, GradeCode[]> = {
  '1-4': lowerPrimaryGrades,
  '5-7': upperPrimaryGrades,
  '8-10': lowerSecondaryGrades,
  'vgs': upperSecondaryGrades,
  'voksen': adultEducationGrades,
  'forkurs': prepEducationGrades,
}

export const gradeTypeNames: Record<GradeType, string> = {
  [GradeType.LowerPrimary]: '1-4',
  [GradeType.UpperPrimary]: '5-7',
  [GradeType.LowerSecondary]: '8-10',
  [GradeType.UpperSecondary]: 'vgs',
  [GradeType.AdultEducation]: 'voksen',
  [GradeType.PrepEducation]: 'forkurs',
}
