<script setup lang="ts">
import type { ContentArticle } from '~/models/Content/ContentArticle'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton } from '@aschehoug/kloss'
import useAuthStore from '~/stores/auth'
import ArticleTeacherGuide from '~/components/article/ArticleTeacherGuide.vue'
import ArticleStudentGuide from '~/components/article/ArticleStudentGuide.vue'

const { article } = defineProps<{ article: ContentArticle }>()

const { t } = useI18n()
const { isTeacher } = storeToRefs(useAuthStore())

const isTeacherGuideOpen = ref(false)
const isStudentGuideOpen = ref(false)

const hasTeacherGuide = computed(() => isTeacher.value && Number(article.teacherContent?.destinationContentId) > 0)
const hasStudentGuide = computed(() => Number(article.studentGuide?.length) > 0)
const hasGuides = computed(() => hasTeacherGuide.value || hasStudentGuide.value)

const needsTopMargin = computed(() => article.headerVariation?.identifier === 'text_and_image')
</script>
<template>
  <div
    v-if="hasGuides"
    class="flex gap-2"
    :class="{ 'mt-6': needsTopMargin }"
  >
    <KsButton
      v-if="hasStudentGuide"
      variant="secondary"
      shape="rounded"
      size="large"
      @click="isStudentGuideOpen = true"
    >
      {{ t('article.toStudent') }}
    </KsButton>
    <KsButton
      v-if="isTeacher && hasTeacherGuide"
      variant="secondary"
      shape="rounded"
      size="large"
      @click="isTeacherGuideOpen = true"
    >
      {{ t('article.toTeacher') }}
    </KsButton>
  </div>
  <ArticleStudentGuide
    v-if="hasStudentGuide"
    :is-open="isStudentGuideOpen"
    :content="article.studentGuide"
    @close-guide="isStudentGuideOpen = false"
  />
  <ArticleTeacherGuide
    v-if="isTeacher && hasTeacherGuide"
    :is-open="isTeacherGuideOpen"
    :content-id="article.teacherContent!.destinationContentId"
    @close-guide="isTeacherGuideOpen = false"
  />
</template>

<style scoped>
:deep(.ks-button) {
  --ks-input: rgb(var(--au-orange-20));
  --ks-secondary: rgb(var(--au-coral-60));
  --ks-inputhover: rgb(var(--au-orange-10));
  border: 1px solid rgb(var(--au-coral-60)) !important;
}
</style>
