<script setup lang="ts">
import type { TeacherArticle, TeacherArticleContent } from '~/models/TeacherArticle'
import type { ContentPackage } from '~/models/Content/ContentPackage'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useQuery } from '@tanstack/vue-query'
import { KsDialog } from '@aschehoug/kloss'
import useArrayUtils from '~/utils/arrayUtils'
import { TeleportationTarget } from '~/models/TeleportationTarget'
import { ContentType } from '~/models/Content/ContentType'
import useSlugify from '~/composables/useSlugify'
import { useContentHelper } from '~/composables/useContentHelper'
import useContentApi from '~/api/contentApi'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import TeacherSubitems from '~/components/subject/TeacherSubitems.vue'

const { t } = useI18n()
const { findContents } = useContentApi()
const { getTitle, packageTypes, isPackage, isArticleContent } = useContentHelper()
const { truthy } = useArrayUtils()
const { slugify } = useSlugify()

const emit = defineEmits(['closeGuide'])

const props = defineProps<{
  isOpen: boolean
  contentId: number|string
}>()

const { data: teacherArticle } = useQuery({
  enabled: computed(() => !!props.contentId && props.isOpen),
  staleTime: Infinity,
  queryKey: ['teacher-article', props.contentId],
  queryFn: () => findContents<TeacherArticle>({
      contentIdCriterion: [Number(props.contentId)],
      contentTypeCriterion: [ContentType.TeacherArticle],
    }, 1).then(([article]) => article)
})

const articleRelations = computed(() => teacherArticle.value?.contents?.destinationContentIds ?? [])

const { data: articleContents } = useQuery({
  enabled: computed(() => articleRelations.value.length > 0),
  staleTime: Infinity,
  queryKey: ['teacher-relations', props.contentId],
  queryFn: () => findContents<ContentPackage|TeacherArticleContent>({
    contentIdCriterion: articleRelations.value,
    contentTypeCriterion: [...[ContentType.ArticleContent], ...packageTypes],
    mainLocationCriterion: true,
  }),
  select: (contents: (ContentPackage|TeacherArticleContent)[]) => articleRelations.value
    .map((destinationContentId: number|string) => contents.find(({ contentId }) => contentId === Number(destinationContentId)))
    .filter(truthy<ContentPackage|TeacherArticleContent>)
})
</script>
<template>
  <Teleport :to="TeleportationTarget.AppTop">
    <KsDialog
      :title="t('article.teacherGuide')"
      :open="isOpen"
      size="medium"
      @close="emit('closeGuide')"
    >
      <template #body>
        <article class="prose prose-au mx-auto prose-img:rounded-xl">
          <div class="au-teacher-article">
            <RichTextRenderer :text="teacherArticle?.intro ?? ''" />
          </div>
          <div
            v-for="content in articleContents"
            :id="slugify(getTitle(content))"
            :key="content.contentId"
            class="au-teacher-article last:mb-16"
          >
            <h2 v-text="getTitle(content)" />
            <TeacherSubitems
              v-if="isPackage(content)"
              :header="content"
            />
            <RichTextRenderer
              v-if="isArticleContent(content)"
              :text="content.body"
            />
          </div>
        </article>
      </template>
    </KsDialog>
  </Teleport>
</template>
