import type { Task, TaskUser, TaskUserRequest } from '~/models/AssignTask'
import { computed, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import useGroupsStore from '~/stores/groups'
import useAuthStore from '~/stores/auth'
import { TaskStatus } from '~/models/AssignTask'
import { useTasksApi } from '~/api/tasksApi'
import { useSseApi } from '~/api/sseApi'

export function useAssignTask() {
  const {
    getTasks: apiGetTasks,
    createTask: apiCreateTask,
    updateTask: apiUpdateTask,
    deleteTask: apiDeleteTask,
    closeTask: apiCloseTask,
    openTask: apiOpenTask,
    getTaskUsers: apiGetTaskUsers,
    addTaskUsers: apiAddTaskUsers,
    updateTaskUser: apiUpdateTaskUser,
  } = useTasksApi()

  const { t } = useI18n()
  const { createStream, closeStream, notifyStream } = useSseApi()
  const { groups, hasLoadedGroups } = storeToRefs(useGroupsStore())
  const { isTeacher, hasLoadedUser } = storeToRefs(useAuthStore())

  const toast = <Toast>inject('ksToast')
  const groupIds = computed(() => groups.value.map(group => group.groupId))
  const queryClient = useQueryClient()
  const currentYear = new Date().getFullYear().toString()
  const tags = [`term-exam-${currentYear}`]
  const queryKey = ['assigned_tasks', groupIds.value, tags, isTeacher.value, groupIds.value.length]

  const {
    data: tasks,
    isLoading,
    isError,
  } = useQuery({
    queryKey,
    queryFn: () => {
      if (isTeacher.value && groupIds.value.length === 0) {
        return []
      }
      const params = isTeacher.value ? { groups: groupIds.value, tags } : { tags }
      return apiGetTasks(params).then(({ response }) => response)
    },
    enabled: computed(() => hasLoadedUser.value && hasLoadedGroups.value),
    staleTime: Infinity,
  })

  const createTask = useMutation({
    mutationFn: async (task: Partial<Task>) => apiCreateTask(task),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    onSettled: (data, error) => error
      ? toast.error(t('assign.toasts.createError'))
      : toast.success(t('assign.toasts.createSuccess')),
  })

  const updateTask = useMutation({
    mutationFn: async (task: Task) => apiUpdateTask(task),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  })

  const deleteTask = useMutation({
    mutationFn: async (task: Task) => apiDeleteTask(task),
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({ queryKey })
      await closeStream(String(variables.taskId))
    },
    onSettled: (data, error) => error
      ? toast.error(t('assign.toasts.deleteError'))
      : toast.success(t('assign.toasts.deleteSuccess')),
  })

  const closeTask = useMutation({
    mutationFn: async (task: Task) => apiCloseTask(task),
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({ queryKey })
      await notifyStream(String(variables.taskId), { id: crypto.randomUUID(), status: TaskStatus.Closed, all: true })
    },
    onSettled: (data, error) => error
      ? toast.error(t('assign.toasts.closeError'))
      : toast.success(t('assign.toasts.closeSuccess')),
  })

  const openTask = useMutation({
    mutationFn: async (task: Task) => apiOpenTask(task),
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({ queryKey })
      await createStream(String(variables.taskId))
    },
    onSettled: (data, error) => error
      ? toast.error(t('assign.toasts.openError'))
      : toast.success(t('assign.toasts.openSuccess')),
  })

  const getTaskUsers = useMutation({
    mutationFn: async (task: Task) => apiGetTaskUsers(task),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  })

  const addTaskUsers = useMutation({
    mutationFn: async ({ task, users }: {
      task: Task
      users: TaskUserRequest
    }) => apiAddTaskUsers(task, users),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    onSettled: (data, error) => error
      ? toast.error(t('assign.toasts.saveError'))
      : toast.success(t('assign.toasts.saveSuccess')),
  })

  const updateTaskUser = useMutation({
    mutationFn: async ({ task, user, status }: {
      task: Task
      user: TaskUser
      status: TaskStatus
    }) => apiUpdateTaskUser(task, user, status),

    onMutate: async ({ task, user, status }) => {
      await queryClient.cancelQueries({ queryKey })

      const previousTasks = queryClient.getQueryData(queryKey)

      queryClient.setQueryData(queryKey, (oldData: Task[]) => {
        return oldData.map((t: Task) => {
          if (t.taskId === task.taskId) {
            return {
              ...t,
              users: t.users?.map((u) =>
                u.userId === user.userId ? { ...u, status } : u
              ),
            }
          }
          return t
        })
      })

      return { previousTasks }
    },

    onError: (err, variables, context) => {
      queryClient.setQueryData(queryKey, context?.previousTasks)
    },

    onSuccess: async (data, variables) => {
      await notifyStream(String(variables.task.taskId), {
        id: crypto.randomUUID(),
        status: variables.user.status === TaskStatus.Open ? 'CLOSED' : 'OPEN',
        all: false,
        userId: String(variables.user.userId),
      })
    },

    onSettled: async (data, error, variables) => {
      await queryClient.invalidateQueries({ queryKey })
      return error
        ? toast.error(t('assign.toasts.updateUserError'))
        : toast.success(t('assign.toasts.updateUserSuccess', {
          status: variables.user.status === TaskStatus.Open ? t('assign.closed').toLowerCase() : t('assign.opened').toLowerCase(),
          user: variables.user.fullName,
        }))
    },
  })

  const addTaskUsersAndOpen = useMutation({
    mutationFn: async ({ task, users }: {
      task: Task
      users: TaskUserRequest
    }) => {
      await apiAddTaskUsers(task, users)
      await apiOpenTask(task)
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({ queryKey })
      await createStream(String(variables.task.taskId))
    },
    onSettled: (data, error) => error
      ? toast.error(t('assign.toasts.addUsersAndOpenError'))
      : toast.success(t('assign.toasts.addUsersAndOpenSuccess')),
  })

  return {
    tasks,
    isLoading,
    isError,
    createTask,
    updateTask,
    deleteTask,
    closeTask,
    openTask,
    getTaskUsers,
    addTaskUsers,
    updateTaskUser,
    addTaskUsersAndOpen,
  }
}
