<script lang="ts" setup>
import type { SubjectCode } from '~/models/Subject'
import type { GradeCode } from '~/models/Grade'
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed, onMounted, onUnmounted } from 'vue'
import { RouterLink, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useQuery } from '@tanstack/vue-query'
import { KsButton } from '@aschehoug/kloss'
import { sortByGradeIndex } from '~/utils/gradeSorter'
import useProductStore from '~/stores/product'
import useLicenseControlStore from '~/stores/licenseControl'
import useFilterStore from '~/stores/filter'
import { useAuthStore } from '~/stores/auth'
import { ContentType } from '~/models/Content/ContentType'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useSubjectView from '~/composables/useSubjectView'
import useSearchHelper from '~/composables/useSearchHelper'
import useProductHelper from '~/composables/useProductHelper'
import useFullScreen from '~/composables/useFullscreen'
import useContentApi from '~/api/contentApi'

const { t } = useI18n()
const router = useRouter()
const { isSupported, isFullscreen, onFullscreenChange, setCurrentFullscreenState } = useFullScreen()
const { hasAccessRestricted } = storeToRefs(useLicenseControlStore())
const { buildResourceUrl, resolveBaseUrl } = useUrlBuilder()
const { isAuthenticated } = storeToRefs(useAuthStore())
const { hasLoaded: hasLoadedProducts } = storeToRefs(useProductStore())
const { getProductFromResource } = useProductStore()
const { isExams, isSupportProduct } = useProductHelper()
const { findContents } = useContentApi()
const { isComponentView, isUpcomingView } = useSubjectView()
const { cardFields } = useSearchHelper()
const { selectedGrade } = storeToRefs(useFilterStore())

const props = defineProps<{
  item?: BaseItem
  subjectCode?: SubjectCode
  gradeCode?: GradeCode
}>()

const headerTypes = [ContentType.ProductHeader, ContentType.ProductPackage, ContentType.Folder]

const protectedPathDepth = '/1/2/160/subject/'.split('/').length

const protectedLocationIds = [
  160162, // Biblioteket: Tema
  253703, // Bibliotekt: Alle bøker
]

const hideCloseButton = computed(() => hasAccessRestricted.value && props.item)

const subjectCode = computed(() => props.subjectCode ?? product.value?.subjects[0])
const gradeCode = computed(() => props.gradeCode ?? selectedGrade.value ?? productGrades.value[0])

const product = computed(() => props.item && getProductFromResource(props.item))
const productUrl = computed(() => product.value && resolveBaseUrl(product.value, props.item, subjectCode.value, gradeCode.value))
const productGrades = computed(() => (product.value?.grades ?? []).sort(sortByGradeIndex))

const homeUrl = router.resolve({ name: 'home' }).href

const pathLocationIds = computed(() => {
  if (!props.item?.locationId) return []
  return props.item.pathString.split('/').slice(protectedPathDepth).map((id) => Number(id))
    .filter((locationId) => locationId && props.item && locationId !== props.item.locationId && !protectedLocationIds.includes(locationId))
    .reverse().slice(0, 3)
})

const isComponentSubject = computed(() => product.value
  && subjectCode.value
  && isComponentView(subjectCode.value, gradeCode.value)
  && !isSupportProduct(product.value))

const isUpcomingSubject = computed(() => product.value
  && props.item
  && isUpcomingView(product.value, props.item)
  && !isSupportProduct(product.value))

const goToImmediateParent = computed(() => !(product.value && (
  isComponentSubject.value
  || isUpcomingSubject.value
  || isExams(product.value)
)))

const { data: headers, isLoading } = useQuery({
  enabled: computed(() => isAuthenticated.value && pathLocationIds.value.length > 0),
  queryKey: computed(() => ['close-button-headers', pathLocationIds.value]),
  queryFn: () => findContents({
    locationIdCriterion: pathLocationIds.value,
    sortField: 'path',
    sortOrder: 'desc',
  }, pathLocationIds.value.length, 0, cardFields),
  staleTime: Infinity
})

const parentRoute = computed(() => {
  if (!headers?.value?.length) return

  // Certain contexts should go to immediate parent
  const parent = headers.value[0]
  if (parent && goToImmediateParent.value) {
    return buildResourceUrl(parent)
  }

  // Find first header parent
  const headerIndex = headers.value
    .findIndex((header) => headerTypes.includes(header.contentTypeIdentifier))
  if (headerIndex < 0) return

  // Go to next header if it exists
  const nextHeaderType = headers.value[headerIndex + 1]?.contentTypeIdentifier
  if (nextHeaderType && headerTypes.includes(nextHeaderType)) {
    return buildResourceUrl(headers.value[headerIndex + 1])
  }

  // Else go to first parent header
  const headerUrl = buildResourceUrl(headers.value[headerIndex])
  return router.resolve(headerUrl).name !== 'generic' ? headerUrl : undefined
})

const closeRoute = computed(() => parentRoute.value || productUrl.value || homeUrl)

onMounted(async () => {
  if (!isAuthenticated.value) return
  if (isSupported()) {
    addEventListener('fullscreenchange', onFullscreenChange)
    setCurrentFullscreenState()
  }
})

onUnmounted(() => {
  if (isSupported()) {
    removeEventListener('fullscreenchange', onFullscreenChange)
  }
})
</script>

<template>
  <KsButton
    :is="RouterLink"
    v-if="isAuthenticated && !hideCloseButton && !isFullscreen"
    size="large"
    variant="secondary"
    shape="round"
    :aria-label="t('close')"
    icon-left="xmark"
    :disabled="!hasLoadedProducts || isLoading"
    :to="closeRoute"
  />
</template>
