<script setup lang="ts">
import type { ContentAuthor } from '~/models/Content/ContentAuthor'
import type { ContentArticle } from '~/models/Content/ContentArticle'
import { computed } from 'vue'
import { createReusableTemplate } from '@vueuse/core'
import { useQuery } from '@tanstack/vue-query'
import { firstOf } from '~/utils/queryUtils'
import { Subtree } from '~/models/Content/Subtree'
import { ContentType } from '~/models/Content/ContentType'
import { useMedia } from '~/composables/useMedia'
import useContentApi from '~/api/contentApi'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import CopyrightButton from '~/components/utils/CopyrightButton.vue'
import Image from '~/components/media/Image.vue'
import ArticleHeaderBar from '~/components/article/ArticleHeaderBar.vue'

const { article } = defineProps<{
  article: ContentArticle
}>()

const { findContents } = useContentApi()
const [DefineHeaderContent, ReuseHeaderContent] = createReusableTemplate()

const { data: authors } = useQuery({
  staleTime: Infinity,
  queryKey: ['app-article-authors', article.locationId],
  queryFn() {
    if (!article.authors?.destinationContentIds?.length) return null
    return findContents<ContentAuthor>({
      contentIdCriterion: article.authors.destinationContentIds,
      contentTypeCriterion: [ContentType.Author],
      subtreeCriterion: [Subtree.Authors],
      mainLocationCriterion: true,
    })
  },
  enabled: computed(() => (article.authors?.destinationContentIds.length ?? 0) > 0)
})

const { data: bgMedia } = firstOf(useMedia(computed(() => Number(article.image?.destinationContentId) ? [Number(article.image?.destinationContentId)] : [])))
</script>
<template>
  <!-- Header -->
  <DefineHeaderContent>
    <div class="flex h-full flex-col justify-center gap-6">
      <div
        v-if="article.overline"
        class="font-semibold uppercase tracking-wider"
      >
        {{ article.overline }}
      </div>
      <div class="space-y-4">
        <h1 class="au-title font-bold">
          {{ article.title }}
        </h1>
        <div class="au-subtitle au-prose">
          <RichTextRenderer :text="article.body" />
        </div>
      </div>
      <div
        v-if="authors"
        class="mt-auto"
      >
        {{ $t('article.text') }}:
        <strong
          v-for="(author, index) in authors"
          :key="author.contentId"
        >
          {{ author.title }}<template v-if="index + 1 < authors.length">, </template>
        </strong>
      </div>
    </div>
  </DefineHeaderContent>

  <header
    v-if="article.headerVariation.identifier === 'text_on_image'"
    class="col-[fill] grid grid-flow-row grid-cols-subgrid grid-rows-[50vh] gap-y-6"
  >
    <div class="col-start-1 -col-end-1 row-start-1 row-end-1">
      <Image
        v-if="bgMedia"
        class="!size-full min-h-full object-cover"
        :content="bgMedia"
      />
    </div>
    <CopyrightButton
      v-if="bgMedia && 'copyright' in bgMedia"
      class="-col-end-2 row-start-1 row-end-2 ml-auto mt-auto"
      :copyright="bgMedia.copyright"
      color-theme
      style="--ks-roundness: 0"
    />
    <div class="col-[extext] grid grid-cols-subgrid self-end bg-[--color-lighter] md:row-start-1 md:rounded-t-lg md:pt-[--col-width]">
      <div
        v-if="article.overline"
        class="col-[text] font-semibold uppercase"
      >
        {{ article.overline }}
      </div>
      <h1 class="au-title col-[text] font-bold">
        {{ article.title }}
      </h1>
    </div>
    <div class="col-[text] flex flex-col gap-6">
      <div class="au-subtitle au-prose contents">
        <RichTextRenderer :text="article.body" />
      </div>
      <div
        v-if="authors"
        class="mt-auto"
      >
        {{ $t('article.text') }}:
        <strong
          v-for="(author, index) in authors"
          :key="author.contentId"
        >
          {{ author.title }}<template v-if="index + 1 < authors.length">, </template>
        </strong>
      </div>
      <ArticleHeaderBar :article="article" />
    </div>
  </header>

  <template v-else-if="article.headerVariation.identifier === 'text_and_image'">
    <header class="col-[media] mt-24">
      <div class="grid grid-cols-1 text-[--color-pop] md:grid-cols-2">
        <ReuseHeaderContent class="bg-[--color-darker] p-[--col-width] max-md:rounded-t-lg md:rounded-l-lg" />
        <div class="overflow-hidden bg-[--color-light] p-[--col-gap] max-md:order-1 max-md:rounded-b-lg md:rounded-r-lg">
          <div class="relative aspect-[10/9] size-full">
            <Image
              v-if="bgMedia"
              class="max-h-full min-h-full object-cover"
              :content="bgMedia"
            />
            <CopyrightButton
              v-if="bgMedia && 'copyright' in bgMedia"
              class="!absolute bottom-0 right-0"
              :copyright="bgMedia.copyright"
              color-theme
              style="--ks-roundness: 0"
            />
          </div>
        </div>
      </div>
      <ArticleHeaderBar :article="article" />
    </header>
  </template>

  <header
    v-else-if="article.headerVariation.identifier === 'color'"
    class="col-start-1 -col-end-1 grid grid-cols-subgrid bg-[--color-darker] pb-[--col-width] pt-32 text-[--color-pop]"
  >
    <div class="col-[text] flex flex-col gap-6">
      <ReuseHeaderContent />
      <ArticleHeaderBar :article="article" />
    </div>
  </header>

  <header
    v-else-if="article.headerVariation.identifier === 'simple'"
    class="col-[text] flex flex-col gap-6 pt-32"
  >
    <ReuseHeaderContent />
    <ArticleHeaderBar :article="article" />
  </header>

  <header
    v-else
    class="col-start-1 -col-end-1"
  >
    <h1 class="sr-only">
      {{ article.title }}
    </h1>
  </header>
</template>
