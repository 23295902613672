<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import CardSkeleton from '~/components/skeletons/CardSkeleton.vue'

const route = useRoute()

const showHighlightedSkeleton = computed(() => route.name === 'blog')
const showSinglePostSkeleton = computed(() => !!route.params.locationId)
</script>

<template>
  <div v-if="!showSinglePostSkeleton">
    <KsSkeletonWrapper
      v-if="showHighlightedSkeleton"
      class="mx-auto mt-8 flex flex-col gap-8 md:flex-row"
    >
      <KsSkeleton
        width="100%"
        height="17.5rem"
        border-radius="24px"
      />
      <div class="flex w-full flex-col gap-6 md:gap-8">
        <KsSkeleton
          height="10px"
          width="50%"
        />
        <div class="space-y-4">
          <KsSkeleton
            height="40px"
            width="95%"
          />
          <KsSkeleton
            height="40px"
            width="80%"
          />
        </div>
        <div>
          <KsSkeleton
            height="12px"
            width="80%"
          />
          <KsSkeleton
            height="12px"
            width="95%"
          />
          <KsSkeleton
            height="12px"
            width="100%"
          />
          <KsSkeleton
            height="12px"
            width="80%"
          />
        </div>
      </div>
    </KsSkeletonWrapper>
    <KsSkeletonWrapper
      class="grid max-w-5xl grid-cols-1 gap-x-8 gap-y-12 sm:grid-cols-2 lg:grid-cols-3"
      :class="showHighlightedSkeleton ? 'mt-20' : 'mt-14'"
    >
      <div
        v-for="i in 6"
        :key="i"
        class="flex flex-col"
      >
        <CardSkeleton
          height="11rem"
          style="border-radius: 24px"
        />
        <KsSkeleton
          height="8px"
          width="40%"
          class="mt-2"
        />
        <KsSkeleton
          class="mb-3 mt-4"
          height="18px"
          width="90%"
        />
        <KsSkeleton
          class="mb-2"
          height="10px"
        />
        <KsSkeleton height="12px" />
      </div>
    </KsSkeletonWrapper>
  </div>
  <KsSkeletonWrapper
    v-else
    class="mx-auto flex flex-col items-center justify-center gap-12"
  >
    <KsSkeleton width="25%" />
    <KsSkeleton
      height="4rem"
      width="70%"
    />
    <KsSkeleton
      width="100%"
      height="26rem"
      border-radius="30px"
    />
    <div class="mx-auto w-full max-w-prose">
      <KsSkeleton
        class="mb-6"
        width="80%"
        height="2rem"
      />
      <KsSkeleton width="90%" />
      <KsSkeleton width="95%" />
      <KsSkeleton width="99%" />
      <KsSkeleton width="98%" />
      <KsSkeleton width="98%" />
      <KsSkeleton width="89%" />
      <KsSkeleton />
      <KsSkeleton width="98%" />
      <KsSkeleton />
    </div>
  </KsSkeletonWrapper>
</template>
