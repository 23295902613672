import type { SubjectCode } from '~/models/Subject'
import type { GradeCode } from '~/models/Grade'
import type { ContentType } from '~/models/Content/ContentType'
import { computed, type Ref, ref } from 'vue'
import { useRoute } from 'vue-router'

export default function useReport() {
  const route = useRoute()

  const getRouteQueryValue = (name: string): string => {
    return (route.query[name] || '') as string
  }

  const groupId = ref<string>(getRouteQueryValue('groupId'))
  const contentType = ref<ContentType>(<ContentType>getRouteQueryValue('contentType'))
  const subject = ref<SubjectCode>(<SubjectCode>getRouteQueryValue('subject'))
  const grade = ref<GradeCode>(<GradeCode>getRouteQueryValue('grade'))
  const lastActivityAfter = ref<string>(getRouteQueryValue('lastActivityAfter'))
  const lastActivityBefore = ref<string>(getRouteQueryValue('lastActivityBefore'))
  const context = ref<string>(getRouteQueryValue('context'))

  const queryParams = computed(() => {
    const params: Record<string, string> = {}

    params.groupId = groupId.value
    params.contentType = contentType.value
    params.subject = subject.value
    params.grade = grade.value
    params.lastActivityAfter = lastActivityAfter.value
    params.lastActivityBefore = lastActivityBefore.value
    params.context = context.value

    return params
  })

  function setFilterValue(key: string, value: string) {
    const filterMap: Record<string, Ref<string>> = {
      groupId: groupId,
      contentType: contentType,
      subject: subject,
      grade: grade,
      lastActivityAfter: lastActivityAfter,
      lastActivityBefore: lastActivityBefore,
      context: context,
    }

    const criterion = filterMap[key]
    if (criterion) criterion.value = value
  }

  return {
    groupId,
    contentType,
    subject,
    grade,
    lastActivityAfter,
    lastActivityBefore,
    context,
    queryParams,
    setFilterValue,
  }
}
