<script setup lang="ts">
import type { Task } from '~/models/AssignTask'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { KsButton, KsDialog, KsSpinner } from '@aschehoug/kloss'
import TeleportationTarget from '~/models/TeleportationTarget'
import { PendoFeature } from '~/models/Pendo'
import { useAssignTask } from '~/composables/useAssignTask'

const { task, disableBtn = false } = defineProps<{ task: Task; disableBtn?: boolean }>()

const { t } = useI18n()
const { deleteTask } = useAssignTask()

const open = ref(false)
</script>

<template>
  <KsButton
    class="mr-auto"
    shape="rounded"
    :disabled="disableBtn"
    style="--ks-secondary: rgb(var(--au-coral-40))"
    @click="open = true"
  >
    {{ t('assign.delete') }}
  </KsButton>
  <Teleport :to="TeleportationTarget.AppTop">
    <KsDialog
      :open
      :title="task.name"
      size="small"
      @close="open = false"
    >
      <template #body>
        <p v-text="t('assign.deleteConfirm', { name: task.name })" />
      </template>
      <template #footer>
        <div class="flex items-center justify-center gap-4">
          <KsButton
            variant="secondary"
            shape="rounded"
            :disabled="deleteTask.isPending.value"
            @click="open = false"
          >
            {{ t('cancel') }}
          </KsButton>
          <KsButton
            variant="primary"
            style="--ks-primary: rgb(var(--au-coral-50)); --ks-secondary: rgb(var(--au-coral-40))"
            shape="rounded"
            :disabled="deleteTask.isPending.value"
            :data-pendo="PendoFeature.TermExam.DeleteTask"
            @click="deleteTask.mutateAsync(task)"
          >
            <div class="flex gap-1">
              {{ deleteTask.isPending.value ? t('assign.deletingExam') : t('assign.deleteExam') }}
              <KsSpinner
                v-if="deleteTask.isPending.value"
                style="--ks-primary: rgb(var(--au-seagreen-30))"
                size="small"
              />
            </div>
          </KsButton>
        </div>
      </template>
    </KsDialog>
  </Teleport>
</template>
