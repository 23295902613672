<script lang="ts" setup>
import type { ChatMessage, ContentChatbot } from '~/models/Content/ContentChatbot'
import type { ClaudeModel } from '~/models/Content/BaseField'
import { computed, nextTick, ref, useTemplateRef, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { faArrowUp } from '@fortawesome/pro-regular-svg-icons'
import { KsButton, KsIcon, KsSpinner } from '@aschehoug/kloss'
import { PendoTrackName } from '~/models/Pendo'
import usePendo from '~/composables/usePendo'

const props = defineProps<{
  bot?: ContentChatbot
  isLoading: boolean
  isError: boolean
  isStreaming: boolean
  chatHistory: ChatMessage[]
  maxRecall: number
  prompt: (
    value: string,
    initial?: boolean,
    model?: ClaudeModel,
    maxTokens?: number,
    topK?: number,
    topP?: number,
    temperature?: number
  ) => Promise<void>
  autoFocus: boolean
  isInteracting: boolean
}>()

const inputValue = ref<string>('')
const interactions = ref<number>(0)
const initialized = ref<boolean>(false)

const { t } = useI18n()
const { pendoTrack, pendoFlushNow } = usePendo()

const submit = () => {
  if (!inputValue.value) return
  interactions.value++
  pendoTrack(PendoTrackName.Chat, {
    title: document.title,
    url: window.location.href,
    interactions: interactions.value,
    locationId: props.bot?.locationId,
  })

  props.prompt(
    inputValue.value,
    false,
    props?.bot?.model,
    props?.bot?.maxTokens,
    props?.bot?.topK,
    props?.bot?.topP,
    props?.bot?.temperature,
  )
  inputValue.value = ''
  pendoFlushNow()
}

const input = useTemplateRef('chatInput')

const screenReaderMessage = computed(() => props.isStreaming
  ? t('chatbot.answering')
  : t('chatbot.answered'))

watch(props, () => {
  if (props.bot && !initialized.value) {
    props.prompt(
      props.bot.prompt,
      true,
      props?.bot?.model,
      props?.bot?.maxTokens,
      props?.bot?.topK,
      props?.bot?.topP,
      props?.bot?.temperature,
    )
    initialized.value = true
  }
  if (props.isStreaming || !input.value) return
  if (!props.autoFocus && !interactions.value) return
  nextTick(() => input?.value?.focus())
}, { immediate: true })
</script>

<template>
  <section
    class="chatbot mx-auto h-full motion-safe:animate-fade"
    :aria-label="t('labels.chatbot')"
  >
    <div
      class="sr-only"
      aria-live="assertive"
      role="log"
    >
      {{ screenReaderMessage }}
    </div>
    <div
      v-if="isLoading"
      v-text="t('loading')"
    />
    <div
      v-else
      class="grid h-full min-h-0 min-w-0 grid-rows-[auto,max-content] gap-4"
    >
      <div class="min-h-0 min-w-0">
        <div class="grid auto-rows-max gap-4">
          <template
            v-for="(message, index) in chatHistory"
            :key="index"
          >
            <div
              class="message prose relative rounded-xl p-4"
              :class="[
                message.from,
                (message.from === 'assistant' ? 'mr-4 animate-fade-in-right justify-self-start rounded-bl-[2px] bg-[--theme-5,white] text-[--theme-60,black] sm:mr-8 md:mr-10' : ''),
                (message.from === 'user' ? 'ml-4 animate-fade-in-left justify-self-end rounded-br-[2px] bg-[--theme-50,#FFC483] text-[--theme-5,black] sm:ml-8 md:ml-10' : ''),
                props.autoFocus && 'prose-lg'
              ]"
            >
              <div
                v-if="message.from === 'assistant'"
                v-dompurify-html="message.message"
                :aria-label="t('chatbot.answer')"
              />
              <div
                v-else
                :aria-label="t('chatbot.message')"
                v-text="message.message"
              />
            </div>
            <div
              v-if="((chatHistory.length - 1) - index) === maxRecall"
              class="justify-self-center text-gray-40"
              v-text="t('chatbot.remember')"
            />
          </template>
        </div>
      </div>
      <div class="relative flex w-full">
        <input
          ref="chatInput"
          v-model="inputValue"
          class="w-full rounded-full bg-white py-4 pl-6 pr-14 text-[--theme-60,black] ring-[--theme-50,black] transition placeholder:text-gray-30 focus:ring disabled:cursor-not-allowed disabled:opacity-95"
          type="text"
          :placeholder="t('chatbot.placeholder')"
          :disabled="isLoading || isStreaming || isInteracting"
          @keydown.enter="submit"
        >
        <KsButton
          variant="secondary"
          shape="round"
          size="large"
          :style="{
            '--ks-input': 'var(--theme-pop, rgb(var(--au-seagreen-40)))',
            '--ks-secondary': 'var(--theme-50, rgb(var(--au-seagreen-5)))',
            '--ks-inputhover': 'var(--theme-10, rgb(var(--au-seagreen-50)))',
          }"
          class="absolute right-1 top-1/2 -translate-y-1/2"
          :disabled="!inputValue.length || isLoading || isStreaming || isInteracting"
          @click.prevent="submit"
        >
          <KsSpinner
            v-if="isStreaming || isLoading"
            size="small"
            :style="{'--ks-primary': 'var(--theme-50, rgb(var(--au-seagreen-20)))'}"
          />
          <KsIcon
            v-else
            :icon="faArrowUp"
            :bounce="isInteracting"
          />
        </KsButton>
      </div>
      <div
        v-if="isError"
        v-text="t('chatbot.error')"
      />
    </div>
  </section>
</template>

<style scoped>
.prose {
  --tw-prose-bullets: var(--theme-60, #000);
  --tw-prose-counters: var(--theme-60, #000);
  --tw-prose-bold: var(--theme-60, #000);
  --tw-prose-th-borders: var(--theme-60, #000);
  --tw-prose-td-borders: var(--theme-50, #000);
}
</style>
