import type { ComputedRef } from 'vue'
import type { ContentProductHeader } from '~/models/Content/ContentProductHeader'
import { computed, unref } from 'vue'

export default function useCardStyles(colorTheme: ComputedRef) {
  const theme = unref(colorTheme)

  const style1 = computed(() => ({
    '--card-bg': `rgb(var(${theme[5].var}))`,
    '--card-text': `rgb(var(${theme[60].var}))`,
    '--card-border': `rgb(var(${theme['pop'].var}))`,
    '--card-accent': `rgb(var(${theme[60].var}))`,
    '--card-hover': `rgb(var(${theme[5].var}))`
  }))

  const style2 = computed(() => ({
    '--card-bg': `rgb(var(${theme[5].var}))`,
    '--card-text': `rgb(var(${theme[60].var}))`,
    '--card-border': `rgb(var(${theme[60].var}))`,
    '--card-accent': `rgb(var(${theme[60].var}))`,
    '--card-hover': `rgb(var(${theme[5].var}))`
  }))

  const style3 = computed(() => ({
    '--card-bg': `rgb(var(${theme[50].var}))`,
    '--card-text': `rgb(var(${theme[10].var}))`,
    '--card-border': `rgb(var(${theme['pop'].var}))`,
    '--card-accent': `rgb(var(${theme[10].var}))`,
    '--card-hover': `rgb(var(${theme[60].var}))`
  }))

  const style4 = computed(() => ({
    '--card-bg': `rgb(var(${theme[20].var}))`,
    '--card-text': `rgb(var(${theme[60].var}))`,
    '--card-border': `rgb(var(${theme[60].var}))`,
    '--card-accent': `rgb(var(${theme[60].var}))`,
    '--card-hover': `rgb(var(${theme[10].var}))`
  }))

  const themeStyle = computed(() => ({
    '--theme-60': `rgb(var(${theme[60].var}))`,
    '--theme-50': `rgb(var(${theme[50].var}))`,
    '--theme-pop': `rgb(var(${theme['pop'].var}))`,
    '--theme-20': `rgb(var(${theme[20].var}))`,
    '--theme-10': `rgb(var(${theme[10].var}))`,
    '--theme-5': `rgb(var(${theme[5].var}))`,
    ...style2.value,
  }))

  function cardStyle(content: ContentProductHeader, index: number) {
    if (content.viewMode === 'grid') {
      return style1.value
    }

    if (content.viewMode === 'task') {
      return index % 2 ? style4.value : style3.value
    }

    return style2.value
  }

  return {
    themeStyle,
    cardStyle
  }
}
