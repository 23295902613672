import { onMounted, onUnmounted, ref } from 'vue'

export const useAppHeaderPosition = (position: 'relative'|'absolute') => {
  const appHeader = ref<HTMLElement | null>(null)
  appHeader.value = document.getElementById('app-header')


  onMounted(() => appHeader.value?.style.setProperty('position', position))

  onUnmounted(() => appHeader.value?.style.removeProperty('position'))
}
