<script setup lang="ts">
import type { TestResult } from '~/models/Assignment'
import { computed } from 'vue'
import { KsProgress } from '@aschehoug/kloss'

const props = defineProps<{
  testResult: TestResult
}>()

const shownValues = computed((): { [key: string]: {color: string; score: number}} => {
  let values = {}
  const { correct, wrong, unanswered } = props.testResult
  const result = { correct, wrong, unanswered }

  for (const [key, value] of Object.entries(result)) {
    if (value > 0) {
      values[key] = {
        color: key === 'correct' ? '#006A52' : key === 'wrong' ? '#FF6472' : '#D4D4D4',
        score: value
      }
    }
  }

  return values
})

const progressMax = computed((): number => {
  const { correct, wrong, unanswered } = props.testResult
  return Object.values({ correct, wrong, unanswered }).reduce((acc, value) => acc + value, 0)
})

const progressValues = computed((): number[] => {
  return Object.entries(shownValues.value).map(([key, value]) => {
    return value.score
  })
})

const progressColors = computed((): string[] => {
  return Object.entries(shownValues.value).map(([key, value]) => {
    return value.color
  })
})

</script>
<template>
  <div class="flex content-center">
    <KsProgress
      class="self-center"
      :max="progressMax"
      :values="progressValues"
      :colors="progressColors"
    />
  </div>
</template>
