import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useTaskStreamsStore = defineStore('taskStreams', () => {
  const subscribedTaskIds = ref(new Set<string>())

  const addTaskId = (taskId: string) => {
    subscribedTaskIds.value.add(taskId)
  }

  const hasTaskId = (taskId: string) => {
    return subscribedTaskIds.value.has(taskId)
  }

  return {
    subscribedTaskIds,
    addTaskId,
    hasTaskId,
  }
})
