<script setup lang="ts">
import type { SideBySidePage } from '~/models/Presentation/Pages/SideBySidePage'
import type { BasePageProps } from '~/models/Presentation/BasePage'
import { computed } from 'vue'
import useImage from '~/composables/useImage'
import useHiddenHeading from '~/composables/useHiddenHeading'
import ScrollBox from '~/components/utils/ScrollBox.vue'
import PresentationRichText from '~/components/PresentationPages/Components/PresentationRichText.vue'
import LottiePlayer from '~/components/media/LottiePlayer.vue'
import ImageWithCaption from '~/components/media/ImageWithCaption.vue'

const props = defineProps<BasePageProps & { page: SideBySidePage }>()
const { getSvgOrImage, getImageSizeClass } = useImage()
const { hasHeading } = useHiddenHeading()

const image = getSvgOrImage(props.page.svg, props.page.image)

const imageIsRight = computed(() => props.page.imagePlacement.includes('right'))
const imageIsLeft = computed(() => props.page.imagePlacement.includes('left'))
const imageIs30 = computed(() => props.page.imagePlacement.includes('30'))
const lottieId = computed( () => Number(props.page.lottie.destinationContentId))
</script>

<template>
  <div
    class="grid size-full grid-cols-1 grid-rows-2 md:grid-cols-2 md:grid-rows-1"
    :class="{ 'md:grid-cols-5': imageIs30 }"
    :style="{ background: page.colorPair.background.rgb, color: page.colorPair.text.rgb }"
  >
    <h1
      v-if="!hasHeading"
      class="sr-only"
      v-text="page.title"
    />
    <Transition
      v-if="imageIsLeft"
      enter-active-class="duration-1000 ease delay-600"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      appear
    >
      <div
        class="aspect-auto size-full"
        :class="{ 'col-span-2': imageIs30 }"
      >
        <LottiePlayer
          v-if="lottieId"
          :content-id="lottieId"
          :color-pair="page.colorPair"
        />
        <ImageWithCaption
          v-else-if="image"
          :image="image"
          :classes="`h-full w-full ${getImageSizeClass(page.imageSize)}`"
          :color-pair="page.colorPair"
        />
      </div>
    </Transition>
    <Transition
      enter-active-class="duration-1000 ease delay-1000"
      enter-from-class="-translate-y-[10%] transform opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      appear
    >
      <ScrollBox
        class="m-auto max-h-[calc(100%-9rem)] overflow-y-auto px-16 md:max-h-[calc(100%-11rem)]"
        :class="[page.textMaxWidth, { 'col-span-3': imageIs30 }]"
      >
        <h1
          v-if="page.header"
          class="text-pretty"
          :class="[page.textSizes.heading, page.fontFamily, page.fontWeight]"
          v-text="page.header"
        />
        <PresentationRichText
          v-if="page.body"
          :page="page"
        />
      </ScrollBox>
    </Transition>
    <Transition
      v-if="imageIsRight"
      enter-active-class="duration-1000 ease delay-600"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      appear
    >
      <div
        class="aspect-auto size-full"
        :class="{ 'col-span-2': imageIs30 }"
      >
        <LottiePlayer
          v-if="lottieId"
          :content-id="lottieId"
          :color-pair="page.colorPair"
        />
        <ImageWithCaption
          v-else-if="image"
          :image="image"
          :classes="`h-full w-full ${getImageSizeClass(page.imageSize)}`"
          :color-pair="page.colorPair"
        />
      </div>
    </Transition>
  </div>
</template>
