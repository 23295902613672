import { reactive } from 'vue'
import { defineStore } from 'pinia'

type Dialog = 'assign' | 'edit'

type Dialogs = {
  [key: string]: {
    [K in Dialog]?: boolean
  }
}

const useAssignTaskDialog = defineStore('assignTaskDialog', () => {
  const dialogs = reactive<Dialogs>({})
  const taskHasBeenClosed = reactive<{ [key: string]: boolean }>({})

  const setDialogOpen = (id: string, type: Dialog) => {
    if (!dialogs[id]) dialogs[id] = {}
    dialogs[id][type] = true
  }

  const setDialogClose = (id: string, type: Dialog) => {
    if (!dialogs[id]) return
    dialogs[id][type] = false
  }

  const isDialogOpen = (id: string, type: Dialog) => !!dialogs[id]?.[type]

  return {
    dialogs,
    setDialogOpen,
    setDialogClose,
    isDialogOpen,
    taskHasBeenClosed
  }
})

export default useAssignTaskDialog
