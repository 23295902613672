import type { SubjectCode } from '~/models/Subject'
import type { Product } from '~/models/Product'
import type { GradeCode } from '~/models/Grade'
import type { BaseItem } from '~/models/Content/BaseItem'
import { lowerPrimaryGrades, lowerSecondaryGrades } from '~/utils/gradeSorter'

export default () => {
  const isComponentView = (subject: SubjectCode, grade?: GradeCode) => [
    { subject: 'SAF', grades: lowerSecondaryGrades },
    { subject: 'SPA', grades: lowerSecondaryGrades },
    { subject: 'SAF', grades: lowerPrimaryGrades },
    { subject: 'TVE', grades: lowerPrimaryGrades },
    { subject: 'NAT', grades: lowerPrimaryGrades },
    { subject: 'MAT', grades: lowerPrimaryGrades },
    { subject: 'ENG', grades: lowerPrimaryGrades },
  ].some(({ subject: s, grades }) => subject && grade && s === subject && grades.includes(grade))

  const isUpcomingView = (product: Product, item: BaseItem) => product.aunivers.relatedLocations.upcomingContentLocationId
    ? item.pathString.includes(`/${product.aunivers.relatedLocations.upcomingContentLocationId}/`)
    : false

  return {
    isComponentView,
    isUpcomingView,
  }
}
