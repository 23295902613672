const STORAGE_KEY = 'flyt-progress-locationid'

interface StoredProgress {
  id: number | string
  progress: number
  total: number
}

function getStored() {
  const stored = JSON.parse(localStorage.getItem(STORAGE_KEY) ?? 'null')
  return new Map<StoredProgress['id'], StoredProgress>(stored || [])
}

const stored = getStored()

function setFlytProgressLocally(id: StoredProgress['id'], total: number, progress: number) {
  stored.set(id, {
    id,
    progress,
    total,
  })

  localStorage.setItem(STORAGE_KEY, JSON.stringify([...stored]))
}

function getFlytProgressLocally(locationId: number) {
  const location = stored.get(locationId)

  return location
    ? {
      ...location,
      completed: location.progress === location.total,
    }
    : undefined
}

export {
  setFlytProgressLocally,
  getFlytProgressLocally,
}
