<script setup lang="ts">
import type { BasicUser } from '~/models/User/BasicUser'
import type { FlytTestAssignment, TestAnswer } from '~/models/Assignment'
import { computed, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { Chart, ArcElement, DoughnutController } from 'chart.js'
Chart.register(ArcElement, DoughnutController)

const { t } = useI18n()

const props = defineProps<{ reportData: FlytTestAssignment[]; usersInResults: BasicUser[] }>()
const resultsModel = defineModel<{correct: number; wrong: number; unanswered: number}>()

const groupData = computed(() => {
  const dataFields = props.reportData.flatMap(assignment => {
    return assignment.responseSummary.flatMap(summary => {
      return summary.latestAnswer?.data
    })
  })

  const items: TestAnswer[] = dataFields
    .filter(answer => answer !== undefined)
    .map(answer => JSON.parse(answer))
    .flatMap(data => {
    return data.items
  })

  const groupedItems = items.reduce((acc, item) => {
    if (!acc[item.itemId]) {
      acc[item.itemId] = []
    }
    acc[item.itemId].push(item)
    return acc
  }, {} as { [key: string]: TestAnswer[] })

  let totalMissing = 0
  const desiredCount = props.usersInResults.length
  for (const group in groupedItems) {
    const count = groupedItems[group].length
    if (count < desiredCount) {
      const missing = (desiredCount - count)
      const item = groupedItems[group][0]
      totalMissing += item.score.totalQuestions * missing
    }
  }

  let correctAnswers = 0
  let wrongAnswers = 0
  let unansweredAnswers = totalMissing

  items.forEach(item => {
    correctAnswers += item.score.correctAnswers
    wrongAnswers += item.score.totalQuestions - item.score.correctAnswers
  })

  return {
    correct: correctAnswers,
    wrong: wrongAnswers,
    unanswered: unansweredAnswers,
  }
})

watch (groupData, () => {
  resultsModel.value = groupData.value
}, { immediate: true })

onMounted(() => {
  initGraph()
})

const initGraph = () => {
  const chartElement = document.getElementById('groupGraph') as HTMLCanvasElement | null
  if (!chartElement) return

  new Chart(
    chartElement,
    {
      type: 'doughnut',
      options: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false
          },
        }
      },
      data: {
        labels: [
          t('report.status.correct'),
          t('report.status.wrong'),
          t('report.status.unanswered'),
        ],
        datasets: [{
          data: Object.values(groupData.value),
          backgroundColor: [
            '#006A52',
            '#FF6472',
            '#D4D4D4'
          ],
        }]
      }
    }
  )
}
</script>

<template>
  <div>
    <div class="h-[380px] content-center">
      <canvas id="groupGraph" />
    </div>
  </div>
</template>
