<script setup lang="ts">
import type { PlannedItem } from '~/models/Content/PlannedItem'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { KsIcon } from '@aschehoug/kloss'
import { PendoFeature } from '~/models/Pendo'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import { useContentHelper } from '~/composables/useContentHelper'
import LinkOrAnchor from '~/components/utils/LinkOrAnchor.vue'
import PlannedResourceButton from '~/components/planner/PlannedResourceButton.vue'
import FileDownloadLink from '~/components/planner/FileDownloadLink.vue'
import CardMenu from '~/components/cards/buttons/CardMenu.vue'

const { t } = useI18n()
const { isUploadedFile } = useContentHelper()
const { buildResourceUrl } = useUrlBuilder()

const props = defineProps<{ resource: PlannedItem }>()

const isUploadedResource = computed(() => isUploadedFile(props.resource))
const link = computed(() => buildResourceUrl(props.resource))
const meta = computed(() => isUploadedFile(props.resource)
  ? t('contentTypes.file')
  : t('contentTypes.link'))
const tracking = computed(() => isUploadedFile(props.resource)
  ? { 'data-pendo': PendoFeature.Planner.DownloadFile }
  : {})
</script>

<template>
  <article class="relative h-full min-h-[190px] min-w-[215px] rounded-2xl bg-white text-base shadow-md motion-safe:animate-fade-in-down">
    <div
      class="flex flex-col"
      v-bind="tracking"
    >
      <div class="relative flex aspect-[3/2] h-unset max-w-full items-center justify-center overflow-hidden rounded-t-2xl bg-blue-30 text-white">
        <aside
          v-if="meta"
          role="complementary"
          class="absolute left-3 top-3 rounded-md bg-gray-50/80 bg-clip-padding px-2 py-1 text-xs font-medium text-white backdrop-blur-sm"
          v-text="meta"
        />
        <KsIcon
          :icon="isUploadedResource ? 'photo-video' : 'link'"
          :scale="7"
        />
      </div>
      <div class="p-4">
        <span class="mb-14 block overflow-hidden text-lg font-semibold leading-tight text-blue-50">
          <FileDownloadLink
            v-if="isUploadedResource"
            :file-key="link"
            :title="resource.title"
            class="line-clamp-3 outline-0 before:absolute before:inset-0 before:rounded-2xl before:transition before:hover:shadow-lg before:focus-visible:ring"
          />
          <LinkOrAnchor
            v-else
            :link="link"
            new-tab
            is-anchor
            class="line-clamp-3 outline-0 before:absolute before:inset-0 before:rounded-2xl before:transition before:hover:shadow-lg before:focus-visible:ring"
          >
            {{ resource.title }}
          </LinkOrAnchor>
        </span>
      </div>
    </div>
    <div class="absolute bottom-4 left-4 flex w-max">
      <PlannedResourceButton :resource="resource" />
    </div>
    <CardMenu
      :resource="resource"
      class="absolute bottom-4 right-4"
    />
  </article>
</template>
