<script lang="ts" setup>
import type { Toast } from '~/models/Kloss'
import type { FavoriteCreateInput, FavoriteList, UpToDateFavoriteList } from '~/models/Favorites'
import type { BaseItem } from '~/models/Content/BaseItem'
import { ref, computed, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { faHeartCirclePlus as faHeartCirclePlusSolid } from '@fortawesome/pro-solid-svg-icons'
import { faHeartCirclePlus } from '@fortawesome/pro-regular-svg-icons'
import { KsButton, KsDialog } from '@aschehoug/kloss'
import { TeleportationTarget } from '~/models/TeleportationTarget'
import { useFavorites } from '~/composables/useFavorites'
import EditFavoriteListDialog from '~/components/favorites/EditFavoriteListDialog.vue'
import DeleteFavoriteListDialog from '~/components/favorites/DeleteFavoriteListDialog.vue'
import CreateFavoriteListDialog from '~/components/favorites/CreateFavoriteListDialog.vue'
import AddToFavoriteDialog from '~/components/favorites/AddToFavoriteDialog.vue'

const props = withDefaults(defineProps<{
  item: UpToDateFavoriteList | BaseItem | null
  action: 'addTo' | 'create' | 'delete' | 'edit'
}>(), {
  item: null,
})

const emit = defineEmits<{
  update: [item: FavoriteList]
  delete: [item: FavoriteList]
  create: [item: FavoriteList]
  addToList: [{ item: FavoriteCreateInput; list: FavoriteList }]
}>()

const toast = inject('ksToast') as Toast
const { t } = useI18n()
const { addItemToList, createFavoriteList, locationIds } = useFavorites()

const open = ref<boolean>(false)
const isFavorite = computed(() => props.item && ('locationId' in props.item) && locationIds.value.includes(props.item.locationId))
const computedProps = computed(() => ({
  addTo: {
    title: t('favorite.addToList'),
    icon: isFavorite.value ? faHeartCirclePlusSolid : faHeartCirclePlus,
    dialogContent: AddToFavoriteDialog,
    activatorName: t('favorite.save'),
  },
  create: {
    title: t('favorite.createListDialogTitle'),
    icon: '',
    dialogContent: CreateFavoriteListDialog,
    activatorName: t('favorite.createListButtonName'),
  },
  delete: {
    title: t('favorite.deleteList'),
    icon: 'trash',
    dialogContent: DeleteFavoriteListDialog,
    activatorName: t('favorite.deleteList'),
  },
  edit: {
    title: t('favorite.editListDialogTitle'),
    icon: 'pencil',
    dialogContent: EditFavoriteListDialog,
    activatorName: t('favorite.editListInfo'),
  },
})[props.action])

const updateList = (item: FavoriteList) => {
  emit('update', item)
  open.value = false
}

const createList = async (item: FavoriteList) => {
  emit('create', item)
  await createFavoriteList.mutateAsync(item)
  toast.success(t('favorite.createSuccess'))
  open.value = false
}

const deleteList = (item: FavoriteList) => {
  emit('delete', item)
  open.value = false
}

const addToList = async ({ item, list }: { item: FavoriteCreateInput; list: FavoriteList }) => {
  emit('addToList', { item, list })
  await addItemToList.mutateAsync({ item, list })
  toast.success(t('favorite.addToListSuccess', { name: item.name, listName: list.name }))
  open.value = false
}
</script>


<template>
  <div class="h-full">
    <div
      class="h-full"
      @click.capture.prevent="open = true"
    >
      <slot
        name="activator"
        :icon="computedProps.icon"
      >
        <KsButton
          stretch
          :icon-left="computedProps.icon"
        >
          {{ computedProps.activatorName }}
        </KsButton>
      </slot>
    </div>
    <Teleport :to="TeleportationTarget.AppTop">
      <KsDialog
        :title="computedProps.title"
        :open="open"
        @close="open = false"
      >
        <template #body>
          <component
            :is="computedProps.dialogContent"
            :item="props.item"
            @close="open = false"
            @update="updateList"
            @create="createList"
            @delete="deleteList"
            @add-to-list="addToList"
          />
        </template>
      </KsDialog>
    </Teleport>
  </div>
</template>

