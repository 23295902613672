<script setup lang="ts">
import type { ContentSectionStandalone } from '~/models/Content/ContentSectionStandalone'
import type { ImageRelationField } from '~/models/Content/BaseField'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useQuery } from '@tanstack/vue-query'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useImage from '~/composables/useImage'
import useContentApi from '~/api/contentApi'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import LinkOrAnchor from '~/components/utils/LinkOrAnchor.vue'

const { section } = defineProps<{ section: ContentSectionStandalone }>()

const { t } = useI18n()
const { findContents } = useContentApi()
const { buildResourceUrl } = useUrlBuilder()
const { getImageSrc, getImageAltText } = useImage()

const image = computed(() => ({
  src: Number(section.image?.destinationContentId) ? getImageSrc(section.image as ImageRelationField, 'thumbnail_large') : resource.value?.thumbnail,
  alt: Number(section.image?.destinationContentId) > 0 ? getImageAltText(section.image as ImageRelationField) : ''
}))

const enabled = computed(() => Number(section.resource?.destinationContentId) > 0)

const { data: resource } = useQuery({
  queryKey: ['product_relevant_card', section.contentId],
  queryFn: async () => (await findContents({
    contentIdCriterion: [Number(section.resource?.destinationContentId)],
    mainLocationCriterion: true,
  }, 1))[0] ?? null,
  staleTime: Infinity,
  enabled,
})
</script>

<template>
  <article
    class="relative grid size-full animate-fade grid-cols-4 border-2 border-transparent bg-purple-20/10 transition"
    :class="resource && 'hover:border-purple-30'"
  >
    <div
      v-if="section.image?.destinationContentId"
      class="col-span-1 aspect-auto w-full py-3 pl-3"
    >
      <img
        class="size-full object-cover"
        :src="image.src"
        :alt="image.alt"
      >
    </div>
    <div
      class="flex h-full flex-col justify-center gap-2 p-5"
      :class="section.image?.destinationContentId ? 'col-span-3': 'col-span-full'"
    >
      <span
        class="absolute -right-2 -top-2 bg-purple-40 px-2 py-1 text-xs font-medium uppercase tracking-wide text-purple-5 shadow"
        v-text="t('labels.recommended')"
      />
      <span
        v-if="section.title"
        class="text-xl font-extrabold text-gray-60"
      >
        <LinkOrAnchor
          v-if="resource"
          class="line-clamp-3 outline-0 before:absolute before:inset-0 before:transition before:focus-visible:ring"
          :link="buildResourceUrl(resource)"
        >
          {{ section.title }}
        </LinkOrAnchor>
        <span
          v-else
          v-text="section.title"
        />
      </span>
      <RichTextRenderer
        v-if="section.description"
        :text="section.description"
        class="max-w-lg text-sm text-gray-50"
      />
    </div>
  </article>
</template>
