import { DateTime, Interval } from 'luxon'
import { useTime } from '~/composables/useTime'

export function useSchoolYear() {
  const { currentWeekNumber } = useTime()

  const schoolYearStartWeek = 31

  const currentYear: number = DateTime.local().year

  const currentYearSchoolStart: DateTime = DateTime.fromObject({
    weekYear: currentYear,
    weekNumber: schoolYearStartWeek,
    weekday: 1,
    second: 1,
  })

  const currentSchoolYearStartYear: number = DateTime.now() > currentYearSchoolStart
    ? currentYear
    : currentYear - 1

  const currentSchoolYearStart: DateTime = DateTime.fromObject({
    weekYear: currentSchoolYearStartYear,
    weekNumber: schoolYearStartWeek,
    weekday: 1,
    second: 1,
  })

  const currentSchoolYearInterval: Interval = Interval.fromDateTimes(
    currentSchoolYearStart,
    currentSchoolYearStart.plus({ years: 1 }),
  )
  if (!currentSchoolYearInterval.isValid) throw new Error('Invalid school year interval')

  const currentSchoolYearCode: string = DateTime.now() > currentYearSchoolStart
    ? `${currentYear}-${currentYear + 1}`
    : `${currentYear-1}-${currentYear}`

  const nextSchoolYearCode: string = DateTime.now() > currentYearSchoolStart
     ? `${currentYear + 1}-${currentYear + 2}`
      : `${currentYear}-${currentYear + 1}`

  const yearsInCurrentSchoolYear = currentSchoolYearCode.split('-').map((year) => parseInt(year, 10))

  const nextSchoolYear: Interval = currentSchoolYearInterval
    .mapEndpoints((endpoint: DateTime) => {
      return endpoint.plus({ years: 1 })
    })

  const previousSchoolYear: Interval = currentSchoolYearInterval
    .mapEndpoints((endpoint: DateTime) => {
      return endpoint.minus({ years: 1 })
    })

  const convertIntervalToSchoolYear = (interval: Interval): string => {
    return interval.toFormat('yyyy', { separator: '-' })
  }

  const previousSchoolYearAvailable: boolean = currentSchoolYearInterval.start!.year === currentYear
  const nextSchoolYearAvailable: boolean = currentSchoolYearInterval.end!.year === currentYear
    && DateTime.now() > DateTime.fromObject({
    year: currentSchoolYearInterval.end!.year,
    month: 3,
    day: 31,
  })

  const activeSchoolYears = (): string[] => {
    if(previousSchoolYearAvailable) {
      return [convertIntervalToSchoolYear(previousSchoolYear), convertIntervalToSchoolYear(currentSchoolYearInterval)]
    }

    if(nextSchoolYearAvailable) {
      return [convertIntervalToSchoolYear(currentSchoolYearInterval), convertIntervalToSchoolYear(nextSchoolYear)]
    }

    return [convertIntervalToSchoolYear(currentSchoolYearInterval)]
  }

  const schoolYearByWeek = (weekNumber: number): number => {
    if (weekNumber < schoolYearStartWeek) {
      return yearsInCurrentSchoolYear[1]
    }
    return yearsInCurrentSchoolYear[0]
  }

  const getIntervalFromWeekNumber = (weekNumber: number): Interval => {
    const { weekNumberToDateTime } = useTime()
    const startWeek = weekNumberToDateTime(weekNumber, schoolYearByWeek(weekNumber))
    const endWeek = weekNumberToDateTime(weekNumber, schoolYearByWeek(weekNumber)).plus({ weeks: 1 }).minus({ day: 1 })
    return Interval.fromDateTimes(startWeek, endWeek)
  }

  return {
    currentWeekNumber,
    activeSchoolYears,
    currentSchoolYearCode,
    nextSchoolYearCode,
    nextSchoolYearAvailable,
    schoolYearStartWeek,
    currentSchoolYearInterval,
    yearsInCurrentSchoolYear,
    getIntervalFromWeekNumber,
    schoolYearByWeek,
    currentYear,
  }
}

export default useSchoolYear
