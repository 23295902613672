import { useQuery } from '@tanstack/vue-query'
import { getAccessToken } from '~/api/client/oidcClient'

type FetchAndConvertToBase64 = ({ queryKey }: { queryKey: (string | number)[] }) => Promise<string>
const fetchAndConvertToBase64: FetchAndConvertToBase64 = async ({ queryKey }) => {
  const url = `${import.meta.env.VITE_CONTENT_API_URL}/download/${queryKey[0]}/${queryKey[1]}`

  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  })

  if (!response.ok) {
    throw new Error(`Failed to fetch ${url}: ${response.status} ${response.statusText}`)
  }

  // convert to base64
  const buffer = await response.arrayBuffer()
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }

  return window.btoa(binary)
}

type UseBase64FileQuery = (contentId: number, nameOfFileField: string, { enabled }: { enabled: boolean }) => ReturnType<typeof useQuery<string>>

export const useBase64FileQuery: UseBase64FileQuery = (contentId, nameOfFileField, { enabled }) => useQuery({
  queryKey: [contentId, nameOfFileField],
  queryFn: fetchAndConvertToBase64,
  enabled,
})
