<script setup lang="ts">
import type { ContentFlashcard } from '~/models/Content/ContentFlashcards'
import { computed, ref, watch } from 'vue'
import { vIntersectionObserver } from '@vueuse/components'
import { KsButton, KsIcon, KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { PendoTrackName } from '~/models/Pendo'
import { ContentType } from '~/models/Content/ContentType'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import usePendo from '~/composables/usePendo'
import { useFlashcardsQuery } from '~/composables/useFlashcardsQuery'
import FlashcardAnimation from '~/assets/lottie/Flashcard_Complete.json'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import Image from '~/components/media/Image.vue'
import Carousel from '~/components/media/Carousel.vue'
import AudioPlayer from '~/components/media/AudioPlayer.vue'
import FlashCard from '~/components/flashcards/FlashCard.vue'

const { locationId } = defineProps<{
  locationId: number
}>()

const { data, isLoading } = useFlashcardsQuery(locationId)
const { pendoTrack, pendoFlushNow } = usePendo()
const { buildAudioUrl } = useUrlBuilder()

const cards = ref<ContentFlashcard[]>([])

const hasFlashcards = computed(() => data.value?.cards.some((card) => card.bodyBack))
const mappedCards = computed(() => cards.value.map((card) => ({
  card,
  image: (card.imageFront && data.value?.images && data.value.images.get(Number(card.imageFront.destinationContentId))!) || undefined,
  audio: (card.audioFront && data.value?.audioFiles && data.value.audioFiles.get(Number(card.audioFront.destinationContentId))!) || undefined,
})))

function shuffle<T>(cards: T[]): T[] {
  const newCards = [...cards]
  const random = Array.from({ length: newCards.length }, Math.random)
  return newCards.sort((a, b) => random[newCards.indexOf(a)] > random[newCards.indexOf(b)] ? 1 : -1)
}

function updateInViewEndObserver() {
  pendoTrack(PendoTrackName.FlashcardComplete, {
    title: data.value?.location.title ?? document.title,
    url: window.location.href
  })
  pendoFlushNow()
}

watch(data, (data) => {
  if (!data?.cards) return
  cards.value = hasFlashcards.value ? shuffle(data.cards) : data.cards
}, { immediate: true })
</script>

<template>
  <KsSkeletonWrapper
    v-if="isLoading"
    style="container-type: inline-size;"
    class="grid place-items-center"
  >
    <KsSkeleton
      width="80cqw"
      height="50cqw"
    />
  </KsSkeletonWrapper>
  <Carousel
    v-else
    nav="below"
    style="--carousel-spacing: 1rem; container-type: inline-size;"
    class="grid auto-rows-max"
    :class="hasFlashcards ? 'max-h-[1000px]' : 'max-h-[600px]'"
  >
    <template
      v-if="hasFlashcards"
      #pre="{ scrollTo }"
    >
      <div class="grid aspect-[3/2] h-[53.33cqw] max-h-full place-content-center place-items-center gap-5 text-4xl">
        <h1 class="text-5xl">
          {{ data?.location.title }}
        </h1>
        <p class="text-xl">
          {{ $t('flashcards.tip') }}
        </p>
        <KsButton
          shape="rounded"
          variant="border"
          icon-right="arrow-right"
          :style="{
            '--ks-border': 'var(--theme-60,black)',
          }"
          size="large"
          @click="scrollTo(1, { focus: true })"
        >
          {{ $t('flashcards.start') }}
        </KsButton>
      </div>
    </template>

    <template #default="{ scrollTo }">
      <FlashCard
        v-for="{card, image, audio } in mappedCards"
        :key="card.contentId"
        class="relative grid aspect-[3/2] h-[53.33cqw] max-h-full place-items-center overflow-hidden rounded-md bg-[--theme-20,white] text-4xl text-[--theme-60,black] transition-opacity"
      >
        <template #front>
          <Image
            v-if="image"
            :content="image"
            class="max-h-full min-h-0 !w-full min-w-0 place-self-stretch"
            :class="image.contentTypeIdentifier === ContentType.Svg ? 'object-contain' : 'object-cover'"
          />
          <div
            class="max-w-[40ch] px-24"
            :class="{ 'sr-only': image }"
          >
            <RichTextRenderer :text="card.bodyFront ?? 'no text'" />
          </div>
        </template>

        <template
          v-if="card.bodyBack"
          #back
        >
          <RichTextRenderer :text="card.bodyBack" />
        </template>

        <template
          v-if="audio"
          #overlay
        >
          <AudioPlayer
            :media-id="audio.metadata?.contentItemId"
            :src="{
              src: buildAudioUrl(audio),
              type: audio.metadata?.mimeType ?? audio.file?.mimeType
            }"
            class="-translate-x-1/2 !rounded-full"
          >
            <template #controls>
              <media-play-button
                tabindex="-1"
                class="group grid size-32 place-content-center rounded-full bg-[--theme-5] text-3xl text-[--theme-60] shadow-lg"
              >
                <KsIcon
                  icon="play"
                  variant="solid"
                  class="hidden translate-x-[10%] group-data-[paused]:block"
                />
                <KsIcon
                  icon="pause"
                  variant="solid"
                  class="group-data-[paused]:hidden"
                />
              </media-play-button>
            </template>
          </AudioPlayer>
        </template>
      </FlashCard>

      <div
        v-if="hasFlashcards"
        v-intersection-observer="[updateInViewEndObserver, { threshold: .5 }]"
        class="flex aspect-[3/2] h-[53.33cqw] max-h-full place-content-center place-items-center gap-5 text-4xl"
      >
        <LottieAnimation
          :animation-data="FlashcardAnimation"
          width="250px"
          height="100%"
          class="self-end"
        />
        <div class="flex flex-col items-center gap-8">
          <p class="text-center text-4xl">
            {{ $t('flashcards.complete') }}
          </p>
          <KsButton
            shape="rounded"
            variant="border"
            icon-left="rotate-left"
            :style="{
              '--ks-border': 'var(--theme-60,black)',
            }"
            size="large"
            @click="scrollTo(0, { focus: true }); cards = shuffle(data?.cards ?? [])"
          >
            {{ $t('flashcards.retry') }}
          </KsButton>
        </div>
        <LottieAnimation
          :animation-data="FlashcardAnimation"
          width="250px"
          height="100%"
          class="rotate-180 self-start"
        />
      </div>
    </template>

    <template #counter="{ index }">
      {{ Math.min(data?.cards.length ?? 0, Math.max(1, index + (hasFlashcards ? 0 : 1))) }} / {{ data?.cards.length }}
    </template>
  </Carousel>
</template>
