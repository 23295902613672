<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { KsButton, KsCallout, KsSpinner } from '@aschehoug/kloss'
import useOidc from '~/composables/useOidc'

const { t } = useI18n()
const router = useRouter()
const { loginRedirect, saveRedirectUri, getRedirectUri, isLoading, hasError } = useOidc()

const redirectUri = computed(() => String(router.currentRoute.value.query?.redirectUri ?? getRedirectUri()))

onMounted(() => {
  saveRedirectUri(redirectUri.value)
  loginRedirect()
})
</script>
<template>
  <div
    v-if="isLoading"
    class="absolute inset-0 flex items-center justify-center"
  >
    <KsSpinner />
  </div>
  <div
    v-else-if="hasError"
    class="mx-1 mb-12 mt-20 rounded-xl p-6 font-inter max-md:px-4 sm:mx-4 lg:mx-auto lg:max-w-[var(--flyt-ideal-content-width-wide)]"
  >
    <KsCallout
      variant="error"
      :title="t('login.error.title')"
    >
      {{ t('login.error.unknown') }}
    </KsCallout>
    <KsButton
      variant="secondary"
      size="medium"
      href="/"
      class="mt-6"
      :icon-left="faArrowLeft"
    >
      {{ t('back') }}
    </KsButton>
  </div>
</template>
