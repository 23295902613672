import type { ContentChatbot, ChatMessage } from '~/models/Content/ContentChatbot'
import type { ClaudeModel } from '~/models/Content/BaseField'
import { computed, ref } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { ContentType } from '~/models/Content/ContentType'
import useContentApi from '~/api/contentApi'

interface UseChatbotOptions {
  locationId: number
  maxRecall?: number
}

interface StreamMessage {
  text: Nullable<string>
  messageType: Nullable<string>
}

export default (options: UseChatbotOptions) => {
  const { locationId, maxRecall } = { ...{ maxRecall: 15 }, ...options }

  const { findContents } = useContentApi()

  const chatHistory = ref<ChatMessage[]>([])
  const isStreaming = ref(false)
  const isInteracting = ref(false)
  const isError = ref(false)

  const chatContinuation = computed(() =>
    chatHistory.value.slice((Math.min(maxRecall, chatHistory.value.length) * -1))
      .map((m) => ({ [m.from]: m.message }))
  )

  const findBot = (locationId: number) => useQuery({
    staleTime: 3600,
    queryKey: ['chatbot', locationId],
    queryFn: async () => (await findContents<ContentChatbot>({
      locationIdCriterion: [locationId],
      contentTypeCriterion: [ContentType.Chatbot],
    }, 1))[0]
  })

  const { data: bot, isLoading } = findBot(locationId)

  const systemInstructions = computed(() => ({
    system: bot ? bot.value?.prompt : '',
  }))

  async function* getIterableStream<T>(body: ReadableStream<Uint8Array>): AsyncIterable<T> {
    const reader = body.getReader()
    const decoder = new TextDecoder()
    isStreaming.value = true
    while(true) {
      const { value, done } = await reader.read()
      if (done) {
        isStreaming.value = false
        break
      }
      const decoded = decoder.decode(value).split('\n')
      for (let i = 0; i < decoded.length; i++) {
        try {
          yield JSON.parse(decoded[i])
        } catch (e) {
          // skip, likely not a complete JSON object yet
        }
      }
    }
  }

  const generateStream = async <T>(value: string, model?: ClaudeModel, maxTokens?: number, topK?: number, topP?: number, temperature?: number): Promise<AsyncIterable<T>> => {
    const response = await fetch(`${import.meta.env.VITE_CORE_KI_API_URL}/queue/stream/chat`, {
      method: 'POST',
      headers: {
        'accept': 'application/x-ndjson',
        'Content-Type': 'application/json',
        'model': model ?? 'sonnet',
        'maxTokens': maxTokens?.toString() ?? '300',
        'topK': topK?.toString() ?? '100',
        'topP': topP?.toString() ?? '0.5',
        'temperature': temperature?.toString() ?? '0.2',
      },
      body: JSON.stringify([systemInstructions.value, ...chatContinuation.value, { user: value }]),
    })

    if (response.status !== 200) throw new Error(response.status.toString())
    if (!response.body) throw new Error('Response body does not exist')
    return getIterableStream<T>(response.body)
  }

  const prompt = async (value: string, initial = false, model?: ClaudeModel, maxTokens?: number, topK?: number, topP?: number, temperature?: number) => {
    if (!value) return

    isInteracting.value = true

    if(initial && Number(bot.value?.opener?.length) > 0) {
      chatHistory.value = [...chatHistory.value, { from: 'assistant', message: bot.value?.opener ?? '' }]
      isInteracting.value = false
      return
    }

    let stream: AsyncIterable<StreamMessage>

    try {
      stream = await generateStream<StreamMessage>(value, model, maxTokens, topK, topP, temperature)
    } catch (error) {
      isError.value = true
      throw error
    } finally {
      isInteracting.value = false
      isError.value = false
    }

    if (initial) {
      chatHistory.value = [...chatHistory.value, { from: 'assistant', message: '' }]
    } else {
      chatHistory.value = [...chatHistory.value, { from: 'user', message: value }, { from: 'assistant', message: '' }]
    }

    for await (const chunk of stream) {
      chatHistory.value = chatHistory.value.map((m, i) => {
        if (i === chatHistory.value.length - 1) m.message += chunk?.text ?? ''
        return m
      })
    }
  }

  return {
    bot,
    isLoading,
    isError,
    isStreaming,
    chatHistory,
    maxRecall,
    findBot,
    prompt,
    isInteracting,
  }
}
