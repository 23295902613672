<script lang="ts" setup>
import type { FavoriteList } from '~/models/Favorites'
import { reactive, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { KsButton, KsInput } from '@aschehoug/kloss'
import { PendoFeature } from '~/models/Pendo'
import { type ValidateFormResults, type IsValid, useValidation } from '~/composables/useValidation'

type FormData = {
  name: string
  note: string
}

const { t } = useI18n()
const { rules, validateForm } = useValidation()

const props = defineProps<{
  item: FavoriteList
}>()

const emit = defineEmits<{
  close: []
  update: [item: FavoriteList]
}>()

const form = reactive<FormData>({ name: props.item.name, note: props.item.description })
const validationResults = reactive<ValidateFormResults<FormData> & IsValid>({
  name: [],
  note: [],
  isValid: false,
})

const update = () => {
  if (!validationResults.isValid) return
  emit('update', { ...props.item, name: form.name, description: form.note })
}

const onNoteInput = (event: Event) => form.note = (event.target as HTMLInputElement).value

watch(form, () => validateForm(
  form,
  {
    name: [
      { rule: rules.string(), message: t('validation.favorite.name.error') },
      { rule: rules.required(), message: t('validation.general.required.error') },
    ],
    note: [
      { rule: rules.string(), message: t('validation.favorite.note.error') },
    ],
  },
  validationResults,
))
</script>

<template>
  <div class="-m-6 mt-0 grid gap-5 rounded-b-2xl bg-seagreen-5 p-6 pb-10">
    <label class="pt-4">
      <span
        class="font-semi-bold text-black"
        v-text="t('favorite.editListNameLabel')"
      />
      <KsInput
        v-model="form.name"
        v-focus
        class="!mt-2 !rounded-md !border-2 !border-solid !border-gray-20 !bg-white"
        type="text"
        shape="normal"
        @keydown.enter="update"
      />
    </label>
    <div
      v-if="validationResults.name.some(r => r.isError)"
      class="text-error"
    >
      <div
        v-for="error in validationResults.name"
        v-text="error.message"
      />
    </div>
    <KsButton
      :data-pendo="PendoFeature.Favorites.ChangeName"
      class="justify-self-end"
      variant="primary"
      shape="rounded"
      :disabled="!validationResults.isValid"
      @click="update"
    >
      {{ t('save') }}
    </KsButton>
  </div>
</template>
