<script lang="ts" setup>
import type { Component } from 'vue'
import type { Presentation } from '~/models/Presentation/Presentation'
import type { TextAndImage } from '~/models/Presentation/Pages/GridPage'
import type { PresentationPage } from '~/models/Presentation/BasePage'
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed, onBeforeMount, onBeforeUnmount, provide, ref, watch } from 'vue'
import { renderVTTCueString } from 'media-captions'
import { useQuery } from '@tanstack/vue-query'
import useArrayUtils from '~/utils/arrayUtils'
import { Subtree } from '~/models/Content/Subtree'
import { ContentType } from '~/models/Content/ContentType'
import { useContentHelper } from '~/composables/useContentHelper'
import useContentApi from '~/api/contentApi'
import TopBar from '~/components/utils/TopBar.vue'
import VideoPage from '~/components/PresentationPages/VideoPage.vue'
import ThreeStepPageComponent from '~/components/PresentationPages/ThreeStepPage.vue'
import SideBySidePageComponent from '~/components/PresentationPages/SideBySidePage.vue'
import RelatedContentPage from '~/components/PresentationPages/RelatedContentPage.vue'
import OnlyTextPageComponent from '~/components/PresentationPages/OnlyTextPage.vue'
import ImageAndMaybeTextComponent from '~/components/PresentationPages/ImageAndMaybeText.vue'
import GridPageComponent from '~/components/PresentationPages/GridPage.vue'
import PresentationPageNavigation from '~/components/PresentationPages/Components/PresentationPageNavigation.vue'
import AudioPage from '~/components/PresentationPages/AudioPage.vue'
import ArticlePage from '~/components/PresentationPages/ArticlePage.vue'

const props = defineProps<{
  pageContentIds: number[]
  pageNumber: number
  presentation: Presentation
}>()

const emit = defineEmits<{ 'update:pageNumber': [pageNumber: number]; 'replace:pageNumber': [pageNumber: number] }>()

const { truthy } = useArrayUtils()
const { isGridPage  } = useContentHelper()
const { findContents } = useContentApi()

const animationDirection = ref('')
const transcription = ref<VTTCue[]>([])
const showTranscript = ref(false)

const currentPageComponent = computed(() => pageComponents[currentPage.value.contentTypeIdentifier] || 'component')
const totalPages = computed(() => pages.value?.length ?? 0)
const currentPage = computed(() => pages.value ? pages.value[props.pageNumber - 1] : undefined)
const canGoToNextPage = computed(() => props.pageNumber < totalPages.value)
const canGoToPreviousPage = computed(() => props.pageNumber > 1)
const translateX = computed(() => animationDirection.value === 'left' ? '-translate-x-full' : 'translate-x-full')

const pageComponents: Record<string, Component> = {
  [ContentType.ThreeStepPage]: ThreeStepPageComponent,
  [ContentType.GridPage]: GridPageComponent,
  [ContentType.SideBySidePage]: SideBySidePageComponent,
  [ContentType.ImageAndMaybeText]: ImageAndMaybeTextComponent,
  [ContentType.OnlyTextPage]: OnlyTextPageComponent,
  [ContentType.RelatedContentPage]: RelatedContentPage,
  [ContentType.AudioPage]: AudioPage,
  [ContentType.VideoPage]: VideoPage,
  [ContentType.ArticlePage]: ArticlePage,
}

/**
 * Helper function to abstract away the search logic. This is slightly nicer than having separate
 * fetchResults per type you want to fetch.
 *
 * @param contentIds ContentIds to fetch
 */
const fetchContent = async <T extends BaseItem>(contentIds: number[]): Promise<T[]> => {
  if (!contentIds.length) return []
  const results = await findContents({
    contentIdCriterion: contentIds,
    subtreeCriterion: [Subtree.Content, Subtree.Media],
    mainLocationCriterion: true,
  })
  return contentIds
    .map((id) => results.find(({ contentId }) => contentId === id))
    .filter(truthy<T>)
}

const {
  data: pages,
} = useQuery({
  staleTime: Infinity,
  queryKey: computed(() => ['presentation-pages', props.presentation.locationId]),
  queryFn: () =>
    findContents<PresentationPage>({
      contentIdCriterion: props.pageContentIds,
      subtreeCriterion: [Subtree.Content, Subtree.Media],
    }, 100)
      .then((contentPages) => contentPages
        .flatMap((page) => Array(page.subPages).fill(page).map((page, index) => ({ ...page, step: index + 1 })))
        .filter((page) => Object.keys(pageComponents).includes(page.contentTypeIdentifier)) // only include pages that has page components
      )
      .then(async (pages) => {
        for (const page of pages) {
          if (isGridPage(page) && !Array.isArray(page.textAndImages)) {
            page.textAndImages = await fetchContent<TextAndImage>(page.textAndImages.destinationContentIds)
          }
        }
        return pages
      })
      .then((contentPages) => contentPages.sort((a, b) => props.pageContentIds.indexOf(a.contentId) - props.pageContentIds.indexOf(b.contentId)))
})

const goToPage = (pageNumber: number, direction: string) => {
  if (!canGoToNextPage.value && pageNumber > totalPages.value) return
  if (!canGoToPreviousPage.value && pageNumber < 1) return
  animationDirection.value = direction

  emit('update:pageNumber', pageNumber)
}

const keyboardNavigation = (event: KeyboardEvent) => {
  if (event.altKey || event.ctrlKey || event.shiftKey || event.metaKey) return
  if (event.key === 'ArrowRight') goToPage(props.pageNumber + 1, 'right')
  if (event.key === 'ArrowLeft') goToPage(props.pageNumber - 1, 'left')
}

onBeforeMount(() => window.addEventListener('keydown', keyboardNavigation))
onBeforeUnmount(() => window.removeEventListener('keydown', keyboardNavigation))

provide('onEmbedTranscription', (cues: VTTCue[]) => {
  transcription.value = cues
})

provide('onEmbedShowTranscription', (value: boolean) => {
  showTranscript.value = value
})

watch(currentPage, () => {
  transcription.value = []
  showTranscript.value = false
})

watch(pages, () => {
  if (!pages.value) return

  if (props.pageNumber > pages.value.length) emit('replace:pageNumber', pages.value.length)
  if (props.pageNumber < 1) emit('replace:pageNumber', 1)
}, { immediate: true })

</script>
<template>
  <div
    v-if="currentPage"
    class="h-screen w-screen overflow-hidden font-inter transition-colors duration-1000"
    :style="{
      background: currentPage.colorPair.background.rgb,
      '--media-color': currentPage.colorPair.background.rgb,
      '--media-bg-color': currentPage.colorPair.text.rgb,
    }"
  >
    <TopBar
      variant="colorPair"
      :item="presentation"
      :color-pair="currentPage.colorPair"
    />
    <Transition
      enter-active-class="duration-[900ms] ease-out delay-200"
      :enter-from-class="translateX"
      enter-to-class="translate-x-0"
      leave-active-class="duration-200 ease-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
      appear
    >
      <component
        :is="currentPageComponent"
        v-show="!showTranscript"
        :key="currentPage.contentId"
        v-model:show-transcript="showTranscript"
        :inert="showTranscript"
        :page="currentPage"
        :current-page-number="pageNumber"
        :total-pages="totalPages"
        :animation-direction="animationDirection"
      />
    </Transition>
    <div
      v-if="showTranscript"
      class="grid h-full auto-rows-max grid-cols-[minmax(0,80ch)] place-content-center overflow-auto px-16 py-48 lg:px-36 2xl:px-48"
      :style="{ color: currentPage.colorPair.text.rgb }"
    >
      <span class="pb-2 text-sm tracking-wider underline decoration-2 underline-offset-8">{{ $t('labels.audio_transcription') }}</span>
      <h1 class="my-4 text-pretty text-4xl font-bold">
        {{ currentPage.title }}
      </h1>
      <div class="flex flex-col gap-1 text-2xl">
        <p
          v-for="cue in transcription"
          :key="cue.id"
          v-html="renderVTTCueString(cue)"
        />
      </div>
    </div>
    <PresentationPageNavigation
      :page-number="pageNumber"
      :pages="pages || []"
      :presentation="presentation"
      @next-page="goToPage(pageNumber + 1, 'right')"
      @prev-page="goToPage(pageNumber - 1, 'left')"
    />
  </div>
</template>
