<script setup lang="ts">
import { type Component, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { useSectionStore } from '~/stores/section'
import { useAuthStore } from '~/stores/auth'
import { GradeType } from '~/models/GradeType'
import { ContentType } from '~/models/Content/ContentType'
import useSectionPath from '~/composables/useSectionPath'
import UpperPrimaryBannerArticle from '~/components/home/upperPrimary/UpperPrimaryBannerArticle.vue'
import LowerSecondaryBannerArticle from '~/components/home/lowerSecondary/LowerSecondaryBannerArticle.vue'

const sectionStore = useSectionStore()
const { isLoading, userSections } = storeToRefs(sectionStore)
const { loadSections } = sectionStore
const { homePath } = useSectionPath()
const { selectedGradeType, selectedGradeTypeGrades } = storeToRefs(useAuthStore())

void loadSections(homePath)

const limit = 2

const gradeTypeComponentMap = new Map<GradeType, Component>([
  [GradeType.LowerPrimary, UpperPrimaryBannerArticle],
  [GradeType.UpperPrimary, UpperPrimaryBannerArticle],
  [GradeType.LowerSecondary, LowerSecondaryBannerArticle],
])

const shownSections = computed(() => [...userSections.value]
  .filter((section) => section.contentTypeIdentifier === ContentType.SectionStandalone)
  .filter((section) => section.grades.some((grade) => selectedGradeTypeGrades.value.includes(grade)))
  .sort((a, b) => (new Date(a.publishedDate)).getTime() - (new Date(b.publishedDate)).getTime())
  .reverse()
  .slice(0, limit)
)

const sectionCount = computed(() => Math.min(shownSections.value.length, 2))

const articleType = computed(() => {
  if (!selectedGradeType.value) return
  return gradeTypeComponentMap.get(selectedGradeType.value)
})
</script>

<template>
  <div
    v-if="articleType"
    class="w-full"
  >
    <div
      v-if="!isLoading && sectionCount > 0"
      class="mb-16 mt-8 grid gap-x-4 gap-y-12"
      :class="sectionCount === 1 ? 'grid-cols-1' : 'grid-cols-1 md:grid-cols-2 auto-rows-fr'"
    >
      <component
        :is="articleType"
        v-for="section in shownSections"
        :key="section.locationId"
        :section="section"
      />
    </div>

    <KsSkeletonWrapper
      v-if="isLoading"
      class="mb-16 mt-8 grid w-full grid-cols-1 gap-x-4 gap-y-12 md:grid-cols-2"
    >
      <KsSkeleton
        v-for="n in 2"
        :key="n"
        height="250px"
        width="100%"
        background="rgb(var(--au-seagreen-40))"
      />
    </KsSkeletonWrapper>
  </div>
</template>
