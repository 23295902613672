<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import useAuthStore from '~/stores/auth'
import { useAppColor } from '~/composables/useAppColor'
import SubscriptionBox from '~/components/home/SubscriptionBox.vue'
import LowerSecondarySubjects from '~/components/home/lowerSecondary/LowerSecondarySubjects.vue'
import DefaultMessages from '~/components/home/DefaultMessages.vue'
import DefaultGreetings from '~/components/home/DefaultGreetings.vue'
import BannerArticles from '~/components/home/BannerArticles.vue'

const { t } = useI18n()
const { user, isTeacher } = storeToRefs(useAuthStore())
const { themeColor } = useAppColor()
</script>

<template>
  <DefaultMessages class="-mb-24 mt-24 w-full" />
  <section :class="`bg-${themeColor?.name} text-white`">
    <div class="relative z-20 mx-auto flex size-full max-w-screen-au flex-wrap items-center gap-14 text-clip px-4 pb-10 pt-40 transition-all xs:pb-14 sm:px-8 sm:pt-32">
      <div class="mx-auto w-full space-y-4 md:max-w-[70%] lg:pr-6">
        <div class="flex flex-wrap gap-8">
          <SubscriptionBox v-if="isTeacher" />
          <div class="content-center">
            <h1
              class="sr-only"
              v-text="t('home.title')"
            />
            <DefaultGreetings
              v-if="user"
              :name="user.firstName"
            />
          </div>
        </div>
        <LowerSecondarySubjects />
      </div>
      <BannerArticles class="w-full" />
    </div>
  </section>
</template>
