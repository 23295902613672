<script lang="ts" setup>
import type { ColorPair } from '~/models/Presentation/BasePage'
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useFullscreen } from '@vueuse/core'
import { KsButton } from '@aschehoug/kloss'
import useAuthStore from '~/stores/auth'
import { PendoFeature } from '~/models/Pendo'
import usePendo from '~/composables/usePendo'
import FullscreenButton from '~/components/utils/FullscreenButton.vue'
import CloseButton from '~/components/utils/CloseButton.vue'
import FavoriteDialog from '~/components/favorites/FavoriteDialog.vue'

const { viewsWithTrackingEnabled } = usePendo()
const { isAuthenticated, isTeacher } = storeToRefs(useAuthStore())
const { isFullscreen } = useFullscreen()
const router = useRouter()

const {
  variant = 'default',
  item,
  colorPair,
  favorite = true,
  fullscreen = true,
} = defineProps<{
  variant?: 'default' | 'colorPair'
  item?: BaseItem
  colorPair?: ColorPair
  favorite?: boolean
  fullscreen?: boolean
}>()

const defaultStyles = {
  '--ks-input': 'rgb(var(--au-white))',
  '--ks-inputhover': 'rgb(var(--au-gray-50))',
}

const colorPairStyles = computed(() => ({
  '--ks-input': colorPair?.background.rgb,
  '--ks-secondary': colorPair?.text.rgb,
  '--ks-inputhover': colorPair?.text.rgb,
}))

const buttonConfig = computed(() => variant === 'colorPair' ? colorPairStyles.value : defaultStyles)
const borderColor = computed(() => variant === 'colorPair' ? colorPair?.text.rgb : 'rgb(var(--au-gray-50))')
const textHoverColor = computed(() => variant === 'colorPair' ? colorPair?.background.rgb : 'rgb(var(--au-white))')

const showFavoriteButton = computed(() => favorite && !(router.currentRoute.value.params.taskId && !isTeacher.value))

let fullscreenPendo = ''
let closePendo  = ''

onMounted(() => {
  if(item?.contentTypeIdentifier && viewsWithTrackingEnabled.includes(item?.contentTypeIdentifier)) {
    fullscreenPendo = PendoFeature.Presentation.Fullscreen
    closePendo = PendoFeature.Presentation.Exit
  }
})
</script>

<template>
  <div
    class="pointer-events-none fixed inset-x-0 top-0 z-10 grid grid-cols-2 p-4 *:pointer-events-auto"
    :style="buttonConfig"
  >
    <div class="max-w-fit">
      <FavoriteDialog
        v-if="!isFullscreen && showFavoriteButton && item"
        action="addTo"
        :item
      >
        <template #activator="{ icon }">
          <KsButton
            :icon-left="icon"
            variant="secondary"
            shape="round"
            size="large"
          />
        </template>
      </FavoriteDialog>
    </div>

    <div class="flex items-center gap-2 place-self-end">
      <FullscreenButton
        v-if="fullscreen"
        :data-pendo="fullscreenPendo"
        :disabled="!isAuthenticated"
      />
      <CloseButton
        :data-pendo="closePendo"
        :disabled="!isAuthenticated"
        :item
      />
    </div>
  </div>
</template>

<style scoped>
:deep(.ks-button) {
  border: 2px solid v-bind(borderColor) !important;
  &:hover { color: v-bind(textHoverColor); }
}
</style>
