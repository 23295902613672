<script setup lang="ts">
import type { ArticlePage } from '~/models/Presentation/Pages/ArticlePage'
import type { BasePageProps } from '~/models/Presentation/BasePage'
import type { ArticleContent } from '~/models/Content/ArticleContent'
import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import slugify from 'slugify'
import { useQuery } from '@tanstack/vue-query'
import { KsSkeletonWrapper, KsSkeleton } from '@aschehoug/kloss'
import { firstOf } from '~/utils/queryUtils'
import useText from '~/composables/useText'
import { useMedia } from '~/composables/useMedia'
import useContentApi from '~/api/contentApi'
import ScrollBox from '~/components/utils/ScrollBox.vue'
import PresentationRichText from '~/components/PresentationPages/Components/PresentationRichText.vue'
import ImageWithCaption from '~/components/media/ImageWithCaption.vue'
import Image from '~/components/media/Image.vue'

const props = defineProps<BasePageProps & { page: ArticlePage }>()
const router = useRouter()
const route = useRoute()
const { findContents } = useContentApi()
const { getTextSizes } = useText()

const { isLoading, data: articles } = useQuery({
  enabled: Number(props.page.content?.destinationContentIds?.length) > 0,
  queryKey: ['article-page', props.page.locationId],
  queryFn() {
    if (!props.page.content?.destinationContentIds) return []
    return findContents<ArticleContent>({ contentIdCriterion: props.page.content.destinationContentIds })
  },
  staleTime: Infinity,
})

const levels = computed(() => {
  const contentIds = props.page.content?.destinationContentIds ?? []
  const levels = articles.value?.filter((a) => a.difficulty) ?? []
  return levels.sort((a, b) => contentIds.indexOf(a.contentId) - contentIds.indexOf(b.contentId))
})

const activeLevel = ref(levels.value[0])

const { data: media } = firstOf(useMedia([Number(props.page.image?.destinationContentId)]))
const imageIsRight = computed(() => props.page.imagePlacement.includes('right'))
const imageIsLeft = computed(() => props.page.imagePlacement.includes('left'))
const imageIs30 = computed(() => props.page.imagePlacement.includes('30'))

const pageWithSizes = computed(() => {
  const fontSize = activeLevel.value?.fontSize ?? props.page.fontSize
  return {
    ...props.page,
    textSizes: fontSize ? getTextSizes(fontSize) : props.page.textSizes
  }
})

watch(levels, (levels) => {
  if (!activeLevel.value) {
    activeLevel.value = levels.find(({ difficulty }) => difficulty && slugify(difficulty) === route.hash.slice(1)) ?? levels[0]
  }
})

watch(activeLevel, (level) => {
  if (level.difficulty) {
    router.replace({ hash: `#${slugify(level.difficulty)}` })
  }
})
</script>
<template>
  <div
    class="grid h-full md:grid-cols-[--cols] md:grid-rows-1"
    :style="{
      background: page.colorPair.background.rgb,
      color: page.colorPair.text.rgb,
      '--cols': `${imageIsLeft && imageIs30 ? '30%' : '1fr'} ${imageIsRight && imageIs30 ? '30%' : '1fr'}`,
    }"
  >
    <ImageWithCaption
      v-if="media && page.image"
      :color-pair="page.colorPair"
      :copyright="('copyright' in media && media.copyright) || ''"
      :caption="('caption' in media && media.caption) || ''"
      :class="{ 'md:order-last': imageIsRight }"
    >
      <Image
        :content="Number(page.image.destinationContentId)"
        width="50vw"
        class="object-cover md:!h-full"
      />
    </ImageWithCaption>

    <div
      class="m-auto flex size-full max-h-[calc(100%-9rem)] flex-col gap-2 justify-self-center md:max-h-[calc(100%-11rem)] md:overflow-auto"
      :class="page.textMaxWidth"
    >
      <KsSkeletonWrapper v-if="isLoading">
        <KsSkeleton height="4rem" />
        <KsSkeleton
          height="2rem"
          width="80%"
        />
        <KsSkeleton
          height="2rem"
          width="70%"
        />
        <KsSkeleton
          height="2rem"
          width="75%"
        />
      </KsSkeletonWrapper>

      <template v-else-if="levels.length > 0">
        <nav
          class="flex flex-wrap gap-4 px-8 sm:px-12"
          :style="{ background: page.colorPair.background.rgb}"
        >
          <button
            v-for="level in levels"
            :key="level.locationId"
            class="inline-block rounded border-2 bg-[--bg] px-2 py-1 font-inter text-sm font-medium text-[--text] transition-all hover:bg-[--hover-bg] hover:text-[--hover-text] focus-visible:ring md:px-5 xl:text-base"
            :style="activeLevel === level
              ? {
                borderColor: page.colorPair.text.rgb,
                '--bg': page.colorPair.text.rgb,
                '--text': page.colorPair.background.rgb,
                '--hover-bg': page.colorPair.text.rgb,
                '--hover-text': page.colorPair.background.rgb,
              }
              : {
                borderColor: page.colorPair.text.rgb,
                '--bg': 'transparent',
                '--text': page.colorPair.text.rgb,
                '--hover-bg': page.colorPair.text.rgb,
                '--hover-text': page.colorPair.background.rgb,
              }"
            @click="activeLevel = level"
            v-text="level.difficulty"
          />
        </nav>
        <ScrollBox
          v-if="activeLevel"
          class="my-auto p-8 sm:px-12"
        >
          <PresentationRichText
            :page="pageWithSizes"
            :text="activeLevel.body"
          >
            <template #header>
              <h1
                class="text-pretty"
                :class="[pageWithSizes.textSizes.heading, pageWithSizes.fontWeight, pageWithSizes.fontFamily]"
                :style="{ color: pageWithSizes.colorPair.text.rgb }"
                v-text="activeLevel.title"
              />
            </template>
          </PresentationRichText>
        </ScrollBox>
      </template>
      <ScrollBox
        v-else
        class=" p-8 sm:px-12"
      >
        <article>
          <h1
            class="text-pretty"
            :class="[pageWithSizes.textSizes.heading, pageWithSizes.fontWeight, pageWithSizes.fontFamily]"
            v-text="pageWithSizes.title"
          />

          <PresentationRichText
            v-if="pageWithSizes.body"
            :page="pageWithSizes"
          />
        </article>
      </ScrollBox>
    </div>
  </div>
</template>
