<script setup lang="ts">
import type { Group, GroupMember } from '~/models/Group'
import { GroupRole } from '~/models/Group'
import type { YearPlan } from '~/models/Planner/YearPlan'
import { UserRole } from '~/models/User/UserRole'
import { computed, inject, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { KsButton, KsCallout, KsDialog, KsExpand, KsExpandItem, KsSpinner } from '@aschehoug/kloss'
import { faPenToSquare } from "@fortawesome/pro-regular-svg-icons";
import { Settings } from 'luxon'
import { useI18n } from 'vue-i18n'
import useGroupsStore from '~/stores/groups'
import usePlannerStore from '~/stores/planner'
import { usePlannerApi } from '~/api/plannerApi'

const props = defineProps<{
  group: Group
  groupMembers: GroupMember[]
}>()

const emit = defineEmits(['deleteGroup', 'changeView', 'copyGroup'])

const deleteDialogOpen = ref(false)

const { t } = useI18n()
const ksToast = <Toast>inject('ksToast')
const disjunction = new Intl.ListFormat(Settings.defaultLocale, { type: 'disjunction' })
const groupsStore = useGroupsStore()
const { isAdminInGroup, removeGroup, sortMembers } = groupsStore
const { isLoading } = storeToRefs(groupsStore)
const { yearPlans } = storeToRefs(usePlannerStore())
const { updateYearPlansByGroupId } = usePlannerApi()

const isAdmin = computed(() => isAdminInGroup(props.group))
const membersTitle = computed(() => t('groups.membersCount', { count: props.groupMembers.length }))

const groupMembersSorted = computed(() => {
  const teachers = props.groupMembers.filter(({ role }) => role === UserRole.Teacher).sort(sortMembers)
  const students = props.groupMembers.filter(({ role }) => role !== UserRole.Teacher).sort(sortMembers)
  return [teachers, students].flatMap((member) => member)
})

const adminNames = computed(() => disjunction.format(props.groupMembers
  .filter(({ groupRole }) => groupRole === GroupRole.Admin)
  .map(({ firstName }) => firstName))
)
const nonAdminText = computed(() => adminNames.value.length > 0
  ? t('groups.nonAdminContact', { admins: adminNames.value })
  : t('groups.nonAdminGroup')
)
const details = computed(() => {
  const details = []
  if (props.group.grade) details.push(t(`metadata.grades.${props.group.grade}`))
  if ((props.group?.subjects || []).length) details.push(t(`metadata.subjects.${props.group.subjects[0]}`))
  return details.join(', ')
})

const removeGroupFromYearPlans = (group: Group): void => {
  yearPlans.value.forEach((yearPlan: YearPlan) => {
    if (!yearPlan.groups) return
    if (yearPlan.groups.find(({ groupId }: Group) => groupId === group.groupId )) {
      yearPlan.groups.forEach(({ groupId }: Group, index: number) => {
        if (yearPlan.groups && groupId === group.groupId) { yearPlan.groups.splice(index, 1) }
      })
    }})
}

async function deleteGroup() {
  const { group } = props
  try {
    await removeGroup(group)
    updateYearPlansByGroupId(group.groupId)
    removeGroupFromYearPlans(group)
    ksToast.success(t('groups.groupDeleted'))
  } catch (error) {
    ksToast.error(t('groups.groupDeleteError'))
    throw error
  } finally {
    emit('deleteGroup')
  }
}
</script>

<template>
  <div class="flex animate-fade flex-col gap-6 border-t border-gray-10 py-6">
    <KsCallout
      v-if="!isAdmin"
      variant="info"
      :dismissable="false"
      class="pt-6"
    >
      {{ nonAdminText }}
    </KsCallout>
    <div class="flex items-center justify-between">
      <div>
        <h2
          class="text-xl font-medium"
          v-text="props.group.name"
        />
        <p
          v-if="details"
          class="text-gray-40"
          v-text="details"
        />
        <p
          v-else
          v-text="t('groups.noGradesConnected')"
        />
      </div>
    </div>
    <div
      v-if="isLoading"
      class="flex justify-center"
    >
      <KsSpinner />
    </div>
    <KsExpand
      v-if="!isLoading"
      size="small"
    >
      <KsExpandItem :title="membersTitle">
        <template #content>
          <ul class="space-y-2">
            <li
              v-for="member in groupMembersSorted"
              :key="`${props.group.groupId}-${member.userId}`"
              class="font-medium"
            >
              <p class="flex items-center gap-3">
                {{ member.fullName }}
                <span
                  v-if="member.groupRole === GroupRole.Admin"
                  class="rounded-lg bg-blue-30 px-2 py-1 text-xs font-bold uppercase tracking-widest text-white"
                  v-text="t('groups.admin')"
                />
              </p>
            </li>
          </ul>
        </template>
      </KsExpandItem>
    </KsExpand>
    <div
      v-if="!isLoading"
      class="mt-2 flex flex-wrap items-center gap-4"
    >
      <KsButton
        variant="secondary"
        :icon-left="faPenToSquare"
        shape="rounded"
        :disabled="!isAdmin"
        @click="emit('changeView')"
      >
        {{ t('groups.editGroup') }}
      </KsButton>
      <KsButton
        variant="secondary"
        icon-left="copy"
        shape="rounded"
        @click="emit('copyGroup')"
      >
        {{ t('groups.copyGroup') }}
      </KsButton>
      <KsButton
        variant="tertiary"
        shape="rounded"
        icon-left="trash"
        :disabled="!isAdmin"
        @click="deleteDialogOpen = true"
      >
        {{ t('groups.deleteGroup') }}
      </KsButton>
      <Teleport to=".app-top">
        <KsDialog
          :title="t('groups.deleteGroup')"
          :open="deleteDialogOpen"
          @close="deleteDialogOpen = false"
        >
          <template #body>
            <p v-text="t('groups.groupDeleteConfirm')" />
          </template>
          <template #footer>
            <div class="flex items-center justify-end gap-2">
              <KsButton
                variant="tertiary"
                shape="rounded"
                @click="deleteDialogOpen = !deleteDialogOpen"
              >
                {{ t('groups.cancel') }}
              </KsButton>
              <KsButton
                variant="primary"
                shape="rounded"
                @click="deleteGroup"
              >
                {{ t('groups.deleteGroup') }}
              </KsButton>
            </div>
          </template>
        </KsDialog>
      </Teleport>
    </div>
  </div>
</template>
