<script setup lang="ts">
import type { Product } from '~/models/Product'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { KsDropdown } from '@aschehoug/kloss'
import useSubjectsStore from '~/stores/subjects'
import useProductStore from '~/stores/product'
import useFilterStore from '~/stores/filter'

const filterStore = useFilterStore()
const { productsWithRelatedLocations } = storeToRefs(useProductStore())
const { setSelectedProduct } = filterStore
const { selectedGrade, selectedProduct } = storeToRefs(filterStore)
const { currentSubject } = storeToRefs(useSubjectsStore())

const filteredProducts = computed(() => {
  const relevantProducts = productsWithRelatedLocations.value
    .filter((product) =>
      currentSubject.value
      && selectedGrade.value
      && product.subjects.includes(currentSubject.value)
      && product.grades.includes(selectedGrade.value))

  if (selectedProduct.value && !relevantProducts.includes(selectedProduct.value)) {
    relevantProducts.push(selectedProduct.value)
  }
  return relevantProducts
})

const selectOrDeselect = (product: Product) => {
  if (product.ean === selectedProduct.value?.ean) {
    selectedProduct.value = undefined
    setSelectedProduct(undefined)
    return
  }
  selectedProduct.value = product
  setSelectedProduct(product)
}

</script>

<template>
  <KsDropdown
    v-if="productsWithRelatedLocations.length > 1"
    :model-value="selectedProduct"
    :options="filteredProducts"
    :option-id="(product: Product) => product.ean"
    :option-label="(product: Product) => product.name"
    :placeholder="$t('filters.chooseProduct')"
    shape="rounded"
    autolabel
    flexible
    resettable
    @select="(product) => selectOrDeselect(product)"
    @reset="setSelectedProduct(undefined)"
  />
</template>
