<script setup lang="ts">
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { KsSpinner } from '@aschehoug/kloss'
import useOidc from '~/composables/useOidc'

const router = useRouter()
const { vippsRedirect, redirectUri, getRedirectUri, saveRedirectUri } = useOidc()

redirectUri.value = String(router.currentRoute.value.query?.redirectUri) || getRedirectUri()

onMounted(() => {
  saveRedirectUri(redirectUri.value)
  vippsRedirect()
})
</script>
<template>
  <div class="absolute inset-0 flex items-center justify-center">
    <KsSpinner />
  </div>
</template>
