<script setup lang="ts">
import type { ContentProductPackage } from '~/models/Content/ContentProductPackage'
import type { ContentProductHeader } from '~/models/Content/ContentProductHeader'
import { computed } from 'vue'
import { RouterLink } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useQuery } from '@tanstack/vue-query'
import { KsButton } from '@aschehoug/kloss'
import { ContentType } from '~/models/Content/ContentType'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useContentApi from '~/api/contentApi'

const { selectedHeader } = defineProps<{
  selectedHeader: ContentProductHeader|ContentProductPackage
}>()

const { t } = useI18n()
const { findContents } = useContentApi()
const { buildResourceUrl } = useUrlBuilder()

const { data: teacherArticle } = useQuery({
  enabled: computed(() => Number(selectedHeader.teacherGuide?.destinationContentId) > 0),
  queryKey: ['teacher-article', Number(selectedHeader.teacherGuide?.destinationContentId)],
  staleTime: Infinity,
  queryFn: () => findContents({
    contentIdCriterion: [Number(selectedHeader?.teacherGuide?.destinationContentId)],
    contentTypeCriterion: [ContentType.TeacherArticle],
  }, 1).then(([article]) => article)
})

const teacherArticleLink = computed(() => teacherArticle.value
  ? buildResourceUrl(teacherArticle.value) : '')
</script>

<template>
  <KsButton
    :is="RouterLink"
    v-if="selectedHeader && teacherArticleLink"
    :to="teacherArticleLink"
    variant="secondary"
    size="medium"
    shape="rounded"
    :style="{
      '--ks-input': 'rgb(var(--au-orange-20))',
      '--ks-secondary': 'rgb(var(--au-coral-60))',
      '--ks-inputhover': 'rgb(var(--au-orange-10))',
      'border': '1px solid rgb(var(--au-coral-60))',
    }"
  >
    {{ t('header.teacherGuide.teacherGuide') }}
  </KsButton>
</template>
