<script setup lang="ts">
import type { GradeCode } from '~/models/Grade'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { KsCallout, KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import arrayUtils from '~/utils/arrayUtils'
import { useAssignTask } from '~/composables/useAssignTask'
import AssignedTaskCard from '~/components/cards/AssignedTaskCard.vue'

const { selectedGrades } = defineProps<{ selectedGrades: GradeCode[] }>()

const { t } = useI18n()
const { tasks, isLoading, isError } = useAssignTask()
const { intersect } = arrayUtils()

const filteredTasks = computed(() => (tasks?.value ?? []).filter((task) => !task.grades
  || !selectedGrades.length
  || intersect(selectedGrades, task.grades).length > 0))
</script>

<template>
  <section class="space-y-2">
    <h2
      class="text-2xl font-bold"
      v-text="t('assign.planned')"
    />
    <KsSkeletonWrapper v-if="isLoading">
      <KsSkeleton height="70px" />
      <KsSkeleton height="70px" />
      <KsSkeleton height="70px" />
    </KsSkeletonWrapper>
    <div
      v-else-if="isError"
      v-text="t('assign.error')"
    />
    <div v-else-if="!filteredTasks?.length">
      <KsCallout
        variant="info"
        :title="t('assign.noTasks')"
      >
        <p class="flex flex-col space-y-2">
          <span v-text="t('assign.noTasksInfo1')" />
          <span v-text="t('assign.noTasksInfo2')" />
        </p>
      </KsCallout>
    </div>
    <ul
      v-else
      class="grid grid-cols-1 gap-2"
    >
      <AssignedTaskCard
        v-for="task in filteredTasks"
        :key="`task-${task.taskId}`"
        :task
      />
    </ul>
  </section>
</template>
