import type { Plugin } from '~/models/Plugin'
import FlytTask from '~/components/flyt/FlytTask.vue'
import EmbedVideo from '~/components/embeds/EmbedVideo.vue'
import EmbedImage from '~/components/embeds/EmbedImage.vue'
import EmbedFlashcardDeck from '~/components/embeds/EmbedFlashcardDeck.vue'
import EmbedFlashcard from '~/components/embeds/EmbedFlashcard.vue'
import EmbedAudio from '~/components/embeds/EmbedAudio.vue'

export const install: Plugin['fn'] = (app) => {
  app.component('FlytTask', FlytTask)
  app.component('EmbedVideo', EmbedVideo)
  app.component('EmbedAudio', EmbedAudio)
  app.component('EmbedImage', EmbedImage)
  app.component('EmbedFlashcardDeck', EmbedFlashcardDeck)
  app.component('EmbedFlashcard', EmbedFlashcard)
}
