import { type Color, ColorName } from '~/models/Content/Color'
import useText from '~/composables/useText'

export function colorIsLight(color: string) {
  let r, g, b: number
  if (color.charAt(0) === '#') {
    const colorHex = +('0x' + color.slice(1))

    if (color.length < 5) {
      colorHex.toString().replace(/./g, '$&$&')
    }

    r = colorHex >> 16
    g = colorHex >> 8 & 255
    b = colorHex & 255
  } else {
    const splitColor = color.split(' ')
    r = Number(splitColor[0])
    g = Number(splitColor[1])
    b = Number(splitColor[2])
  }

  const hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b),
  )

  return (hsp > 127.5)
}

// Generated subset of css custom props
export const colors: string[] = (() => {
  const ret: string[] = []
    const colorList = ['yellow', 'orange', 'coral', 'violet', 'purple', 'blue', 'seagreen', 'green']
    colorList.forEach((color) => {
    [5, 10, 20, 'pop'].forEach((shade) => {
        ret.push(`--au-${color}-${shade}`)
    })
  })
  return ret
})()

// Returns a color from the given list, indexed by the given id property
export const getColorForId = (_id: string | number, colors: string[]) => {
  const id = String(_id)
  let hash = 0
  for (let i = 0; i < id.length; i++) {
    const char = id.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash |= 0 // Convert to 32bit integer
  }
  // Use the absolute value of the hash to ensure a positive index
  // Use modulo to loop back within the range of the color array length
  const index = Math.abs(hash) % colors.length
  return colors[index]
}

export const getColorAndShade = (color: string, shade: string) => {
  const { capitalize } = useText()

  const colorParts = color.split('-')
  const capitalizedColor = capitalize(colorParts[0])
  return ColorName[`${capitalizedColor}${shade}` as keyof typeof ColorName]
}

export const forceColorShade = (color: string, shade: '5' | '10' | '20' | '30' | 'pop') =>
  color.replace(/-(\d+|pop)(?!.*-(\d+|pop))/, `-${shade}`)

export const popThisColor = (color: string): string => forceColorShade(color, 'pop')

export const colorMap = new Map<ColorName, Color>([
  [ColorName.White, {
    name: ColorName.White,
    rgb: 'rgb(255 255 255)',
    var: '--au-white',
  }],
  [ColorName.Black, {
    name: ColorName.Black,
    rgb: 'rgb(33 36 39)',
    var: '--au-black',
  }],
  [ColorName.Yellow5, {
    name: ColorName.Yellow5,
    rgb: 'rgb(255 251 236)',
    var: '--au-yellow-5',
  }],
  [ColorName.Yellow10, {
    name: ColorName.Yellow10,
    rgb: 'rgb(255 248 225)',
    var: '--au-yellow-10',
  }],
  [ColorName.Yellow20, {
    name: ColorName.Yellow20,
    rgb: 'rgb(255 243 199)',
    var: '--au-yellow-20',
  }],
  [ColorName.Yellow30, {
    name: ColorName.Yellow30,
    rgb: 'rgb(255 234 150)',
    var: '--au-yellow-30',
  }],
  [ColorName.Yellow40, {
    name: ColorName.Yellow40,
    rgb: 'rgb(255 219 70)',
    var: '--au-yellow-40',
  }],
  [ColorName.Yellow50, {
    name: ColorName.Yellow50,
    rgb: 'rgb(234 194 32)',
    var: '--au-yellow-50',
  }],
  [ColorName.Yellow60, {
    name: ColorName.Yellow60,
    rgb: 'rgb(181 146 0)',
    var: '--au-yellow-60',
  }],
  [ColorName.YellowPop, {
    name: ColorName.YellowPop,
    rgb: 'rgb(255 240 100)',
    var: '--au-yellow-pop',
  }],
  [ColorName.Orange5, {
    name: ColorName.Orange5,
    rgb: 'rgb(255 246 238)',
    var: '--au-orange-5',
  }],
  [ColorName.Orange10, {
    name: ColorName.Orange10,
    rgb: 'rgb(255 223 188)',
    var: '--au-orange-10',
  }],
  [ColorName.Orange20, {
    name: ColorName.Orange20,
    rgb: 'rgb(255 196 131)',
    var: '--au-orange-20',
  }],
  [ColorName.Orange30, {
    name: ColorName.Orange30,
    rgb: 'rgb(255 153 57)',
    var: '--au-orange-30',
  }],
  [ColorName.Orange40, {
    name: ColorName.Orange40,
    rgb: 'rgb(241 120 21)',
    var: '--au-orange-40',
  }],
  [ColorName.Orange50, {
    name: ColorName.Orange50,
    rgb: 'rgb(175 75 8)',
    var: '--au-orange-50',
  }],
  [ColorName.Orange60, {
    name: ColorName.Orange60,
    rgb: 'rgb(115 49 5)',
    var: '--au-orange-60',
  }],
  [ColorName.OrangePop, {
    name: ColorName.OrangePop,
    rgb: 'rgb(255 136 27)',
    var: '--au-orange-pop',
  }],
  [ColorName.Coral5, {
    name: ColorName.Coral5,
    rgb: 'rgb(255 242 243)',
    var: '--au-coral-5',
  }],
  [ColorName.Coral10, {
    name: ColorName.Coral10,
    rgb: 'rgb(255 215 215)',
    var: '--au-coral-10',
  }],
  [ColorName.Coral20, {
    name: ColorName.Coral20,
    rgb: 'rgb(255 171 171)',
    var: '--au-coral-20',
  }],
  [ColorName.Coral30, {
    name: ColorName.Coral30,
    rgb: 'rgb(228 90 123)',
    var: '--au-coral-30',
  }],
  [ColorName.Coral40, {
    name: ColorName.Coral40,
    rgb: 'rgb(186 53 85)',
    var: '--au-coral-40',
  }],
  [ColorName.Coral50, {
    name: ColorName.Coral50,
    rgb: 'rgb(127 36 58)',
    var: '--au-coral-50',
  }],
  [ColorName.Coral60, {
    name: ColorName.Coral60,
    rgb: 'rgb(83 14 32)',
    var: '--au-coral-60',
  }],
  [ColorName.CoralPop, {
    name: ColorName.CoralPop,
    rgb: 'rgb(255 100 114)',
    var: '--au-coral-pop',
  }],
  [ColorName.Violet5, {
    name: ColorName.Violet5,
    rgb: 'rgb(255 234 252)',
    var: '--au-violet-5',
  }],
  [ColorName.Violet10, {
    name: ColorName.Violet10,
    rgb: 'rgb(241 205 232)',
    var: '--au-violet-10',
  }],
  [ColorName.Violet20, {
    name: ColorName.Violet20,
    rgb: 'rgb(219 147 202)',
    var: '--au-violet-20',
  }],
  [ColorName.Violet30, {
    name: ColorName.Violet30,
    rgb: 'rgb(176 96 157)',
    var: '--au-violet-30',
  }],
  [ColorName.Violet40, {
    name: ColorName.Violet40,
    rgb: 'rgb(135 35 114)',
    var: '--au-violet-40',
  }],
  [ColorName.Violet50, {
    name: ColorName.Violet50,
    rgb: 'rgb(91 21 81)',
    var: '--au-violet-50',
  }],
  [ColorName.Violet60, {
    name: ColorName.Violet60,
    rgb: 'rgb(53 1 46)',
    var: '--au-violet-60',
  }],
  [ColorName.VioletPop, {
    name: ColorName.VioletPop,
    rgb: 'rgb(255 184 242)',
    var: '--au-violet-pop',
  }],
  [ColorName.Purple5, {
    name: ColorName.Purple5,
    rgb: 'rgb(244 234 255)',
    var: '--au-purple-5',
  }],
  [ColorName.Purple10, {
    name: ColorName.Purple10,
    rgb: 'rgb(227 204 243)',
    var: '--au-purple-10',
  }],
  [ColorName.Purple20, {
    name: ColorName.Purple20,
    rgb: 'rgb(183 119 229)',
    var: '--au-purple-20',
  }],
  [ColorName.Purple30, {
    name: ColorName.Purple30,
    rgb: 'rgb(152 93 186)',
    var: '--au-purple-30',
  }],
  [ColorName.Purple40, {
    name: ColorName.Purple40,
    rgb: 'rgb(101 57 124)',
    var: '--au-purple-40',
  }],
  [ColorName.Purple50, {
    name: ColorName.Purple50,
    rgb: 'rgb(60 37 81)',
    var: '--au-purple-50',
  }],
  [ColorName.Purple60, {
    name: ColorName.Purple60,
    rgb: 'rgb(26 9 45)',
    var: '--au-purple-60',
  }],
  [ColorName.PurplePop, {
    name: ColorName.PurplePop,
    rgb: 'rgb(217 154 255)',
    var: '--au-purple-pop',
  }],
  [ColorName.Blue5, {
    name: ColorName.Blue5,
    rgb: 'rgb(233 245 255)',
    var: '--au-blue-5',
  }],
  [ColorName.Blue10, {
    name: ColorName.Blue10,
    rgb: 'rgb(174 208 246)',
    var: '--au-blue-10',
  }],
  [ColorName.Blue20, {
    name: ColorName.Blue20,
    rgb: 'rgb(125 177 233)',
    var: '--au-blue-20',
  }],
  [ColorName.Blue30, {
    name: ColorName.Blue30,
    rgb: 'rgb(88 146 208)',
    var: '--au-blue-30',
  }],
  [ColorName.Blue40, {
    name: ColorName.Blue40,
    rgb: 'rgb(61 112 167)',
    var: '--au-blue-40',
  }],
  [ColorName.Blue50, {
    name: ColorName.Blue50,
    rgb: 'rgb(24 66 110)',
    var: '--au-blue-50',
  }],
  [ColorName.Blue60, {
    name: ColorName.Blue60,
    rgb: 'rgb(3 17 55)',
    var: '--au-blue-60',
  }],
  [ColorName.BluePop, {
    name: ColorName.BluePop,
    rgb: 'rgb(130 195 255)',
    var: '--au-blue-pop',
  }],
  [ColorName.Seagreen5, {
    name: ColorName.Seagreen5,
    rgb: 'rgb(229 253 255)',
    var: '--au-seagreen-5',
  }],
  [ColorName.Seagreen10, {
    name: ColorName.Seagreen10,
    rgb: 'rgb(182 227 231)',
    var: '--au-seagreen-10',
  }],
  [ColorName.Seagreen20, {
    name: ColorName.Seagreen20,
    rgb: 'rgb(132 210 216)',
    var: '--au-seagreen-20',
  }],
  [ColorName.Seagreen30, {
    name: ColorName.Seagreen30,
    rgb: 'rgb(110 174 183)',
    var: '--au-seagreen-30',
  }],
  [ColorName.Seagreen40, {
    name: ColorName.Seagreen40,
    rgb: 'rgb(61 126 128)',
    var: '--au-seagreen-40',
  }],
  [ColorName.Seagreen50, {
    name: ColorName.Seagreen50,
    rgb: 'rgb(9 84 86)',
    var: '--au-seagreen-50',
  }],
  [ColorName.Seagreen60, {
    name: ColorName.Seagreen60,
    rgb: 'rgb(0 47 48)',
    var: '--au-seagreen-60',
  }],
  [ColorName.SeagreenPop, {
    name: ColorName.SeagreenPop,
    rgb: 'rgb(162 248 255)',
    var: '--au-seagreen-pop',
  }],
  [ColorName.Green5, {
    name: ColorName.Green5,
    rgb: 'rgb(234 255 248)',
    var: '--au-green-5',
  }],
  [ColorName.Green10, {
    name: ColorName.Green10,
    rgb: 'rgb(170 220 210)',
    var: '--au-green-10',
  }],
  [ColorName.Green20, {
    name: ColorName.Green20,
    rgb: 'rgb(77 156 139)',
    var: '--au-green-20',
  }],
  [ColorName.Green30, {
    name: ColorName.Green30,
    rgb: 'rgb(0 106 82)',
    var: '--au-green-30',
  }],
  [ColorName.Green40, {
    name: ColorName.Green40,
    rgb: 'rgb(0 88 80)',
    var: '--au-green-40',
  }],
  [ColorName.Green50, {
    name: ColorName.Green50,
    rgb: 'rgb(0 66 62)',
    var: '--au-green-50',
  }],
  [ColorName.Green60, {
    name: ColorName.Green60,
    rgb: 'rgb(0 36 34)',
    var: '--au-green-60',
  }],
  [ColorName.GreenPop, {
    name: ColorName.GreenPop,
    rgb: 'rgb(131 255 210)',
    var: '--au-green-pop',
  }],
  [ColorName.Gray5, {
    name: ColorName.Gray5,
    rgb: 'rgb(249 249 249)',
    var: '--au-gray-5',
  }],
  [ColorName.Gray10, {
    name: ColorName.Gray10,
    rgb: 'rgb(240 240 240)',
    var: '--au-gray-10',
  }],
  [ColorName.Gray20, {
    name: ColorName.Gray20,
    rgb: 'rgb(212 212 212)',
    var: '--au-gray-20',
  }],
  [ColorName.Gray30, {
    name: ColorName.Gray30,
    rgb: 'rgb(168 168 168)',
    var: '--au-gray-30',
  }],
  [ColorName.Gray40, {
    name: ColorName.Gray40,
    rgb: 'rgb(101 101 101)',
    var: '--au-gray-40',
  }],
  [ColorName.Gray50, {
    name: ColorName.Gray50,
    rgb: 'rgb(71 71 71)',
    var: '--au-gray-50',
  }],
  [ColorName.Gray60, {
    name: ColorName.Gray60,
    rgb: 'rgb(33 36 39)',
    var: '--au-gray-60',
  }],
])
