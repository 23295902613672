import type { Favorite, FavoriteList, FavoriteListCreateInput, FavoriteCreateInput, UpToDateFavorite, UpToDateFavoriteList } from '~/models/Favorites'
import { externalClient } from '~/api/client/externalClient'

export const useFavoritesApi = () => ({
  getFavoriteLists: async (): Promise<FavoriteList[]> =>
    (await externalClient.get<FavoriteList[]>('/frontend/favorites')).data,

  updateFavoriteList: async (item: FavoriteList): Promise<FavoriteList> =>
    (await externalClient.put<FavoriteList>(`/frontend/favorites/${item.id}`, item)).data,

  deleteFavoriteList: async (item: FavoriteList): Promise<void> =>
    await externalClient.delete(`/frontend/favorites/${item.id}`),

  createFavoriteList: async (item: FavoriteListCreateInput): Promise<FavoriteList> =>
    await externalClient.post('/frontend/favorites', item),

  createFavorite: async (item: FavoriteCreateInput, list: FavoriteList): Promise<Favorite> =>
    await externalClient.post(`/frontend/favorites/${list.id}`, item),

  removeFavoriteFromList: async ({ item, list }: { item: UpToDateFavorite, list: UpToDateFavoriteList }) =>
    await externalClient.delete(`/frontend/favorites/${list.id}/${item.id}`),
})
