<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { KsButton, KsPopover } from '@aschehoug/kloss'

const props = defineProps<{
  copyright: string
  isAnimation?: boolean
  colorTheme?: boolean
}>()

const { t } = useI18n()

const copyrightText = computed(() => props.isAnimation
  ? t('copyright.animation', { copyright: props.copyright })
  : t('copyright.image', { copyright: props.copyright }))

const colorTheme = props.colorTheme ? {
  '--ks-input': 'var(--color-dark)',
  '--ks-secondary': 'var(--color-light)',
  '--ks-inputhover': 'var(--color-darker)',
} : {}
</script>

<template>
  <KsPopover
    :title="t('copyrightBtn')"
    direction="up left"
    :style="colorTheme"
  >
    <template #toggle>
      <KsButton
        variant="secondary"
        shape="square"
        size="small"
        icon-left="copyright"
      />
    </template>
    <template #content>
      <p
        class="w-max max-w-[40ch] bg-white px-2 text-black"
        v-text="copyrightText"
      />
    </template>
  </KsPopover>
</template>
