<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router'
import type { TeacherArticle, TeacherArticleContent } from '~/models/TeacherArticle'
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useQuery } from '@tanstack/vue-query'
import { waitFor } from '~/utils/asyncUtils'
import useArrayUtils from '~/utils/arrayUtils'
import useProductStore from '~/stores/product'
import { ContentType } from '~/models/Content/ContentType'
import useSlugify from '~/composables/useSlugify'
import { useScrollBehavior } from '~/composables/useScrollBehavior'
import useGradeString from '~/composables/useGradeString'
import { useContentHelper } from '~/composables/useContentHelper'
import useBreakpoint from '~/composables/useBreakpoint'
import useContentApi from '~/api/contentApi'
import TopBar from '~/components/utils/TopBar.vue'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import TeacherSubitems from '~/components/subject/TeacherSubitems.vue'
import ArticleContentSkeleton from '~/components/skeletons/ArticleContentSkeleton.vue'

useScrollBehavior()

const props = defineProps<{
  locations: TeacherArticle[]
  closeOverride?: RouteLocationRaw
}>()

const menu = ref<HTMLElement>()

const { t } = useI18n()
const { findContents } = useContentApi()
const { getProductFromResource } = useProductStore()
const { getTitle, packageTypes, isPackage, isArticleContent } = useContentHelper()
const { truthy } = useArrayUtils()
const { slugify } = useSlugify()
const { getBreakpoint } = useBreakpoint()
const { createGradeString } = useGradeString()

const article = computed(() => props.locations[0])
const articleRelations = computed(() => article.value?.contents?.destinationContentIds ?? [])

const articleGrades = computed(() => {
  if (!article.value) return ''
  return createGradeString(article.value.grades)
})

const articleSubject = computed(() => {
  if (!article.value) return ''
  const product = getProductFromResource(article.value)
  if (product?.subjects.length !== 1) return ''
  return t(`metadata.subjects.${product.subjects.at(0)}`)
})

const menuItems = computed(() => {
  if (!article.value) return []
  return [
    article.value,
    ...( articleContents.value ? articleContents.value: [])
  ].map((articleContent) => {
    const title = getTitle(articleContent)
    return {
      title,
      id: slugify(title),
    }
  })
})

const { data: articleContents, isLoading } = useQuery({
  enabled: articleRelations.value.length > 0,
  staleTime: Infinity,
  queryKey: computed(() => ['teacher-subject-content', props.locations.map(({ locationId }) => locationId).join(',')]),
  queryFn: () => findContents<BaseItem|TeacherArticleContent>({
    contentIdCriterion: articleRelations.value,
    contentTypeCriterion: [...[ContentType.ArticleContent], ...packageTypes],
    mainLocationCriterion: true,
  }),
  select: contents => articleRelations.value
    .map((destinationContentId: number|string) => contents.find(({ contentId }) => contentId === Number(destinationContentId)))
    .filter(truthy<BaseItem|TeacherArticleContent>)
})

const setScrollMargin = () => {
  if (!menu.value) return
  const menuHeight = getComputedStyle(menu.value).height
  document.querySelectorAll('header, article').forEach((el: Element) => {
    if (!(el instanceof HTMLElement)) return
    if (getBreakpoint() > 2) el.style.scrollMarginTop = '0px'
    else el.style.scrollMarginTop = menuHeight
  })
}

onMounted(async () => {
  await waitFor(() => !!menu.value, 1000)
  window.addEventListener('resize', setScrollMargin)
  setScrollMargin()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', setScrollMargin)
})
</script>
<template>
  <div
    v-if="article"
    class="flex min-h-full flex-col text-blue-50 md:grid md:grid-cols-12"
    :style="{
      '--card-bg': 'rgb(var(--au-orange-10))',
      '--card-text': 'rgb(var(--au-blue-50))',
      '--card-hover': 'rgb(var(--au-orange-20))',
      '--card-border': 'currentColor',
      '--card-accent': 'currentColor',
    }"
  >
    <TopBar
      :item="article"
      :fullscreen="false"
      :favorite="false"
    />
    <ul
      ref="menu"
      class="sticky top-0 col-span-4 flex h-fit w-full flex-wrap gap-2 bg-orange-10 p-5 pr-16 md:top-16 md:w-fit md:flex-col md:gap-4 md:bg-transparent md:py-0 md:pl-4 md:pr-0 lg:col-span-3 xl:pl-10"
    >
      <li
        v-for="menuItem in menuItems"
        :key="menuItem.id"
        class=""
      >
        <a
          class="inline-block rounded-full bg-[#FFF6EE] px-3 py-2 font-inter text-sm font-medium transition-all hover:bg-orange-30/40 focus-visible:ring md:px-5 xl:text-base"
          :href="`#${menuItem.id}`"
          v-text="menuItem.title"
        />
      </li>
    </ul>
    <div class="col-span-8 lg:col-span-9">
      <header
        :id="slugify(getTitle(article))"
        class="au-teacher-article bg-orange-5 p-5 fluid-text-lg md:mx-5 md:mt-16 md:p-8"
      >
        <div class="divide-x text-base">
          <span
            v-if="articleGrades"
            class="pr-2"
            v-text="articleGrades"
          />
          <span
            v-if="articleSubject"
            class="px-2"
            v-text="articleSubject"
          />
          <span
            class="pl-2"
            v-text="t(`contentTypes.guide`)"
          />
        </div>
        <h1
          class="mb-4 mt-8 font-extrabold fluid-text-4xl"
          v-text="getTitle(article)"
        />
        <RichTextRenderer :text="article.intro" />
      </header>
      <ArticleContentSkeleton
        v-if="isLoading"
        color="rgb(var(--au-orange-5))"
        class="au-teacher-article mt-14 px-5 py-1 last:mb-16"
      />
      <article
        v-for="content in articleContents"
        :id="slugify(getTitle(content))"
        :key="content.contentId"
        class="au-teacher-article px-5 py-1 last:mb-16"
      >
        <h2 v-text="getTitle(content)" />
        <TeacherSubitems
          v-if="isPackage(content)"
          :header="content"
        />
        <RichTextRenderer
          v-if="isArticleContent(content)"
          :text="content.body"
        />
      </article>
    </div>
  </div>
</template>
