<script setup lang="ts">
import type { SearchResponse, SearchResult } from '~/models/Content/Response'
import { computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useQuery } from '@tanstack/vue-query'
import { KsPagination, KsSpinner } from '@aschehoug/kloss'
import { setTitle } from '~/utils/dom'
import arrayUtils from '~/utils/arrayUtils'
import useProductStore from '~/stores/product'
import { PendoTrackName } from '~/models/Pendo'
import { ColorName } from '~/models/Content/Color'
import useSearchParams from '~/composables/useSearchParams'
import useSearchHelper from '~/composables/useSearchHelper'
import useSearchClient from '~/composables/useSearchClient'
import { useScrollBehavior } from '~/composables/useScrollBehavior'
import usePendo from '~/composables/usePendo'
import useContentMapper from '~/composables/useContentMapper'
import { useAppColor } from '~/composables/useAppColor'
import SearchInput from '~/components/search/SearchInput.vue'
import SearchActiveFilters from '~/components/search/SearchActiveFilters.vue'
import NoSearchResults from '~/components/search/NoSearchResults.vue'
import GlobalSearchResults from '~/components/search/global/GlobalSearchResults.vue'
import GlobalSearchFilters from '~/components/search/global/GlobalSearchFilters.vue'
import FeedbackSection from '~/components/feedback/FeedbackSection.vue'

const { t } = useI18n()
const router = useRouter()
const { intersect } = arrayUtils()
const { mapResult, mapContents } = useContentMapper()
const { pendoTrack, pendoFlushNow } = usePendo()
const { searchPath, cardFields, defaultContentTypes } = useSearchHelper()
const { matchingProducts, hasLoaded: hasLoadedProducts, supportProducts } = storeToRefs(useProductStore())

const {
  searchQuery,
  page,
  queryParams,
  gradeCriterion,
  sortedGradeCriterion,
  labelCriterion,
  activityCriterion,
  accessCriterion,
  subjectCriterion,
  supportCriterion,
  genreCriterion,
  hasQuery,
  hasCriterions,
  hasCriterionsOrQuery,
  sort,
  toggleCriterion,
  resetCriterions,
  resetSearch,
  setSortField
} = useSearchParams({ setGradesOnMount: true })

const { fetchResults, results: response } = useSearchClient<SearchResponse>(
  searchPath.value,
  { transformData: (response: SearchResponse) => response }
)

useScrollBehavior()
useAppColor({ themeColor: ColorName.Green50, bgColor: ColorName.Gray5 })
setTitle(t('search.title'))

const FUZZINESS = 1.0
const PAGE_LIMIT = 24
const AGGREGATION_FIELDS = ['grades', 'labels', 'activities', 'access', 'genres']
const BOOST_FIELDS = { 'search_keywords': 20, 'title': 10, 'm_intro': 4, 'intro': 2 }

const results = computed(() => data.value ? mapContents(data.value) : [])
const totalCount = computed(() => data.value?.count || 0)
const totalPages = computed(() => Math.ceil(totalCount.value / PAGE_LIMIT))
const isLoading = computed(() => isLoadingResults.value || isFetching.value)
const offset = computed(() => (page.value - 1) * PAGE_LIMIT)
const queryKey = computed(() => ['global-search', searchQuery.value, page.value, criterions.value, sort.value])
const productAggregations = computed(() => supportCriterion.value.includes('true') ? supportProducts.value : matchingProducts.value)

const subtreeCriterion = computed(() => {
  const subject = matchingProducts.value
    .filter((product) => !supportProducts.value.includes(product))
    .filter((product) => intersect(product.subjects, subjectCriterion.value).length > 0)

  const support = supportCriterion.value.includes('true') ? supportProducts.value : []

  if (!subject.length && !support.length) return []
  if (support.length && !subject.length) return support.map(({ aunivers }) => aunivers.pathString as string)
  if (subject.length && support.length) {
    const filteredSupport = support.filter(product => intersect(product.subjects, subjectCriterion.value).length > 0)
    return filteredSupport.length
      ? filteredSupport.map(({ aunivers }) => aunivers.pathString as string)
      : subject.map(({ aunivers }) => aunivers.pathString as string)
  }
  return subject.map(({ aunivers }) => aunivers.pathString as string)
})

const matchingSubtrees = computed(() => {
  const supportProductPaths = supportProducts.value.map(({ aunivers }) => aunivers.pathString as string)
  const matchingProductPaths = matchingProducts.value.map(({ aunivers }) => aunivers.pathString as string)

  return matchingProductPaths.filter(path => !supportProductPaths.includes(path))
})

const spellingIncorrect = computed(() => !!response.value?.Spellcheck?.Incorrect)
const spellingSuggestion = computed(() => response.value?.Spellcheck?.Suggestion ?? '')

const criterions = computed(() => ({
  subject: subjectCriterion.value,
  label: labelCriterion.value,
  activity: activityCriterion.value,
  grade: sortedGradeCriterion.value,
  access: accessCriterion.value,
  support: supportCriterion.value,
  genre: genreCriterion.value,
}))

const doSearch = async ({ signal }: { signal?: AbortSignal }) => {
  const result = await fetchResults({
    text: searchQuery.value,
    fuzziness: FUZZINESS,
    spellcheck: true,
    fields: cardFields,
    boost: BOOST_FIELDS,
  }, {
    contentTypeCriterion: defaultContentTypes,
    labelFieldCriterion: labelCriterion.value,
    activityFieldCriterion: activityCriterion.value,
    genreFieldCriterion: genreCriterion.value,
    subtreeCriterion: subtreeCriterion.value.length > 0
      ? subtreeCriterion.value
      : matchingSubtrees.value,
    gradeFieldCriterion: sortedGradeCriterion.value,
    accessStateCriterion: accessCriterion.value,
    sortField: sort.value.field,
    sortOrder: sort.value.order,
    gradeOperator: 'or',
    activityOperator: 'or',
    aggregationCriterion: AGGREGATION_FIELDS,
    mainLocationCriterion: true,
  }, PAGE_LIMIT, offset.value, signal)

  pendoTrack(PendoTrackName.SearchPage, {
    text: searchQuery.value || '',
    results: response.value?.View.Result.count || 0,
    subjects: subjectCriterion.value || '',
    grades: gradeCriterion.value || '',
    labels: labelCriterion.value || '',
    genre: genreCriterion.value || '',
    activities: activityCriterion.value || '',
  })
  pendoFlushNow()

  return result
}

const {
  data,
  isError,
  isLoading: isLoadingResults,
  isFetching,
} = useQuery({
  queryKey,
  queryFn: doSearch,
  select: (data) => mapResult(data),
  enabled: computed(() => hasLoadedProducts.value),
  staleTime: Infinity,
  placeholderData: (previousData) => previousData,
})

const replaceRoute = () =>
  router.replace({ name: 'search', query: queryParams.value })

const updateSearchQuery = (newQuery: string) => {
  searchQuery.value = newQuery
  page.value = 1
}

const pushPage = (newPage: number) => {
  page.value = newPage
  scrollTo(0, 0)
}

watch(totalPages, () => {
  if (totalPages.value && page.value > totalPages.value)
    pushPage(1)
})

watch(queryKey, () => replaceRoute(), { deep: true })
</script>

<template>
  <div
    v-if="!hasLoadedProducts"
    class="absolute inset-0 flex items-center justify-center"
  >
    <KsSpinner />
  </div>
  <div
    v-else
    class="mx-auto my-12 grid max-w-screen-au grid-cols-1 grid-rows-[auto,auto,auto,1fr,auto] gap-x-8 gap-y-4 px-4 grid-areas-search-view-mobile sm:px-8 md:grid-cols-3 md:gap-x-12 md:grid-areas-search-view-tablet"
  >
    <SearchInput
      :title="t('search.globalSearch')"
      :placeholder="t('search.placeholder')"
      :query-key="queryKey"
      :search-query="searchQuery"
      @update-search-query="updateSearchQuery"
    />
    <GlobalSearchFilters
      :results="data ?? {} as SearchResult"
      :is-loading="isLoadingResults"
      :is-refetching="isLoading"
      :criterions="criterions"
      :products="productAggregations"
      @toggle-criterion="toggleCriterion"
    />
    <SearchActiveFilters
      :criterions="criterions"
      :is-loading="isLoading"
      :page="page"
      :total-pages="totalPages"
      :total-count="totalCount"
      :field="searchQuery.length ? sort.field : undefined"
      group-grades-by-grade-type
      @remove-criterion="toggleCriterion"
      @reset-criterions="resetCriterions"
      @set-sort-field="setSortField"
    />
    <div class="grid-in-results">
      <GlobalSearchResults
        :results="results"
        :is-loading="isLoading"
      />
      <NoSearchResults
        :no-results="!results.length"
        :has-query="hasQuery"
        :has-failed="isError"
        :is-loading="isLoading"
        :has-criterions="hasCriterions"
        :spelling-suggestion="spellingIncorrect ? spellingSuggestion : ''"
        @reset-criterions="resetCriterions"
        @reset-search="resetSearch"
        @suggested-search="updateSearchQuery(spellingSuggestion)"
      />
    </div>
    <KsPagination
      v-if="totalCount > PAGE_LIMIT"
      :current-page="page"
      :items-per-page="PAGE_LIMIT"
      :item-count="totalCount"
      class="ml-auto mt-12 grid-in-pagination"
      :disabled="isLoading"
      @push-page="pushPage"
    />
  </div>
  <FeedbackSection
    v-if="hasCriterionsOrQuery"
    class="mt-10 bg-orange-10"
    context="search"
    :data="JSON.stringify(queryParams)"
  />
</template>
