<script setup lang="ts">
import type { BasicUser } from '~/models/User/BasicUser'
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useQuery } from '@tanstack/vue-query'
import { KsCallout } from '@aschehoug/kloss'
import { setTitle } from '~/utils/dom'
import { useSchoolYear } from '~/composables/useSchoolYear'
import useReport from '~/composables/useReport'
import useAssignmentApi from '~/api/assignmentApi'
import StudentGraph from '~/components/reports/StudentGraph.vue'
import ReportToolbar from '~/components/reports/ReportToolbar.vue'
import ReportHeader from '~/components/reports/ReportHeader.vue'

const {
  groupId,
  contentType,
  subject,
  grade,
  lastActivityAfter,
  lastActivityBefore,
  context,
  queryParams,
  setFilterValue,
} = useReport()

const { t } = useI18n()
const router = useRouter()
const { getReport } = useAssignmentApi()
const { currentSchoolYearInterval } = useSchoolYear()

setTitle(t('report.title'))

const filterModel = ref({
  groupIdValue: undefined,
  contentTypeValue: 'flyt_test',
  gradeValue: '',
  subjectValue: 'ENG',
  afterValue: currentSchoolYearInterval.start?.toISO({ suppressMilliseconds: true }) ?? '',
  beforeValue: currentSchoolYearInterval.end?.toISO({ suppressMilliseconds: true }) ?? '',
  contextValue: 'term_exam_spring_2025_eng',
})

const replaceRoute = () => router.replace({ name: 'report', query: queryParams.value })
const queryKey = computed(() => ['report', queryParams.value])
const hasAllParams = computed(() => !!groupId.value && !!contentType.value && !!subject.value && !!grade.value && !!lastActivityAfter.value && !!lastActivityBefore.value && !!context.value)

const isSetAndDifferent = (newValue: string, currentValue: string) => {
  if (!newValue) return false
  return newValue !== currentValue
}

watch(filterModel, () => {
  if (isSetAndDifferent(filterModel.value.groupIdValue, groupId.value)) setFilterValue('groupId', filterModel.value.groupIdValue)
  if (isSetAndDifferent(filterModel.value.contentTypeValue, contentType.value)) setFilterValue('contentType', filterModel.value.contentTypeValue)
  if (isSetAndDifferent(filterModel.value.gradeValue, grade.value)) setFilterValue('grade', filterModel.value.gradeValue)
  if (isSetAndDifferent(filterModel.value.subjectValue, subject.value)) setFilterValue('subject', filterModel.value.subjectValue)
  if (isSetAndDifferent(filterModel.value.afterValue, lastActivityAfter.value)) setFilterValue('lastActivityAfter', filterModel.value.afterValue)
  if (isSetAndDifferent(filterModel.value.beforeValue, lastActivityBefore.value)) setFilterValue('lastActivityBefore', filterModel.value.beforeValue)
  if (isSetAndDifferent(filterModel.value.contextValue, context.value)) setFilterValue('context', filterModel.value.contextValue)
}, { deep: true })

watch(queryParams, () => {
  if (isSetAndDifferent(groupId.value, filterModel.value.groupIdValue)) filterModel.value.groupIdValue = groupId.value
  if (isSetAndDifferent(contentType.value, filterModel.value.contentTypeValue)) filterModel.value.contentTypeValue = contentType.value
  if (isSetAndDifferent(grade.value, filterModel.value.gradeValue)) filterModel.value.gradeValue = grade.value
  if (isSetAndDifferent(subject.value, filterModel.value.subjectValue)) filterModel.value.subjectValue = subject.value
  if (isSetAndDifferent(lastActivityAfter.value, filterModel.value.afterValue)) filterModel.value.afterValue = lastActivityAfter.value
  if (isSetAndDifferent(lastActivityBefore.value, filterModel.value.beforeValue)) filterModel.value.beforeValue = lastActivityBefore.value
  if (isSetAndDifferent(context.value, filterModel.value.contextValue)) filterModel.value.contextValue = context.value

  replaceRoute()
}, { deep: true, immediate: true })

const { data: reportData, isLoading, isError } = useQuery({
  staleTime: Infinity,
  enabled: hasAllParams,
  retry: 1,
  queryKey: queryKey,
  queryFn: async () => await getReport(
    groupId.value,
    lastActivityAfter.value,
    lastActivityBefore.value,
    contentType.value,
    context.value,
    subject.value,
    grade.value,
  )
})

const missingFilters = computed(() => !groupId.value || !grade.value)
const noResults = computed((): boolean => !isLoading.value && !!reportData.value && reportData.value?.length === 0)
const isReady = computed((): boolean =>  !isLoading.value && !!reportData.value && reportData.value?.length > 0)

const usersInResults = computed((): BasicUser[] => {
  if (noResults.value) return []

  const studentIds: string[] = []
  const users: BasicUser[] = []

  reportData.value?.forEach(assignment => {
    assignment.responseSummary.forEach(summary => {
      if (!studentIds.includes(summary.student.userId)) {
        studentIds.push(summary.student.userId)
        users.push(summary.student)
      }
    })
  })

  return users.sort((a, b) => a.lastName.localeCompare(b.lastName))
})

</script>
<template>
  <div class="h-full bg-green-5">
    <header class="relative h-[320px] flex-col justify-center bg-green-50 text-white">
      <div class="relative mx-auto size-full max-w-screen-au px-4 pb-10 pt-32 transition-all sm:px-8 sm:pb-20">
        <h1
          class="relative z-10 font-semibold fluid-text-3xl"
          v-text="t('report.title')"
        />
        <ReportToolbar v-model="filterModel" />
      </div>
    </header>
    <div
      v-if="isError"
      class="mx-auto max-w-screen-au px-4 py-8 sm:px-8"
    >
      <KsCallout
        variant="info"
        :title="t('report.messages.error.title')"
      >
        <p v-text="t('report.messages.error.message')" />
      </KsCallout>
    </div>
    <div
      v-else-if="missingFilters"
      class="mx-auto max-w-screen-au px-4 py-8 sm:px-8"
    >
      <KsCallout
        variant="info"
        :title="t('report.messages.missingFilters.title')"
      >
        <p v-text="t('report.messages.missingFilters.message')" />
      </KsCallout>
    </div>
    <div
      v-else-if="noResults"
      class="mx-auto max-w-screen-au px-4 py-8 sm:px-8"
    >
      <KsCallout
        variant="info"
        :title="t('report.messages.noResults.title')"
      >
        <p v-text="t('report.messages.noResults.message')" />
      </KsCallout>
    </div>
    <div
      v-else-if="isReady && reportData"
      class="mx-auto max-w-screen-au px-4 py-8 sm:px-8"
    >
      <ReportHeader
        :report-data="reportData"
        :users-in-results="usersInResults"
        :group-id="groupId"
      />
      <div class="mx-auto mt-8 max-w-screen-au rounded-t-lg bg-white">
        <div class="grid h-14 rounded-t-lg bg-green-10 pr-12 xs:grid-cols-2">
          <div class="content-center p-4 uppercase">
            <p>{{ t('report.studentList.name') }}</p>
          </div>
          <div class="content-center py-4 uppercase">
            <p>{{ t('report.studentList.result') }}</p>
          </div>
        </div>
        <StudentGraph
          v-for="user in usersInResults"
          :key="user.userId"
          :report-data="reportData"
          :user="user"
          class="border-b-2 border-green-10 odd:bg-green-5/50 even:bg-white"
        />
      </div>
    </div>
  </div>
</template>
