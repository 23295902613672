<script setup lang="ts">
import type { ContentModelText } from '~/models/Content/ContentModelText'
import { computed, useTemplateRef } from 'vue'
import { faArrowRight, faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { KsIcon } from '@aschehoug/kloss'
import { ArticleSize } from '~/models/Content/ContentArticle'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'

const props = defineProps<{
  content: ContentModelText
  size: ArticleSize
}>()

const richtext = useTemplateRef('richtext')
const tags = computed(() => richtext.value?.querySelectorAll('[data-group]') ?? [])
</script>

<template>
  <div
    class="au-model-text grid grid-cols-subgrid rounded-xl border-4 border-[--color-light] bg-white py-[calc(var(--col-gap)+var(--col-width))] shadow-[8px_8px_0_var(--color-pop)]"
    :class="{ 'au-model-text-is-child-level': content.viewMode === 'child_level' }"
  >
    <div
      class="relative"
      :class="size === ArticleSize.Medium ? 'col-[5/-5]' : 'col-[4/-4]'"
    >
      <Teleport
        v-for="(el, index) of tags"
        :key="index"
        :to="el"
        defer
      >
        <label class="absolute top-0 grid size-11 translate-x-[calc(100%*-1-1rem)] place-items-center rounded-full border-2 border-[--color-pop] bg-[--color-pop] text-base text-[--color-darker] hover:border-[--color-light] hover:bg-[--color-light] has-[:checked]:!border-[--color-darker] has-[:focus-visible]:ring">
          <input
            type="checkbox"
            class="sr-only"
          >
          <KsIcon
            :icon="faArrowRight"
            class="[input:checked~&]:hidden"
          />
          <KsIcon
            :icon="faArrowLeft"
            class="[input:not(:checked)~&]:hidden"
          />
        </label>
      </Teleport>

      <div ref="richtext" class="au-prose">
        <RichTextRenderer :text="content.text" />
      </div>
    </div>
  </div>
</template>

<style scoped>
  .au-model-text-is-child-level :deep(.tag) {
    display: block;
    position: relative;

    &:has(> label input:checked) {
      background-color: var(--color-light);

      &::before {
        display: inline-block;
        content: attr(data-group);
        background-color: var(--color-darker);
        border: 2px solid var(--color-light);
        color: var(--color-pop);
        border-radius: 50%;
        aspect-ratio: 1;
        overflow: hidden;
        display: grid;
        place-items: center;
        width: calc(var(--col-width) * 4);
        position: absolute;
        left: -2rem;
        font-size: 1.5rem;
        top: calc(2.75rem / 2); /* tailwind's size-11 / 2 */
        transform: translateY(-50%);
        text-align: center;
        padding: 1rem;
      }
    }
  }

  .au-model-text:not(.au-model-text-is-child-level) :deep(.tag) {
    display: block;
    position: relative;

    &:has(> label input:checked) {
      background-color: var(--color-light);

      &::before {
        display: inline-block;
        content: attr(data-group);
        background-color: var(--color-darker);
        color: var(--color-pop);
        border-end-end-radius: 10rem;
        border-start-end-radius: 10rem;
        padding-inline: .8em;
        transform: translateX(-.8em);
        box-shadow: calc(var(--col-gap) * -1 + .8em) 0 0 var(--color-darker);
      }
    }
  }
</style>
