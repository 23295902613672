<script setup lang="ts">
import type { SubjectCode } from '~/models/Subject'
import type { GradeCode } from '~/models/Grade'
import type { BaseItem } from '~/models/Content/BaseItem'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { KsDropdown } from '@aschehoug/kloss'
import useSlugify from '~/composables/useSlugify'

const props = defineProps<{
  selected: BaseItem
  subjectCode: SubjectCode
  gradeCode: GradeCode
  options: BaseItem[]
}>()

const { t } = useI18n()
const router = useRouter()
const { slugify } = useSlugify()

const selectHeader = async (header: BaseItem) => await router.replace({
  name: 'header',
  params: {
    subject: props.subjectCode.toLowerCase(),
    locationId: header.locationId,
    slug: slugify(header.title)
  },
  query: {
    grade: props.gradeCode
  },
})
</script>

<template>
  <KsDropdown
    v-if="options.length > 0"
    :model-value="selected"
    :options="options"
    :option-id="(header: BaseItem) => header.locationId"
    :option-label="(header: BaseItem) => header.title"
    :selectable="(header: BaseItem) => header.locationId !== selected.locationId"
    :placeholder="t('filters.chooseTheme')"
    shape="rounded"
    autolabel
    flexible
    @select="(header: BaseItem) => selectHeader(header)"
  />
</template>
