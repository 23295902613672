<script setup lang="ts">
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { ContentType } from '~/models/Content/ContentType'
import { useContentHelper } from '~/composables/useContentHelper'
import useContentApi from '~/api/contentApi'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import SimpleCard from '~/components/cards/SimpleCard.vue'

const { findContents } = useContentApi()
const { packageTypes } = useContentHelper()

const props = defineProps<{
  header: BaseItem
  showTitle?: boolean
}>()

const { data, isLoading } = useQuery({
  staleTime: Infinity,
  queryKey: computed(() =>['teacher-header', props.header.contentId]),
  queryFn: async () => {
    const children = await findContents<BaseItem>({
      parentLocationIdCriterion: [props.header.mainLocationId],
      sortField: props.header.sortField,
      sortOrder: props.header.sortOrder,
    })

    const headers = children
      .filter(({ contentTypeIdentifier }) => packageTypes.includes(contentTypeIdentifier))
    const cards = children
      .filter(({ contentTypeIdentifier }) => !packageTypes.includes(contentTypeIdentifier))
    const isFileCards = cards
      .every(({ contentTypeIdentifier }) => contentTypeIdentifier === ContentType.File)

    return { headers, cards, isFileCards }
  },
})
</script>
<template>
  <h3
    v-if="showTitle"
    v-text="props.header.title"
  />

  <RichTextRenderer
    v-if="props.header.intro"
    :text="props.header.intro"
  />

  <KsSkeletonWrapper
    v-if="isLoading"
    class="grid gap-px border border-transparent sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
  >
    <KsSkeleton
      v-for="i in 12"
      :key="`skeleton-${i}`"
      height="80px"
      background="rgb(var(--au-orange-5))"
      class="!rounded-none border border-transparent"
    />
  </KsSkeletonWrapper>

  <template v-else-if="data && data.cards.length > 0">
    <ul class="grid gap-px pl-0 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      <li
        v-for="card in data.cards"
        :key="`card-${card.locationId}`"
        class="m-0 list-none p-0"
      >
        <SimpleCard
          :resource="card"
          :show-label="!data.isFileCards"
          no-image
        />
      </li>
    </ul>

    <TeacherSubitems
      v-for="subheader in data.headers"
      :key="`header-${subheader.locationId}`"
      :header="subheader"
      :show-title="true"
    />
  </template>
</template>
