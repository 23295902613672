<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton, KsInput } from '@aschehoug/kloss'
import useAuthStore from '~/stores/auth'
import { GradeType } from '~/models/GradeType'
import UpperSecondaryGradeSelectorPopover from '~/components/settings/UpperSecondaryGradeSelectorPopover.vue'

const props = defineProps<{ gradeType: GradeType; disabled?: boolean }>()

const { t } = useI18n()
const { selectedGradeType } = storeToRefs(useAuthStore())

const emit = defineEmits(['saveGradeType'])

const gradeTypeIsChecked = computed({
  get() {
    return selectedGradeType.value === props.gradeType
  },
  set(value) {
    if (value) emit('saveGradeType')
  }
})

</script>
<template>
  <KsButton
    variant="secondary"
    shape="rounded"
    type="label"
  >
    <KsInput
      v-model="gradeTypeIsChecked"
      :checked="gradeTypeIsChecked"
      name="gradeType"
      type="radio"
      :disabled="props.disabled"
    />
    {{ t(`metadata.gradeTypes.${gradeType}`) }}
    <UpperSecondaryGradeSelectorPopover v-if="gradeType === GradeType.UpperSecondary" />
  </KsButton>
</template>
