<script setup lang="ts">
import type { TeacherArticle } from '~/models/TeacherArticle'
import { computed, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useQuery } from '@tanstack/vue-query'
import { KsSpinner } from '@aschehoug/kloss'
import { setTitle } from '~/utils/dom'
import useLicenseControlStore from '~/stores/licenseControl'
import useAuthStore from '~/stores/auth'
import { ContentType } from '~/models/Content/ContentType'
import { ColorName } from '~/models/Content/Color'
import { useContentHelper } from '~/composables/useContentHelper'
import { useAppColor } from '~/composables/useAppColor'
import useContentApi from '~/api/contentApi'
import TeacherContent from '~/components/subject/TeacherContent.vue'
import NotFound from '~/components/ResourceEngine/Components/NotFound.vue'

useAppColor(ColorName.Orange10)

const props = defineProps<{ locationId: number }>()

const { isTeacher } = storeToRefs(useAuthStore())
const { getTitle } = useContentHelper()
const { findContents } = useContentApi()

const {
  data: articles,
  isLoading: isLoadingArticle,
  isError
} = useQuery({
  staleTime: Infinity,
  queryKey: computed(() => ['teacher-article', props.locationId]),
  queryFn: async () => await findContents<TeacherArticle>({
    locationIdCriterion: [props.locationId],
    contentTypeCriterion: [ContentType.TeacherArticle],
  })
})

watch(articles, async() => {
  if (!articles.value || !articles.value.length) return
  setTitle(getTitle(articles.value[0]))
  const { checkItemLicense } = useLicenseControlStore()
  await checkItemLicense(articles.value[0])
})
</script>
<template>
  <KsSpinner
    v-if="isLoadingArticle"
    position="center"
  />
  <NotFound v-else-if="isError || !isTeacher || !articles?.length" />
  <TeacherContent
    v-else
    :locations="articles"
  />
</template>
