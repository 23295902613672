<script setup lang="ts">
import type { SubjectCode } from '~/models/Subject'
import type { Group } from '~/models/Group'
import type { ContentProductPackage } from '~/models/Content/ContentProductPackage'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { faArrowTurnRight } from '@fortawesome/pro-regular-svg-icons'
import { KsButton, KsCallout, KsDialog, KsDropdown, KsSpinner } from '@aschehoug/kloss'
import useProductStore from '~/stores/product'
import TeleportationTarget from '~/models/TeleportationTarget'
import { PendoFeature } from '~/models/Pendo'
import { LocationType, TaskStatus } from '~/models/AssignTask'
import { useAssignTask } from '~/composables/useAssignTask'
import GroupSelectionDropdown from '~/components/products/exams/assignment/GroupSelectionDropdown.vue'

const { resource } = defineProps<{ resource: ContentProductPackage }>()

const { t } = useI18n()
const { createTask: create } = useAssignTask()
const { getProductFromResource } = useProductStore()

const open = ref(false)
const selectedGroup = ref<Group>()
const selectedSubject = ref<SubjectCode>()

const currentYear = new Date().getFullYear().toString()

const product = computed(() => getProductFromResource(resource))

const createTask = async () => {
  if (!selectedGroup.value) return

  await create.mutateAsync({
    name: resource.title,
    groups: [selectedGroup.value.groupId],
    grades: resource.grades,
    status: TaskStatus.Closed,
    subjects: resource.subjects?.length
      ? resource.subjects
      : selectedSubject.value ? [selectedSubject.value] : [],
    locationIds: [resource.locationId],
    locationType: LocationType.ParentLocation,
    pathString: product.value?.aunivers.pathString,
    tags: [`term-exam-${currentYear}`],
  })
  open.value = false
  window.scrollTo(0, 0)
}
</script>

<template>
  <KsButton
    variant="secondary"
    :icon-left="faArrowTurnRight"
    class="!text-sm"
    stretch
    center
    @click="open = true"
  >
    {{ t('assign.createExam') }}
  </KsButton>
  <Teleport :to="TeleportationTarget.AppTop">
    <KsDialog
      :open
      :title="t('termExam.byline', { title: resource.title.toLowerCase() })"
      @close="open = false"
    >
      <template #body>
        <div
          class="flex flex-col gap-4"
          style="--ks-roundness: .6"
        >
          <GroupSelectionDropdown v-model="selectedGroup" />
          <label
            v-if="!resource.subjects?.length && product"
            class="space-y-2"
          >
            <span
              class="text-base font-medium"
              v-text="t('filters.chooseSubject')"
            />
            <KsDropdown
              v-model="selectedSubject"
              :options="product.subjects"
              :option-label="(subjectCode: SubjectCode) => t(`metadata.subjects.${subjectCode}`)"
              :placeholder="t('filters.chooseSubject')"
              class="w-full"
            />
          </label>
          <p v-text="t('assign.createInfo1')" />
          <p v-text="t('assign.createInfo2')" />
          <KsCallout
            v-if="create.isError.value"
            variant="error"
          >
            {{ t('assign.createError') }}
          </KsCallout>
        </div>
      </template>
      <template #footer>
        <div class="flex items-center justify-end gap-4">
          <KsButton
            variant="secondary"
            shape="rounded"
            :disabled="create.isPending.value"
            @click="open = !open"
          >
            {{ t('cancel') }}
          </KsButton>
          <KsButton
            variant="primary"
            :disabled="!selectedGroup || create.isPending.value || (!resource.subjects?.length && !selectedSubject)"
            shape="rounded"
            :data-pendo="PendoFeature.TermExam.CreateTask"
            @click="createTask"
          >
            <div class="flex gap-1">
              {{ create.isPending.value ? t('assign.creatingExam') : t('assign.createExam') }}
              <KsSpinner
                v-if="create.isPending.value"
                style="--ks-primary: rgb(var(--au-seagreen-30))"
                size="small"
              />
            </div>
          </KsButton>
        </div>
      </template>
    </KsDialog>
  </Teleport>
</template>
