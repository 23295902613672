<script setup lang="ts">
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed } from 'vue'
import { faArrowRight, faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { KsIcon } from '@aschehoug/kloss'
import { PendoFeature } from '~/models/Pendo'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import { useStaticProduct } from '~/composables/useStaticProduct'
import useDescriptor from '~/composables/useDescriptor'

const { item, direction } = defineProps<{
  item?: BaseItem
  direction: 'previous'|'next'
}>()

const icon = {
  previous: faArrowLeft,
  next: faArrowRight
}[direction]

const { buildResourceUrl } = useUrlBuilder()
const { getDescriptor } = useDescriptor()
const { Terminprover } = useStaticProduct()

const href = computed(() => item ? buildResourceUrl(item) : null)

const tracking = computed(() => item && item.pathString.startsWith(Terminprover.aunivers.pathString!)
  ? { 'data-pendo': PendoFeature.TermExam.NavigateTask }
  : {}
)
</script>
<template>
  <RouterLink
    v-if="item && href"
    :to="{ path: href }"
    class="m-2 flex max-w-fit flex-1 flex-col gap-2 rounded-lg p-4 text-[--color-text] transition hover:bg-[--color-text] hover:text-[--color-background] focus-visible:ring md:m-4"
    :class="direction === 'next' && 'items-end'"
    v-bind="tracking"
  >
    <div
      class="text-xs font-semibold uppercase text-[--card-accent]"
      v-text="getDescriptor(item)"
    />
    <h3
      class="font-semibold md:text-lg"
      v-text="item.title"
    />
    <KsIcon :icon="icon" />
  </RouterLink>
</template>
