<script lang="ts" setup generic="T">
import { computed } from 'vue'

const props = withDefaults(defineProps<{
  item?: T
  colors: string[]
  darken?: boolean
  depth?: number
  maxDepth?: number
  stackOffset?: number
  paddedStack?: boolean
  element?: string
}>(), {
  colors: () => ['bg-white'],
  darken: true,
  depth: 0,
  maxDepth: 6,
  stackOffset: 3,
  paddedStack: false,
  element: 'li',
})
const fgColor = computed<string>( () => [...props.colors].shift() || 'bg-white')
const stackColors = computed<string[]>(() => {
  const remainingColors = [...props.colors]
  remainingColors.shift()
  return remainingColors
})

const getColor = (idx: number) => {
  if (stackColors.value.length < 1) {
    return fgColor.value
  }
  return stackColors.value[idx] ? stackColors.value[idx] : stackColors.value[stackColors.value.length - 1]
}
</script>


<template>
  <component :is="props.element">
    <div
      class="h-full"
      :style="paddedStack
        ? {
          paddingInlineEnd: `${Math.min(depth, maxDepth) * stackOffset}px`,
          paddingBlockEnd: `${Math.min(depth, maxDepth) * stackOffset}px`,
        }
        : {}
      "
    >
      <div class="relative h-full">
        <div
          v-for="(i) in Math.min(depth, maxDepth)"
          :key="i"
          class="absolute inset-0 rounded-md"
          :style="[
            `transform: translate(${i * stackOffset}px, ${i * stackOffset}px);`,
            `background: rgba(var(${getColor(i - 1)}));`,
            `filter: brightness(${darken ? Math.max(Math.min(1 - (i / 6), 1), 0) : 1});`,
            `z-index: ${Math.min(depth, maxDepth) - i};`,
          ]"
        />
        <div
          class="relative z-10 grid min-h-full min-w-12 rounded-md"
          :style="`background: rgb(var(${fgColor}));`"
        >
          <slot :item="item" />
        </div>
      </div>
    </div>
  </component>
</template>
