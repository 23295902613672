import type { Organization } from '~/models/Organization'
import axios from 'axios'
import { externalClient } from '~/api/client/externalClient'

export default function useLicenseControlApi() {

  /**
   * Should return false on 403, otherwise _always_ true.
   * @param {string} path - The path to check. E.g. /1/2/160/1228/1276/3293/105114/284407/
   * @param organization
   */
  const checkLicenseByPathString = async (path: string, organization?: Organization): Promise<boolean> => {
    try {
      await externalClient.get('/frontend/licenses/path', {
        params: {
          pathstring: path,
          site: 'aunivers',
          orgnumber: organization?.number,
        },
      })
      return true
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 403) return false
    }

    return true
  }

  return {
    checkLicenseByPathString,
  }
}
