<script lang="ts" setup>
import type { SubjectViewProps } from '~/models/Subject'
import { computed, onBeforeUnmount, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { setTitle } from '~/utils/dom'
import { useSubjectViewStore } from '~/stores/subjectView'
import useSubjectsStore from '~/stores/subjects'
import { useSectionStore } from '~/stores/section'
import useProductStore from '~/stores/product'
import useFilterStore from '~/stores/filter'
import { useAuthStore } from '~/stores/auth'
import useSectionPath from '~/composables/useSectionPath'
import useQueryFilter from '~/composables/useQueryFilter'
import SectionsSkeleton from '~/components/skeletons/SectionsSkeleton.vue'
import TeacherResources from '~/components/ResourceFinder/TeacherResources.vue'
import ResourceFinder from '~/components/ResourceFinder/ResourceFinder.vue'
import SectionRenderer from '~/components/ResourceEngine/SectionRenderer.vue'
import SubjectBanner from '~/components/banners/SubjectBanner.vue'

const props = defineProps<SubjectViewProps>()

const { t } = useI18n()
const filterStore = useFilterStore()
const { filterWatchers, $reset } = filterStore
const { selectedGrade, selectedHeaders, isLoading: filtersLoading } = storeToRefs(filterStore)

const { setCurrentSubject } = useSubjectsStore()
const { currentSubject } = storeToRefs(useSubjectsStore())
const { isTeacher } = storeToRefs(useAuthStore())
const { hasLoaded: hasLoadedProducts } = storeToRefs(useProductStore())
const { subjectPath } = useSectionPath()

const sectionStore = useSectionStore()
const { loadSections } = sectionStore
const { subjectSections, isLoading: sectionsLoading } = storeToRefs(sectionStore)

const { loadSubjectData } = useSubjectViewStore()
const { subjectHeader } = storeToRefs(useSubjectViewStore())

const watchers = filterWatchers()

onMounted(() => useQueryFilter())

onBeforeUnmount(() => {
  watchers.stopFilterWatchers()
  setCurrentSubject(undefined)
  $reset()
})

watch(props, async () => {
  setCurrentSubject(props.subjectCode)
}, { immediate: true })

watch(subjectHeader, (newHeader) => {
  setTitle(t(newHeader.title))
}, { deep: true })

watch(props, () => {
  if (!subjectPath[props.subjectCode]) return
  loadSubjectData(props.subjectCode)
    .catch((error) => console.warn(error))
  loadSections(subjectPath[props.subjectCode] as string)
    .catch((error) => console.warn(error))
}, { immediate: true })

const showSections = computed(() => !sectionsLoading.value
  && !filtersLoading.value
  && subjectSections.value.length
  && !selectedHeaders.value.length)

const showTeacherResources = computed(() => isTeacher.value
  && hasLoadedProducts.value
  && !selectedHeaders.value.length
  && selectedGrade.value
  && currentSubject.value)
</script>

<template>
  <SubjectBanner
    :title="t(subjectHeader.title)"
    :image="subjectHeader.image"
    :text="subjectHeader.text"
  />

  <ResourceFinder />

  <TeacherResources
    v-if="showTeacherResources"
    :subject-code="currentSubject"
    :grade-code="selectedGrade"
  />

  <SectionRenderer
    v-if="showSections"
    :items="subjectSections"
  />
  <div
    v-if="sectionsLoading"
    class="mx-auto max-w-screen-au"
  >
    <SectionsSkeleton />
  </div>
</template>
