<script setup lang="ts">
import { ref, useSlots, watch } from 'vue'
import { vIntersectionObserver } from '@vueuse/components'
import { PendoTrackName } from '~/models/Pendo'
import usePendo from '~/composables/usePendo'

const emit = defineEmits<{ view: [inView: boolean] }>()

const isRevealed = ref(false)
const inView = ref(false)

const { pendoTrack, pendoFlushNow } = usePendo()
const slots = useSlots()

function reveal() {
  if (!slots.back) return

  pendoTrack(PendoTrackName.FlashcardReveal, {
    title: document.title,
    url: window.location.href
  })

  if (inView.value) isRevealed.value = !isRevealed.value

  pendoFlushNow()
}

function observer([entry]: IntersectionObserverEntry[]) {
  return entry.isIntersecting
    ? inView.value = true
    : isRevealed.value = inView.value = false
}

function onFocus({ target }: FocusEvent) {
  const element = target as HTMLDivElement | null
  if (!element) return
  setTimeout(() => element.scrollIntoView({
    behavior: 'smooth', inline: 'center', block: 'nearest'
  }), 0)
}

watch(inView, (value) => emit('view', value))
</script>

<template>
  <div
    v-intersection-observer="[observer, { threshold: .5 }]"
    class="flashcard select-none focus-visible:ring"
    tabindex="0"
    :aria-live="inView ? 'polite' : 'off'"
    :class="{ 'flashcard--revealed': !isRevealed }"
    @focus="onFocus"
    @click="reveal"
    @keydown.enter="reveal"
    @keydown.space.prevent="reveal"
  >
    <Transition
      mode="out-in"
      :duration="250"
    >
      <div
        v-if="isRevealed"
        class="contents"
      >
        <slot name="back" />
      </div>

      <div
        v-else
        class="contents"
      >
        <slot name="front" />
      </div>
    </Transition>

    <div
      class="absolute bottom-[var(--flashcard-safe-inset,0)] left-1/2 mb-8"
      @click.stop
    >
      <slot name="overlay" />
    </div>
  </div>
</template>

<style scoped>
.flashcard {
  animation: flip1 500ms linear;
}

.flashcard--revealed {
  animation-name: flip2;
}

@keyframes flip1 {
  50% {
    transform: rotateY(90deg);
  }
}

@keyframes flip2 {
  50% {
    transform: rotateY(90deg);
  }
}
</style>
