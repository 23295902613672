import type { GeoGebraAppletApi, GeoGebraAppletParams } from '~/models/Geogebra'
import { ref, watch } from 'vue'
import { useScriptTag } from '@vueuse/core'
import stringUtils from '~/utils/stringUtils'
import { useBase64FileQuery } from '~/composables/useBase64FileQuery'

export const defaultParams: GeoGebraAppletParams = {
  appName: 'classic',
  showMenuBar: false,
  showToolBar: false,
  showKeyboardOnFocus: 'auto',
  showFullScreenButton: true,
  showStartTooltip: false,
  enableFileFeatures: false,
  preventFocus: true,
  scale: 1,
}

const appletScriptSrc = `${import.meta.env.VITE_ASSETS_URL}/geogebra/deployggb.js`

export const useGeoGebraApplet = (params: GeoGebraAppletParams, contentId: number, scriptSrc: string = appletScriptSrc) => {
  const { generateRandomString } = stringUtils()
  const apiRef= ref<GeoGebraAppletApi>()
  const appletId = `applet-${generateRandomString()}`

  useScriptTag(
    scriptSrc,
    // on script tag loaded.
    () => {
      // when embedded in a template (Ibexa), the params are passed as a string
      if (typeof params === 'string') {
        params = JSON.parse(params)
      }

      // @ts-expect-error - GBDApplet is a global variable attached to the window object
      // see: https://wiki.geogebra.org/en/Reference:GeoGebra_Apps_Embedding#Quick_Start
      const applet = new GGBApplet({
        appletOnLoad: (api: GeoGebraAppletApi) => apiRef.value = api,
        ...defaultParams,
        ...params,
      }, true)

      applet.setHTML5Codebase(`${import.meta.env.VITE_ASSETS_URL}/geogebra/HTML5/5.0/web3d`)

      applet.inject(appletId)
    },
  )

  const geoGebraFile = useBase64FileQuery(
    contentId,
    'geogebra_file',
    { enabled: !!contentId },
  )

  watch([geoGebraFile.data, apiRef], ([data, api]) => {
    if (data && api) {
      api.setBase64(data)
    }
  })

  return {
    appletId,
    appName: params?.appName,
    api: apiRef,
  }
}
