import type { Plugin } from '~/models/Plugin'
import { createApp } from 'vue'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import { KsTooltip } from '@aschehoug/kloss'
import '~/styles/index.css'
import disableTabindex from '~/directives/disable-tabindex'
import App from '~/App.vue'
import FileDownloadBlock from '~/components/utils/FileDownloadBlock.vue'
import TipBlock from '~/components/TipBlock.vue'
import GeoGebraApplet from '~/components/geogebra/GeoGebraApplet.vue'
import Glossary from '~/components/customTags/Glossary.vue'
import EmbedBot from '~/components/chat/EmbedBot.vue'
import '../__setups__/luxonSetup'

const app = createApp(App)
app.use(VueDOMPurifyHTML, {
  default: {
    USE_PROFILES: { html: true },
  },
})
app.directive('ks-tooltip', KsTooltip)
app.directive<HTMLElement, { delay?: number } | undefined>('focus', {
  mounted: (el, binding) => {
    if (binding.value?.delay) {
      return setTimeout(() => {
        el.focus()
      }, binding.value.delay)
    }

    el.focus()
  }
})
app.directive('disable-tabindex', disableTabindex)
const plugins = Object.values(import.meta.glob('./plugins/*.ts', { eager: true })) as Plugin[]
plugins.forEach((p) => p.install(app))
app.component('TipBlock', TipBlock)
app.component('FileDownloadBlock', FileDownloadBlock)
app.component('Glossary', Glossary)
app.component('EmbedBot', EmbedBot)
app.component('GeoGebraApplet', GeoGebraApplet)

app.mount('#app')
