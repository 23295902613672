import { ValidateResult } from 'async-validator'
import type { Reactive } from 'vue'

export interface ValidationError {
	message: string
	isError: true
}

export interface ValidationSuccess {
	message: '',
	isError: false
}

export interface ValidationRule {
	rule: (input: string | number) => boolean,
	message: string,
}

export interface ValidationOptions {
	rules: ValidationRule[]
}

export type ValidateForm<T> = Reactive<{
	[key in keyof T]: T[keyof T]
}>

export type ValidateFormRules<T> = {
	[key in keyof T]: ValidationRule[]
}

export type ValidateFormResults<T> = {
	[key in keyof T]: Array<ValidationError | ValidationSuccess>
}

export type IsValid = {
	isValid: boolean
}

export type ValidateFormFunction = <T>(
	form: ValidateForm<T>,
	rules: ValidateFormRules<T>,
	result: ValidateFormResults<T> & IsValid,
) => ValidateFormResults<T> & IsValid

export const useValidation = () => {
	const rules = {
		string: () => (input: string | number) =>
			String(input).match(new RegExp(/[^a-zA-ZæøåÆØÅ0-9\.\,\@\!\?\|\§\#\<\>\[\]\-\+\'\*\"\^\`\:\;\s]/gm)) === null,
		required: () => (input: string | number) =>
			String(input).length > 0,
		min: (num: number) => (input: string | number) =>
			typeof input === 'string' ? input.length >= num : input >= num,
		max: (num: number) => (input: string | number) =>
			typeof input === 'string' ? input.length <= num : input <= num,
	}

	const validate = (input: string | number, rule: ValidationRule): ValidationError | ValidationSuccess => {
		return rule.rule(input) ? { isError: false, message: '' } : { isError: true, message: rule.message }
	}

	const validateForm: ValidateFormFunction = <T>(
		form: ValidateForm<T>,
		rules: ValidateFormRules<T>,
		result: ValidateFormResults<T> & IsValid
	): ValidateFormResults<T> & IsValid => {
		let isValid = true
		for (const key of Object.keys(form)) {
			(result as ValidateFormResults<T>)[key as keyof ValidateFormResults<T>] = rules[key as keyof T]
				.map((rule) => validate(form[key as keyof ValidateForm<T>] as string | number, rule))
			isValid = isValid && (result as ValidateFormResults<T>)[key as keyof ValidateFormResults<T>].every((result) => !result.isError)
		}
		result.isValid = isValid
		return result
	}

	return {
		rules,
		validate,
		validateForm,
		validateString: (input: string | number) =>
			validate(input, { rule: rules.string(), message: 'Dissallowed character detected'}),
	}
}
